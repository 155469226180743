import Vue from 'vue';
import { helpers } from '@/utility';
import apiServiceAsync from '@/api/apiServiceAsync';

async function getContent(fileUrl) {
  const api = await apiServiceAsync();
  const route = Vue.router?.currentRoute;
  let content;
  // For unprotected adult document upload view handle serve document properly
  if (route.name === 'AdultDocumentsUploadForm') {
    const { uuid, token } = route.params;
    if (uuid && token) {
      const serveFileUrl = fileUrl.startsWith('/') ? fileUrl.substring(1) : fileUrl;
      content = await api.get(`/api/v1/applications/adult-serve-document/${uuid}/${token}/${encodeURIComponent(serveFileUrl)}`, { responseType: 'arraybuffer', timeout: 0 })
        .catch(() => {
          Vue.notify({
            type: 'danger',
            text: Vue.options.filters.translate('unprotected_access_not_allowed'),
          });
        });
    } else console.error('Error: Missing url params \'uuid\' and \'token\'.');
  } else if (/^\/?media\//.test(fileUrl)) content = await api.get(`serve-media-files?url=${encodeURIComponent(fileUrl)}`, { responseType: 'arraybuffer', timeout: 0 });
  else {
    const fileExtension = helpers.getFileExtension(fileUrl);
    let responseType;

    switch (fileExtension) {
      case 'heic':
        responseType = '';
        break;
      default:
        responseType = 'arraybuffer';
        break;
    }
    content = await api.get(fileUrl, { responseType, timeout: 0 });
  }
  return content;
}

export default {
  download(fileUrl) {
    if (fileUrl) {
      return getContent(fileUrl).then((response) => {
        const blob = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        const link = document.createElement('a');
        link.href = blob;
        link.download = fileUrl.substr(fileUrl.lastIndexOf('/') + 1);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          link.remove();
          window.URL.revokeObjectURL(blob);
        }, 100);
      });
    }
    return Promise.reject(new Error('File url is required'))
      .then(() => {}, (e) => e);
  },
  downloadFile(file, fileName, contentType) {
    if (!file || !fileName || !contentType) return;
    const blob = window.URL.createObjectURL(new Blob([file], { type: contentType }));
    const link = document.createElement('a');
    link.href = blob;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      link.remove();
      window.URL.revokeObjectURL(blob);
    }, 100);
  },
  content(fileUrl) {
    return getContent(fileUrl);
  },
};
