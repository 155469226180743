import Vue from 'vue';
import apiServiceAsync from '@/api/apiServiceAsync';
import { pdf } from '@/utility';

const api = await apiServiceAsync();
export default {
  getDatepickerAndStatusData() {
    return api.get('/api/v1/appointments/init').then((response) => response.data);
  },
  getBookedAppointmentsTables(state, params) {
    return api.get('api/v1/appointments/with-participants', { params }).then((response) => response.data);
  },
  getSingleBookedAppointmentsTable(state, params) {
    return api.get('api/v1/appointments/with-participants', { params }).then((response) => response.data);
  },
  getCreateAppointmentsData(state, objectId) {
    const url = objectId ? `/api/v1/appointments/apartments/${objectId}/create` : '/api/v1/appointments/create';

    return api.get(url).then((response) => response.data);
  },
  createAppointments(state, params) {
    return api.post('/api/v1/appointments/create', params).then((response) => response.data);
  },
  getEditAppointment(state, appointmentId) {
    return api.get(`/api/v1/appointments/${appointmentId}/edit`).then((response) => response.data);
  },
  editAppointment(state, { appointmentId, params }) {
    return api.post(`/api/v1/appointments/${appointmentId}/edit`, params).then((response) => response.data);
  },
  deleteAppointment(state, appointmentId) {
    return api.post(`/api/v1/appointments/${appointmentId}/delete`).then((response) => response.data);
  },
  getMailCancelAppointment(state, appointmentId) {
    return api.get(`/api/v1/appointments/${appointmentId}/cancel`).then((response) => response.data);
  },
  sendMailCancelAppointment(state, { appointmentId, params }) {
    return api.post(`/api/v1/appointments/${appointmentId}/cancel`, params).then((response) => response.data);
  },
  getMailInterestedPeople(state, appointmentId) {
    return api.get(`/api/v1/appointments/${appointmentId}/send-mail`).then((response) => response.data);
  },
  sendMailInterestedPeople(state, { appointmentId, params }) {
    return api.post(`/api/v1/appointments/${appointmentId}/send-mail`, params).then((response) => response.data);
  },
  downloadInterestedPeople(state, appointmentId) {
    Vue.notify.info({
      id: appointmentId,
      text: Vue.options.filters.translate('admin_participant_list_downloading'),
      duration: 60000,
    });

    return api.post(`/api/v1/appointments/${appointmentId}/export-participants`, null, { responseType: 'arraybuffer', timeout: 0 })
      .then((response) => {
        const fileName = response.headers['content-disposition'].split('filename="')[1].slice(0, -1);
        const contentType = response.headers['content-type'];
        pdf.downloadFile(response.data, fileName, contentType);
      }).catch((err) => {
        if (err?.response?.data?.notify) state.dispatch('notify', err.response.data.notify, { root: true });
        else Vue.notify.danger(err.stack.split('\n', 1).join('').slice(6));
      }).finally(() => {
        Vue.notify.close(appointmentId);
      });
  },
  addParticipantComment(state, { appointmentId, participantId, params }) {
    return api.post(`/api/v1/appointments/${appointmentId}/${participantId}/comments`, params).then((response) => response.data);
  },
  changeParticipantPresence(state, { appointmentId, participantId, params }) {
    return api.post(`/api/v1/appointments/${appointmentId}/${participantId}/present`, params).then((response) => response.data);
  },
  finishAppointment(state, { appointmentId, params }) {
    return api.post(`/api/v1/appointments/${appointmentId}/complete`, params).then((response) => response.data);
  },
  getGeneralAppointmentsForBooking(state, interestedPersonSlug) {
    const params = {
      participant_slug: interestedPersonSlug,
    };
    return api.get('/api/v1/appointments/public/without-object/booking', { params }).then((response) => response.data);
  },
  getObjectAppointmentsForBooking(state, { objectSlug, interestedPersonSlug }) {
    const params = {
      participant_slug: interestedPersonSlug,
    };
    return api.get(`/api/v1/appointments/public/with-object/${objectSlug}/booking`, { params }).then((response) => response.data);
  },
  bookAppointment(state, { appointmentSlug, interestedPersonSlug }) {
    return api.post(`/api/v1/appointments/public/${appointmentSlug}/${interestedPersonSlug}/booking`).then((response) => response.data);
  },
  bookAppointmentWithContact(state, { appointmentSlug, params }) {
    return api.post(`/api/v1/appointments/public/${appointmentSlug}/booking`, params).then((response) => response.data);
  },
  getCancelBooking(state, { appointmentSlug, interestedPersonSlug }) {
    return api.get(`/api/v1/appointments/public/${appointmentSlug}/${interestedPersonSlug}/decline`).then((response) => response.data);
  },
  cancelBooking(state, { appointmentSlug, interestedPersonSlug, cancelReason }) {
    return api.post(`/api/v1/appointments/public/${appointmentSlug}/${interestedPersonSlug}/decline`, { decline_reason: cancelReason }).then((response) => response.data);
  },
  downloadIcal(state, appointmentSlug) {
    Vue.notify.info({
      id: appointmentSlug,
      text: Vue.options.filters.translate('admin_ical_downloading'),
      duration: 60000,
    });

    return api.post(`/api/v1/appointments/public/${appointmentSlug}/export-ical`, null, { responseType: 'arraybuffer', timeout: 0 })
      .then((response) => {
        const fileName = response.headers['content-disposition'].split('filename="')[1].slice(0, -1);
        const contentType = response.headers['content-type'];
        pdf.downloadFile(response.data, fileName, contentType);
      }).catch((err) => {
        if (err?.response?.data?.notify) state.dispatch('notify', err.response.data.notify, { root: true });
        else Vue.notify.danger(err.stack.split('\n', 1).join('').slice(6));
      }).finally(() => {
        Vue.notify.close(appointmentSlug);
      });
  },
  getEditAppointmentPublic(state, appointmentSlug) {
    return api.get(`/api/v1/appointments/public/${appointmentSlug}/edit`).then((response) => response.data);
  },
  addParticipantCommentPublic(state, { appointmentSlug, participantSlug, params }) {
    return api.post(`/api/v1/appointments/public/${appointmentSlug}/${participantSlug}/comments`, params).then((response) => response.data);
  },
  changeParticipantPresencePublic(state, { appointmentSlug, participantSlug, params }) {
    return api.post(`/api/v1/appointments/public/${appointmentSlug}/${participantSlug}/present`, params).then((response) => response.data);
  },
  finishAppointmentPublic(state, { appointmentSlug, params }) {
    return api.post(`/api/v1/appointments/public/${appointmentSlug}/complete`, params).then((response) => response.data);
  },
  downloadInterestedPeoplePublic(state, appointmentSlug) {
    Vue.notify.info({
      id: appointmentSlug,
      text: Vue.options.filters.translate('admin_participant_list_downloading'),
      duration: 60000,
    });

    return api.post(`/api/v1/appointments/public/${appointmentSlug}/export-participants`, null, { responseType: 'arraybuffer', timeout: 0 })
      .then((response) => {
        const fileName = response.headers['content-disposition'].split('filename="')[1].slice(0, -1);
        const contentType = response.headers['content-type'];
        pdf.downloadFile(response.data, fileName, contentType);
      }).catch((err) => {
        if (err?.response?.data?.notify) state.dispatch('notify', err.response.data.notify, { root: true });
        else Vue.notify.danger(err.stack.split('\n', 1).join('').slice(6));
      }).finally(() => {
        Vue.notify.close(appointmentSlug);
      });
  },
  postRandomInterestedPeopleGenerator(state, { objectId, params }) {
    return api.post(`/api/v1/appointments/apartments/${objectId}/random-generator`, params).then((response) => response.data);
  },
  postRandomApplicantsGenerator(state, { objectId, payload }) {
    return api.post(`/api/v1/applications/random-generator/${objectId}`, payload);
  },
  addAppointmentToInterested(state, { interestedPersonId, params }) {
    return api.post(`/api/v1/appointments/applications/${interestedPersonId}`, params).then((response) => response.data);
  },
};
