<template>
  <MdtModalConfirmation
    :title="'admin_activate_application' | translate"
    :info-msg="infoMsg"
    :btn-text="'admin_activate' | translate"
    type="success"
    @close="$emit('close')"
    @confirm="activateApplications">
    <MdtLoader v-if="loading" />
  </MdtModalConfirmation>
</template>

<script>
import { mixinRefreshTableOrDetailView } from '@/mixins';

export default {
  name: 'ModalActivateApplication',
  mixins: [mixinRefreshTableOrDetailView],
  props: {
    selectedRows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    infoMsg() {
      return this.loading
        ? ''
        : this.$options.filters.translate('admin_activate_reason_message');
    },
  },
  methods: {
    activateApplications() {
      if (this.loading) return;

      const params = {
        targets: this.selectedRows,
        bulkAction: 'activate',
      };

      this.loading = true;
      this.$store.dispatch('table/activateApplications', params).then((data) => {
        this.$store.dispatch('notify', data.notify);
        this.$emit('close');
        // Refresh data properly
        this.mixinRefreshTableOrDetailView(true);
      }).catch(this.showResponseError)
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
