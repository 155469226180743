<template>
  <div class="table-td-button-check">
    <MdtCheckbox
      v-if="editable && item.editable"
      v-model="inputValue"
      class="checkbox" />
    <MdtButton
      v-else-if="value"
      :icon="'fas fa-check'"
      class="check-button" />
  </div>
</template>

<script>
export default {
  name: 'TableTdButtonCheck',
  props: {
    editable: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputValue: false,
    };
  },
  watch: {
    editable(value) {
      if (value === false) this.resetValue();
    },
  },
  mounted() {
    this.resetValue();
  },
  methods: {
    resetValue() {
      this.inputValue = this.value;
    },
    saveValue() {
      this.$emit('input', this.inputValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox ::v-deep {
  input {
    &:checked ~ .state label:before {
      border-color: $color-success;
    }

    &:checked ~ .state label:after {
      background-color: $color-success;
      border-color: $color-success;
    }

    &:not(:checked) ~ .state label {
      opacity: 0.5;

      &:before {
        border-color: $color-back-basic;
      }

      &:after {
        background-color: $color-back-basic;
        border-color: $color-back-basic;
      }
    }
  }

  label:before,
  label:after {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}

.check-button ::v-deep {
  .button {
    width: 20px;
    height: 20px;
    background-color: $color-success !important;
    opacity: 0.5;

    i {
      font-size: 12px;
      line-height: 21px;
    }
  }
}
</style>
