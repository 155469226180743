var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mdt-input",class:[{
    'has-error': _vm.inputHasError,
    disabled: _vm.disabled,
    readonly: _vm.readonly,
  }, ("input-" + _vm.size)]},[(_vm.label)?_c('div',{staticClass:"input-label"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.label))]),(!_vm.required && !_vm.hideOptional)?_c('span',{staticClass:"input-label-optional"},[_vm._v(" ("+_vm._s(_vm._f("translate")('admin_marketing_optional'))+") ")]):_vm._e(),(_vm.required)?_c('i',{staticClass:"fa-asterisk field-required"}):_vm._e()]),(_vm.tooltip)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip),expression:"tooltip"}],staticClass:"far fa-info-circle info-icon"}):_vm._e()]):_vm._e(),_c('div',{staticClass:"input-wrapper",class:{ 'has-icon': !!_vm.$slots['icon'] }},[_c('input',{directives:[{name:"overflow-tooltip",rawName:"v-overflow-tooltip"},{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.type !== 'password' ? _vm.value : '',
        trigger: 'manual',
      }),expression:"{\n        content: type !== 'password' ? value : '',\n        trigger: 'manual',\n      }"}],ref:"input",staticClass:"text-cut",attrs:{"title":"","type":['date', 'time', 'timeWithSeconds'].includes(_vm.type) ? 'text' : _vm.type,"required":_vm.required,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readonly,"step":_vm.step,"autocomplete":"new-password","size":_vm.inputSize !== null ? _vm.inputSize : 20},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('keyupEnter', _vm.value)},"click":function($event){return _vm.$emit('clickInput')}}}),_vm._t("icon")],2),(_vm.clientErrors.length || _vm.serverErrors.length)?_c('div',{staticClass:"input-errors"},[_c('span',{staticClass:"client-errors"},[_vm._v(" "+_vm._s(_vm.clientErrors.join('\n'))+" "+_vm._s(_vm.clientErrors.length && _vm.serverErrors.length ? '\n' : '')+" ")]),_c('span',{staticClass:"server-errors"},[_vm._v(" "+_vm._s(_vm.serverErrors.join('\n'))+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }