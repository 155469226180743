export default {
  getTableData: {
    applications: {
      // [route-name]: [api-path]
      Applications: 'applications/',
    },
    objects: {
      // [route-name]: [api-path]
      Apartments: 'objects/apartments',
      Parking: 'objects/parkingspace',
      Outbuildings: 'objects/other',
      Business: 'objects/craft',
      Buildings: 'buildings/',
      Plots: 'objects/plot',
    },
    tenants: {
      // [route-name]: [api-path]
      Tenant: 'tenants/',
    },
    interestedPeople: {
      // [route-name]: [api-path]
      InterestedPeople: 'applications/interested',
      InterestedPeopleB2B: 'investors/interested',
    },
    budget: {
      // [route-name]: [api-path]
      CostPlanning: 'reporting/costs',
      FeeCalculation: 'reporting/honorary',
    },
    waitlist: {
      // [route-name]: [api-path]
      Waitlist: 'waitlist/applications/',
    },
    investors: {
      // [route-name]: [api-path]
      Investors: 'investors/',
    },
    projects: {
      // [route-name]: [api-path]
      Projects: 'b2b/objects/',
    },
  },
  mappedObjectPaths: {
    // [route-name]: [api-path]
    Apartments: 'apartment',
    Parking: 'parkingspace',
    Outbuildings: 'other',
    Business: 'craft',
    Plots: 'plot',
  },
};
