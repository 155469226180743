export default {
  methods: {
    mixinRefreshTableOrDetailView(preventUpdateTableRow) {
      // if route is detail view -> refresh detail view properly
      if (this.$route.name === 'ApartmentDetails') {
        this.$store.dispatch('apartments/getAllData', this.$route.params).catch(this.showResponseError);
      } else if (this.$route.name === 'ApplicationDetails') {
        this.$store.dispatch('applications/getAllData', this.$route.params).catch(this.showResponseError);
      } else if (this.$route.name === 'TenantDetails') {
        this.$store.dispatch('tenants/getAllData', this.$route.params).catch(this.showResponseError);
      } else if (this.$route.name === 'WaitlistDetails') {
        this.$store.dispatch('waitlist/getAllData', this.$route.params).catch(this.showResponseError);
      } else if (this.$route.name === 'InvestorDetails') {
        this.$store.dispatch('investors/getAllData', this.$route.params).catch(this.showResponseError);
      } else if (this.$route.name === 'ProjectDetails') {
        this.$store.dispatch('projects/getAllData', this.$route.params).catch(this.showResponseError);
      } else if (!preventUpdateTableRow && this.$store.state.sidepanel?.tableRowId) {
        // if route is not detail view -> refresh table row if tableRowId exists
        this.$store.dispatch('table/refreshRow', this.$store.state.sidepanel.tableRowId);
      } else { // refresh table data
        this.$store.dispatch('table/getTableData');
      }
    },
  },
};
