<template>
  <div
    v-if="viewReady"
    class="template-default">
    <MdtLightbox />
    <MdtNotify />
    <div
      class="main-wrapper"
      :class="{ 'navigation-collapsed': menuCollapsed }">
      <div
        class="main-navigation-left"
        :class="{ collapsed: menuCollapsed, hovered: collapsedHovered }">
        <NavigationLeft
          :menu-collapsed="menuCollapsed"
          :collapsed-hovered="collapsedHovered" />
      </div>
      <div
        class="main-center-container"
        :class="{ 'main-navigation-collapsed': menuCollapsed }">
        <div
          class="main-navigation-top"
          :class="{ 'main-navigation-collapsed': menuCollapsed }">
          <NavigationTop />
        </div>
        <div class="main-container">
          <RouterView
            class="main-content-router"
            @openSidepanel="openSidepanel" />
        </div>
      </div>
    </div>
    <transition name="slide-left">
      <MdtSidepanel v-if="!!sidepanel.key" />
    </transition>
  </div>
  <div
    v-else
    class="view-not-ready flex-center">
    <MdtLoader />
  </div>
</template>

<script>
import Vue from 'vue';
import apiPointMaps from '@/store/model/api-point-maps/apiPointMaps';
import NavigationLeft from '@/components/navigation-left/NavigationLeft.vue';
import NavigationTop from '@/components/navigation-top/NavigationTop.vue';
import MdtSidepanel from '@/components/shared/sidepanel/MdtSidepanel.vue';
import MdtLightbox from '@/components/shared/MdtLightbox.vue';
import { MELON_LANGUAGE_DE } from '@/utility/constants';
import { ls } from '@/utility';

export default {
  name: 'DashboardView',
  components: {
    NavigationLeft,
    NavigationTop,
    MdtSidepanel,
    MdtLightbox,
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.dispatch('menu/updatePrevRoutes', from);
    this.$store.dispatch('sidepanel/close');
    this.$nextTick(next);
  },
  data() {
    const { translate } = this.$options.filters;
    return {
      viewReady: false,
      translate,
    };
  },
  computed: {
    sidepanel() {
      return this.$store.state.sidepanel;
    },
    menuCollapsed() {
      return this.$store.getters['menu/mainMenuCollapsed'];
    },
    collapsedHovered() {
      return this.$store.getters['menu/mainMenuCollapsedHovered'];
    },
    language() {
      return this.$store.getters.language;
    },
    projectType() {
      return this.$store.getters.projectType;
    },
  },
  watch: {
    $route(to, from) {
      // don't reload table data if we didn't changed route name
      // changing table state filters will change router query params and refresh table data only
      // and without this check it will reload whole table data and reset all filters etc.
      if (to.name !== from.name) this.getTableData(to);
    },
  },
  created() {
    const user = ls.user.get();
    let colorTheme = '';
    if (!this.$store.state.bootstrap.bootstrapSet) {
      // GET UTC timestamp
      this.$store.dispatch('getBootstrap')
        .then(() => {
          this.viewReady = true;
          this.getTableData(this.$route);
          if (this.language === MELON_LANGUAGE_DE) {
            this.$freshdeskHelpWidget.init();
          }
        });
    } else {
      this.viewReady = true;
      this.getTableData(this.$route);
    }
    switch (this.projectType) {
      case 'reletting':
        colorTheme = '#36ccc4';
        break;
      case 'first-time-letting':
        colorTheme = '#5cdbae';
        break;
      case 'saleb2b':
        colorTheme = '#26A69A';
        break;
      case 'sale':
        colorTheme = '#0a7ee8';
        break;
      default:
        colorTheme = '#36ccc4';
        break;
    }

    // Load vitally only in production mode
    const { environment } = Vue.prototype.$config;
    if (environment && environment.startsWith('prod-')) {
      // Initialize Vitally NPS
      this.$vitally.init();
      this.$vitally.nps(this.translate, user, colorTheme);
    }
  },
  beforeDestroy() {
    this.$vitally.destroy();
  },
  methods: {
    getTableData(route) {
      // Get table data depending on route
      let tableRouteMapping = {};
      Object.keys(apiPointMaps.getTableData).forEach((key) => {
        tableRouteMapping = { ...tableRouteMapping, ...apiPointMaps.getTableData[key] };
      });
      const path = tableRouteMapping[route.name];

      if (path) {
        this.$store.dispatch('table/getTableData', path);
      }
    },
    openSidepanel(settings) {
      let params = {};
      const applicationId = settings.row ? settings.row._id : this.$route.params.id;
      switch (settings.key) {
        case 'ApplicationAdult': {
          params = {
            id: settings.additional.adultId,
            applicationId,
          };
          break;
        }
        case 'ApartmentEdit':
        case 'ApartmentOffer': {
          params = { id: settings.additional.apartmentId };
          break;
        }
        case 'ApplicationEdit':
        case 'ApplicationEvaluation':
        case 'ApplicationOffer': {
          params = { id: settings.additional.applicationId };
          break;
        }
        case 'ApplicationChild': {
          params = {
            id: settings.additional.childId,
            applicationId,
          };
          break;
        }
        case 'ApplicationOfferDeclined': {
          params = {
            apartmentId: settings.additional.apartmentId,
            applicationId: settings.additional.applicationId,
          };
          break;
        }
        case 'BudgetCostPlanEdit':
        case 'BudgetCostCategoryEdit':
        case 'ApplicationTotalPoints':
        case 'BuildingEdit': {
          params = { id: settings.row._id };
          break;
        }
        case 'Messenger':
          params = settings.additional;
          break;
        case 'InvestorEdit':
        case 'InterestedPersonB2BEdit':
          params = { id: settings.additional.value };
          break;
        case 'InvestorOffer':
          params = { id: settings.row._apply_id }; // eslint-disable-line no-underscore-dangle
          break;
        default: {
          params = { id: settings.additional.id };
        }
      }

      // Open sidepanel
      this.$store.dispatch('sidepanel/open', {
        key: settings.key,
        params,
        tableRowId: settings?.row?._id,
      });
    },
  },
};
</script>

<style>
/* vitally custom feedback checkmark css */
.Vitally--ScorePicker-check svg circle {
  stroke-dasharray: 166px !important;
  stroke-dashoffset: 166px !important;
  stroke-width: 2 !important;
  stroke-miterlimit: 10 !important;
  stroke: #00b184 !important;
  fill: none !important;
  animation: Vitally--AnimatedCheck-stroke .6s cubic-bezier(0.65, 0, 0.45, 1) forwards !important;
}

.Vitally--ScorePicker-check svg {
  border-radius: 50% !important;
  display: block !important;
  stroke-width: 2 !important;
  stroke: #fff !important;
  stroke-miterlimit: 10 !important;
  margin: 0 auto !important;
  animation: Vitally--AnimatedCheck-fill .4s ease-in-out .4s forwards,Vitally--AnimatedCheck-scale .3s ease-in-out .9s both !important;
}

.Vitally--ScorePicker-check svg:not(.Vitally--AnimatedCheck-large) {
  width: 45px !important;
  height: 45px !important;
  animation: Vitally--AnimatedCheck-fill .4s ease-in-out .4s forwards,Vitally--AnimatedCheck-scale .3s ease-in-out .9s both !important;
}

.Vitally--ScorePicker-check svg.Vitally--AnimatedCheck-large {
  width: 75px !important;
  height: 75px !important;
  animation: Vitally--AnimatedCheck-fill-large .4s ease-in-out .4s forwards,Vitally--AnimatedCheck-scale .3s ease-in-out .9s both !important;
}

.Vitally--ScorePicker-check svg path {
  transform-origin: 50% 50% !important;
  stroke-dasharray: 48px !important;
  animation: Vitally--AnimatedCheck-stroke .3s cubic-bezier(0.65, 0, 0.45, 1) .8s forwards !important;
}

@keyframes Vitally--AnimatedCheck-stroke {
  100% {
    stroke-dashoffset: 0px;
  }
}

@keyframes Vitally--AnimatedCheck-scale {
  0%, 100% {
    -webkit-transform: none;
    transform: none;
  }

  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes Vitally--AnimatedCheck-fill {
  100% {
    -webkit-box-shadow: inset 0px 0px 0px 30px #00b184;
    box-shadow: inset 0px 0px 0px 30px #00b184;
  }
}

@keyframes Vitally--AnimatedCheck-fill-large {
  100% {
    -webkit-box-shadow: inset 0px 0px 0px 50px #00b184;
    box-shadow: inset 0px 0px 0px 50px #00b184;
  }
}
</style>

<style lang="scss" scoped>
.view-not-ready {
  height: 100vh;
}

.main-container {
  .main-content-router {
    width: 100%;
    transition-duration: .4s;
    transition-property: width;
    transition-timing-function: ease-out;
  }
}
</style>
