import {
  Chart,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  BarController,
  LineController,
  DoughnutController,
  RadarController,
  BubbleController,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  Legend,
  Filler,
  Tooltip,
} from 'chart.js';
import { merge } from 'chart.js/helpers';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import color from '@/utility/color';
import variables from '@/scss/abstract/_variablesExport.module.scss';

const {
  analyticsColorWhite,
  analyticsColorBlack,
  analyticsColorGray,
  analyticsColorGray2,
  analyticsColorGray3,
  analyticsColorDarkBlue,
  analyticsColorBlue,
  analyticsColorPurple,
  analyticsColorDarkGreen,
  analyticsColorGreenishYellow,
  analyticsColorRed,
  analyticsColorGreen,
  analyticsColorPaleGreeen,
  analyticsColorYellow,
  analyticsColorPaleBlue,
  colorTextSecondary,
} = variables;

let defaultsSet = false;
export default {
  defaultBackgroundColor: analyticsColorDarkBlue,
  defaultBorderColor: analyticsColorBlue,
  datasetColors: {
    darkBlue: analyticsColorDarkBlue,
    blue: analyticsColorBlue,
    purple: analyticsColorPurple,
    darkGreen: analyticsColorDarkGreen,
    greenishYellow: analyticsColorGreenishYellow,
    red: analyticsColorRed,
    green: analyticsColorGreen,
    paleGreen: analyticsColorPaleGreeen,
    yellow: analyticsColorYellow,
    paleBlue: analyticsColorPaleBlue,
  },
  datalabelColors: {
    default: analyticsColorWhite,
    black: analyticsColorBlack,
    [analyticsColorDarkBlue]: analyticsColorWhite,
    [analyticsColorBlue]: analyticsColorWhite,
    [analyticsColorPurple]: analyticsColorWhite,
    [analyticsColorDarkGreen]: analyticsColorWhite,
    [analyticsColorGreenishYellow]: analyticsColorWhite,
    [analyticsColorRed]: analyticsColorBlack,
    [analyticsColorGreen]: analyticsColorBlack,
    [analyticsColorPaleGreeen]: analyticsColorBlack,
    [analyticsColorYellow]: analyticsColorBlack,
    [analyticsColorPaleBlue]: analyticsColorBlack,
  },
  getDatasetColor(col) {
    return this.datasetColors[col] || this.defaultBackgroundColor;
  },
  getDatalabelColor(backgroundColor) {
    return this.datalabelColors[backgroundColor] || this.datalabelColors.default;
  },
  setDefaults() {
    if (!defaultsSet) {
      const { defaults, overrides, register } = Chart;
      const colorTextSecondaryHex = colorTextSecondary.split(' ')[1].slice(0, -1);

      register(
        BarElement,
        LineElement,
        PointElement,
        ArcElement,
        BarController,
        LineController,
        DoughnutController,
        RadarController,
        BubbleController,
        CategoryScale,
        LinearScale,
        RadialLinearScale,
        Legend,
        Filler,
        ChartDataLabels,
        Tooltip,
      );

      // Defaults
      merge(defaults, {
        maintainAspectRatio: false,
        hover: { mode: null },
        font: { family: 'Lato' },
        backgroundColor: this.defaultBackgroundColor,
        borderColor: this.defaultBorderColor,
        layout: {
          // Remove bottom padding added by legend
          padding: { bottom: ({ chart }) => (chart.options.plugins.legend.display ? -9 : 0) },
        },
        scale: {
          grid: {
            drawBorder: false,
            color: analyticsColorGray2,
            tickLength: 10,
            tickColor: 'transparent',
          },
          ticks: {
            color: colorTextSecondaryHex,
            padding: 0,
          },
        },
        scales: {
          category: {
            grid: { display: false },
            ticks: {
              // Set lineHeight to 0 for Y category scale
              font: { lineHeight: ({ chart }) => (chart.options.indexAxis === 'y' ? 0 : defaults.font.lineHeight) },
            },
          },
          radialLinear: {
            grid: {
              // Style last grid line different
              color: (context) => (context.index === context.scale.ticks.length - 2
                ? analyticsColorGray3 : analyticsColorGray),
              lineWidth: (context) => (context.index === context.scale.ticks.length - 2 ? 1 : 8),
            },
            ticks: { display: false },
            pointLabels: { color: colorTextSecondaryHex },
            angleLines: { color: analyticsColorGray3 },
          },
        },
        elements: {
          line: { borderWidth: 2 },
          point: { radius: 0 },
        },
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              boxWidth: 10,
              padding: 15,
              usePointStyle: true,
              pointStyle: 'rectRounded',
              color: analyticsColorBlack,
            },
            onClick: () => {},
          },
          datalabels: {
            offset: 0,
            color: this.datalabelColors.default,
            textStrokeWidth: 2,
            textStrokeColor: this.defaultBackgroundColor,
            font: {
              size: 10,
              weight: 'bold',
            },
            tooltip: {
              enabled: true,
            },
          },
        },
      });

      merge(overrides, {
        bar: {
          barThickness: 12,
          borderRadius: 4,
          plugins: {
            datalabels: { anchor: 'start' },
          },
        },
        doughnut: {
          plugins: {
            legend: { onClick: () => {} },
          },
        },
        bubble: {
          borderWidth: 2,
          scales: {
            x: {
              grid: { display: false },
            },
          },
        },
      });

      // Custom plugins
      register({
        // Set tickLength to 20 for Y scale
        id: 'tickLengthScaleY',
        beforeInit(chart) {
          if (chart.options.scales?.y?.grid) {
            chart.options.scales.y.grid.tickLength = 20;
          }
        },
      });

      // Export doughnut original generateLabels function
      this.doughnutGenerateLabelsOriginal = Chart.overrides.doughnut
        .plugins.legend.labels.generateLabels;

      this.heatMapBackgroundColors = {
        low: `rgba(${color.hexToRgb(analyticsColorBlue)}, 0.2)`,
        medium: `rgba(${color.hexToRgb(analyticsColorBlue)}, 0.4)`,
        high: `rgba(${color.hexToRgb(analyticsColorBlue)}, 0.6)`,
      };

      defaultsSet = true;
    }
  },
};
