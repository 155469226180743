import apiServiceAsync from '@/api/apiServiceAsync';

export default {
  async upload(path, formData, onUploadProgress) {
    const api = await apiServiceAsync();
    return api.post(path, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
      onUploadProgress,
    }).then((response) => response.data);
  },
};
