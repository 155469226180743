export default function formatDate(value, format, valueFormat) {
  if (value) {
    let date;
    if (value instanceof Date) date = value;
    else date = value.isValid ? value : String(value);

    return moment(date, valueFormat).format(format || 'DD.MM.YYYY');
  }

  return value;
}
