<template>
  <div class="navigation-left-logo">
    <img
      class="logo-image pointer"
      :src="logoImg"
      @click="goToMain">
  </div>
</template>

<script>
import logoBuild from '@/assets/images/logo/logo-build.svg';
import logoMarket from '@/assets/images/logo/logo-market.svg';
import logoRent from '@/assets/images/logo/logo-rent.svg';
import logoSale from '@/assets/images/logo/logo-sale.svg';
import logoInvest from '@/assets/images/logo/logo-invest.svg';
import logoNegativeBuild from '@/assets/images/logo/logo-negative-build.svg';
import logoNegativeMarket from '@/assets/images/logo/logo-negative-market.svg';
import logoNegativeRent from '@/assets/images/logo/logo-negative-rent.svg';
import logoNegativeSale from '@/assets/images/logo/logo-negative-sale.svg';
import logoNegativeInvest from '@/assets/images/logo/logo-negative-invest.svg';

export default {
  name: 'NavigationLeftLogo',
  props: {
    logoName: {
      type: String,
      default: 'simple',
      validator: (value) => {
        const match = ['simple', 'negative'];
        return match.includes(value);
      },
    },
  },
  data() {
    return {
      logos: {
        simple: {
          build: logoBuild,
          new: logoMarket,
          market: logoMarket,
          rent: logoRent,
          sale: logoSale,
          invest: logoInvest,
        },
        negative: {
          build: logoNegativeBuild,
          new: logoNegativeMarket,
          market: logoNegativeMarket,
          rent: logoNegativeRent,
          sale: logoNegativeSale,
          invest: logoNegativeInvest,
        },
      },
    };
  },
  computed: {
    projectType() {
      return this.$store.getters.projectType;
    },
    projectTheme() {
      return this.$store.getters.projectTheme;
    },
    logoImg() {
      if (!this.projectType) return '';

      return this.logos[this.logoName][this.projectTheme];
    },
  },
  methods: {
    goToMain() {
      this.$router.push('/');
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-left-logo {
  display: flex;
  align-items: center;
}

.logo-image {
  width: 210px;
  display: block;
  margin: 0 auto;
}
</style>
