export default {
  searchVal: '',
  basicFilters: {},
  selectedItem: {},
  list: {
    items: [],
    pagination: {},
    filters: [],
    sortSettings: {},
    bulkExtraOptionsV2: [],
    mailCounter: {},
  },
  inbox: {
    loaded: false,
    tableData: {
      body: [],
    },
    pagination: {},
  },
  sendingMail: false,
  loading: false,
  mailbox: 0,
};
