<template>
  <div class="table-random-generator-activated">
    <vue-scroll :ops="vueScrollOptions">
      <div
        v-for="message in randomApplicantsViewing.messages"
        :key="message.key">
        {{ message.displayName }}
      </div>
    </vue-scroll>
  </div>
</template>

<script>
export default {
  name: 'TableRandomApplicantsGeneratorActivated',
  props: {
    randomApplicantsViewing: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      vueScrollOptions: {
        scrollPanel: {
          scrollingX: false,
          maxHeight: 130,
        },
        bar: {
          keepShow: true,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.table-random-generator-activated {
  border-radius: 4px;
  margin-bottom: 20px;
  color: $color-text-secondary;
  background-color: $color-back-light;
  font-size: 14px;
  padding-left: 12px;
  font-weight: $font-weight-bold;
}
</style>
