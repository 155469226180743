export default {
  copy(text) {
    return navigator.clipboard.writeText(text);
  },
  paste() {
    return navigator.clipboard.readText();
  },
  copyText(text) {
    const textarea = document.createElement('textarea');
    textarea.classList.add('clipboard-hidden-element');
    textarea.style.position = 'absolute';
    textarea.style.width = '0';
    textarea.style.height = '0';
    textarea.innerHTML = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('Copy');
    const elements = document.querySelectorAll('.clipboard-hidden-element');
    elements.forEach((element) => {
      element.parentNode.removeChild(element);
    });
  },
};
