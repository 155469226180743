import routeMap from '@/store/model/route-map/routeMap';
import { ls } from '@/utility';

function superadminPermitted(role, permission, features) {
  // set availableFeatures
  const availableFeatures = features || {};
  // get permission part until first _
  const featurePermission = permission.split('_')[0];
  // mandatory permit 'superadmin' user only:
  // - if available feature is not equal featurePermission
  // - or available feature is activated
  return (role === 'superadmin' && (
    !Object.keys(availableFeatures).includes(featurePermission)
      || !!availableFeatures[featurePermission]
  ));
}

export default {
  isAuthenticated: (state) => !!state.user.token,
  user: (state) => state.user,
  userRole: (state) => state.user?.role,
  isContractUser: (state) => state.user.role === 'contract',
  isRoutePermitted: (state) => (routeName) => {
    const { permissions, role, features } = state.user;
    // if no permissions logout user
    if (!permissions && role !== 'superadmin') {
      state.user = {};
      ls.user.remove();
      return 'logout';
    }
    let permitted = true;
    let parentRouteMatch = false;
    let childRouteMatch = false;
    Object.keys(routeMap).forEach((routeParentKey) => {
      const routeParent = routeMap[routeParentKey];
      // match parent route
      if (routeParent.urlName === routeName) {
        // set parent route match -> it means that there is parent route definition in routeMap
        parentRouteMatch = true;
        // if parent route has no permission prop -> don't permit
        if (routeParent.permission === undefined) permitted = false;
        else { // check proper parent route permission
          permitted = routeParent.permission === 'admin'
            ? role === 'superadmin' || role === 'admin'
            : routeParent.permission === 'permit' || superadminPermitted(role, routeParent.permission, features)
              || permissions.includes(routeParent.permission);
        }
      }
      // match child route
      if (permitted && routeParent.children) {
        Object.keys(routeParent.children).forEach((routeChildKey) => {
          const routeChild = routeParent.children[routeChildKey];
          // match child route
          if (routeChild.urlName === routeName) {
            // set child route match -> it means that there is child route definition in routeMap
            childRouteMatch = true;
            // if child route has no permission prop -> don't permit
            if (routeChild.permission === undefined) permitted = false;
            else { // check proper child route permission
              permitted = routeChild.permission === 'admin'
                ? role === 'superadmin' || role === 'admin'
                : routeChild.permission === 'permit' || superadminPermitted(role, routeChild.permission, features)
                  || permissions.includes(routeChild.permission);
            }
          }
        });
      }
    });
    return parentRouteMatch || childRouteMatch ? permitted : false;
  },
  isPermitted: (state) => (permission) => {
    const { role, permissions, features } = state.user;
    // mandatory permit superadmin if superadminPermitted
    if (superadminPermitted(role, permission, features)) return true;
    // otherwise -> check if user has permission
    return permissions ? permissions.includes(permission) : false;
  },
};
