<template>
  <div class="mdt-mail-preview-template-list">
    <div
      class="template-list-button"
      @click="$emit('selectTemplate', mailSignature)">
      <MdtEditButton
        :editable="false"
        :edit-icon="'fas fa-plus'"
        :theme="'default'"
        class="no-pointerevents" />
      {{ msg.composeNewMail }}
    </div>
    <div class="template-selection">
      <div class="title">
        {{ msg.selectMailTemplate }}
      </div>
      <vue-scroll class="template-list-scroll">
        <div
          class="template-list"
          @wheel.stop
          @touchmove.stop
          @scroll.stop>
          <div
            v-for="(template, i) in templates"
            :key="i"
            class="template-list-button"
            @click="$emit('selectTemplate', template)">
            <i
              :class="'fas fa-file-alt'"
              class="template-icon" />
            <div class="template-subject-and-name">
              <div class="template-subject">
                {{ template.subject }}
              </div>
              <div
                v-if="template.title"
                class="template-name">
                {{ template.title }}
              </div>
            </div>
          </div>
        </div>
      </vue-scroll>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MdtMailPreviewTemplateList',
  props: {
    templates: {
      type: Array,
      default: () => [],
    },
    mailSignature: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      msg: {
        composeNewMail: this.$options.filters.translate('admin_compose_new_mail'),
        selectMailTemplate: this.$options.filters.translate('admin_select_email_template'),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.mdt-mail-preview-template-list {
  width: 100%;
}

.template-selection {
  .title {
    padding: 16px 0 10px 0;
    color: $color-text-secondary;
  }
}

.template-list-scroll {
  &.hasVBar {
    .template-list {
      padding-right: 17px;
    }
  }
}

.template-list {
  height: calc(700px - #{$modal-header-height} - #{$modal-header-height} - 172px);
}

.template-list-button {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 64px;
  margin-bottom: 20px;
  padding: 0 20px;
  border: 1px solid $border-color;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  .template-icon {
    margin-right: 16px;
    color: $color-text-secondary;
    font-size: 20px;
  }

  .template-subject-and-name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .template-subject {
    padding-bottom: 4px;
    color: $color-text-primary;
    font-size: 16px;
    font-weight: $font-weight-bold;
    line-height: 16px;
  }

  .template-name {
    color: $color-text-secondary;
    font-size: 16px;
    line-height: 16px;
  }

  .mdt-edit-button {
    margin-right: 16px;

    ::v-deep {
      .edit-button {
        width: 23px;
        height: 23px;
      }
    }
  }
}
</style>
