import vuescroll from 'vuescroll';
import variables from '@/scss/abstract/_variablesExport.module.scss';

export default {
  plugin: vuescroll,
  options: {
    ops: {
      vuescroll: {},
      scrollPanel: {},
      rail: {},
      bar: {
        background: variables.vuescrollBarColorBg,
        onlyShowBarOnScroll: false,
        gutterOfSide: '8px',
        size: '8px',
        opacity: 0.3,
      },
    },
  },
};
