export default {
  apartment: {},
  withOffer: {},
  assigned: {},
  rented: {},
  reserved: {},
  sold: {},
  detailView: {
    loading: true,
  },
  createWizard: {
    wizardData: {
      details: {
        area: [],
      },
      prices: {},
      address: {},
      upload: {
        images: [],
        tempImages: [],
        files: [],
        tempFiles: [],
      },
      rental: {},
      additionalInformations: {
        useInspectForm: [],
        applicationForms: [],
        subsidized: [],
      },
    },
    rawData: {
      applications: [],
      inspectForm: [],
      subsidized: [],
    },
  },
  parentRouteName: 'Objects',
};
