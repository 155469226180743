import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
export default {
  getDashboard(context, payload) {
    const params = {
      ...payload,
      enableCache: true,
    };
    return api.get('api/v1/analytics/dashboard?format=json', { params }).then((response) => response.data);
  },
  getDetails(context, id) {
    return api.get(`/api/v1/analytics/dashboard/detail/${id}`, { params: { enableCache: true } }).then((response) => response.data);
  },
  downloadReport(context, data) {
    return api.post('/api/v1/analytics/generate-serve-report-files', data, { timeout: 0, responseType: 'arraybuffer' });
  },
  getIsochrones(context, { travelType, coordinates, contoursMinutes }) {
    const query = `contours_minutes=${contoursMinutes}&polygons=true&access_token=${mapboxgl.accessToken}`;

    return axios.get(`https://api.mapbox.com/isochrone/v1/mapbox/${travelType}/${coordinates}?${query}`)
      .then((response) => response.data);
  },
  getRoute(context, { travelType, coordinates }) {
    const query = `geometries=geojson&access_token=${mapboxgl.accessToken}`;

    return axios.get(`https://api.mapbox.com/directions/v5/mapbox/${travelType}/${coordinates}?${query}`)
      .then((response) => response.data);
  },
  saveTabsForDownload({ commit }, tabs) {
    commit('saveTabsForDownload', tabs);
  },
};
