import Vue from 'vue';
import showResponseError from './showResponseError';
import isPermitted from './isPermitted';

// register global mixins
Vue.mixin(showResponseError);
Vue.mixin(isPermitted);

export default {};
export { default as mixinToggleDropdown } from './toggleDropdown';
export { default as mixinMouseEnterLeave } from './mouseEnterLeave';
export { default as mixinRefreshTableOrDetailView } from './refreshTableOrDetailView';
