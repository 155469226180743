import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
export default {
  createPerson(state, data) {
    return api.post('/api/v1/applications/interested/create', data).then((response) => response.data);
  },
  getBuyingConfirmationPrios(state, params) {
    return api.get(`/api/v1/sales/applications/${params.id}/buying-confirmation-prios`)
      .then((response) => response.data);
  },
  getBuyingConfirmationMailModal(state, settings) {
    const path = `/api/v1/sales/applications/${settings.applicationId}/interested/send-buying-confirmation-form`;
    return api.get(path, settings.params).then((response) => response.data);
  },
  postBuyingConfirmationMailModal(state, settings) {
    const path = `/api/v1/sales/applications/${settings.applicationId}/interested/send-buying-confirmation-form`;
    return api.post(path, settings.params)
      .then((response) => response.data);
  },
  deactivate(state, params) {
    return api.post('/api/v1/applications/interested', params).then((response) => response.data);
  },
  getCreatePersonB2B() {
    return api.get('api/v1/investors/interested/create').then((response) => response.data);
  },
  createPersonB2B(state, data) {
    return api.post('api/v1/investors/interested/create', data).then((response) => response.data);
  },
  deactivateB2B(state, targets) {
    const params = {
      targets,
      bulkAction: 'deactivate',
    };

    return api.post('api/v1/investors/interested', params).then((response) => response.data);
  },
  activateB2B(state, targets) {
    const params = {
      targets,
      bulkAction: 'activate',
    };

    return api.post('/api/v1/investors/interested', params).then((response) => response.data);
  },
  getEditB2B(state, { id }) {
    return api.get(`/api/v1/investors/interested/${id}/edit`).then((response) => response.data);
  },
  postEditB2B(state, settings) {
    return api.post(`/api/v1/investors/interested/${settings.params.id}/edit`, settings.data).then((response) => response.data);
  },
};
