import MdtCheckbox from '@/components/shared/form-elements/MdtCheckbox.vue';
import MdtCheckboxList from '@/components/shared/form-elements/MdtCheckboxList.vue';
import MdtDatepicker from '@/components/shared/form-elements/MdtDatepicker.vue';
import MdtDatepickerRange from '@/components/shared/form-elements/MdtDatepickerRange.vue';
import MdtFilters from '@/components/shared/form-elements/MdtFilters.vue';
import MdtInput from '@/components/shared/form-elements/MdtInput.vue';
import MdtMultiselect from '@/components/shared/form-elements/MdtMultiselect.vue';
import MdtRadio from '@/components/shared/form-elements/MdtRadio.vue';
import MdtRadioList from '@/components/shared/form-elements/MdtRadioList.vue';
import MdtRangeSlider from '@/components/shared/form-elements/MdtRangeSlider.vue';
import MdtSearch from '@/components/shared/form-elements/MdtSearch.vue';
import MdtSelect from '@/components/shared/form-elements/MdtSelect.vue';
import MdtTextarea from '@/components/shared/form-elements/MdtTextarea.vue';
import MdtTimepicker from '@/components/shared/form-elements/MdtTimepicker.vue';
import MdtTimepickerRange from '@/components/shared/form-elements/MdtTimepickerRange.vue';
import MdtNumberIncrementer from '@/components/shared/form-elements/MdtNumberIncrementer.vue';
import MdtInputPassword from '@/components/shared/form-elements/MdtInputPassword.vue';
import MdtFileSingle from '@/components/shared/form-elements/MdtFileSingle.vue';
import MdtInputTags from '@/components/shared/form-elements/MdtInputTags.vue';
import MdtColorPicker from '@/components/shared/form-elements/MdtColorPicker.vue';
import MdtMultiselectRemote from '@/components/shared/form-elements/MdtMultiselectRemote.vue';
import MdtInputRange from '@/components/shared/form-elements/MdtInputRange.vue';
import MdtPhoneNumberInput from '@/components/shared/form-elements/MdtPhoneNumberInput.vue';
import MdtInputUsername from '@/components/shared/form-elements/MdtInputUsername.vue';
import MdtInputPasswordWithConfirm from '@/components/shared/form-elements/MdtInputPasswordWithConfirm.vue';

export default {
  install(Vue) {
    Vue.component('MdtCheckbox', MdtCheckbox);
    Vue.component('MdtCheckboxList', MdtCheckboxList);
    Vue.component('MdtDatepicker', MdtDatepicker);
    Vue.component('MdtDatepickerRange', MdtDatepickerRange);
    Vue.component('MdtFilters', MdtFilters);
    Vue.component('MdtInput', MdtInput);
    Vue.component('MdtMultiselect', MdtMultiselect);
    Vue.component('MdtRadio', MdtRadio);
    Vue.component('MdtRadioList', MdtRadioList);
    Vue.component('MdtRangeSlider', MdtRangeSlider);
    Vue.component('MdtSearch', MdtSearch);
    Vue.component('MdtSelect', MdtSelect);
    Vue.component('MdtTextarea', MdtTextarea);
    Vue.component('MdtTimepicker', MdtTimepicker);
    Vue.component('MdtTimepickerRange', MdtTimepickerRange);
    Vue.component('MdtNumberIncrementer', MdtNumberIncrementer);
    Vue.component('MdtInputPassword', MdtInputPassword);
    Vue.component('MdtFileSingle', MdtFileSingle);
    Vue.component('MdtInputTags', MdtInputTags);
    Vue.component('MdtColorPicker', MdtColorPicker);
    Vue.component('MdtMultiselectRemote', MdtMultiselectRemote);
    Vue.component('MdtInputRange', MdtInputRange);
    Vue.component('MdtPhoneNumberInput', MdtPhoneNumberInput);
    Vue.component('MdtInputUsername', MdtInputUsername);
    Vue.component('MdtInputPasswordWithConfirm', MdtInputPasswordWithConfirm);
  },
};
