import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
export default {
  importErp(state, type) {
    return api.post(`/api/v1/interface/initial-import-erp/${type}`)
      .then((response) => response.data);
  },
  syncApplicationErp(state, params) {
    return api.post(`/api/v1/interface/synchronize-application-erp/${params.applicationId}`)
      .then((response) => response.data);
  },
  syncObjectErp(state, params) {
    return api.post(`/api/v1/interface/synchronize-object-erp/${params.objectId}`)
      .then((response) => response.data);
  },
  createContract(state, params) {
    return api.post(`/api/v1/interface/create-contract-erp/${params.assignmentId}`)
      .then((response) => response.data);
  },
};
