import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
const url = '/api/v1/reporting';

export default {
  getOverviewTables() {
    return api.get(`${url}/overview`).then((response) => response.data);
  },
  getCategoryOptions({ commit }) {
    return api.get(`${url}/costs/create`).then((response) => {
      const categoryOptions = response.data.find((item) => item.value === 'category').choices;
      commit('setCategoryOptions', categoryOptions);
    });
  },
  createCategoryOption({ commit }, data) {
    commit('setCategoryOption', data);
  },
  createCostPlan(context, data) {
    return api.post(`${url}/costs/create`, data).then((response) => response.data);
  },
  getCostPlanEdit(context, data) {
    return api.get(`${url}/costs/${data.id}/edit`).then((response) => response.data);
  },
  getCostCategoryEdit(context, data) {
    return api.get(`${url}/costs/${data.id}/edit?form=category-budget`).then((response) => response.data);
  },
  editCostPlan(context, { params, data }) {
    return api.post(`${url}/costs/${params.id}/edit`, data).then((response) => response.data);
  },
  deleteCostPlan(context, id) {
    return api.delete(`${url}/costs/${id}/edit`).then((response) => response.data);
  },
  editTotalBudget(context, data) {
    return api.post('/api/v1/settings/reporting_cost_total_amount', data).then((response) => response.data);
  },
  downloadFeeExport({ dispatch }) {
    dispatch('table/setLoading', true, { root: true });
    return api.post(`${url}/honorary`, {
      bulkAction: 'export-xls',
    }, {
      responseType: 'arraybuffer',
      timeout: 0,
    }).finally(() => { dispatch('table/setLoading', false, { root: true }); });
  },
  createFee(context, data) {
    return api.post(`${url}/honorary/create`, data).then((response) => response.data);
  },
  createPeriod(context, data) {
    return api.post(`${url}/period/create`, data).then((response) => response.data);
  },
};
