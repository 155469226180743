const VITALLY_CDN = 'https://cdn.vitally-eu.io/vitally.js/v1/vitally.js';
const VITALLY_BASE_API_URL = 'https://api.vitally-eu.io/analytics/v1';

const vitally = {
  install(Vue) {
    const init = () => {
      const vitallyScript = document.createElement('script');
      vitallyScript.setAttribute('src', VITALLY_CDN);
      vitallyScript.setAttribute('defer', 'defer');
      vitallyScript.setAttribute('id', 'vitallyScript');
      document.body.appendChild(vitallyScript);
      /* eslint-disable */
      !function(n,t,r) {
        for(var i=n[t]=n[t]||[],o=function(r){
          i[r]=i[r]||function(){
            for(var n=[],t=0;t<arguments.length;t++)n[t]=arguments[t];
            return i.push([r,n])
          };
        },u=0,c=["init","user","account","track","nps"];u<c.length;u++){
          o(c[u])
        }
      }(window,"Vitally");
      window.Vitally.init(process.env.VUE_APP_VITALLY_API_KEY, VITALLY_BASE_API_URL);
      /* eslint-enable */
    };
    const account = (currentUser) => {
      window.Vitally.account({
        accountId: currentUser.id,
        traits: {
          name: currentUser.name,
          email: currentUser.email,
          company: currentUser.company,
          role: currentUser.role,
        },
      });
    };
    const user = (currentUser) => {
      window.Vitally.user({
        userId: currentUser.id,
        traits: {
          name: `${currentUser.name} ${currentUser.lastName}`,
          email: currentUser.email,
          company: currentUser.company,
          role: currentUser.role,
        },
      });
    };
    const nps = (translate, currentUser, colorTheme) => {
      account(currentUser);
      user(currentUser);

      window.Vitally.nps('survey', {
        delay: 1000,
        primaryColor: `${colorTheme}`,
        npsQuestion: translate('general_nps_question'),
        followUpTitle: translate('general_thank_for_feedback'),
        followUpSubtitle: ({ score }) => {
          const followUpSubtitle = translate('general_why_selected_nps_score').replace('[d]', score);
          return followUpSubtitle;
        },
        thanksTitle: translate('general_thank_you'),
        thanksSubtitle: translate('general_thank_you_for_feedback'),
        submitText: translate('general_submit_response'),
        dismissText: translate('general_dismiss_text'),
        placeholderText: translate('general_enter_feedback_placeholder'),
        minLabel: translate('general_not_likely'),
        maxLabel: translate('general_very_likely'),
      });
    };
    const destroy = () => {
      const vitallyScript = document.querySelector('#vitallyScript');
      const vitallyNpsPopup = document.querySelector('.Vitally--NpsSurveyView-npsSurvey');

      if (vitallyScript && vitallyNpsPopup) {
        vitallyScript.remove();
        vitallyNpsPopup.remove();
      }
    };

    const widget = {
      init,
      nps,
      destroy,
    };

    Vue.prototype.$vitally = widget;
    Vue.vitally = widget;
  },
};

export default vitally;
