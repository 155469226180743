import Vue from 'vue';

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';

// import Router from "vue-router";

// Import custom globally plugins and directives
import './filters';
import './plugins';
import './directives';

Vue.config.productionTip = false;
Vue.router = router;

const config = process.env.NODE_ENV === 'production' ? '/config.json' : '/config.local.json';
await axios.get(config).then((response) => {
  const { data } = response;
  Vue.prototype.$config = data;
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: data.environment,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [data.baseApiUrl],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  new Vue({
    name: 'MelonWebUI',
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
});
