/*
  NOTE:
    # permission prop is used in route permission check in auth module (isRoutePermitted):
      - value 'permit' -> allows route without checking
      - value 'admin' -> allows route only if logged user is 'superadmin' or 'admin'
      - permission value (eg 'homepage_read') -> we can add permission name
        and there will be check if logged user has that permission
*/

export default {
  redirect: {
    url: '/',
    urlName: 'Redirect',
    permission: 'permit',
  },
  home: {
    url: 'home',
    urlName: 'Home',
    permission: 'homepage_read',
  },
  applications: {
    url: 'applications',
    urlName: 'Applications',
    permission: 'applications_read',
  },
  applicationDetails: {
    url: 'applications/:id',
    urlName: 'ApplicationDetails',
    permission: 'applications_read',
  },
  objects: {
    url: 'objects',
    urlName: 'Objects',
    permission: 'object_read',
    children: {
      apartments: {
        url: 'apartments',
        urlName: 'Apartments',
        permission: 'object_read',
      },
      parkingSpace: {
        url: 'parking',
        urlName: 'Parking',
        permission: 'object_read',
      },
      outbuildings: {
        url: 'outbuildings',
        urlName: 'Outbuildings',
        permission: 'object_read',
      },
      business: {
        url: 'business',
        urlName: 'Business',
        permission: 'object_read',
      },
      buildings: {
        url: 'buildings',
        urlName: 'Buildings',
        permission: 'object_read',
      },
      plots: {
        url: 'plots',
        urlName: 'Plots',
        permission: 'object_read',
      },
    },
  },
  objectDetails: {
    url: 'objects/:id',
    urlName: 'ApartmentDetails',
    permission: 'object_read',
  },
  tenant: {
    url: 'tenant',
    urlName: 'Tenant',
    permission: 'tenants_read',
  },
  tenantDetails: {
    url: 'tenant/:id',
    urlName: 'TenantDetails',
    permission: 'tenants_read',
  },
  reporting: {
    url: 'reporting',
    urlName: 'Reporting',
    permission: 'reporting_read',
    children: {
      pdfReporting: {
        url: 'pdf',
        urlName: 'Pdf',
        permission: 'reporting_read',
      },
    },
  },
  budget: {
    url: 'budget',
    urlName: 'Budget',
    permission: 'budget_read',
    children: {
      overview: {
        url: 'overview',
        urlName: 'Overview',
        permission: 'budget_read',
      },
      costPlanning: {
        url: 'cost-planning',
        urlName: 'CostPlanning',
        permission: 'budget_read',
      },
      feeCalculation: {
        url: 'fee-calculation',
        urlName: 'FeeCalculation',
        permission: 'budget_read',
      },
    },
  },
  communication: {
    url: 'communication',
    urlName: 'Communication',
    permission: 'shared_communication',
  },
  interestedPeople: {
    url: 'interested-people',
    urlName: 'InterestedPeople',
    permission: 'interested_people',
  },
  interestedPeopleB2B: {
    url: 'interested-people-b2b',
    urlName: 'InterestedPeopleB2B',
    permission: 'interested_people',
  },
  projectSettings: {
    url: 'project',
    urlName: 'ProjectSettings',
    permission: 'admin',
    children: {
      general: {
        url: 'general',
        urlName: 'ProjectSettingsGeneral',
        permission: 'admin',
      },
      contact: {
        url: 'contact',
        urlName: 'ProjectSettingsContact',
        permission: 'admin',
      },
    },
  },
  documentSettings: {
    url: 'document',
    urlName: 'documentSettings',
    permission: 'admin',
    children: {
      flyer: {
        url: 'flyer',
        urlName: 'ProjectSettingsFlyer',
        permission: 'admin',
      },
      businessCard: {
        url: 'business-card',
        urlName: 'ProjectSettingsBusinessCard',
        permission: 'admin',
      },
    },
  },
  processSettings: {
    url: 'process-settings',
    urlName: 'ProcessSettings',
    permission: 'admin',
  },
  userManagement: {
    url: 'user-management',
    urlName: 'UserManagement',
    permission: 'admin',
    children: {
      users: {
        url: 'users',
        urlName: 'ProjectSettingsUsers',
        permission: 'admin',
      },
      teams: {
        url: 'teams',
        urlName: 'ProjectSettingsTeams',
        permission: 'admin',
      },
      roles: {
        url: 'roles',
        urlName: 'ProjectSettingsRoles',
        permission: 'admin',
      },
      owners: {
        url: 'owners',
        urlName: 'ProjectSettingsOwners',
        permission: 'admin',
      },
    },
  },
  communicationSettings: {
    url: 'communication',
    urlName: 'CommunicationSettings',
    permission: 'admin',
    children: {
      emailSignature: {
        url: 'mail-signature',
        urlName: 'ProjectSettingsEmailSignature',
        permission: 'admin',
      },
      emailTemplates: {
        url: 'mail-templates',
        urlName: 'ProjectSettingsEmailTemplates',
        permission: 'admin',
      },
    },
  },
  reservationSettings: {
    url: 'reservation',
    urlName: 'ReservationSettings',
    permission: 'admin',
  },
  websiteSettings: {
    url: 'website',
    urlName: 'WebsiteSettings',
    permission: 'admin',
    children: {
      pageBuilder: {
        url: 'page-builder',
        urlName: 'ProjectSettingsPageBuilder',
        permission: 'admin',
      },
      iframe: {
        url: 'iframe',
        urlName: 'ProjectSettingsIframe',
        permission: 'admin',
      },
    },
  },
  softwareInterfaces: {
    url: 'software-interfaces',
    urlName: 'SoftwareInterfaces',
    permission: 'admin',
    children: {
      creditCheck: {
        url: 'credit-check',
        urlName: 'ProjectSettingsCreditCheck',
        permission: 'admin',
      },
      abaImmo: {
        url: 'aba-immo',
        urlName: 'ProjectSettingsAbaImmo',
        permission: 'admin',
      },
      rentalPlatforms: {
        url: 'rental-platforms',
        urlName: 'ProjectSettingsRentalPlatforms',
        permission: 'admin',
      },
      authentication: {
        url: 'authentication',
        urlName: 'ProjectSettingsAuthentication',
        permission: 'admin',
      },
    },
  },
  digitalSignatureSettings: {
    url: 'digital-signature',
    urlName: 'digitalSignatureSettings',
    permission: 'admin',
  },
  formSettings: {
    url: 'form-settings',
    urlName: 'formSettings',
    permission: 'admin',
    children: {
      forms: {
        url: 'forms',
        urlName: 'formSettingsForms',
        permission: 'admin',
      },
      general: {
        url: 'general',
        urlName: 'formSettingsGeneral',
        permission: 'admin',
      },
    },
  },
  dmsSettings: {
    url: 'dms',
    urlName: 'DmsSettings',
    permission: 'admin',
    children: {
      roles: {
        url: 'roles',
        urlName: 'ProjectSettingsDmsRoles',
        permission: 'admin',
      },
      categories: {
        url: 'categories',
        urlName: 'ProjectSettingsDmsCategories',
        permission: 'admin',
      },
    },
  },
  profileSettings: {
    url: 'profile-settings',
    urlName: 'ProfileSettings',
    permission: 'permit',
    children: {
      account: {
        url: 'account',
        urlName: 'ProfileSettingsAccount',
        permission: 'permit',
      },
      notifications: {
        url: 'notifications',
        urlName: 'ProfileSettingsNotifications',
        permission: 'permit',
      },
    },
  },
  waitlist: {
    url: 'waitlist',
    urlName: 'Waitlist',
    permission: 'waitlist',
  },
  waitlistDetails: {
    url: 'waitlist/applications/:id',
    urlName: 'WaitlistDetails',
    permission: 'waitlist',
  },
  viewingAppointments: {
    url: 'viewing-appointments',
    urlName: 'ViewingAppointments',
    permission: 'viewing',
    children: {
      booked: {
        url: 'booked',
        urlName: 'AppointmentsBooked',
        permission: 'viewing',
      },
      withoutRegistrations: {
        url: 'without-registrations',
        urlName: 'AppointmentsWithoutRegistrations',
        permission: 'viewing',
      },
    },
  },
  investors: {
    url: 'investors',
    urlName: 'Investors',
    permission: 'permit',
  },
  investorDetails: {
    url: 'investors/:id',
    urlName: 'InvestorDetails',
    permission: 'permit',
  },
  projects: {
    url: 'projects',
    urlName: 'Projects',
    permission: 'permit',
  },
  projectDetails: {
    url: 'projects/:id',
    urlName: 'ProjectDetails',
    permission: 'permit',
  },
  createContract: {
    url: 'create-contract/:id',
    urlName: 'CreateContract',
    permission: 'digitalcontract',
  },
  b2bProjectNda: {
    url: 'project-nda/:projectSlug',
    urlName: 'B2BProjectNda',
    permission: 'permit',
  },
  b2bProjectMemorandum: {
    url: 'project-memorandum/:projectSlug',
    urlName: 'B2BProjectMemorandum',
    permission: 'permit',
  },
  b2bProjectMemorandumPitch: {
    url: 'project-memorandum-pitch/:projectSlug',
    urlName: 'B2BProjectMemorandumPitch',
    permission: 'permit',
  },
  analytics: {
    url: 'analytics',
    urlName: 'Analytics',
    permission: 'analytics_read',
  },
  analyticsDetails: {
    url: 'analytics/:id',
    urlName: 'AnalyticsDetails',
    permission: 'analytics_read',
  },
  chartsTesting: {
    url: 'charts-testing',
    urlName: 'ChartsTesting',
    permission: 'permit',
  },
  dms: {
    url: 'dms/:dmsSlug',
    urlName: 'Dms',
    permission: 'permit',
  },
  applicationForm: {
    url: 'form/application/new',
    urlName: 'NewApplicationForm',
    permission: 'permit',
    meta: {
      unprotected: true,
    },
    children: {
      landingPage: {
        url: 'form/application/new',
        urlName: 'LandingPage',
        permission: 'permit',
        meta: {
          unprotected: true,
        },
      },
      newForm: {
        url: 'form/application/new/:slug/:token/:formId',
        urlName: 'ApplicationFormData',
        permission: 'permit',
        meta: {
          unprotected: true,
        },
      },
    },
  },
  applicationFormCreateAdult: {
    url: '/form/application/:slug/:token/create-adult',
    urlName: 'CreateAdult',
    permission: 'permit',
    meta: {
      unprotected: true,
    },
  },
  applicationFormUpdate: {
    url: 'form/application/:slug/:token/update/:formId?',
    urlName: 'UpdateApplicationForm',
    permission: 'permit',
    meta: {
      unprotected: true,
    },
  },
};
