/* eslint-disable no-else-return */
import Vue from 'vue';
import store from '@/store';

// it's better to use Vue.router.push() instead of next()
// because of Uncaught (in promise) Error: Redirected

export default {
  isUserAuthenticated(to, from, next) {
    // update prev routes
    store.dispatch('menu/updatePrevRoutes', from);

    const { 'auth/isAuthenticated': isAuthenticated, 'auth/isRoutePermitted': isRoutePermitted } = store.getters;

    // If to-route is unprotected view -> allow to route
    if (to.meta?.unprotected === true) {
      next();
      return;
    }

    // User authenticated:

    if (isAuthenticated) {
      // If user has no permissions prop -> it returns 'logout' -> then redirect to login
      if (isRoutePermitted(to.name) === 'logout') {
        Vue.router.push({
          path: '/login',
          query: { redirect: to.fullPath },
        }).catch(() => {});
        return;
      }
      // If user has no permission to enter route -> redirect to 403
      if (isRoutePermitted(to.name) === false) {
        Vue.router.push({ name: 'AccessDenied' }).catch(() => {});
        return;
      }
      // Otherwise, user has permission -> allow to route
      next();
      return;
    }

    // User not authenticated:

    // Redirect to login
    if (to.meta.b2b) {
      Vue.router.push({
        name: 'B2BEntry',
        params: {
          projectSlug: to.params.projectSlug,
        },
      }).catch(() => {});
    } else if (to.meta.dms) {
      Vue.router.push({
        name: 'DmsLogin',
        params: {
          dmsSlug: to.params.dmsSlug,
        },
      }).catch(() => {});
    } else {
      Vue.router.push({
        path: '/login',
        query: { redirect: to.fullPath },
      }).catch(() => {});
    }
  },
};
