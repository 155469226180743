import MdtModal from '@/components/shared/modals/MdtModal.vue';
import MdtModalConfirmation from '@/components/shared/modals/MdtModalConfirmation.vue';
import MdtReorder from '@/components/shared/modals/MdtReorder.vue';
import MdtMailPreview from '@/components/shared/modals/mdt-mail-preview/MdtMailPreview.vue';
import MdtPdfPreview from '@/components/shared/modals/MdtPdfPreview.vue';
import MdtGoogleBucketPdfPreview from '@/components/shared/modals/MdtGoogleBucketPdfPreview.vue';
import MdtModalDocumentUpload from '@/components/shared/modals/MdtModalDocumentUpload.vue';
import MdtWizard from '@/components/shared/wizard/MdtWizard.vue';
import MdtWizardSimple from '@/components/shared/wizard-simple/MdtWizardSimple.vue';
import MdtWizardSimpleEmbedded from '@/components/shared/wizard-simple-embedded/MdtWizardSimpleEmbedded.vue';
import MdtModalProcessing from '@/components/shared/modals/MdtModalProcessing.vue';

export default {
  install(Vue) {
    Vue.component('MdtModal', MdtModal);
    Vue.component('MdtModalConfirmation', MdtModalConfirmation);
    Vue.component('MdtReorder', MdtReorder);
    Vue.component('MdtMailPreview', MdtMailPreview);
    Vue.component('MdtPdfPreview', MdtPdfPreview);
    Vue.component('MdtGoogleBucketPdfPreview', MdtGoogleBucketPdfPreview);
    Vue.component('MdtModalDocumentUpload', MdtModalDocumentUpload);
    Vue.component('MdtWizard', MdtWizard);
    Vue.component('MdtWizardSimple', MdtWizardSimple);
    Vue.component('MdtWizardSimpleEmbedded', MdtWizardSimpleEmbedded);
    Vue.component('MdtModalProcessing', MdtModalProcessing);
  },
};
