export default {
  searchVal: (state) => state.searchVal,
  basicFilters: (state) => state.basicFilters,
  selectedItem: (state) => state.selectedItem,
  listPagination: (state) => state.list.pagination,
  inboxPagination: (state) => state.inbox.pagination,
  sortBy: (state) => state.list.sortSettings,
  sortKey: (state) => state.list.sortSettings.selected,
  mailBox: (state) => state.mailBox,
  list: (state) => state.list,
};
