import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
export default {
  getAllData({ dispatch, getters, rootGetters }, data) {
    const params = data || { id: getters.tenantId };
    // Remove stashed data
    dispatch('deleteTenantData');

    return dispatch('getTenant', params).then((tenant) => {
      // get project type
      const { projectType } = rootGetters;

      // get evaluation data if project type different then 'sale'
      if (projectType !== 'sale') {
        dispatch('applications/getEvaluation', params, { root: true });
      }

      if (tenant.progress_state === 'without_contract') {
        if (rootGetters.availableFeatures.digitalcontract) {
          dispatch('applications/getAssignmentV2', params, { root: true });
        } else {
          dispatch('applications/getAssignment', params, { root: true });
        }
      } else if (tenant.progress_state === 'with_contract'
        || tenant.progress_state === 'contract_sent') {
        if (rootGetters.availableFeatures.digitalcontract) {
          dispatch('getContractV2', params);
        } else {
          dispatch('getContract', params);
        }
      } else if (tenant.progress_state === 'quit') {
        if (rootGetters.availableFeatures.digitalcontract) {
          dispatch('getTerminateInfoV2', params);
        } else {
          dispatch('getTerminateInfo', params);
        }
      }
      return tenant;
    });
  },
  getTenant({ commit }, params) {
    // set detail view loader to true
    commit('setDetailViewLoader', true);
    return api.get(`/api/v1/tenants/${params.id}`).then((response) => {
      commit('setTenant', response.data);
      return response.data;
    }).finally(() => {
      // set detail view loader to false
      commit('setDetailViewLoader', false);
    });
  },
  getTenantWithoutLoader({ commit }, params) {
    return api.get(`/api/v1/tenants/${params.id}`).then((response) => {
      commit('setTenant', response.data);
      return response.data;
    });
  },
  getContract({ commit }, params) {
    api.get(`/api/v1/applications/${params.id}/assignment`).then((response) => {
      commit('setContract', response.data);
    });
  },
  getContractV2({ commit }, params) {
    api.get(`/api/v1/tenants/${params.id}/contract-info`).then((response) => {
      commit('setContract', response.data);
    });
  },
  getTerminateInfo({ commit }, params) {
    api.get(`/api/v1/tenants/${params.id}/terminate-info`).then((response) => {
      commit('setQuit', response.data);
    });
  },
  getTerminateInfoV2({ commit }, params) {
    api.get(`/api/v1/tenants/${params.id}/terminate-contract-info`).then((response) => {
      commit('setQuit', response.data);
    });
  },
  terminateTenant(state, params) {
    return api.post(`/api/v1/tenants/${params.id}/terminate`)
      .then((response) => response.data);
  },
  saveContractComment({ getters }, comment) {
    const id = getters.tenantId;
    api.post(`/api/v1/applications/${id}/assignment`, { comment });
  },
  deleteTenantData({ commit }) {
    commit('deleteTenantData');
  },
  setFavorite({ dispatch, commit }, options) {
    return dispatch('table/setFavorite', options, { root: true }).then(() => {
      commit('setFavorite', options);
    });
  },
  downloadMultipleExports({ dispatch }, params) {
    dispatch('table/setLoading', true, { root: true });
    return api.post(
      '/api/v1/generate-application-export',
      { targets: params.tenantIds, exportAction: params.exportAction },
      {
        responseType: 'arraybuffer',
      },
    ).finally(() => { dispatch('table/setLoading', false, { root: true }); });
  },
  reorderOptions({ getters }, params) {
    const { tenantSettingsKey } = getters;
    const options = {
      value: params,
    };
    return api.post(`/api/v1/settings/${tenantSettingsKey}/list`, options);
  },
  resetToDefault(state, params) {
    return api.post(`/api/v1/tenants/${params.id}/reset-to-default`).then((response) => response.data);
  },
  deleteTenant({ commit, getters }) {
    const { tenantId } = getters;
    return api.delete(`/api/v1/tenants/${tenantId}`).then((response) => {
      commit('deleteTenantData');
      return response.data;
    });
  },
  getCreateContractData(state, tenantId) {
    return api.get(`/api/v1/tenants/${tenantId}/contract-preview`).then((response) => response.data);
  },
  getCreateContractMailPreviewData(state, params) {
    return api.post(`/api/v1/tenants/${params.tenantId}/contract-email-preview`, params.options).then((response) => response.data);
  },
  createContract(state, params) {
    return api.post(`/api/v1/tenants/${params.tenantId}/send-contract-email`, params.options, { timeout: 0 }).then((response) => response.data);
  },
  getContractReminderMailPreviewData(state, tenantId) {
    return api.get(`/api/v1/tenants/${tenantId}/send-contract-reminder-email`).then((response) => response.data);
  },
  sendContractReminder(state, params) {
    return api.post(`/api/v1/tenants/${params.tenantId}/send-contract-reminder-email`, params.options).then((response) => response.data);
  },
  cancelContract(state, params) {
    return api.post(`/api/v1/tenants/${params.tenantId}/cancel-contract`, params.options).then((response) => response.data);
  },
  getTenantDeleteInfo(state, { applicationId }) {
    return api.get(`/api/v1/tenants/${applicationId}/delete-info`).then((response) => response.data);
  },
};
