<template>
  <MdtMailPreview
    v-if="mailPreviewLoaded"
    :title="'general_write_email' | translate"
    :mail-preview="mailData.mailPreview"
    :mail-template="mailData.mailTemplate"
    :mail-signature="mailData.mailSignature"
    :templates="mailData.templates"
    :contacts="mailData.contactGroups"
    :send-mail-disabled="sendMailDisabled"
    :allow-attachment-upload="allowAttachmentUpload"
    :selected-rows="selectedRows"
    show-info-card-close-btn
    @getMailPreview="getMailPreview"
    @sendMails="sendMails"
    @close="$emit('close')">
    <div
      slot="section-right-label"
      class="section-right-label">
      {{ receiversCount() }} {{ 'admin_recipients' | translate }}
    </div>
  </MdtMailPreview>
</template>

<script>
import { pdf } from '@/utility';

export default {
  name: 'ModalBulkMail',
  props: {
    selectedRows: {
      type: Array,
      required: true,
    },
    bulkOption: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sendMailDisabled: false,
      mailPreviewLoaded: false,
      mailData: {
        templates: [],
        contacts: [],
        mailTemplate: {
          subject: '',
          body: '',
        },
        mailPreview: {
          subject: '',
          body: '',
        },
        mailSignature: {
          subject: '',
          body: '',
        },
      },
      loading: false,
    };
  },
  computed: {
    allowAttachmentUpload() {
      return this.$route.name === 'Applications' || this.$route.name === 'Waitlist'
        || (this.$route.name === 'ApartmentDetails' && ['related_applications', 'related_interested_applications'].includes(this.bulkOption.mailView))
        || this.$route.name === 'Tenant' || this.$route.name === 'InterestedPeople';
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const targets = this.selectedRows;
      const { mailView } = this.bulkOption;
      this.$store.dispatch('table/getBulkMail', { targets, mailView }).then((data) => {
        this.mailData.templates = data.mailTemplates;
        this.mailData.contactGroups = data.contactGroups;
        this.mailData.mailSignature = data.newTemplate;
        this.mailPreviewLoaded = true;
      }).catch(this.showResponseError);
    },
    getMailPreview(data) {
      if (this.loading) return;
      const { mailView } = this.bulkOption;
      const contactGroup = this.getApplicationIdContactGroup(data.mailTemplate.adultId);
      const params = {
        ...data.mailTemplate,
        mailView,
        apartmentId: Number(this.$route.params.id),
        applicationId: contactGroup.applicationId,
      };
      this.loading = true;
      this.$store.dispatch('table/getReferenceMailPreview', params).then((mailPreview) => {
        this.mailData.mailPreview = mailPreview;
      }).catch(this.showResponseError)
        .finally(() => {
          this.loading = false;
          data.cancelLoading();
        });
    },
    sendMails(mailSettings) {
      if (this.loading) return;
      const { mailView } = this.bulkOption;
      const params = {
        ...mailSettings.params,
        mailView,
        apartmentId: Number(this.$route.params.id),
        applicationIds: this.selectedRows,
      };
      this.loading = true;
      this.sendMailDisabled = true;
      this.$store.dispatch('table/sendBulkMail', params).then((data) => {
        // Download pdf if response contains pdf
        pdf.download(data.fileUrl);
        this.$store.dispatch('notify', data.notify);
        if (this.$route.name === 'InterestedPeople') {
          this.$store.dispatch('table/getTableDataOnly');
        }
        // Refresh whole ProjectDetails page on send mail
        if (this.$route.name === 'ProjectDetails') {
          this.$store.dispatch('projects/getAllData').catch(this.showResponseError);
        }
        this.$emit('close');
      }).catch((error) => {
        this.showResponseError(error);
        // if sending mail bounced then
        // refresh tableData to display exclamation mark on messenger icon
        // this is use cases when data need to be refreshed without loader
        const tableRoutes = ['Applications', 'Waitlist', 'Tenant', 'InterestedPeople'];
        if (tableRoutes.includes(this.$route.name)) {
          this.$store.dispatch('table/getTableDataWithoutLoader');
        }
      })
        .finally(() => {
          this.sendMailDisabled = false;
          this.loading = false;
        });
    },
    receiversCount() {
      if (this.mailData.contactGroups) {
        let count = 0;
        this.mailData.contactGroups.forEach((contactGroup) => {
          count += contactGroup.contacts.length;
        });
        return count;
      }
      return 0;
    },
    getApplicationIdContactGroup(adultId) {
      return this.mailData.contactGroups
        .find((contactGroup) => contactGroup.contacts
          .some((contact) => contact.id === adultId));
    },
  },
};
</script>
