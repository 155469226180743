<template>
  <div class="table-allocation-request-messages">
    <i class="far fa-info-circle info-icon" />
    <span
      v-for="allocation in allocationRequestMessage"
      :key="allocation.key"
      class="allocation-request-message">
      {{ allocation.displayName }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'TableAllocationProposalSent',
  props: {
    allocationRequestMessage: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.table-allocation-request-messages {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 7.5px 12px;
  border-radius: 4px;
  margin-bottom: 20px;
  color: $color-text-secondary;
  background-color: $color-back-light;
  font-size: 14px;

  .info-icon {
    margin-right: 8px;
    font-size: 16px;
  }

  .request-date {
    margin-right: 5px;
    font-weight: $font-weight-bold;
  }
}
</style>
