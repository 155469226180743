<template>
  <MdtInput
    ref="mdtInput"
    class="mdt-input-password"
    :value="value"
    :type="showPassword ? 'text' : 'password'"
    v-bind="{
      ...defaultValidation,
      ...config,
    }"
    @input="onInput"
    @keyupEnter="$emit('keyupEnter', $event)">
    <i
      slot="icon"
      class="fas pointer password-icon"
      :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"
      @click="showPassword = !showPassword" />
  </MdtInput>
</template>

<script>
export default {
  name: 'MdtInputPassword',
  props: {
    value: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    useDefaultValidation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let defaultValidation = {};

    if (this.useDefaultValidation) {
      const tooltipInfo = this.$options.filters.translate('admin_allowed_characters_only').replace('[d]', '6-20');
      const space = this.$options.filters.translate('admin_space_character');
      const tooltipInfo2 = this.$options.filters.translate('admin_allowed_only')
        .replace('[d]', `a-z, A-Z, 0-9, ${space}, !"#$%&'()*+,-./:;<=>?@[]^_\`{|}~`);

      defaultValidation = {
        required: true,
        minLength: 6,
        maxLength: 20,
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/,
        tooltip: `${tooltipInfo}<br/>${tooltipInfo2}`,
      };
    }

    return {
      showPassword: false,
      defaultValidation,
    };
  },
  methods: {
    isValid() {
      return this.$refs.mdtInput.isValid();
    },
    onInput(event) {
      this.$emit('input', event);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');
    },
  },
};
</script>

<style lang="scss" scoped>
.password-icon {
  width: 20px;
  height: 16px;
}
</style>
