<template>
  <div
    class="container"
    @click="$emit('click')">
    <i class="fas fa-plus-circle" />
    {{ label }}
  </div>
</template>

<script>

export default {
  name: 'MdtCreateButton',
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: $color-back-card;
  color: $color-text-secondary;
  font-size: 14px;
  font-weight: $font-weight-bold;
  cursor: pointer;

  i {
    color: $color-theme;
    font-size: 24px;
  }

  &:hover i {
    color: rgba($color-theme-rgb, 0.8);
  }
}
</style>
