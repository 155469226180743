export default {
  sortKey: (state) => state.sortKey,
  searchVal: (state) => state.searchVal,
  pagination: (state) => state.pagination,
  basicFilters: (state) => state.basicFilters,
  additionalFilters: (state) => state.additionalFilters,
  activeTableStatusFilter: (state) => {
    if (!state.stateFilters) {
      return { key: undefined, choice: {} };
    }
    const choice = state.stateFilters?.choices
      ?.find((filter) => filter.active);
    return {
      key: state.stateFilters.key,
      choice: choice || {},
    };
  },
  // additional tables
  additionalTableApiPoint: (state) => (tableId) => state.additionalTables[tableId].apiPoint,
  additionalTableSortKey: (state) => (tableId) => state.additionalTables[tableId].sortKey,
  additionalTableSearchVal: (state) => (tableId) => state.additionalTables[tableId].searchVal,
  additionalTablePagination: (state) => (tableId) => state.additionalTables[tableId].pagination,
  additionalTableBasicFilters: (state) => (tableId) => state.additionalTables[tableId].basicFilters,
  additionalTableAdditionalFilters:
    (state) => (tableId) => state.additionalTables[tableId].additionalFilters,
  // saved table data on main route/page before going to detail view
  savedTableData: (state) => state.savedTableData,
  getCurrentAdditionalTableRequestData: (state) => state.currentAdditionalTableRequestData,
};
