var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mdt-input-password-with-confirm",class:[_vm.direction]},[_c('MdtInputPassword',{ref:"password",attrs:{"use-default-validation":"","config":{
      label: _vm.type === 'edit'
        ? _vm.$options.filters.translate('general_new_password')
        : _vm.$options.filters.translate('general_password'),
    }},on:{"mdtDataChanged":function($event){return _vm.$emit('mdtDataChanged')},"keyupEnter":function($event){return _vm.$emit('keyupEnter', $event)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('MdtInputPassword',{ref:"confirmPassword",attrs:{"value":_vm.value,"use-default-validation":"","config":{
      label: _vm.$options.filters.translate('general_confirm_password'),
      errors: _vm.confirmPasswordErrors,
    }},on:{"input":function($event){return _vm.$emit('input', $event)},"mdtDataChanged":function($event){return _vm.$emit('mdtDataChanged')},"keyupEnter":function($event){return _vm.$emit('keyupEnter', $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }