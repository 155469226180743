export default {
  setApplicationFormCredentials(state, payload) {
    state.formsCredentials = payload;
  },
  setApplicationFormSettings(state, payload) {
    state.formsSettings = payload;
  },
  setApplicationFormsNew(state, payload) {
    state.forms = payload;
  },
  setApplicationForm: (state, payload) => {
    state.applicationForm[payload.formKey] = payload.data;
  },
  setMinMaxOccupancyValidation: (state, payload) => {
    state.isMinMaxOccupancyValid = payload;
  },
};
