export default {
  formsCredentials: {
    slug: '',
    token: '',
  },
  forms: [],
  formsSettings: {},
  applicationForm: {},
  isMinMaxOccupancyValid: {
    adults: false,
    children: false,
  },
};
