<template>
  <div class="tableTitle">
    <div class="text">
      <template v-if="displayValue">
        <span v-if="title.label">
          {{ title.label }}:
        </span>
        <span class="font-bold">
          {{ displayValue }}
        </span>
      </template>
      <template v-else>
        <span class="font-bold">
          {{ title.label }}
        </span>
      </template>
    </div>
    <MdtEditButton
      v-if="title.editable"
      :editable="modalKey === title.key"
      @edit="modalKey = title.key" />
    <ModalBudgetTotalEdit
      v-if="modalKey === 'editCostCustomTotal'"
      :initial-value="value"
      @edited="edited($event)"
      @close="modalKey = ''" />
  </div>
</template>

<script>
import ModalBudgetTotalEdit from '@/components/budget/ModalBudgetTotalEdit.vue';

export default {
  name: 'TableTitle',
  components: {
    ModalBudgetTotalEdit,
  },
  props: {
    title: {
      required: true,
      type: [Object, String],
    },
  },
  data() {
    return {
      displayValue: typeof this.title === 'string' ? this.title : this.title?.displayValue,
      value: this.title?.value,
      modalKey: '',
    };
  },
  watch: {
    title(data) {
      this.displayValue = data.displayValue;
      this.value = data.value;
    },
  },
  methods: {
    edited(data) {
      this.displayValue = data.displayValue;
      this.value = Number(data.data.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.tableTitle {
  display: flex;
  flex-direction: flex-start;
  padding: 10px 0 20px 0;

  .text {
    font-size: 20px;
    margin-right: 10px;
  }
}
</style>
