// components/index.js
export default {
  MdtWizardText: () => import('@/components/shared/wizard/components/MdtWizardText.vue'),
  MdtWizardSeparator: () => import('@/components/shared/wizard/components/MdtWizardSeparator.vue'),
  CreateApartmentWizardArea: () => import('@/components/apartments/create-wizard/CreateApartmentWizardArea.vue'),
  CreateApartmentWizardPrices: () => import('@/components/apartments/create-wizard/CreateApartmentWizardPrices.vue'),
  CreateApartmentWizardAddress: () => import('@/components/apartments/create-wizard/CreateApartmentWizardAddress.vue'),
  CreateApartmentWizardUpload: () => import('@/components/apartments/create-wizard/CreateApartmentWizardUpload.vue'),
  CreateApartmentWizardRental: () => import('@/components/apartments/create-wizard/CreateApartmentWizardRental.vue'),
  CreateApartmentWizardAdditionalInformations: () => import('@/components/apartments/create-wizard/CreateApartmentWizardAdditionalInformations.vue'),
  CreateApartmentWizardSummary: () => import('@/components/apartments/create-wizard/CreateApartmentWizardSummary.vue'),
  CreateParkingWizardPrices: () => import('@/components/apartments/create-wizard/CreateParkingWizardPrices.vue'),
  CreateOtherWizardPrices: () => import('@/components/apartments/create-wizard/CreateOtherWizardPrices.vue'),
  CreateWizardSalePrices: () => import('@/components/apartments/create-wizard/CreateWizardSalePrices.vue'),
  MdtWizardInputs: () => import('@/components/projects/create-wizard/MdtWizardInputs.vue'),
  ProjectCreateWizardUploads: () => import('@/components/projects/create-wizard/ProjectCreateWizardUploads.vue'),
};
