<template>
  <div class="mdt-timepicker-range">
    <MdtTimepicker
      ref="from"
      :value="value.from"
      :label="`${$options.filters.translate('admin_marketing_on')} /
        ${$options.filters.translate('general_by')}`"
      :required="required"
      :readonly="readonly"
      @onSelectedInput="onInput($event, 'from')"
      @mdtDataChanged="$emit('mdtDataChanged')" />
    <MdtTimepicker
      ref="to"
      :value="value.to"
      :label="'general_at_the' | translate"
      :required="required"
      :readonly="readonly"
      :errors="errors"
      @onSelectedInput="onInput($event, 'to')"
      @mdtDataChanged="$emit('mdtDataChanged')" />
  </div>
</template>

<script>
export default {
  name: 'MdtTimepickerRange',
  props: {
    value: {
      type: Object,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    errors() {
      if (this.value.from && this.value.to) {
        const fromSeconds = moment.duration(this.value.from).asSeconds();
        const toSeconds = moment.duration(this.value.to).asSeconds();
        if (fromSeconds > toSeconds) {
          return [this.$options.filters.translate('admin_timepicker_range_error')];
        }
      }
      return [];
    },
  },
  methods: {
    onInput(time, type) {
      this.$emit('input', {
        from: type === 'from' ? time : this.value.from || '',
        to: type === 'to' ? time : this.value.to || '',
      });
    },
    isValid() {
      return Object.values(this.$refs).map((r) => r.isValid()).every((r) => r);
    },
  },
};
</script>

<style lang="scss" scoped>
$timepicker-margin: 5px;

.mdt-timepicker-range {
  display: flex;

  .mdt-timepicker {
    flex: 1;
    min-width: calc(50% - #{$timepicker-margin} / 2);

    &:first-child {
      margin-right: $timepicker-margin;
    }
  }
}
</style>
