export default {
  bootstrap: (state) => state.bootstrap,
  projectType: (state) => state.bootstrap?.settings?.project_type,
  isProjectType: (state) => (projectType) => {
    // state.bootstrap?.settings?.project_type is current project type
    // projectType is argument passed to getter
    let projectTypes = [];
    switch (state.bootstrap?.settings?.project_type) {
      case 'first-time-letting':
      case 'new':
      case 'market': {
        projectTypes = ['first-time-letting', 'new', 'market'];
        return projectTypes.includes(projectType);
      }
      case 'saleb2b':
      case 'invest': {
        projectTypes = ['saleb2b', 'invest'];
        return projectTypes.includes(projectType);
      }
      case 'reletting':
      case 'rent': {
        projectTypes = ['reletting', 'rent'];
        return projectTypes.includes(projectType);
      }
      default:
        return state.bootstrap?.settings?.project_type === projectType;
    }
  },
  projects: (state) => state.bootstrap?.projects || [],
  baseBackendUrl: (state) => state.bootstrap.settings.baseBackendUrl,
  projectTheme: (state) => {
    switch (state.bootstrap?.settings?.project_type) {
      case 'first-time-letting':
      case 'new':
      case 'market':
        return 'new';
      case 'sale':
        return 'sale';
      case 'saleb2b':
      case 'invest':
        return 'invest';
      case 'build':
        return 'build';
      case 'reletting':
      default:
        return 'rent';
    }
  },
  availableFeatures: (state) => state.bootstrap?.settings?.availableFeatures || {},
  siteTitle: (state) => state.siteTitle,
  language: (state) => state.language,
};
