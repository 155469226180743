// Directive is taken from https://stackoverflow.com/questions/36170425/detect-click-outside-element
export default {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (e) => {
      if (!(el === e.target || el.contains(e.target))) {
        vnode.context[binding.expression](e, binding.arg);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent, true);
    document.body.addEventListener('touchstart', el.clickOutsideEvent, true);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
    document.body.removeEventListener('touchstart', el.clickOutsideEvent);
  },
};
