import { ls } from '@/utility';
import User from '@/store/model/user/user';

export default {
  login(state, user) {
    state.user = new User(user);
    ls.user.set(state.user);
  },
  logout(state) {
    state.user = {};
    ls.user.remove();
  },
  updateUser(state, user) {
    // get current user from local storage
    const storageUser = ls.user.get();
    // update 'superadmin' role if it was set previously and new role is 'admin'
    if (storageUser.role === 'superadmin' && user.role === 'admin') user.role = 'superadmin';
    // update user
    user = { ...storageUser, ...user };
    // update user in state too
    state.user = { ...user };
    // set updated user to local storage
    ls.user.set(user);
  },
};
