import Vue from 'vue';
import { ls, url, helpers } from '@/utility';
import apiPointMaps from '@/store/model/api-point-maps/apiPointMaps';
import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
function preserveTableState(filter) {
  // replace query params in router (skip if route has the same query params)
  const queryParam = { [filter.key]: filter.value };
  if (JSON.stringify(Vue.router.currentRoute.query) !== JSON.stringify(queryParam)) {
    Vue.router.replace({ query: queryParam });
  }
  // set url params and history
  const urlParams = url.setParam(filter.key, filter.value);
  if (urlParams) window.history.pushState(null, null, `?${urlParams.toString()}`);
  else window.history.pushState(null, null, null);
}

function updatePagePath(pagePath, rootGetters) {
  // handle sales project type table routes
  const { projectType } = rootGetters;
  if (projectType === 'sale') {
    // update pagePath regarding matching application subpage
    Object.keys(apiPointMaps.getTableData.applications).forEach((key) => {
      if (apiPointMaps.getTableData.applications[key] === pagePath) pagePath = `sales/${apiPointMaps.getTableData.applications[key]}`;
    });
    // update pagePath regarding matching object subpage
    Object.keys(apiPointMaps.getTableData.objects).forEach((key) => {
      if (key !== 'Buildings' && apiPointMaps.getTableData.objects[key] === pagePath) pagePath = `sales/${apiPointMaps.getTableData.objects[key]}`;
    });
  }
  return pagePath;
}

function isMainTableRoute() {
  let match = false;
  Object.keys(apiPointMaps.getTableData).forEach((parentKey) => {
    const child = apiPointMaps.getTableData[parentKey];
    Object.keys(child).forEach((childKey) => {
      // get main table routes matches current route name
      if (childKey === Vue.router.currentRoute.name) match = true;
    });
  });
  return match;
}
let pagePath = '';
function prepareTableDataRequest(getters, rootGetters, path) {
  pagePath = path || pagePath;

  // update pagePath regarding project type
  pagePath = updatePagePath(pagePath, rootGetters);

  const {
    pagination,
    savedTableData,
    activeTableStatusFilter: tableStatus,
    basicFilters,
    searchVal,
  } = getters;

  // get table pagination rowsPerPage from storage
  const { rowsPerPage } = ls.pagination.get();

  // Get table filter state
  const urlParamEntries = url.getParams().entries();
  const basicFilter = {};
  // eslint-disable-next-line
  for (const entry of urlParamEntries) {
    // eslint-disable-next-line
    basicFilter[entry[0]] = entry[1];
  }

  const options = {
    params: {},
    cancelToken: Vue.axiosRequest.createCancelToken('getTableData'),
  };

  const savedTableRouteMatchesCurrent = savedTableData.tableRoute !== null
    && savedTableData.tableRoute?.path === Vue.router.currentRoute?.path;

  // there is saved table data for current route
  if (savedTableRouteMatchesCurrent) {
    options.params = {
      page: savedTableData?.pagination?.currentPage,
      rowsPerPage: savedTableData?.pagination?.rowsPerPage || rowsPerPage,
      search: helpers.valueOrUndefined(savedTableData?.searchVal),
      sort: helpers.valueOrUndefined(savedTableData?.sortKey),
      [tableStatus.key]: tableStatus.choice.value,
      ...savedTableData?.basicFilters,
      ...savedTableData?.additionalFilters,
    };
  } else { // there are no saved table data for current route
    options.params = {
      rowsPerPage: rowsPerPage || pagination?.rowsPerPage,
      search: helpers.valueOrUndefined(searchVal),
      ...basicFilter, // basic filters from url at first table load, e.g facets-progress_state
      ...basicFilters, // basic filters from store
    };
  }
  return { options, savedTableRouteMatchesCurrent };
}
function triggerGetTableDataRequest(
  options,
  savedTableRouteMatchesCurrent,
  commit,
  mutationName,
) {
  api.get(`/api/v1/${pagePath}`, options).then((response) => {
    // set updateFromSavedTableData prop needed in setTableData
    response.data.updateFromSavedTableData = savedTableRouteMatchesCurrent;
    commit(mutationName, response.data);
    commit('setLoading', false);
    // delete saved table data if current route match main table route
    if (savedTableRouteMatchesCurrent || isMainTableRoute()) {
      window.requestAnimationFrame(() => commit('deleteSavedTableData'));
    }
  }).catch(() => {
    commit('deleteTableData', {});
  });
}
export default {
  getTableData({ commit, getters, rootGetters }, path) {
    const {
      options,
      savedTableRouteMatchesCurrent,
    } = prepareTableDataRequest(getters, rootGetters, path);
    commit('setLoading', true);
    triggerGetTableDataRequest(options, savedTableRouteMatchesCurrent, commit, 'setTableData');
  },
  getTableDataWithoutLoader({ commit, getters, rootGetters }, path) {
    const {
      options,
      savedTableRouteMatchesCurrent,
    } = prepareTableDataRequest(getters, rootGetters, path);
    triggerGetTableDataRequest(options, savedTableRouteMatchesCurrent, commit, 'setTableDataOnly');
  },
  getTableDataOnly({ commit, getters }, settings) {
    // get table pagination rowsPerPage from storage
    const { rowsPerPage } = ls.pagination.get();

    const {
      pagination,
      sortKey,
      searchVal,
      basicFilters,
      activeTableStatusFilter: tableStatus,
      additionalFilters,
    } = getters;
    const options = {
      params: {
        tableDataOnly: true,
        [tableStatus.key]: tableStatus?.choice?.value,
        page: settings?.page || pagination?.currentPage,
        rowsPerPage: rowsPerPage || pagination?.rowsPerPage,
        search: helpers.valueOrUndefined(searchVal),
        sort: helpers.valueOrUndefined(sortKey),
        refreshStateFilters: true,
        ...basicFilters,
        ...additionalFilters,
      },
    };
    commit('setLoading', true);
    api.get(`/api/v1/${pagePath}`, options).then((response) => {
      commit('setTableDataOnly', response.data);
      commit('setLoading', false);
    }).catch(() => {
      commit('deleteTableData', {});
    });
  },
  refreshRow({ commit, dispatch, rootGetters }, params) {
    const tableId = typeof params === 'object' ? params.tableId : null;
    const rowId = typeof params === 'object' ? params.rowId : params;

    if (!rowId) return;

    const options = {
      params: {
        refreshItemId: rowId,
      },
    };

    // if default table
    if (!tableId) {
      commit('setLoading', true);
      api.get(`/api/v1/${pagePath}`, options).then((response) => {
        const refreshTableData = !response?.data?.tableData?.body
          || !response.data.tableData.body.length;
        if (refreshTableData) { // if tableData body returned []
          // close sidepanel
          dispatch('sidepanel/close', null, { root: true });
          // refresh all table data
          dispatch('getTableDataOnly');
        } else { // refresh only table row data
          commit('refreshRow', {
            id: rowId,
            data: response.data,
          });
          commit('setLoading', false);
        }
      });
    } else { // if additional table
      commit('table/setAdditionalTableLoading', { tableId, loading: true }, { root: true });

      // get additional table api point
      const apiPoint = rootGetters['table/additionalTableApiPoint'](tableId);

      api.get(apiPoint, options).then((response) => {
        const refreshTableData = !response?.data?.tableData?.body
          || !response.data.tableData.body.length;
        if (refreshTableData) { // if tableData body returned []
          // close sidepanel
          dispatch('sidepanel/close', null, { root: true });
          // refresh all additional table data
          const additionalOptions = {
            tableId,
            additional: {
              apiPoint,
            },
            dataOnly: true,
          };
          dispatch('getAdditionalTableData', additionalOptions);
        } else { // refresh only table row data
          commit('refreshRow', {
            id: rowId,
            data: response.data,
            tableId,
          });
          commit('table/setAdditionalTableLoading', { tableId, loading: false }, { root: true });
        }
      });
    }
  },
  setFavorite({ commit }, data) {
    const options = {
      overall_star: data.checked,
    };
    return api.put(`/api/v1/applications/${data.id}/evaluation`, options).then(() => {
      commit('setFavorite', data);
    });
  },
  setFavoriteInvestors({ commit }, data) {
    const options = {
      checked: data.checked,
    };
    return api.post(`api/v1/investors/${data.id}/${data.applicationId}/create-or-update-favorite-apply`, options).then(() => {
      commit('setFavorite', data);
    });
  },
  setTableBasicFilters({ commit, dispatch }, filters) {
    // Set basic filters
    commit('setTableBasicFilters', { filters });

    // Set pagination to 1. page and get data
    const options = [{
      key: 'currentPage',
      value: 1,
    }];
    dispatch('updatePagination', options);
  },
  setTableAdditionalFilters({ commit, dispatch }, filter) {
    // Active table additional filters
    commit('setTableAdditionalFilters', { filters: filter });

    // Set pagination to 1. page and get data
    const options = [{
      key: 'currentPage',
      value: 1,
    }];
    dispatch('updatePagination', options);
  },
  setTableStatusFilter({ commit, dispatch }, filter) {
    // Active table status filter
    commit('setTableStatusFilter', { filter });
    preserveTableState(filter);

    // Set pagination to 1. page and get data
    const options = [{
      key: 'currentPage',
      value: 1,
    }];
    dispatch('updatePagination', options);
  },
  sortTableData({ commit, dispatch }, sortKey) {
    commit('updateSortKey', { sortKey });
    dispatch('getTableDataOnly');
  },
  updatePagination({ commit, dispatch }, options) {
    commit('updatePagination', { options });
    dispatch('getTableDataOnly');
  },
  search({ commit, dispatch }, searchVal) {
    commit('updateSearchVal', { searchVal });

    // Set pagination to 1. page and get data
    const options = [{
      key: 'currentPage',
      value: 1,
    }];
    dispatch('updatePagination', options);
  },
  deleteSelectedRows({ dispatch }, params) {
    return api.post(`/api/v1/${pagePath}`, params).then((response) => {
      dispatch('getTableDataOnly');
      return response.data;
    });
  },
  deleteTableData({ commit }, params) {
    commit('deleteTableData', params || {});
  },
  getReferenceMails(state, settings) {
    const params = { targets: settings.targets.join(',') };
    return api.get(`/api/v1/communication/${settings.type}/reference-mails`, { params })
      .then((response) => response.data);
  },
  getReferenceMailPreview(state, params) {
    return api.post('/api/v1/communication/mail-preview', params, { params: params.params })
      .then((response) => response.data);
  },
  sendReferenceMails(state, settings) {
    // Used to send mails and download pdf
    return api.post(`/api/v1/communication/${settings.type}/reference-mails`, settings.params)
      .then((response) => response.data);
  },
  getBulkMail(state, settings) {
    const params = {
      targets: settings.targets.join(','),
      mailView: settings.mailView,
      apartmentId: state.rootGetters['apartments/apartmentId'],
    };

    return api.get('/api/v1/communication/bulk-mail', { params })
      .then((response) => response.data);
  },
  sendBulkMail(state, params) {
    // Used to send mails and download pdf
    return api.post('/api/v1/communication/bulk-mail', params, { timeout: 0 })
      .then((response) => response.data);
  },
  deactivateApplications(state, params) {
    return api.post('/api/v1/applications/', params).then((response) => response.data);
  },
  activateApplications(state, params) {
    return api.post('api/v1/applications/', params).then((response) => response.data);
  },
  setLoading({ commit }, loading) {
    commit('setLoading', loading);
  },
  setTableBasicFiltersOnly({ commit }, settings) {
    commit('setTableBasicFilters', settings);
  },
  updateSortKeyOnly({ commit }, settings) {
    commit('updateSortKey', settings);
  },
  updatePaginationOnly({ commit }, settings) {
    commit('updatePagination', settings);
  },
  updateSearchValOnly({ commit }, settings) {
    commit('updateSearchVal', settings);
  },
  setTableStatusFilterOnly({ commit }, settings) {
    // Active table status filter
    commit('setTableStatusFilter', settings);
    preserveTableState(settings.filter);
  },
  setTableAdditionalFiltersOnly({ commit }, settings) {
    // Active table additional filters
    commit('setTableAdditionalFilters', settings);
  },
  prepareAdditionalTable({ commit }, tableId) {
    commit('prepareAdditionalTable', tableId);
  },
  setAdditionalTableLoading({ commit }, settings) {
    commit('setAdditionalTableLoading', settings);
  },
  // save table data before going to detail view
  saveTableData({ commit }, params) {
    commit('saveTableData', params);
  },
  deleteSavedTableData({ commit }) {
    commit('deleteSavedTableData');
  },
  getAdditionalTableData({ rootGetters, commit }, settings) {
    // get table pagination rowsPerPage from storage
    const { rowsPerPage } = ls.pagination.get();

    const pagination = rootGetters['table/additionalTablePagination'](settings.tableId);
    const sortKey = rootGetters['table/additionalTableSortKey'](settings.tableId);
    const searchVal = rootGetters['table/additionalTableSearchVal'](settings.tableId);
    const basicFilters = rootGetters['table/additionalTableBasicFilters'](settings.tableId);
    const additionalFilters = rootGetters['table/additionalTableAdditionalFilters'](settings.tableId);
    const options = {
      params: {
        page: helpers.valueOrUndefined(pagination?.currentPage),
        rowsPerPage: helpers.valueOrUndefined(rowsPerPage || pagination?.rowsPerPage),
        search: helpers.valueOrUndefined(searchVal),
        ...basicFilters,
        ...additionalFilters,
        sort: helpers.valueOrUndefined(sortKey),
        ...settings.params,
      },
    };
    if (!settings.dataOnly) {
      commit('table/setAdditionalTableLoading', {
        tableId: settings.tableId,
        loading: true,
      }, { root: true });
    }
    return api.get(settings.additional.apiPoint, options).then((response) => {
      settings.table = response.data;
      if (settings.dataOnly) commit('table/setAdditionalTableDataOnly', settings, { root: true });
      else commit('table/setAdditionalTableData', settings, { root: true });
      if (!settings.dataOnly) {
        commit('table/setAdditionalTableLoading', {
          tableId: settings.tableId,
          loading: false,
        }, { root: true });
      }
    }).catch(() => {
      commit('deleteTableData', { tableId: settings.tableId });
    });
  },
  downloadExcel({ dispatch }, data) {
    dispatch('table/setLoading', true, { root: true });
    const downloadPath = data.additionalTableApi || `/api/v1/${pagePath}`;
    const params = {
      bulkAction: 'exportExcel',
      ...data.payload,
    };

    return api.post(downloadPath, params, { responseType: 'arraybuffer', timeout: 0 })
      .finally(() => { dispatch('table/setLoading', false, { root: true }); });
  },
  setCurrentAdditionalTableRequestData({ commit }, data) {
    commit('setCurrentAdditionalTableRequestData', data);
  },
};
