import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
export default {
  getMailSignatureSettings() {
    return api.get('/api/v1/settings/email-signature').then((response) => response.data);
  },
  postMailSignatureSettings(state, params) {
    return api.post('/api/v1/settings/email-signature', params).then((response) => response.data);
  },
  getMailTemplateCreateSettings() {
    return api.get('/api/v1/settings/email-templates/create').then((response) => response.data);
  },
  saveMailTemplateCreateSettings(state, settings) {
    return api.post('/api/v1/settings/email-templates/create', settings.params).then((response) => response.data);
  },
  getMailTemplateEditSettings(state, templateId) {
    return api.get(`/api/v1/settings/email-templates/${templateId}/edit`).then((response) => response.data);
  },
  saveMailTemplateEditSettings(state, settings) {
    return api.post(`/api/v1/settings/email-templates/${settings.templateId}/edit`, settings.params).then((response) => response.data);
  },
  saveMailTemplateAutomated(state, settings) {
    return api.post(`/api/v1/settings/email-templates/${settings.tableId}`, settings.params).then((response) => response.data);
  },
  deleteTemplates(state, settings) {
    return api.post(`/api/v1/settings/email-templates/${settings.tableId}`, settings.params).then((response) => response.data);
  },
  getProjectSettings() {
    return api.get('/api/v1/settings/project').then((response) => response.data);
  },
  postProjectSettings(state, params) {
    return api.post('/api/v1/settings/project', params).then((response) => response.data);
  },
  getProcessSettings() {
    return api.get('/api/v1/settings/process').then((response) => response.data);
  },
  postProcessSettings(state, params) {
    return api.post('/api/v1/settings/process', params).then((response) => response.data);
  },
  getDocumentFylerSettings() {
    return api.get('/api/v1/settings/flyer').then((response) => response.data);
  },
  postDocumentFylerSettings(state, params) {
    return api.post('/api/v1/settings/flyer', params).then((response) => response.data);
  },
  getDocumentBusinessCardSettings() {
    return api.get('/api/v1/settings/business-card').then((response) => response.data);
  },
  postDocumentBusinessCardSettings(state, params) {
    return api.post('/api/v1/settings/business-card', params).then((response) => response.data);
  },
  getReservationSettings() {
    return api.get('/api/v1/settings/reservations').then((response) => response.data);
  },
  postReservationSettings(state, params) {
    return api.post('/api/v1/settings/reservations', params).then((response) => response.data);
  },
  getWebsiteSettings() {
    return api.get('/api/v1/settings/website').then((response) => response.data);
  },
  postWebsiteSettings(state, params) {
    return api.post('/api/v1/settings/website', params).then((response) => response.data);
  },
  getUserCreateProfileSettings() {
    return api.get('/api/v1/users/create').then((response) => response.data);
  },
  saveUserCreateProfileSettings(state, options) {
    return api.post('/api/v1/users/create', options.params).then((response) => response.data);
  },
  getExistingUserCreateProfileSettings() {
    return api.get('/api/v1/users/add').then((response) => response.data);
  },
  saveExistingUserCreateProfileSettings(state, params) {
    return api.post('/api/v1/users/add', params).then((response) => response.data);
  },
  getUserEditProfileSettings(state, userId) {
    return api.get(`/api/v1/users/${userId}/edit`).then((response) => response.data);
  },
  saveUserEditProfileSettings(state, options) {
    return api.post(`/api/v1/users/${options.userId}/edit`, options.params).then((response) => response.data);
  },
  changeUserEditProfilePassword(state, options) {
    return api.post(`/api/v1/users/${options.userId}/reset-password`, options.params).then((response) => response.data);
  },
  deleteUsers(state, params) {
    return api.post('/api/v1/settings/users', params).then((response) => response.data);
  },
  getTeamCreateProfileSettings() {
    return api.get('/api/v1/teams/create').then((response) => response.data);
  },
  saveTeamCreateProfileSettings(state, options) {
    return api.post('/api/v1/teams/create', options.params).then((response) => response.data);
  },
  getTeamEditProfileSettings(state, teamId) {
    return api.get(`/api/v1/teams/${teamId}/edit`).then((response) => response.data);
  },
  saveTeamEditProfileSettings(state, options) {
    return api.post(`/api/v1/teams/${options.teamId}/edit`, options.params).then((response) => response.data);
  },
  deleteTeams(state, params) {
    return api.post('/api/v1/settings/teams', params).then((response) => response.data);
  },
  saveRoleSettings(state, params) {
    return api.post('/api/v1/settings/roles', params).then((response) => response.data);
  },
  getCreditCheckSettings() {
    return api.get('/api/v1/settings/credit-check').then((response) => response.data);
  },
  postCreditCheckSettings(state, params) {
    return api.post('/api/v1/settings/credit-check', params).then((response) => response.data);
  },
  getAbaImmoSettings() {
    return api.get('/api/v1/settings/abbaimmo').then((response) => response.data);
  },
  postAbaImmoSettings(state, params) {
    return api.post('/api/v1/settings/abbaimmo', params).then((response) => response.data);
  },
  getOrganization() {
    return api.get('/api/v1/settings/organization').then((response) => response.data);
  },
  postOrganization(state, params) {
    return api.post('/api/v1/settings/organization', params).then((response) => response.data);
  },
  getRentalPlatformsSettings() {
    return api.get('/api/v1/settings/marketing-platforms').then((response) => response.data);
  },
  postRentalPlatformsSettings(state, params) {
    return api.post('/api/v1/settings/marketing-platforms', params).then((response) => response.data);
  },
  getDigitalSignatureSettings() {
    return api.get('/api/v1/settings/digital-signature').then((response) => response.data);
  },
  postDigitalSignatureSettings(state, params) {
    return api.post('/api/v1/settings/digital-signature', params).then((response) => response.data);
  },
  getOwnerCreate() {
    return api.get('/api/v1/settings/owners/create').then((response) => response.data);
  },
  createOwner(state, payload) {
    return api.post('/api/v1/settings/owners/create', payload).then((response) => response.data);
  },
  createExistingOwner(state, payload) {
    return api.post('/api/v1/settings/owners/add', payload).then((response) => response.data);
  },
  getOwnerEdit(state, ownerId) {
    return api.get(`/api/v1/settings/owners/${ownerId}/edit`).then((response) => response.data);
  },
  editOwner(state, params) {
    return api.post(`/api/v1/settings/owners/${params.ownerId}/edit`, params.payload).then((response) => response.data);
  },
  changeOwnerPassword(state, params) {
    return api.post(`/api/v1/owners/${params.ownerId}/reset-password`, params.payload).then((response) => response.data);
  },
  deleteOwners(state, targets) {
    return api.post('/api/v1/settings/owners', { bulkAction: 'delete', targets }).then((response) => response.data);
  },
  getDmsRoles() {
    return api.get('/api/v1/dms/settings/roles').then((response) => response.data);
  },
  createDmsRole(state, name) {
    const payload = { name };
    return api.post('/api/v1/dms/settings/roles', payload).then((response) => response.data);
  },
  renameDmsRole(state, { id, payload }) {
    return api.patch(`/api/v1/dms/settings/roles/${id}`, payload).then((response) => response.data);
  },
  deleteDmsRole(state, id) {
    return api.delete(`/api/v1/dms/settings/roles/${id}`).then((response) => response.data);
  },
  getDmsCategories() {
    return api.get('/api/v1/dms/settings/categories').then((response) => response.data);
  },
  createDmsCategory(state, params) {
    return api.post('/api/v1/dms/settings/categories', params.payload).then((response) => response.data);
  },
  renameDmsCategory(state, params) {
    return api.patch(`/api/v1/dms/settings/categories/${params.categoryId}`, params.payload).then((response) => response.data);
  },
  deleteDmsCategory(state, params) {
    return api.delete(`/api/v1/dms/settings/categories/${params.categoryId}`).then((response) => response.data);
  },
  changeCategoryRoleAccess(state, params) {
    return api.put(`/api/v1/dms/settings/categories/${params.categoryId}`, params.payload).then((response) => response.data);
  },
  getAuthentication() {
    return api.get('/api/v1/settings/authentication').then((response) => response.data);
  },
  postAuthentication(state, params) {
    return api.post('/api/v1/settings/authentication', params).then((response) => response.data);
  },
  getFormOptions() {
    return api.get('/api/v1/settings/form-settings').then((response) => response.data);
  },
  getFormType(state, typeValue) {
    return api.get(`/api/v1/settings/form-settings/${typeValue}`).then((response) => response.data);
  },
  getFormData(state, formId) {
    return api.get(`/api/v1/settings/form/${formId}`).then((response) => response.data);
  },
  postTableFormData(state, settings) {
    const {
      formId,
      sectionId,
      fieldId,
      payload,
    } = settings;
    return api.post(`/api/v1/settings/form/${formId}/section/${sectionId}/field/${fieldId}`, payload).then((response) => response.data);
  },
  getTableFormData(state, settings) {
    const {
      formId,
      sectionId,
      fieldId,
    } = settings;

    return api.get(`/api/v1/settings/form/${formId}/section/${sectionId}/field/${fieldId}`).then((response) => response.data);
  },
  postSectionInfoText(state, settings) {
    const {
      formId,
      sectionId,
      payload,
    } = settings;

    return api.post(`/api/v1/settings/form/${formId}/section/${sectionId}`, payload).then((response) => response.data);
  },
  getSectionInfoText(state, settings) {
    const {
      formId,
      sectionId,
    } = settings;

    return api.get(`/api/v1/settings/form/${formId}/section/${sectionId}`).then((response) => response.data);
  },
  getFormSettings() {
    return api.get('/api/v1/settings/form-settings/general').then((response) => response.data);
  },
  postFormSettings(state, payload) {
    return api.post('/api/v1/settings/form-settings/general', payload).then((response) => response.data);
  },
};
