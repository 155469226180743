<template>
  <div class="navigation-top-communication">
    <div
      class="icon-wrapper flex-center pointer communication-wrapper"
      @click="goToCommunication">
      <i class="fas fa-envelope" />
      <div
        v-if="unreadMessages > 0"
        class="communication-counter"
        :class="{
          'more-then-9': unreadMessages > 9,
          'more-then-99': unreadMessages > 99,
        }">
        <template v-if="unreadMessages <= 99">
          {{ unreadMessages }}
        </template>
        <template v-else>
          99+
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationTopCommunication',
  computed: {
    unreadMessages() {
      return this.$store.getters.bootstrap.unread_messages;
    },
  },
  methods: {
    goToCommunication() {
      if (this.$route.name !== 'Communication') {
        this.$router.push({ name: 'Communication' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.communication-wrapper {
  position: relative;

  .communication-counter {
    position: absolute;
    top: -5px;
    right: -1px;
    min-width: 16px;
    height: 16px;
    padding: 0 4px;
    color: $color-white;
    background: #ec5252;
    border: 1px solid $color-white;
    border-radius: 8px;
    font-weight: $font-weight-bold;
    text-align: center;
    line-height: 14px;
    font-size: 11px;
    transition: all 0.3s;
    z-index: 1;

    &.more-then-9 {
      right: -8px;
    }
    &.more-then-99 {
      right: -12px;
    }
  }
}
</style>
