import routeMap from '@/store/model/route-map/routeMap';

// isRoutePermitted ref
let isRoutePermitted;

// list of all prefered backup routes which will be used if permission fails for wanted route
const preferedBackupRoutes = [
  routeMap.home, // Home
  routeMap.interestedPeople, // interested-people
  routeMap.applications, // applications
  routeMap.objects, // objects
  routeMap.tenant, // tenant
  routeMap.analytics, // analytics
  routeMap.reporting, // reporting
  routeMap.budget, // budget
];

// get prevered or backup prefered route
const preferedOrFirstPermittedRedirectUrl = (preferedUrl) => {
  // if user has permission for prefered route -> return prefered route
  if (isRoutePermitted(preferedUrl) === true) return preferedUrl;
  // otherwise return first backup prefered route which the user has permission for it
  let backupPreferedUrl = preferedUrl;
  for (let i = 0; i < preferedBackupRoutes.length; i++) {
    const route = preferedBackupRoutes[i];
    // skip preferedUrl from checking and check permission
    if (preferedUrl !== route.urlName && isRoutePermitted(route.urlName) === true) {
      backupPreferedUrl = route.urlName;
      break;
    }
  }
  return backupPreferedUrl;
};

// set proper redirect url regarding user role/permissions
export default {
  redirectData(isRoutePermittedGetter, user) {
    // set isRoutePermitted ref
    isRoutePermitted = isRoutePermittedGetter;

    // define redirect data
    const redirectData = {
      url: '',
      permitted: false,
    };

    // if there is no user in local storage
    // set 'Home' as prefered redirect page if permitted, otherwise first backup permitted
    // check is redirect url permitted and return redirect data
    if (!Object.keys(user).length) {
      // get redirect url
      redirectData.url = preferedOrFirstPermittedRedirectUrl(routeMap.home.urlName);
      // check is redirect url permitted
      redirectData.permitted = isRoutePermitted(redirectData.url) === true;
      return redirectData;
    }
    // check user role and set proper prefered route regarding it
    switch (user.role) {
      // user role is [superadmin|admin|superuser|user|assistant]
      // set 'Home' as prefered redirect page if permitted, otherwise first backup permitted
      case 'superadmin':
      case 'admin':
      case 'superuser':
      case 'user':
      case 'assistant':
        redirectData.url = preferedOrFirstPermittedRedirectUrl(routeMap.home.urlName);
        break;
      // user role is [marketer]
      // set 'objects' as prefered redirect page if permitted, otherwise first backup permitted
      case 'marketer':
        redirectData.url = preferedOrFirstPermittedRedirectUrl(routeMap.objects.urlName);
        break;
      // user role is [contract]
      // set 'tenant' as prefered redirect page if permitted, otherwise first backup permitted
      case 'contract':
        redirectData.url = preferedOrFirstPermittedRedirectUrl(routeMap.tenant.urlName);
        break;
      // user role is [analyst]
      // set 'analytics' as prefered redirect page if permitted, otherwise first backup permitted
      case 'analyst':
        redirectData.url = preferedOrFirstPermittedRedirectUrl(routeMap.analytics.urlName);
        break;
      // by default -> return 'Home' as prefered redirect page without checking a permission
      default:
        redirectData.url = routeMap.home.urlName;
        break;
    }
    // check is redirect url permitted
    redirectData.permitted = isRoutePermitted(redirectData.url) === true;
    // return redirect data
    return redirectData;
  },
};
