<template>
  <MdtModal
    :title="title"
    @close="$emit('close')"
    @cancel="$emit('close')"
    @save="$emit('saveReorderSettings', ordered)">
    <MdtMessage
      v-if="max !== Number.MAX_SAFE_INTEGER"
      :type="'info'"
      :msg="$options.filters.translate('admin_max_info_items_message').replace('[d]', max)"
      class="max-items-info-message" />
    <div class="mdt-reorder">
      <div class="reorder-column">
        <div class="search-wrapper">
          <div class="search-wrapper-input">
            <MdtInput
              v-model="search.unselected"
              :label="'general_columns' | translate"
              :placeholder="'general_search' | translate">
              <i
                slot="icon"
                class="fa fa-search icon" />
            </MdtInput>
          </div>
        </div>
        <div
          class="column-items-label select-all pointer"
          @click="selectAll">
          {{ 'general_select_all' | translate }}
        </div>
        <vue-scroll class="reorder-scroll-left">
          <div
            v-packery="{ itemSelector: '.draggable' }"
            class="column-items unselected-items">
            <div
              v-for="(item, i) in unselectedItems"
              :key="i"
              v-packery-item
              v-draggabilly="{ axis: 'y' }"
              class="column-item-wrapper draggable"
              @click="select(item)">
              <div class="column-item">
                <span class="item-name">
                  {{ item.displayName }}
                  <i :class="item.icon" />
                </span>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
      <div class="reorder-column">
        <div class="search-wrapper">
          <div class="search-wrapper-input">
            <MdtInput
              v-model="search.selected"
              :label="'admin_selected_columns' | translate"
              :placeholder="'general_search' | translate">
              <i
                slot="icon"
                class="fa fa-search icon" />
            </MdtInput>
          </div>
        </div>
        <div
          class="column-items-label unselect-all pointer"
          @click="unselectAll">
          <i class="fas fa-trash icon-unselect" />
          {{ 'general_clear_all' | translate }}
        </div>
        <vue-scroll class="reorder-scroll-right">
          <div
            ref="packery"
            v-packery="{ itemSelector: '.draggable' }"
            class="column-items column-items-draggable selected-items"
            @layoutComplete="reorderSelected"
            @dragItemPositioned="reorderSelected">
            <div
              v-for="(item, i) in selectedItems"
              :key="i"
              v-packery-item
              v-draggabilly="{ axis: 'y' }"
              :data-name="item.value"
              class="column-item-wrapper draggable">
              <div class="column-item numbered">
                <span class="item-num">
                  {{ i + 1 }}.
                </span>
                <span
                  class="item-name">
                  {{ item.displayName }}
                  <i :class="item.icon" />
                </span>
              </div>
              <div
                class="column-item-remove"
                @click="unselect(item)">
                <i class="fas fa-times-circle" />
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
  </MdtModal>
</template>

<script>
export default {
  name: 'MdtReorder',
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    orderedKeys: {
      type: Array,
      required: true,
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
  },
  data() {
    return {
      search: {
        unselected: '',
        selected: '',
      },
      unselected: [],
      selected: [],
      ordered: [],
    };
  },
  computed: {
    selectedItems() {
      if (this.search.selected.length < 2) return this.selected;

      return this.selected.filter((selected) => selected.displayName?.toLowerCase()
        .includes(this.search.selected.toLowerCase()));
    },
    unselectedItems() {
      if (this.search.unselected.length < 2) return this.unselected;

      return this.unselected.filter((unselected) => unselected.displayName?.toLowerCase()
        .includes(this.search.unselected.toLowerCase()));
    },
  },
  mounted() {
    // Set selected in correct order
    this.orderedKeys.forEach((key) => {
      const item = this.items.find((col) => col.value === key);
      if (item) this.selected.push(item);
    });

    // Set unselected
    this.unselected = this.items.filter((col) => !this.orderedKeys.includes(col.value));
  },
  methods: {
    selectAll() {
      if (this.max !== Number.MAX_SAFE_INTEGER) {
        if (this.selected.length < this.max) {
          const unselected = this.unselected.splice(0, this.max - this.selected.length);
          this.selected = [...this.selected, ...unselected];
        }
      } else {
        this.selected = [...this.selected, ...this.unselected];
        this.unselected = [];
        this.search.unselected = '';
      }
    },
    unselectAll() {
      this.unselected = [...this.unselected, ...this.selected];
      this.selected = [];
      this.search.selected = '';
    },
    select(item) {
      if (this.max === Number.MAX_SAFE_INTEGER
        || (this.max !== Number.MAX_SAFE_INTEGER && this.selected.length < this.max)
      ) {
        const index = this.unselected.indexOf(item);
        this.selected.push(item);
        this.unselected.splice(index, 1);
      }
    },
    unselect(item) {
      const index = this.selected.indexOf(item);
      this.unselected.unshift(item);
      this.selected.splice(index, 1);
    },
    reorderSelected() {
      if (this.$refs.packery) {
        this.ordered = [];

        const children = this.$refs.packery.packery.getItemElements();
        children.forEach((child) => {
          const { name } = child.dataset;
          this.ordered.push(name);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.max-items-info-message {
  margin-bottom: 20px;
}

.mdt-reorder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 750px;

  ::v-deep {
    .input-label {
      font-size: 1rem;
      font-weight: $font-weight-normal;
    }
  }
}

.reorder-column {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-height: 460px;
  width: 49%;
  padding: 24px 24px 0 24px;
  background-color: $color-back-light;
  border-radius: 8px;
}

.reorder-scroll-left.hasVBar {
  .column-item-wrapper {
    width: calc(100% - 17px);
  }
}

.reorder-scroll-right.hasVBar {
  padding-right: 17px !important;

  .column-item-wrapper {
    width: calc(100% - 17px);
  }
}

.column-items-label {
  padding: 16px 0;
  font-size: 14px;
  font-weight: $font-weight-bold;
  transition: color 0.2s ease;

  &.select-all { color: $color-text-important; }
  &.select-all:hover { color: rgba($color-text-important, 0.8); }
  &.unselect-all { color: #f06872; }
  &.unselect-all:hover { color: rgba(#f06872, 0.8); }

  .icon-unselect {
    padding-right: 8px;
  }
}

.column-items {
  padding-bottom: 24px;
  overflow: hidden;
}

.column-item-wrapper {
  display: flex;
  float: left;
  width: 100%;
  margin-bottom: 4px;
  line-height: 38px;

  &:last-child {
    margin-bottom: 0;
  }
}

.column-item {
  width: 100%;
  background-color: $color-white;
  border: 1px solid $border-color;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &.numbered {
    cursor: move;
  }

  &:hover {
    border-color: $color-text-secondary;
  }

  .item-num {
    display: inline-block;
    width: 40px;
    color: $color-text-primary;
    background-color: $color-back-basic;
    font-size: 16px;
    font-weight: $font-weight-bold;
    text-align: center;
    border-radius: 4px 0 0 4px;
  }

  .item-name {
    padding: 0 12px;
    font-size: 16px;
  }
}

.column-item-remove {
  padding-left: 10px;
  color: $color-text-secondary;
  text-align: right;
  cursor: pointer;

  &:hover {
    color: $color-text-primary;
  }

  & > i {
    line-height: inherit;
  }
}
</style>
