<template>
  <div class="mdt-input-range">
    <div
      v-if="label || slotExists"
      class="input-range-label">
      <span v-if="label">
        {{ label }}
        <span v-if="!required">({{ 'admin_marketing_optional' | translate }})</span>
      </span>
      <slot name="min-max" />
    </div>
    <div :class="`input-range-${direction}`">
      <div class="from">
        <MdtInput
          ref="from"
          v-model="selectedData[0]"
          :required="isRequired('from')"
          :type="type"
          :min="min"
          :step="step"
          :hide-optional="true"
          :errors="fieldErrors"
          @input="emitUpdate" />
      </div>
      <div class="input-range-to-label">
        {{ 'general_till' | translate }}
      </div>
      <div class="to">
        <MdtInput
          ref="to"
          v-model="selectedData[1]"
          :required="isRequired('to')"
          :type="type"
          :max="max"
          :step="step"
          :hide-optional="true"
          :errors="fieldErrors"
          @input="emitUpdate" />
      </div>
    </div>
  </div>
</template>

<script>
import { helpers } from '@/utility';

export default {
  name: 'MdtInputRange',
  props: {
    selected: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'number',
      validator: (value) => {
        const match = ['text', 'number'];
        return match.includes(value);
      },
    },
    direction: {
      type: String,
      default: 'column',
      validator: (value) => ['column', 'row'].includes(value),
    },
    min: {
      type: Number,
      default: undefined,
    },
    max: {
      type: Number,
      default: undefined,
    },
    step: {
      type: Number,
      default: 1,
    },
    useValidation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedData: this.selected?.length ? this.selected : [null, null],
      msg: {
        maxMustBeGreterThanMin: this.$options.filters.translate('admin_max_must_be_greater_than_min'),
      },
    };
  },
  computed: {
    isRequired() {
      return (name) => {
        if (!this.useValidation) return this.required;
        if (this.required) return true;
        const from = this.selectedData[0];
        const to = this.selectedData[1];
        if (
          (helpers.isUndefined(from) && helpers.isUndefined(to))
          || (helpers.isNotUndefined(from) && helpers.isNotUndefined(to))
        ) return false;
        switch (name) {
          case 'from': return helpers.isNotUndefined(to);
          case 'to': return helpers.isNotUndefined(from);
          default: return false;
        }
      };
    },
    fieldErrors() {
      const errors = [];
      const from = this.selectedData[0];
      const to = this.selectedData[1];
      if (this.useValidation
        && (helpers.isNotUndefined(from) && helpers.isNotUndefined(to))
        && from >= to) errors.push(this.msg.maxMustBeGreterThanMin);
      return errors;
    },
    slotExists() {
      return !!this.$slots['min-max'] || !!this.$scopedSlots['min-max'];
    },
  },
  watch: {
    selected: {
      immediate: true,
      deep: true,
      handler(selected) {
        this.selectedData = selected?.length ? selected : [null, null];
      },
    },
  },
  methods: {
    isValid() {
      let isValid = true;
      Object.keys(this.$refs).forEach((key) => {
        const ref = this.$refs[key];
        if (ref && ref.isValid && !ref.isValid()) isValid = false;
      });
      return isValid && !this.fieldErrors.length;
    },
    emitUpdate() {
      this.$emit('input', this.selectedData);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-input-range {

  .input-range-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    color: $color-text-secondary;
    font-size: 14px;
    line-height: 14px;
  }

  .input-range-to-label {
    color: $color-text-secondary;
    font-size: 14px;
    line-height: 14px;
  }

  .input-range-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .input-range-to-label {
      padding: 12px 10px 0 10px;
    }

    .from,
    .to {
      flex: 1;
    }

    .from {
      margin: 0;
    }
  }

  .input-range-column {
    .input-range-to-label {
      padding: 10px 0;
    }
  }
}
</style>
