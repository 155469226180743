import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
export default {
  getObjectList(state, params) {
    return api.get('/api/v1/objects/iframe-object-list', { params }).then((response) => response.data);
  },
  getObjectDetails(state, objectId) {
    return api.get(`/api/v1/objects/iframe-object-list/${objectId}`).then((response) => response.data);
  },
  postObjectContactForm(state, params) {
    return api.post('/api/evaluation/settings/template-builder/iframe-interest-form', params).then((response) => response.data);
  },
  settingsAreSet({ commit }) {
    commit('settingsAreSet');
  },
  setFilters({ commit }, params) {
    commit('setFilters', params);
  },
  setActiveFilters({ commit }, params) {
    commit('setActiveFilters', params);
  },
  setSearch({ commit }, params) {
    commit('setSearch', params);
  },
  setCurrentPage({ commit }, params) {
    commit('setCurrentPage', params);
  },
};
