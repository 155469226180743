import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
export default {
  getAllData({ dispatch, getters }, data) {
    const params = data || { id: getters.projectId };
    // Remove stashed data
    dispatch('deleteProjectsData');
    return dispatch('getProject', params).then((project) => project);
  },
  getProject({ commit }, params) {
    commit('setDetailViewLoader', true);
    return api.get(`/api/v1/b2b/objects/${params.id}`).then((response) => {
      commit('setProject', response.data);
      return response.data;
    }).finally(() => {
      commit('setDetailViewLoader', false);
    });
  },
  deleteProjectsData({ commit }) {
    commit('deleteProjectsData');
  },
  reorderOptions({ getters }, value) {
    const { projectSettingsKey } = getters;
    return api.post(`/api/v1/settings/${projectSettingsKey}/list`, { value });
  },
  clearCreateProjectWizardCachedValues({ commit }) {
    commit('clearCreateProjectWizardCachedValues');
  },
  getCreateProjectWizardData({ commit }) {
    return api.get('/api/v1/b2b/objects/create-object').then((response) => {
      commit('setCreateProjectWizardData', response.data);
    });
  },
  postProjectWizard(state, params) {
    return api.post('/api/v1/b2b/objects/create-object', params.publishData).then((response) => response.data);
  },
  saveCreateProjectWizardPageData({ commit }, params) {
    commit('saveCreateProjectWizardPageData', params);
  },
  setToSold(state, projectIds) {
    const params = {
      targets: projectIds,
      bulkAction: 'setObjectToSold',
    };
    return api.post('/api/v1/b2b/objects/', params).then((response) => response.data);
  },
  getSoldProjectData(state, projectId) {
    return api.get(`/api/v1/b2b/objects/${projectId}/sold-project-detail`).then((response) => response.data);
  },
  setToAvailable(state, { projectId, investorId }) {
    const url = investorId
      ? `/api/v1/b2b/objects/assignment/${projectId}/${investorId}/free-project`
      : `/api/v1/b2b/objects/assignment/${projectId}/free-project`;

    return api.post(url).then((response) => response.data);
  },
};
