<template>
  <div class="mdt-table-additional-filters">
    <template
      v-for="(filter, i) in filters.choices">
      <span
        :key="`filter-${i}`"
        class="additional-filter clickable"
        :class="{ 'font-bold': filter.selected }"
        @click="applyFilters(filter)">
        <span v-if="filter.value !== 'all'">
          {{ filter.totalNum }}
        </span>
        {{ filter.displayName }}
      </span>
      <span
        v-if="i < filters.choices.length - 1"
        :key="`separator-${i}`"
        class="separator" />
    </template>
  </div>
</template>

<script>
export default {
  name: 'MdtTableAdditionalFilters',
  props: {
    filters: {
      type: Object,
      required: true,
    },
    tableId: {
      type: String,
      default: '',
    },
  },
  computed: {
    activeFilters() {
      return this.tableId
        ? this.$store.state.table.additionalTables[this.tableId].additionalFilters
        : this.$store.state.table.additionalFilters;
    },
  },
  watch: {
    filters() {
      this.addReactiveState();
    },
  },
  mounted() {
    this.addReactiveState();
  },
  methods: {
    addReactiveState() {
      const hasSelected = this.filters.choices.filter((filter) => filter.selected).length > 0;
      if (hasSelected) return;
      // Add 'selected' reactive state to cache selected values
      this.filters.choices.forEach((filter) => {
        this.$set(filter, 'selected', filter.value === 'all');
      });
    },
    applyFilters(item) {
      const formated = {};
      formated[this.filters.key] = item.value;
      this.$emit('applyAdditionalFilters', formated);
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-table-additional-filters {
  padding-top: 10px;

  .separator {
    margin: 0 16px;
    border-right: 1px solid $border-color;
  }
}
</style>
