const stzhComponents = {
  install(Vue) {
    const DESI_CDN_LINK = 'https://cdn.jsdelivr.net/npm/@oiz/stzh-components';
    const stzhFonts = document.createElement('link');
    stzhFonts.setAttribute('pre', 'preload');
    stzhFonts.setAttribute('as', 'style');
    stzhFonts.setAttribute('href', `${DESI_CDN_LINK}/dist/stzh-components/assets/fonts.css`);
    document.head.appendChild(stzhFonts);

    const stzhFontsStyles = document.createElement('link');
    stzhFontsStyles.setAttribute('rel', 'stylesheet');
    stzhFontsStyles.setAttribute('href', `${DESI_CDN_LINK}/dist/stzh-components/assets/fonts.css`);
    stzhFontsStyles.setAttribute('media', 'print');
    stzhFontsStyles.setAttribute('onload', 'this.media="all"');
    document.head.appendChild(stzhFontsStyles);

    const stzhStyles = document.createElement('link');
    stzhStyles.setAttribute('rel', 'stylesheet');
    stzhStyles.setAttribute('href', `${DESI_CDN_LINK}/dist/stzh-components/stzh-components.css`);
    document.head.appendChild(stzhStyles);

    const stzhScript = document.createElement('script');
    stzhScript.async = true;
    stzhScript.setAttribute('src', `${DESI_CDN_LINK}/dist/stzh-components/stzh-components.esm.js`);
    stzhScript.setAttribute('type', 'module');
    document.head.appendChild(stzhScript);

    window.stzhComponents = window.stzhComponents || {};
    window.stzhComponents.pathTranslations = `${DESI_CDN_LINK}/dist/stzh-components/assets/i18n`;
    window.stzhComponents.pathMedia = `${DESI_CDN_LINK}/dist/stzh-components/assets/media`;
    window.stzhComponents.pathVendors = `${DESI_CDN_LINK}/dist/stzh-components/assets/vendors`;

    Vue.prototype.$stzhComponents = stzhComponents;
    Vue.stzhComponents = stzhComponents;
  },
};

export default stzhComponents;
