export default {
  setCategoryOptions(state, data) {
    state.categoryOptions = data;
  },
  setCategoryOption(state, data) {
    if (!state.categoryOptions.find((option) => option.value === data)) {
      state.categoryOptions = [
        {
          displayName: data,
          value: data,
          newCategory: true,
        },
        ...state.categoryOptions,
      ];
    }
  },
};
