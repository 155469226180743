<template>
  <MdtInput
    ref="mdtInput"
    class="mdt-input-username"
    :value="value"
    :label="hideLabel ? '' : $options.filters.translate('general_username')"
    :min-length="minLength"
    :tooltip="tooltip"
    :errors="validationErrors"
    v-bind="{ ...config }"
    @input="onInput"
    @keyupEnter="$emit('keyupEnter', $event)" />
</template>

<script>
export default {
  name: 'MdtInputUsername',
  props: {
    value: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const minLength = 5;
    const minLengthInfo = this.$options.filters.translate('general_min_length_error')
      .replace('[p]', this.$options.filters.translate('general_username')).replace('[d]', minLength);
    const patternInfo = this.$options.filters.translate('admin_allowed_only').replace('[d]', 'a-z, A-Z, 0-9, ._-');

    return {
      minLength,
      tooltip: `${minLengthInfo}<br/>${patternInfo}`,
      patternInfo,
    };
  },
  computed: {
    validationErrors() {
      return /^[a-zA-Z0-9._-]*$/.test(this.value) ? [] : [this.patternInfo];
    },
  },
  methods: {
    isValid() {
      return this.$refs.mdtInput.isValid();
    },
    onInput(event) {
      this.$emit('input', event);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');
    },
  },
};
</script>
