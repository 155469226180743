export default {
  dashboard: {
    page: 'home',
    availableWidgetComponents: [
      'donut',
      'multi-num',
      'grouped-number',
    ],
    activeWidgets: {},
    activeWidgetsState: null,
  },
};
