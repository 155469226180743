export default {
  resetAdvertisementData(state) {
    state.advertisement.publishPage = {
      advertisementType: '',
      availability: {},
      maxApplications: {},
      objects: [],
      platforms: [],
      messages: {},
      mailTemplates: [],
    };
  },
  setAdvertisementData(state, data) {
    state.advertisement.publishPage.maxApplications = data.maxApplications || {};
    state.advertisement.publishPage.objects = data.objects;
    state.advertisement.publishPage.platforms = data.platforms;
    state.advertisement.publishPage.availability = data.availability;
    state.advertisement.publishPage.messages = data.messages;
    state.advertisement.publishPage.mailTemplates = data.mailTemplates;
    state.advertisement.publishPage.settings = data.settings;
  },
  b2bResetProjectAdvertisementData(state) {
    state.projectAdvertisement.publishPage = {
      projects: [],
      platforms: [],
      buildingZonesHarmonized: [],
      propertyUses: [],
      owners: [],
      contactPeople: [],
      textComponents: [],
    };
  },
  b2bSetProjectAdvertisementData(state, data) {
    state.projectAdvertisement.publishPage.projects = data.projects.map((project) => ({
      ...project,
      ownerName: data.proprietor.find((o) => o.value === project.owner).displayName,
      selectedTextComponents:
        data.text_components.filter((item) => item.required || project[item.key]),
    }));
    state.projectAdvertisement.publishPage.platforms = data.platforms;
    state.projectAdvertisement.publishPage
      .buildingZonesHarmonized = data.building_zones_harmonized;
    state.projectAdvertisement.publishPage.propertyUses = data.property_uses;
    state.projectAdvertisement.publishPage.owners = data.proprietor;
    state.projectAdvertisement.publishPage.contactPeople = data.contact_person;
    state.projectAdvertisement.publishPage.textComponents = data.text_components;
  },
  b2bSetProjectAdvertisementDataAfterSave(state, data) {
    state.projectAdvertisement.publishPage.projects = data.projects.map((project) => ({
      ...project,
      ownerName: data.proprietor.find((o) => o.value === project.owner).displayName,
      selectedTextComponents:
        state.projectAdvertisement.publishPage.textComponents
          .filter((item) => item.required || project[item.key]),
    }));
    state.projectAdvertisement.publishPage.owners = data.proprietor;
  },
};
