<template>
  <div class="mdt-button">
    <div
      class="flex-center pointer no-userselect button"
      :class="[{
        'button-disabled': disabled,
      }, `theme-${theme}`]">
      <i :class="icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MdtButton',
  props: {
    icon: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
      validator: (value) => {
        const match = ['default', 'light'];
        return match.includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-button {
  .button {
    width: 32px;
    height: 32px;
    border-radius: 50%;

    &.theme-default {
      background-color: $color-theme;
      color: $color-white;
      @include button-hover($color-theme-rgb, 0.75);
    }

    &.theme-light {
      background-color: rgba($color-text-secondary-rgb, 0.1);
      color: $color-text-secondary;
      @include button-hover($color-text-secondary, 0.2);
    }

    &.button-disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    i {
      font-size: 14px;
    }
  }
}
</style>
