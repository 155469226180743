import multiselectSearchHelper from './multiselectSearchHelper';

const addressFormatter = (item, boldFormattedItem) => {
  let result = '';
  switch (item.type) {
    case 'city':
      result = boldFormattedItem || item.city;
      break;
    case 'plz': {
      let plz;
      if (boldFormattedItem) plz = boldFormattedItem ? `${boldFormattedItem}` : '';
      else plz = item.plz ? `${item.plz}` : '';
      const city = item.city ? ` (${item.city})` : '';
      result = `${plz}${city}`;
      break;
    }
    case 'colony': {
      let colony;
      if (boldFormattedItem) colony = boldFormattedItem ? `${boldFormattedItem}` : '';
      else colony = item.colony ? `${item.colony}` : '';
      const plz = item.plz ? `${item.plz}` : '';
      const citySpace = plz ? ' ' : '';
      const city = item.city ? `${citySpace}${item.city}` : '';
      const plzCity = plz || city ? ` (${plz}${city})` : '';
      result = `${colony}${plzCity}`;
      break;
    }
    case 'address': {
      let address;
      if (boldFormattedItem) address = boldFormattedItem ? `${boldFormattedItem}` : '';
      else address = item.address ? `${item.address}` : '';
      const plz = item.plz ? ` ${item.plz}` : '';
      const city = item.city ? ` ${item.city}` : '';
      result = `${address}${plz}${city}`;
      break;
    }
    default: break;
  }
  return result;
};

const addressSearchResultMapper = (pattern, searchData, updateItems) => {
  let resultItems = [];
  searchData.forEach((item) => {
    switch (item.type) {
      case 'city':
        resultItems.push(multiselectSearchHelper.boldMatchedPattern(pattern, item.city, item));
        break;
      case 'plz':
        resultItems.push(multiselectSearchHelper.boldMatchedPattern(pattern, item.plz, item));
        break;
      case 'colony':
        resultItems.push(multiselectSearchHelper.boldMatchedPattern(pattern, item.colony, item));
        break;
      case 'address':
        resultItems.push(multiselectSearchHelper.boldMatchedPattern(pattern, item.address, item));
        break;
      default: break;
    }
  });
  resultItems = resultItems.map((item) => {
    const obj = {};
    obj.displayName = addressFormatter(item.item, item.displayName);
    obj.value = addressFormatter(item.item);
    obj.data = {
      ...item.item,
      resultType: item.item.type,
    };
    return obj;
  });
  updateItems(resultItems);
};

const addressSelectedItemsMapper = (item) => {
  const formattedItem = addressFormatter(item.data);
  return {
    displayName: formattedItem,
    value: formattedItem,
    data: item.data,
  };
};

export default {
  addressSearchResultMapper,
  addressSelectedItemsMapper,
};
