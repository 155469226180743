export default {
  selected: {},
  evaluation: {},
  priorityObjects: {},
  alternativeObjects: {},
  offer: {},
  assign: {},
  buyingConfirmation: {},
  selectWidgetItems: [],
  detailView: {
    loading: true,
  },
};
