export default {
  open(state, sidepanel) {
    Object.keys(sidepanel).forEach((key) => {
      state[key] = sidepanel[key];
    });
  },
  close(state) {
    state.key = '';
    state.title = '';
    state.params = {};
    state.data = null;
    state.tableRowId = null;
  },
  updateData(state, sidepanel) {
    Object.keys(sidepanel).forEach((key) => {
      state[key] = sidepanel[key];
    });
  },
};
