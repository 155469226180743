<template>
  <div
    class="mdt-sidepanel"
    :class="sidepanel.key"
    @wheel.stop
    @touchmove.stop
    @scroll.stop>
    <div
      class="sidepanel-header">
      <div>
        <span
          v-overflow-tooltip
          v-tooltip="{
            content: sidepanel.title,
            trigger: 'manual',
          }"
          class="header-title flex-center-v">
          {{ sidepanel.title }}
        </span>
        <i
          v-if="goToRouteData.visible"
          v-tooltip="goToRouteData.tooltip"
          class="fas fa-arrow-circle-right clickable icon-go-to"
          @click="goToRoute" />
        <i
          v-if="isDeleteIconVisible"
          v-tooltip="$options.filters.translate('general_delete')"
          class="fas fa-trash icon-hover icon-delete"
          @click="openConfirmationModal" />
      </div>
      <div
        v-if="isHeaderStatusAvailable"
        class="header-status text-center">
        <div class="btn btn-basic btn-size-24 no-hover text-select text-upper">
          {{ sidepanel.data.stateTitle }}
        </div>
      </div>
      <i
        class="fas fa-times icon-close"
        @click="closeSidepanel" />
    </div>
    <vue-scroll>
      <div class="sidepanel-main-body">
        <component
          :is="`${sidepanel.key}`"
          v-if="sidepanel.data"
          :ref="sidepanel.key"
          :body="sidepanel.data" />
        <MdtLoader v-else />
      </div>
    </vue-scroll>
  </div>
</template>

<script>
export default {
  name: 'MdtSidepanel',
  components: {
    ApartmentEdit: () => import('./SidepanelEdit.vue'),
    ApartmentOffer: () => import('./SidepanelOffer.vue'),
    ApplicationEdit: () => import('./SidepanelEdit.vue'),
    ApplicationAdult: () => import('./ApplicationPerson.vue'),
    ApplicationChild: () => import('./ApplicationPerson.vue'),
    ApplicationEvaluation: () => import('./ApplicationEvaluation.vue'),
    ApplicationOffer: () => import('./SidepanelOffer.vue'),
    ApplicationOfferDeclined: () => import('./SidepanelOffer.vue'),
    ApplicationTotalPoints: () => import('./ApplicationTotalPoints.vue'),
    Messenger: () => import('./Messenger.vue'),
    BudgetCostPlanEdit: () => import('./BudgetCostEdit.vue'),
    BudgetCostCategoryEdit: () => import('./BudgetCostEdit.vue'),
    BuildingEdit: () => import('./SidepanelEdit.vue'),
    InvestorEdit: () => import('./SidepanelEdit.vue'),
    InterestedPersonB2BEdit: () => import('./SidepanelEdit.vue'),
    InvestorOffer: () => import('./SidepanelEdit.vue'),
  },
  computed: {
    sidepanel() {
      return this.$store.state.sidepanel;
    },
    goToRouteData() {
      switch (this.sidepanel.key) {
        case 'ApplicationEdit': {
          // check type of application to set proper back-to
          switch (this.sidepanel?.data?.applicationType) {
            case 'application': {
              const urlName = 'ApplicationDetails';
              return {
                visible: this.$route.name !== urlName,
                tooltip: this.$options.filters.translate('admin_back_to_application'),
                urlName,
              };
            }
            case 'waitlist': {
              const urlName = 'WaitlistDetails';
              return {
                visible: this.$route.name !== urlName,
                tooltip: this.$options.filters.translate('admin_back_to_waitlist'),
                urlName,
              };
            }
            case 'tenant': {
              const urlName = 'TenantDetails';
              return {
                visible: this.$route.name !== urlName,
                tooltip: this.$options.filters.translate('general_to_tenant'),
                urlName,
              };
            }
            default: return { visible: false };
          }
        }
        case 'ApartmentEdit': {
          const urlName = 'ApartmentDetails';
          return {
            visible: this.$route.name !== urlName,
            tooltip: this.$options.filters.translate('admin_back_to_object'),
            urlName,
          };
        }
        case 'InvestorEdit': {
          const urlName = 'InvestorDetails';
          return {
            visible: this.$route.name !== urlName,
            tooltip: this.$options.filters.translate('general_to_investor'),
            urlName,
          };
        }
        default: return { visible: false };
      }
    },
    isDeleteIconVisible() {
      if (['ApplicationAdult', 'ApplicationChild', 'BudgetCostPlanEdit'].includes(this.sidepanel.key)) {
        let permitted = true;
        // check if user has permission regarding type of application
        switch (this.sidepanel?.data?.applicationType) {
          case 'application':
            permitted = this.isPermitted('applications_write');
            break;
          case 'waitlist':
            permitted = this.isPermitted('waitlist');
            break;
          case 'interest':
            permitted = this.isPermitted('interested_people');
            break;
          case 'tenant':
            permitted = this.isPermitted('tenants_write');
            break;
          default: break;
        }
        return permitted && !this.sidepanel.data.isLast;
      }
      return false;
    },
    isHeaderStatusAvailable() {
      return ['ApartmentEdit', 'ApplicationEdit', 'InvestorEdit', 'InterestedPersonB2BEdit']
        .includes(this.sidepanel.key) && !!this.sidepanel?.data?.stateTitle;
    },
  },
  mounted() {
    // hide browser's scrollbar
    document.body.classList.add('no-scroll');
  },
  destroyed() {
    // revert browser's scrollbar back
    document.body.classList.remove('no-scroll');
  },
  methods: {
    goToRoute() {
      const { id } = this.sidepanel.params;

      // if we are on table view -> save table data before go to detail view
      if (!this.$route.meta.parentName) {
        const params = {
          id,
          route: this.$route,
        };
        this.$store.dispatch('table/saveTableData', params);
      }

      this.$router.push({ name: this.goToRouteData.urlName, params: { id } });
    },
    openConfirmationModal() {
      const { key } = this.sidepanel;
      this.$refs[key].openConfirmationModal();
    },
    closeSidepanel() {
      this.$store.dispatch('sidepanel/close');
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-sidepanel {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: $sidepanel-offset-top;
  right: $sidepanel-offset-right;
  bottom: $sidepanel-offset-bottom;
  width: $sidepanel-width;
  height: $sidepanel-height;
  background-color: $color-back-primary;
  box-shadow: 0px 3px 30px 0px #00000040;
  overflow: hidden;
  z-index: 1000;

  &.Messenger {
    width: $sidepanel-messenger-width;

    .sidepanel-main-body {
      padding: 0;

      ::v-deep .sidepanel-buttons {
        width: $sidepanel-messenger-width;
      }
    }
  }
}

.sidepanel-header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: $sidepanel-header-height;
  border-bottom: 1px solid $border-color;
  margin: 0 30px;
  flex-shrink: 0;

  div {
    max-width: 460px;
    display: flex;
    align-items: center;

    .header-title {
      font-size: 22px;
      font-weight: $font-weight-bold;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      margin-right: -4px;
      height: 28px;
      white-space: nowrap;
    }

    .icon-go-to,
    .icon-delete {
      font-size: 1rem;
    }

    .icon-go-to {
      margin-left: 8px;
      color: $color-text-important;

      &:hover {
        opacity: 0.8;
      }
    }
    .icon-delete {
      margin-left: 10px;
    }
  }

  .apartment-url {
    padding-top: 5px;
  }

  .header-status {
    padding-top: 5px;
  }

  .icon-close {
    position: absolute;
    top: 50%;
    right: -8px;
    font-size: 20px;
    transform: translateY(-50%);
  }
}

.sidepanel-main-body {
  padding: 0 30px;
  font-size: 1rem;

  ::v-deep {
    .section.section-accordion {
      border-top: 1px solid $border-color;

      &:first-child {
        border-top: none;
      }

      .section-header {
        position: relative;
        justify-content: space-between;
        height: 52px;
        padding: 16px 8px;
        font-weight: $font-weight-bold;

        &.opened {
          .icon-open {
            color: $color-text-primary;
            background-color: $color-back-light;
          }
        }

        .icon-open {
          color: $color-text-secondary;
          font-size: 16px;
        }
      }

      .section-body {
        padding: 5px 0 20px 0;
      }
    }

    .edit-card {
      position: relative;
      padding: 20px;
      background-color: $color-back-light;
      border-radius: 8px;

      > .mdt-edit-button .edit-button {
        position: absolute;
        right: 20px;
        top: 20px;
      }

      .card-header {
        font-size: 1rem;
        font-weight: $font-weight-bold;

        .header-label:not(:first-child) {
          padding-top: 7px;
        }

        .card-header-title {
          line-height: 30px;
        }

        .card-header-email,
        .card-header-phone {
          font-weight: $font-weight-normal;
          font-size: 0.875rem;
        }

        .icon {
          padding-right: 10px;
        }
      }

      .card-body {
        display: flex;
        flex-direction: column;
        padding-top: 10px;

        .list-item {
          display: flex;
          justify-content: space-between;
          padding: 8px 0;
          border-bottom: 1px solid rgba($color-text-secondary-rgb, 0.15);
          max-width: 470px;
          font-size: 1rem;

          &:last-child {
            border-bottom: 0;
          }

          .item-name {
            color: $color-text-secondary;
            padding-right: 5px;
          }

          .item-value {
            max-width: 75%;
            padding-left: 5px;
          }
        }

        .button-save {
          margin-left: auto;
        }
      }

      .card-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        font-weight: bold;
      }
    }

    .evaluation-card {
      .adult {
        font-size: 1rem;
      }
    }

    .mdt-select {
      .select-dropdown-items-wrapper {
        max-width: 488px;
      }
    }
  }

  ::v-deep .sidepanel-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: $sidepanel-width;
    height: $sidepanel-footer-height;
    margin-left: -30px;
    margin-right: -30px;
    padding-right: 30px;
    box-shadow: 0px 0px 20px 0px #0000001a;

    .btn {
      margin-left: 15px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>
