<template>
  <div class="public-view">
    <MdtNotify />
    <RouterView />
  </div>
</template>

<script>
export default {
  name: 'PublicView',
};
</script>
