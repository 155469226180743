export default {
  setData(state, data) {
    Object.keys(data).forEach((key) => {
      // update state list data but skip sortSettings if exists
      if (key !== 'sortSettings' || (key === 'sortSettings'
        && (!state.list?.sortSettings || !Object.keys(state.list?.sortSettings).length))) {
        state.list[key] = data[key];
      }
    });
  },
  setDataListOnly(state, data) {
    state.list.items = data.items;
    state.list.pagination = data.pagination;
    // update sortSettings if it doesn't exists in state list
    if (!state.list?.sortSettings || !Object.keys(state.list?.sortSettings).length) {
      state.list.sortSettings = data.sortSettings;
    }
  },
  setDataListMoreItems(state, data) {
    state.list.items = [...state.list.items, ...data.items];
  },
  selectItem(state, item) {
    state.loading = false;
    state.selectedItem = item;
  },
  readSelectedItem(state) {
    state.selectedItem.has_unread = false;
    state.inbox.tableData.body.forEach((message) => {
      message.is_read = true;
    });
  },
  updateSearchVal(state, searchVal) {
    state.searchVal = searchVal;
  },
  setBasicFilters(state, filters) {
    state.basicFilters = filters;
  },
  setInbox(state, inbox) {
    Object.keys(inbox).forEach((key) => {
      state.inbox[key] = inbox[key];
    });
    state.inbox.loaded = true;
    state.sendingMail = false;
  },
  setInboxMoreMessages(state, inbox) {
    if (inbox.tableData.body?.length) {
      state.inbox.tableData.body = [...state.inbox.tableData.body, ...inbox.tableData.body];
    }
  },
  unsetInbox(state) {
    state.inbox.loaded = false;
    state.inbox.tableData.body = [];
  },
  deleteData(state) {
    state.searchVal = '';
    state.basicFilters = {};
    state.selectedItem = {};
    // preserve sortSettings
    const sortSettings = JSON.parse(JSON.stringify(state.list.sortSettings)) || {};
    state.list = {
      items: [],
      pagination: {},
      filters: [],
      mailCounter: {},
      bulkExtraOptionsV2: [],
      sortSettings,
    };
    state.inbox = {
      loaded: false,
      tableData: {
        body: [],
      },
      pagination: {},
    };
    state.loading = false;
  },
  setSendingMail(state, value) {
    state.sendingMail = value;
  },
  updateMessage(state, message) {
    const index = state.inbox.tableData.body.findIndex((msg) => msg.id === message.id);
    if (index > -1) {
      const inboxMessage = state.inbox.tableData.body[index];
      const replaceMessage = {};
      Object.keys(inboxMessage).forEach((key) => {
        if (!(key in message)) replaceMessage[key] = inboxMessage[key];
        else replaceMessage[key] = message[key];
      });
      state.inbox.tableData.body.splice(index, 1, replaceMessage);
    }
    state.selectedItem.has_unread = true;
  },
  setMailbox(state, mailbox) {
    state.mailbox = mailbox;
  },
  updateSortKey(state, sortKey) {
    state.list.sortSettings.selected = sortKey;
  },
  setFavorite(state, data) {
    state.selectedItem.evaluation_favorite = data.checked;
  },
  setPreventScrollTo(state, value) {
    state.inbox.preventScrollTo = value;
  },
};
