export default {
  initialize({ commit }, bootstrap) {
    commit('initialize', bootstrap);
  },
  updatePrevRoutes({ commit }, routeFrom) {
    commit('updatePrevRoutes', routeFrom);
  },
  setMainMenuCollapsedHovered({ commit }, collapsedHovered) {
    commit('setMainMenuCollapsedHovered', collapsedHovered);
  },
  setMainMenuCollapsed({ commit }, collapsed) {
    commit('setMainMenuCollapsed', collapsed);
    commit('setMainMenuCollapsedHovered', false);
  },
};
