<template>
  <MdtModal
    :title="'general_documents' | translate"
    @close="cancelFileProcess"
    @cancel="cancelFileProcess"
    @save="saveFiles">
    <div class="section">
      <div class="section-left">
        <div
          v-if="files.length === 0"
          class="documents-list-empty flex-center">
          <img src="@/assets/images/nothing-found.png">
          <div class="empty-info">
            {{ 'admin_no_documents_message' | translate }}
          </div>
        </div>
        <div
          v-else
          class="documents-list-wrapper">
          <vue-scroll>
            <ul class="documents-list">
              <li
                v-for="(file, f) in files"
                :key="f"
                class="list-item">
                <div class="list-item-body">
                  <MdtSelect
                    v-model="file.selectedCategory"
                    :label="'general_category' | translate"
                    :options="categoryData"
                    :class="{ 'has-error': !file.isValid }"
                    :required="true"
                    @input="onChangeCategory(file); validateSelection();" />
                </div>
                <div class="list-item-preview flex-center-v">
                  <MdtPdfPreview
                    :src="file.url"
                    :theme="'highlighted'"
                    @deleteFile="removeDocument(file.displayName)">
                    <div
                      v-overflow-tooltip
                      v-tooltip="{
                        content: file.displayName,
                        trigger: 'manual',
                      }"
                      class="preview-label">
                      {{ file.displayName }}
                    </div>
                  </MdtPdfPreview>
                  <i
                    class="fas fa-trash icon-hover icon-delete"
                    @click="removeDocument(file.displayName)" />
                </div>
              </li>
            </ul>
          </vue-scroll>
        </div>
      </div>
      <div class="section-right">
        <MdtFileUpload
          :upload-url="urls.uploadUrl"
          @uploaded="onFileUpload" />
      </div>
    </div>
  </MdtModal>
</template>

<script>
export default {
  name: 'MdtModalDocumentUpload',
  props: {
    objectId: {
      type: Number,
      default: 0,
    },
    actionPath: {
      type: String,
      default: '',
    },
    uploadUrl: {
      type: String,
      default: '',
    },
    previewOnSave: {
      type: Boolean,
      default: false,
    },
    apiFlags: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const { baseBackendUrl } = this.$store.getters;

    return {
      files: [],
      deletedFiles: [],
      categoryData: [],
      urls: {
        baseBackendUrl,
        uploadUrl: this.uploadUrl || '/api/v1/upload-files',
        actionsPath: this.actionPath || `/api/v1/objects/${this.objectId}/upload-files-setup`,
      },
      loading: false,
    };
  },
  mounted() {
    this.getUploadDocumentsSetup();
  },
  methods: {
    getUploadDocumentsSetup() {
      const settings = {
        path: this.urls.actionsPath,
        payload: {
          marketing: this.apiFlags.isMarketing,
        },
      };
      this.$store.dispatch('apartments/getUploadDocumentsSetup', settings).then((data) => {
        this.onFileUpload(data.files);
        this.categoryData = data.fileChoices;
      }).catch(this.showResponseError);
    },
    onFileUpload(fileList) {
      // Map server response
      if (typeof fileList[0] === 'string') {
        fileList = fileList.map((file) => ({
          url: file,
          key: '',
          id: 0, // ID is 0 for every new file, for easier tracking
        }));
      }

      const list = fileList.map((fileObj) => {
        const { url } = fileObj;
        return {
          displayName: url.substr(url.lastIndexOf('/') + 1),
          url: `${this.urls.baseBackendUrl}${url}`,
          rawUrl: url,
          selectedCategory: fileObj.key || '',
          isValid: fileObj.key !== '',
          id: fileObj.id,
          oldCategory: fileObj.key || '',
        };
      });
      this.files = [...this.files, ...list];
    },
    removeDocument(displayName) {
      const len = this.files.length;
      for (let i = 0; i < len; i++) {
        const file = this.files[i];
        if (file.displayName === displayName) {
          this.files.splice(i, 1);
          this.deletedFiles.push(file);
          break;
        }
      }

      this.validateSelection();
    },
    onChangeCategory(file) {
      if (file.id === 0) {
        this.$set(file, 'oldCategory', file.selectedCategory);
      } else {
        this.$set(file, 'oldCategory', file.oldCategory);
      }
    },
    validateSelection() {
      // Check if there is already same document assigned
      function selectionExists(file, files) {
        let exists = false;
        files.forEach((fileObj) => {
          if (exists || fileObj.displayName === file.displayName) return;
          const isCategorySame = fileObj.selectedCategory === file.selectedCategory;
          exists = isCategorySame;
        });
        return exists;
      }

      const len = this.files.length;
      for (let i = 0; i < len; i++) {
        const file = this.files[i];
        if (file.selectedCategory !== '') {
          file.isValid = !selectionExists(file, this.files);
        } else {
          file.isValid = false;
        }
      }
    },
    cancelFileProcess() {
      // Remove currently uploaded files
      const newFiles = this.files
        .filter((file) => file.id === 0)
        .map((file) => ({
          url: file.rawUrl,
          key: file.selectedCategory || 'temp',
        }));

      const settings = {
        path: this.urls.actionsPath,
        data: {
          removedFiles: newFiles,
        },
      };

      // Dont send request to backend if no changes
      if (!settings.data.removedFiles.length) {
        this.$emit('close');
        return;
      }

      // Send request to backend if there are changes
      this.$store.dispatch('apartments/postUploadDocumentsSetup', settings)
        .catch(this.showResponseError)
        .finally(() => {
          this.$emit('close');
        });
    },
    saveFiles() {
      if (this.loading) return;
      this.loading = true;

      const isSelectionValid = this.files.every((file) => file.isValid);
      if (!isSelectionValid) {
        this.loading = false;
        return;
      }

      const removedFiles = this.deletedFiles.map((file) => ({
        url: file.rawUrl,
        key: file.selectedCategory || 'temp',
      }));

      const updatedFiles = this.files
        .filter((file) => file.id === 0
          || (file.oldCategory && file.oldCategory !== file.selectedCategory))
        .map((file) => ({
          url: file.rawUrl,
          key: file.oldCategory || undefined,
          new_key: file.selectedCategory,
        }));

      const settings = {
        path: this.urls.actionsPath,
        data: {
          removedFiles,
          updatedFiles,
        },
      };

      if (this.previewOnSave) {
        settings.params = {
          display: true,
        };
      }

      // Dont send request to backend if no changes
      if (!settings.data.removedFiles.length && !settings.data.updatedFiles.length) {
        this.$emit('close');
        this.loading = false;
        return;
      }

      // // Send request to backend if there are changes
      this.$store.dispatch('apartments/postUploadDocumentsSetup', settings).then((data) => {
        this.$notify.success(data.details);
        this.$emit('refreshFilesData', data);
        this.$emit('close');
      }).catch(this.showResponseError)
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  display: flex;
  flex-direction: row;

  .section-left { width: 340px; }
  .section-right { width: 500px; }
}

.section-left {
  display: flex;
  max-height: 400px;
  padding-right: 10px;

  .documents-list-empty {
    flex-direction: column;
    height: 100%;
    width: 100%;

    .icon-empty-wrapper {
      width: 160px;
      height: 160px;
      color: #e6e6e6;
      border: 1px solid #e6e6e6;
      border-radius: 50%;
      font-size: 1.5rem;
    }

    .empty-info {
      padding-top: 32px;
      width: 200px;
      color: $color-text-secondary;
      text-align: center;
    }
  }

  .documents-list-wrapper {
    width: 100%;

    ::v-deep & > .__vuescroll > .__panel > .__view {
      width: 100% !important;
    }
  }

  .documents-list {
    .list-item {
      margin-bottom: 32px;
      padding-bottom: 32px;
      border-bottom: 1px solid $border-color;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
    }

    .list-item-preview {
      justify-content: space-between;
      margin-top: 20px;
      background-color: $color-back-light;
      border-radius: 4px;
    }

    .list-item-body .mdt-select {
      padding-top: 15px;
    }

    .icon-file {
      padding-right: 7px;
    }

    .icon-delete {
      margin-right: 16px;
      font-size: 15px;
    }
  }
}

.section-right {
  padding-left: 10px;
}
</style>
