/* Code copyied/edited from https://github.com/t-k-f/vue-packery-draggabilly-plugin/blob/master/src/index.js */
/* eslint-disable func-names */

import Draggabilly from 'draggabilly';
import { packeryEvents } from './packery';

export default {
  install(Vue) {
    Vue.directive('draggabilly', {
      inserted(el, options, vnode) {
        el.draggie = new Draggabilly(el, options?.value);
        el.draggie.on('dragStart', (event) => vnode.elm.dispatchEvent(new PointerEvent('dragStart', event)));
        el.draggie.on('dragMove', (event) => vnode.elm.dispatchEvent(new PointerEvent('dragMove', event)));
        el.draggie.on('dragEnd', (event) => vnode.elm.dispatchEvent(new PointerEvent('dragEnd', event)));
        el.draggie.on('pointerUp', (event) => vnode.elm.dispatchEvent(new PointerEvent('pointerUp', event)));
        el.draggie.on('pointerDown', (event) => vnode.elm.dispatchEvent(new PointerEvent('pointerDown', event)));
        el.draggie.on('pointerMove', (event) => vnode.elm.dispatchEvent(new PointerEvent('pointerMove', event)));
        el.draggie.on('staticClick', (event) => vnode.elm.dispatchEvent(new PointerEvent('staticClick', event)));
        packeryEvents.$emit('draggie', { draggie: el.draggie, node: el.parentNode });
      },
      unbind(el) {
        el.draggie.off('dragStart');
        el.draggie.off('dragMove');
        el.draggie.off('dragEnd');
        el.draggie.off('pointerUp');
        el.draggie.off('pointerDown');
        el.draggie.off('pointerMove');
        el.draggie.off('staticClick');
        el.draggie.destroy();
        el.draggie = null;
      },
    });
  },
};
