<template>
  <MdtModal
    :title="'general_total_budget' | translate"
    width="330px"
    :is-data-changed="isDataChanged"
    :button-disabled="loading"
    @close="close"
    @cancel="close"
    @save="editTotalBudget">
    <MdtLoader v-if="loading" />
    <MdtInput
      v-else
      ref="value"
      v-model="value"
      type="number"
      :label="'general_total_budget' | translate"
      required
      @mdtDataChanged="isDataChanged = true">
      <i slot="icon">
        {{ $store.getters.bootstrap.settings.currency }}
      </i>
    </MdtInput>
  </MdtModal>
</template>

<script>
export default {
  name: 'ModalBudgetTotalEdit',
  props: {
    initialValue: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      value: this.initialValue,
      isDataChanged: false,
      loading: false,
    };
  },
  methods: {
    editTotalBudget() {
      if (!this.$refs.value.isValid()) return;

      this.loading = true;
      this.$store.dispatch('budget/editTotalBudget', {
        value: this.value,
      }).then((data) => {
        this.$store.dispatch('notify', data.notify);
        this.$emit('edited', data);
        this.close();
      }).catch(this.showResponseError)
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
