export default {
  boldMatchedPattern(pattern, word, item) {
    if (!pattern) pattern = '';
    if (!word) word = '';
    const positions = [];
    // get matching pattern start-end positions
    let idx = word.toLowerCase().indexOf(pattern.toLowerCase());
    while (idx > -1) {
      positions.push({ start: idx, end: idx + pattern.length });
      idx = word.toLowerCase().indexOf(pattern.toLowerCase(), idx + 1);
    }
    let result = '';
    // if no matching -> result is whole word
    if (!positions.length) result = `<span>${word}</span>`;
    // else if single item matched
    else if (positions.length === 1) {
      // get first position item
      const pos = positions[0];
      // if whole word matched -> return whole word bold
      if (pos.start === 0 && (pos.end === word.length - 1)) result = `<span class="font-bold">${word}</span>`;
      // if matching at the start of word -> set the result properly
      else if (pos.start === 0) result = `<span class="font-bold">${word.substring(0, pos.end)}</span><span>${word.substring(pos.end, word.length)}</span>`;
      else { // if matching not at the start of word -> set the result properly
        result = `<span>${word.substring(0, pos.start)}</span><span class="font-bold">${word.substring(pos.start, pos.end)}</span>`;
        // if matching not at the end of word -> append the result properly
        if (pos.end !== word.length) result = `${result}<span>${word.substring(pos.end, word.length)}</span>`;
      }
    } else { // if more then 1 position matched
      [...word].forEach((char, i) => {
        // matched position for current item index
        const matchedPos = positions.find((pos) => i >= pos.start && i < pos.end);
        if (matchedPos) { // if position matched
          // check if index is first matched position
          const firstMatchedPos = ((matchedPos.start) === i);
          // if index is first matched postition -> set the result properly
          if (firstMatchedPos) result = `${result}<span class="font-bold">`;
          result = `${result}${char}`;
          // check if index is last matched position
          const lastMatchedPos = ((matchedPos.end - 1) === i);
          // if index is last matched postition -> set the result properly
          if (lastMatchedPos) result = `${result}</span>`;
        } else result = `${result}${char}`;
      });
    }
    return { displayName: result, item };
  },
};
