<template>
  <td
    class="main-table-td-header"
    :class="{ subheader }"
    :colspan="headerColumns"
    :style="{
      color: header.textColor && `${header.textColor} !important`,
      backgroundColor: header.backgroundColor && `${header.backgroundColor} !important`,
    }">
    <span class="text">
      {{ header.value }}
    </span>
  </td>
</template>

<script>
export default {
  name: 'TableTdHeader',
  props: {
    header: {
      type: Object,
      required: true,
    },
    subheader: {
      type: Boolean,
      required: true,
    },
    headerColumns: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.main-table-td-header {
  background-color: $table-header-color !important;
  color: $color-text-primary;

  &.subheader {
    background-color: $table-subheader-color !important;
  }
}
</style>
