<template>
  <MdtStatusButtons
    v-model="selectedTab"
    :items="statusTabs"
    @input="setActive" />
</template>

<script>
export default {
  name: 'MdtTableStatusFilters',
  props: {
    statusFilters: {
      type: Object,
      required: true,
    },
  },
  data() {
    const activeFilter = this.statusFilters.choices.find((filter) => filter.active);

    return {
      selectedTab: activeFilter ? activeFilter.value : '',
    };
  },
  computed: {
    statusTabs() {
      return this.statusFilters.choices.map((tab) => ({
        key: tab.value,
        displayName: tab.displayName,
        count: tab.totalNum,
      }));
    },
  },
  watch: {
    statusFilters() {
      const activeFilter = this.statusFilters.choices.find((filter) => filter.active);
      this.selectedTab = activeFilter ? activeFilter.value : '';
    },
  },
  methods: {
    setActive(value) {
      const filter = {
        key: this.statusFilters.key,
        value,
      };
      this.$emit('applyStatusFilter', filter);
    },
  },
};
</script>
