export default {
  data() {
    return {
      isHover: false,
      mouseEnterLeavetimer: null,
      mouseEnterLeaveDelay: 0,
    };
  },
  methods: {
    onHover() {
      this.mouseEnterLeavetimer = setTimeout(() => {
        this.isHover = true;
      }, this.mouseEnterLeaveDelay);
    },
    onBlur() {
      clearInterval(this.mouseEnterLeavetimer);
      this.isHover = false;
    },
  },
};
