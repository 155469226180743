<template>
  <div
    class="navigation-top-user">
    <div
      class="icon-wrapper flex-center pointer no-userselect icon-options-wrapper headway-app">
      <i class="fa-solid fa-bell" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Headway',
  mounted() {
    const { translate } = this.$options.filters;
    this.$headwayWidget.init(translate);
  },
};
</script>

<style lang="scss">
.navigation-top-user {
  &:hover i {
    color: $color-text-primary !important;
  }
}
#HW_badge_cont {
  position: absolute;
  right: 9px;
  top: 14px;
  z-index: 100;

  #HW_badge {
    position: absolute;
    left: 17px;
    top: 3px;
    background: #ec5252;
    border: 1px solid $color-white;
    box-sizing: content-box;
    width: 14px;
    height: 14px;
    line-height: 14px;
    font-weight: 700;
  }
}
</style>
