import routeMap from '../route-map/routeMap';

export default class Route {
  constructor(options) {
    const route = routeMap[options.key];

    this.title = options.title;
    this.position = options.rank;
    this.icon = options.icon;
    this.key = options.key;
    this.behaviour = options.behaviour;
    this.url = route ? route.url : '';
    this.urlName = route ? route.urlName : '';
    this.setChildren('children' in options ? options.children : [], route);
  }

  setChildren(children, route) {
    if (!children.length) return;
    this.children = children.map((child) => {
      const childMap = route?.children?.[child.key] || {};
      return {
        title: child.title,
        position: child.rank,
        icon: child.icon,
        key: child.key,
        url: childMap?.url || '',
        urlName: childMap?.urlName || '',
      };
    }) || [];
  }
}
