<template>
  <MdtModalConfirmation
    :title="msg.title"
    :info-msg="infoMsg"
    :btn-text="msg.deactivate"
    :hide-vue-scroll="true"
    type="danger"
    @close="$emit('close')"
    @confirm="onConfirm">
    <MdtSelect
      v-if="displayedReasons.length && !loading"
      ref="deactivate-reason"
      v-model="selectedReason"
      :label="'admin_deactivate_reason' | translate"
      :options="displayedReasons"
      :required="true"
      :width="'360px'" />
    <MdtLoader v-else />
  </MdtModalConfirmation>
</template>

<script>
import { mixinRefreshTableOrDetailView } from '@/mixins';

export default {
  name: 'ModalDeactivateApplication',
  mixins: [mixinRefreshTableOrDetailView],
  props: {
    selectedRows: {
      type: Array,
      required: true,
    },
    reasons: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedReason: null,
      msg: {
        title: this.$options.filters.translate('admin_deactivate_application'),
        reason: this.$options.filters.translate('admin_deactivate_reason_message'),
        deactivate: this.$options.filters.translate('admin_deactivate'),
      },
      loading: false,
    };
  },
  computed: {
    displayedReasons() {
      const reasons = this.reasons.map((reason) => ({
        value: reason[0],
        displayName: reason[1],
      }));

      return reasons;
    },
    infoMsg() {
      return this.loading
        ? ''
        : this.msg.reason.replace('[d]', this.selectedRows.length);
    },
  },
  methods: {
    isValid() {
      let isValid = true;
      const deactivateReasonRef = this.$refs['deactivate-reason'];
      if (deactivateReasonRef && !deactivateReasonRef.isValid()) isValid = false;
      return isValid && this.displayedReasons.length;
    },
    onConfirm() {
      if (!this.isValid()) return;
      this.deactivateApplications();
    },
    deactivateApplications() {
      if (!this.selectedReason || this.loading) return;

      const params = {
        targets: this.selectedRows,
        bulkAction: 'deactivate',
        option: this.selectedReason,
      };
      this.loading = true;
      this.$store.dispatch('table/deactivateApplications', params).then((data) => {
        this.$store.dispatch('notify', data.notify);
        this.$emit('close');
        // Refresh data properly
        this.mixinRefreshTableOrDetailView(true);
      }).catch(this.showResponseError)
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
