const HEADWAY_WIDGET_URL = '//cdn.headwayapp.co/widget.js';
let widgetInstance;

const headwayWidget = {
  install(Vue) {
    const init = (translate) => {
      const config = {
        selector: '.headway-app',
        account: process.env.VUE_APP_HEADWAY_ACCOUNT,
        callbacks: {
          onWidgetReady: (widget) => {
            if (!widget.unseenCount) {
              widget.elements.badgeCont.style.opacity = 0;
            }
            // prevent notify icon from jumping
            widget.iframeData.options.eyecatcher = false;
            widgetInstance = widget;
          },
          onShowWidget: () => {
            widgetInstance.elements.badgeCont.style.opacity = 0;
          },
        },
        translations: {
          title: translate('admin_release_notes'),
          readMore: translate('general_read_more'),
          labels: {
            new: translate('general_new'),
            improvement: translate('general_improvement'),
            fix: translate('general_fix'),
          },
          footer: `${translate('general_read_more')} 👉`,
        },
      };
      const headwayScript = document.createElement('script');
      headwayScript.async = true;
      headwayScript.setAttribute('src', HEADWAY_WIDGET_URL);
      document.head.appendChild(headwayScript);

      const waitForHeadway = () => {
        if (window.Headway) {
          window.Headway.init(config);
        } else {
          setTimeout(() => waitForHeadway(), 100);
        }
      };
      waitForHeadway();
    };

    const widget = {
      init,
    };

    Vue.prototype.$headwayWidget = widget;
    Vue.headwayWidget = widget;
  },
};

export default headwayWidget;
