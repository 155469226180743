export default {
  setActiveWidgets(state, data) {
    const { config } = data;
    data.config = undefined;
    const activeWidgets = {};
    Object.keys(data).forEach((key) => {
      if (state.dashboard.availableWidgetComponents
        .includes(config.widgetTypes[state.dashboard.page][key]?.type)) {
        activeWidgets[key] = data[key];
      }
    });
    state.dashboard.activeWidgets = activeWidgets;
    if (state.dashboard.activeWidgetsState === null) {
      state.dashboard.activeWidgetsState = 'init';
    } else {
      state.dashboard.activeWidgetsState = 'reload';
    }
  },
  setActiveWidgetsStateReload(state) {
    state.dashboard.activeWidgetsState = 'reload';
  },
  setActiveWidgetsStateDone(state) {
    state.dashboard.activeWidgetsState = 'done';
  },
  setActiveWidgetsStateError(state) {
    state.dashboard.activeWidgetsState = 'error';
  },
  clearActiveWidgetState(state) {
    state.dashboard.activeWidgetsState = null;
    state.dashboard.activeWidgets = {};
  },
};
