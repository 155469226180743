function setItem(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

function getItem(key) {
  return JSON.parse(localStorage.getItem(key));
}

export default {
  user: {
    get() {
      return getItem('mdt-user') || {};
    },
    set(user = {}) {
      setItem('mdt-user', user);
    },
    remove() {
      localStorage.removeItem('mdt-user');
    },
  },
  pagination: {
    get(key = 'mdt-pagination') {
      return getItem(key) || {};
    },
    set(pagination = {}, key = 'mdt-pagination') {
      setItem(key, pagination);
    },
    remove(key = 'mdt-pagination') {
      localStorage.removeItem(key);
    },
  },
  language: {
    get(key = 'mdt-language') {
      return getItem(key) || {};
    },
    set(language = {}, key = 'mdt-language') {
      setItem(key, language);
    },
    remove(key = 'mdt-language') {
      localStorage.removeItem(key);
    },
  },
  mainMenu: {
    get(key = 'mdt-main-menu') {
      return getItem(key) || {};
    },
    set(mainMenu = {}, key = 'mdt-main-menu') {
      setItem(key, mainMenu);
    },
    remove(key = 'mdt-main-menu') {
      localStorage.removeItem(key);
    },
  },
};
