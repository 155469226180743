import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
export default {
  getDms({ commit }, dmsSlug) {
    return api.get(`api/v1/dms/${dmsSlug}`).then((response) => {
      commit('setDmsData', response.data);
      return response.data;
    });
  },
  createDms(store, payload) {
    return api.post('api/v1/dms/create', payload).then((response) => response.data);
  },
  createDmsCategory(state, params) {
    return api.post(`/api/v1/dms/${params.dmsSlug}/categories`, params.payload).then((response) => response.data);
  },
  renameDmsCategory(state, params) {
    return api.patch(`/api/v1/dms/${params.dmsSlug}/categories/${params.categoryId}`, params.payload).then((response) => response.data);
  },
  deleteDmsCategory(state, params) {
    return api.delete(`/api/v1/dms/${params.dmsSlug}/categories/${params.categoryId}`).then((response) => response.data);
  },
  changeCategoryRoleAccess(state, params) {
    return api.put(`/api/v1/dms/${params.dmsSlug}/categories/${params.categoryId}`, params.payload).then((response) => response.data);
  },
  getInviteBuyerMailData(state, { dmsSlug, email }) {
    return api.get(`api/v2/dms/invite-buyer/${dmsSlug}/${email}`).then((response) => response.data);
  },
  inviteBuyer(state, { dmsSlug, email, payload }) {
    return api.post(`api/v2/dms/invite-buyer/${dmsSlug}/${email}`, payload).then((response) => response.data);
  },
  getInviteMailData(state, { dmsSlug, email }) {
    return api.get(`api/v2/dms/invite/${dmsSlug}/${email}`).then((response) => response.data);
  },
  addThirdPartyPerson(state, { dmsSlug, email, payload }) {
    return api.post(`api/v2/dms/invite/${dmsSlug}/${email}`, payload).then((response) => response.data);
  },
  removeThirdPartyPerson(state, { dmsSlug, payload }) {
    return api.delete(`api/v2/dms/${dmsSlug}/delete`, { data: payload }).then((response) => response.data);
  },
  isUserRegistered(state, { dmsSlug, userSlug, verificationCode }) {
    return api.post(`api/v2/dms/verify-user/${dmsSlug}/${userSlug}/${verificationCode}`).then((response) => response.data);
  },
  getDmsRegisterData(state, { dmsSlug, userSlug }) {
    return api.get(`api/v2/dms/register/${dmsSlug}/${userSlug}`).then((response) => response.data);
  },
  register({ commit }, { dmsSlug, userSlug, payload }) {
    return api.post(`api/v2/dms/register/${dmsSlug}/${userSlug}`, payload).then((response) => {
      commit('saveUserAfterRegister', payload);
      return response.data;
    });
  },
  emptyUserAfterDmsRegister({ commit }) {
    commit('emptyUserAfterDmsRegister');
  },
  phoneNumberAuth({ commit }, { dmsSlug, userSlug, payload }) {
    return api.post(`api/v2/dms/verify-phone-number/${dmsSlug}/${userSlug}`, payload).then((response) => {
      commit('auth/login', {
        ...response.data,
      }, { root: true });
      return response.data;
    });
  },
  resendPhoneNumberAuthCode(state, { dmsSlug, userSlug }) {
    return api.get(`api/v2/dms/verify-phone-number/${dmsSlug}/${userSlug}`).then((response) => response.data);
  },
  login(state, { dmsSlug, payload }) {
    return api.post(`api/v2/dms/login/${dmsSlug}`, payload).then((response) => response.data);
  },
  sendMailForResetPassword(state, { dmsSlug, payload }) {
    return api.post(`api/v2/dms/change-password-request/${dmsSlug}`, payload).then((response) => response.data);
  },
  resetPassword(state, {
    dmsSlug, userSlug, token, payload,
  }) {
    return api.post(`api/v2/dms/change-password/${dmsSlug}/${userSlug}/${token}`, payload).then((response) => response.data);
  },
  sendMailForChangePhoneNumber(state, { dmsSlug, userSlug }) {
    return api.post(`api/v2/dms/change-phone-number-request/${dmsSlug}/${userSlug}`).then((response) => response.data);
  },
  changePhoneNumber(state, { dmsSlug, userSlug, payload }) {
    return api.post(`api/v2/dms/change-phone-number/${dmsSlug}/${userSlug}`, payload).then((response) => response.data);
  },
  logout({ commit }) {
    return api.post('api/v2/dms/logout')
      .catch(() => {})
      .finally(() => {
        commit('auth/logout', null, { root: true });
      });
  },
  postUploadDocumentsSetup(state, settings) {
    return api.post(settings.path, settings.data, { params: settings.params })
      .then((response) => response.data);
  },
};
