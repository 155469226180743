<template>
  <div class="mdt-filters">
    <div
      v-for="(filter, i) in mainFilters"
      :key="i">
      <div
        v-if="filter.type === 'toggle'"
        class="side-filters">
        <div
          class="side-filter pointer toggle-filter"
          :class="{ active: filter.selected[0] }"
          @click="updateSelected([!filter.selected[0]], filter);
                  applyFilter(filter)">
          <i class="fas fa-circle-check icon-check" />
          {{ filter.verbose_name }}
        </div>
      </div>
      <div
        v-else
        v-click-outside:[filter.key]="closeDropdown"
        class="main-filter pointer"
        :class="{
          active: filter.selected.length,
          'dropdown-open': mainFilterDropdownToggle[filter.key],
          'clear-filter-hovered': clearFilterHovered[filter.key],
        }"
        @click="toggleDropdown(filter.key)">
        <span>{{ filter.verbose_name }}</span>
        <template v-if="filter.selected.length">
          <span v-if="filter.type === 'checkbox'">
            : {{ filter.selected.length }}
          </span>
          <span v-else-if="filter.type === 'radio'">
            : {{ filter.choices.find((c) => c.value === filter.selected[0]).displayName }}
          </span>
          <span v-else-if="filter.type === 'slider'">
            : {{ filter.selected.join(' - ') }} {{ filter.unit }}
          </span>
          <span v-else-if="filter.type === 'date'">
            : {{ filter.selected[0] | formatDate('DD.MM.YYYY') }} - {{ filter.selected[1] | formatDate('DD.MM.YYYY') }}
          </span>
          <span v-else-if="filter.type === 'multiselect_remote'">
            : {{ filter.selected.map((item) => searchAddressHelper
              .addressFormatter(item.data)).join(', ') }}
          </span>
          <div
            class="filter-clear flex-center"
            @mouseenter="clearFilterHovered[filter.key] = true"
            @mouseleave="clearFilterHovered[filter.key] = false"
            @click.stop="clearFilter(filter); clearFilterHovered[filter.key] = false;">
            <i class="fas fa-times-circle" />
          </div>
        </template>
        <i
          v-else
          class="fas icon-caret"
          :class="[mainFilterDropdownToggle[filter.key] ? 'fa-caret-up' : 'fa-caret-down']" />
        <div
          v-if="mainFilterDropdownToggle[filter.key]"
          class="main-filter-dropdown"
          :class="position"
          @click.stop>
          <MdtCheckboxList
            v-if="filter.type === 'checkbox'"
            :items="filter.choices"
            :selected="selected[filter.key]"
            items-max-height="320px"
            show-search
            @updateSelected="updateSelected($event, filter)" />
          <MdtRadioList
            v-if="filter.type === 'radio'"
            :value="selected[filter.key].length ? selected[filter.key][0] : null"
            :items="filter.choices"
            unset
            equal-width
            @input="updateSelected([$event], filter)"
            @inputUnset="updateSelected([], filter)" />
          <MdtRangeSlider
            v-if="filter.type === 'slider'"
            :min="filter.choices[0]"
            :max="filter.choices[1]"
            :step="filter.step"
            :selected="selected[filter.key]"
            :label="filter.unit
              ? `${$options.filters.translate('general_in').toLowerCase()} ${filter.unit}` : ''"
            @input="updateSelected($event, filter)" />
          <MdtDatepickerRange
            v-if="filter.type === 'date'"
            ref="main-filter"
            :key="`main-filter-${i}`"
            :selected="selected[filter.key]"
            hide-optional
            use-validation
            @input="updateSelected($event, filter)" />
          <MdtMultiselectRemote
            v-if="filter.type === 'multiselect_remote'"
            :data-request="{ apiPoint: 'waitlist/searchAddresses' }"
            :result-mapper="searchAddressHelper.addressSearchResultMapper"
            :selected-mapper="searchAddressHelper.addressSelectedItemsMapper"
            :selected="selected[filter.key]"
            :search-placeholder="msg.addressesPlaceholder"
            @updateSelected="updateSelected($event, filter)" />
          <div class="dropdown-buttons-row">
            <div
              class="btn btn-light btn-size-32 close-dropdown"
              @click.stop="closeDropdown($event, filter.key)">
              {{ 'general_cancel' | translate }}
            </div>
            <div
              class="btn btn-primary btn-size-32 apply-filter"
              @click.stop="applyFilter(filter)">
              {{ 'general_filter' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="otherFilters.length"
      class="other-filters pointer"
      :class="{
        active: activeOtherFilters.length,
        'clear-filter-hovered': clearFilterHovered.otherFilters,
      }"
      @click="isModalOpen = true">
      <span v-if="mainFilters.length">
        {{ 'general_filter' | translate }}
      </span>
      <template v-else>
        <i
          v-if="!activeOtherFilters.length"
          class="fas fa-filter icon-filter" />
        <span class="other-filters-title">
          {{ title || $options.filters.translate('admin_filter') }}
        </span>
      </template>
      <template v-if="activeOtherFilters.length">
        <span>
          : {{ activeOtherFilters.length }}
        </span>
        <div
          class="filter-clear flex-center"
          @mouseenter="clearFilterHovered.otherFilters = true"
          @mouseleave="clearFilterHovered.otherFilters = false"
          @click.stop="clearOtherFilters(); clearFilterHovered.otherFilters = false;">
          <i class="fas fa-times-circle" />
        </div>
      </template>
    </div>
    <div
      v-if="mainFilters.length + (otherFilters.length ? 1 : 0) > 1 && !isClearFiltersButtonShown"
      class="btn btn-size-32 btn-light reset-btn"
      :class="{ 'btn-disabled': !activeFilters.length }"
      @click="activeFilters.length ? clearAllFilters() : null">
      {{ 'general_reset_to_default' | translate }}
    </div>
    <MdtModal
      v-if="isModalOpen"
      :title="mainFilters.length ? $options.filters.translate('general_more_filters')
        : title || $options.filters.translate('admin_filter')"
      width="605px"
      height="620px"
      @close="closeModal"
      @cancel="closeModal"
      @save="applyFilters">
      <div
        v-for="(filter, i) in otherFilters"
        :key="i"
        class="other-filter">
        <div class="filter-name">
          <span>
            {{ filter.verbose_name }}
          </span>
          <span
            v-if="filter.type === 'slider' && filter.unit"
            class="filter-unit">
            ({{ $options.filters.translate('general_in').toLowerCase() }} {{ filter.unit }})
          </span>
        </div>
        <MdtCheckboxList
          v-if="filter.type === 'checkbox'"
          :items="filter.choices"
          :selected="selected[filter.key]"
          grid
          limit-items
          @updateSelected="updateSelected($event, filter)" />
        <MdtRadioList
          v-if="filter.type === 'radio'"
          :value="selected[filter.key].length ? selected[filter.key][0] : null"
          :items="filter.choices"
          unset
          equal-width
          @input="updateSelected([$event], filter)"
          @inputUnset="updateSelected([], filter)" />
        <MdtRangeSlider
          v-if="filter.type === 'slider'"
          :min="filter.choices[0]"
          :max="filter.choices[1]"
          :step="filter.step"
          :selected="selected[filter.key]"
          @input="updateSelected($event, filter)" />
        <MdtDatepickerRange
          v-if="filter.type === 'date'"
          ref="other-filter"
          :key="`other-filter-${i}`"
          :selected="selected[filter.key]"
          direction="row"
          hide-optional
          use-validation
          @input="updateSelected($event, filter)" />
        <MdtMultiselectRemote
          v-if="filter.type === 'multiselect_remote'"
          :data-request="{ apiPoint: 'waitlist/searchAddresses' }"
          :result-mapper="searchAddressHelper.addressSearchResultMapper"
          :selected-mapper="searchAddressHelper.addressSelectedItemsMapper"
          :selected="selected[filter.key]"
          :search-placeholder="msg.addressesPlaceholder"
          :position="'top'"
          @updateSelected="updateSelected($event, filter)" />
      </div>
    </MdtModal>
  </div>
</template>

<script>
import { searchAddressHelper, helpers } from '@/utility';
import {
  CLEAR_FILTERS_BLACKLIST_ROUTES,
} from '@/utility/constants';

export default {
  name: 'MdtFilters',
  props: {
    filters: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'bottom',
      validator: (value) => ['bottom', 'top'].includes(value),
    },
  },
  data() {
    return {
      selected: {},
      mainFilterDropdownToggle: {},
      clearFilterHovered: {},
      isModalOpen: false,
      searchAddressHelper,
      msg: {
        addressesPlaceholder: `${this.$options.filters.translate('general_city')}, ${this.$options.filters.translate('general_post_code')}, ${this.$options.filters.translate('general_colony')}, ${this.$options.filters.translate('general_address')}`,
      },
    };
  },
  computed: {
    mainFilters() {
      return this.filters.filter((filter) => filter.mainFilters);
    },
    otherFilters: {
      get() {
        return this.filters.filter((filter) => !filter.mainFilters);
      },
      set(newValue) {
        return newValue;
      },
    },
    activeFilters() {
      return this.filters.filter((filter) => filter.selected?.length > 0);
    },
    activeOtherFilters() {
      return this.activeFilters.filter((filter) => !filter.mainFilters);
    },
    isClearFiltersButtonShown() {
      return CLEAR_FILTERS_BLACKLIST_ROUTES.includes(this.$route.name);
    },
    basicFilters() {
      return this.$store.getters['communication/basicFilters'];
    },
  },
  watch: {
    filters: {
      handler() {
        this.addReactiveState();
      },
    },
  },
  beforeMount() {
    this.addReactiveState();
  },
  methods: {
    addReactiveState() {
      // Add 'selected' reactive state to cache selected values
      this.filters.forEach((filter) => {
        if (!filter.selected) this.$set(filter, 'selected', []);
        this.$set(this.selected, `${filter.key}`, JSON.parse(JSON.stringify(filter.selected)));
        if (filter.mainFilters) {
          // Add reactive state to toggle dropdown for main filters
          this.$set(this.mainFilterDropdownToggle, `${filter.key}`, false);
          // Add reactive state to change parent style when clear filter is hovered
          this.$set(this.clearFilterHovered, `${filter.key}`, false);
        } else {
          // when switching tabs we need to persist filters
          // In vuex store we have basicFilters which are persisted
          // set selected values from basicFilters to otherFilters
          // eslint-disable-next-line no-lonely-if
          if (this.basicFilters[filter.key]) {
            this.otherFilters.map((of) => {
              if (of.key === filter.key) {
                of.selected = this.basicFilters[filter.key].split(',');
                this.selected[filter.key] = this.basicFilters[filter.key].split(',');
              }
              return of;
            });
          }
        }
      });
      this.$set(this.clearFilterHovered, 'otherFilters', false);
    },
    updateSelected(selected, filter) {
      this.selected[filter.key] = selected;
    },
    setFilterSelected(filter) {
      const selectedCopy = JSON.parse(JSON.stringify(this.selected[filter.key]));

      // Reset slider filter if min/max value are selected
      if (filter.type === 'slider' && filter.choices[0] === selectedCopy[0]
        && filter.choices[1] === selectedCopy[1]) {
        filter.selected = [];
        this.selected[filter.key] = [];
      } else {
        filter.selected = selectedCopy;
      }
    },
    applyFilters() {
      if (!this.otherFiltersAreValid()) return;

      this.filters.forEach((filter) => {
        this.setFilterSelected(filter);
      });
      this.isModalOpen = false;
      this.emitApply();
    },
    applyFilter(filter) {
      if (!this.mainFilterIsValid()) return;

      this.setFilterSelected(filter);

      this.mainFilterDropdownToggle[filter.key] = false;
      this.emitApply();
    },
    emitApply() {
      // Format filters to match backend rules
      const formated = {};
      this.filters.forEach((filter) => {
        if (filter.selected.length) {
          switch (filter.type) {
            case 'radio':
              [formated[filter.key]] = filter.selected;
              break;
            case 'date':
              formated[`${filter.key}-from`] = helpers.valueOrUndefined(this.$options.filters.formatDate(filter.selected[0], 'DD.MM.YYYY'));
              formated[`${filter.key}-to`] = helpers.valueOrUndefined(this.$options.filters.formatDate(filter.selected[1], 'DD.MM.YYYY'));
              break;
            case 'multiselect_remote':
              formated[filter.key] = JSON.stringify(filter.selected.map((item) => item.data));
              break;
            case 'toggle': {
              const [selected] = filter.selected;
              if (selected) {
                formated[filter.key] = selected;
              } else {
                // remove filter from active filters
                filter.selected = [];
                this.selected[filter.key] = [];
              }
              break;
            }
            default:
              formated[filter.key] = filter.selected.join(',');
          }
        }
      });

      this.$emit('applyFilters', formated);
    },
    clearAllFilters() {
      this.filters.forEach((filter) => {
        filter.selected = [];
        this.selected[filter.key] = [];
      });
      this.$emit('applyFilters', {});
    },
    clearFilter(filter) {
      this.mainFilterDropdownToggle[filter.key] = false;
      filter.selected = [];
      this.selected[filter.key] = [];
      this.emitApply();
    },
    clearOtherFilters() {
      this.otherFilters.forEach((filter) => {
        filter.selected = [];
        this.selected[filter.key] = [];
      });
      this.emitApply();
    },
    resetSelectedFilters() {
      this.filters.forEach((filter) => {
        this.selected[filter.key] = JSON.parse(JSON.stringify(filter.selected));
      });
    },
    closeModal() {
      this.resetSelectedFilters();
      this.isModalOpen = false;
    },
    closeDropdown(e, filterKey) {
      if (this.mainFilterDropdownToggle[filterKey]) {
        this.resetSelectedFilters();
        this.mainFilterDropdownToggle[filterKey] = false;
      }
    },
    toggleDropdown(filterKey) {
      if (this.mainFilterDropdownToggle[filterKey]) {
        this.resetSelectedFilters();
      }
      this.mainFilterDropdownToggle[filterKey] = !this.mainFilterDropdownToggle[filterKey];
    },
    mainFilterIsValid() {
      return this.$refs['main-filter']?.[0] ? this.$refs['main-filter'][0].isValid() : true;
    },
    otherFiltersAreValid() {
      return this.$refs['other-filter']?.length ? this.$refs['other-filter'].map((r) => r.isValid()).every((r) => r) : true;
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  user-select: none;

  .main-filter,
  .other-filters,
  .side-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    margin-bottom: 10px;
    padding: 0 12px;
    border: 1px solid $border-color;
    border-radius: 20px;
    margin-right: 10px;
    line-height: 32px;
    color: $color-text-secondary;
    font-size: 14px;

    &:hover,
    &.dropdown-open {
      background-color: $color-back-light;
    }

    &.active {
      border: none;
      background-color: rgba($color-theme-rgb, 0.15);
      color: $color-theme;

      &:hover {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      }

      &.clear-filter-hovered {
        background-color: rgba($color-theme-rgb, 0.1);
      }
    }

    .filter-clear {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      margin-right: -12px;
      margin-left: 1px;

      &:hover {
        background: rgba(13, 73, 128, 0.05);
      }
    }
  }

  .side-filters {
    .icon-check {
      margin-right: 8px;
    }
  }

  .main-filter {
    position: relative;
    white-space: nowrap;

    .icon-caret {
      margin-left: 8px;
      font-size: 14px;
    }

    .main-filter-dropdown {
      position: absolute;
      top: calc(100% + 6px);
      left: 0;
      min-width: 260px;
      border-radius: 4px;
      background-color: $color-back-primary;
      box-shadow: 0px 2px 20px 0px #0000002a;
      cursor: initial;
      z-index: 10;

      &.top {
        top: auto;
        bottom: calc(100% + 10px);
      }

      .mdt-radio-list {
        padding: 16px;
      }

      .mdt-range-slider {
        padding: 16px 16px 40px 16px;
      }

      .mdt-multiselect-remote {
        padding: 16px;
      }

      ::v-deep {
        .mdt-search {
          margin: 16px;
        }

        .mdt-checkbox {
          display: inline-flex;
          align-items: center;
          height: 40px;
          margin-bottom: 0;
          padding: 0 16px;
          line-height: 38px;

          &:hover {
            background-color: $color-back-light;
          }

          .state {
            .icon {
              top: calc(50% - 8px);
              left: 16px;
            }

            label {
              vertical-align: middle;

              &:before,
              &:after {
                top: calc(50% - 8px);
                left: 16px;
              }
            }
          }
        }
      }

      .dropdown-buttons-row {
        display: flex;
        justify-content: flex-end;
        padding: 16px;
        box-shadow: 0px 0px 20px 0px #0000001A;

        div:last-child {
          margin-left: 10px;
        }
      }
    }

    .mdt-datepicker-range {
      margin: 16px;
    }
  }

  .other-filters {
    .icon-filter{
      margin-right: 10px;
      color: $color-text-secondary;
    }
  }

  .other-filter {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    .filter-name {
      margin-bottom: 20px;
      color: #000;
      font-weight: bold;

      .filter-unit {
        font-weight: normal;
      }
    }
  }

  .reset-btn {
    align-self: flex-start;
  }
}
</style>
