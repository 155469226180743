<template>
  <div
    v-click-outside="closeDropdown"
    class="mdt-multiselect"
    :class="[
      { 'has-error': $v.selectedTags.$error || serverErrors.length, disabled, readonly },
      `select-${size}`]"
    :style="{ width: width || null, maxWidth: maxWidth || null, minWidth: minWidth || null }">
    <div
      v-if="label"
      class="multiselect-label">
      {{ label }}
      <span v-if="!required && !hideOptional">({{ 'admin_marketing_optional' | translate }})</span>
    </div>
    <div
      class="multiselect-wrapper flex-center-v"
      :class="{ focused: isDropdownOpen }"
      @click.stop="wrapperClick"
      @keyup.up="goToPreviousItem"
      @keyup.down="goToNextItem"
      @keyup.enter="selectItem"
      @keydown.esc="clearSearch">
      <i
        class="fas fa-caret-down icon-dropdown pointer"
        :class="{ 'fa-caret-up': isDropdownOpen }" />
      <div class="mdt-multiselect-selection">
        <ul
          v-if="selectedTags.length"
          class="multiselect-selection">
          <li
            v-for="(tag, i) in selectedTags"
            :key="i"
            v-tooltip="tag.readonly !== undefined ? tag.readonly.unavailable_tooltip : ''"
            class="selected-tag">
            {{ tag.displayName }}
            <i
              v-if="tag.readonly === undefined || tag.readonly.value === false"
              class="fas fa-times-circle tag-icon-close"
              @click.stop="removeTag(tag)" />
          </li>
          <span class="search">
            <input
              ref="input"
              v-model="search"
              :placeholder="searchboxPlaceholder"
              :disabled="limitReached"
              :readonly="readonly"
              :size="search.length < 4 ? 4 : search.length"
              type="text">
            <span
              v-if="search"
              class="search-clear"
              @click.stop="clearSearch">
              <i class="fas fa-times-circle" />
            </span>
          </span>
        </ul>
        <span
          v-else
          class="search">
          <input
            ref="input"
            v-model="search"
            :placeholder="searchboxPlaceholder"
            :disabled="limitReached"
            :readonly="readonly"
            :size="search.length < 4 ? 4 : search.length"
            type="text">
          <span
            v-if="search"
            class="search-clear"
            @click.stop="clearSearch">
            <i class="fas fa-times-circle" />
          </span>
        </span>
      </div>
      <div
        v-if="isDropdownOpen"
        class="multiselect-dropdown"
        :class="[position, { 'right-aligned': rightAligned }]"
        :style="{ maxWidth: maxWidth || null, maxHeight: maxHeight || null }">
        <vue-scroll
          ref="scroll"
          :ops="vueScrollOptions">
          <ul class="multiselect-dropdown-items-wrapper">
            <li
              v-for="(item, i) in filteredItems"
              :key="i"
              v-overflow-tooltip
              v-tooltip="item.readonly !== undefined ? item.readonly.unavailable_tooltip : ''"
              :data-value="item.value"
              class="dropdown-item"
              :class="{
                readonly: item.readonly !== undefined && item.readonly.value === true,
                'mdt-multiselect-no-items': item.value === 'mdt-multiselect-no-items',
              }"
              @click.stop="item.value !== 'mdt-multiselect-no-items'
                && (item.readonly === undefined || item.readonly.value === false)
                ? selectTag(item) : null">
              <div class="text-cut">
                {{ item.displayName }}
              </div>
            </li>
          </ul>
        </vue-scroll>
      </div>
    </div>
    <div
      v-if="clientErrors.length || serverErrors.length"
      class="input-errors">
      <span class="client-errors">
        {{ clientErrors.join('\n') }}
        {{ clientErrors.length && serverErrors.length ? '\n' : '' }}
      </span>
      <span class="server-errors">
        {{ serverErrors.join('\n') }}
      </span>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mixinToggleDropdown } from '@/mixins';

export default {
  name: 'MdtMultiselect',
  mixins: [mixinToggleDropdown, validationMixin],
  props: {
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'bottom',
      validator: (value) => ['bottom', 'top'].includes(value),
    },
    rightAligned: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '',
    },
    minWidth: {
      type: String,
      default: '200px',
    },
    maxHeight: {
      type: String,
      default: '',
    },
    searchPlaceholder: {
      type: String,
      default: '',
    },
    selectedLimit: {
      type: Number,
      default: null,
    },
    size: {
      type: String,
      default: 'size-40',
      validator: (value) => {
        const match = ['size-40', 'size-32'];
        return match.includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    additionalSearchableProperties: {
      type: Array,
      default: () => [],
    },
    hideOptional: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      selectedTags: this.selected || [],
      serverErrors: this.errors || [],
      vueScrollOptions: {
        scrollPanel: {
          scrollingX: false,
        },
        bar: {
          keepShow: true,
        },
      },
      msg: {
        noObjectsAvailable: this.$options.filters.translate('admin_no_objects_available'),
      },
    };
  },
  computed: {
    clientErrors() {
      const errors = [];
      const isDirty = this.$v.selectedTags.$dirty;
      if (!this.$v.selectedTags.isTagSelected && isDirty) {
        errors.push(this.$options.filters.translate('general_field_is_required'));
      }
      return errors;
    },
    filteredItems() {
      // Filter items according to search and already selected ones

      let filtered = this.items;

      // if there are no items -> add 'mdt-multiselect-no-items' item and return it
      if (!filtered.length) {
        return [{ displayName: this.msg.noObjectsAvailable, value: 'mdt-multiselect-no-items' }];
      }

      // if there are no selected items (tags) or search is empty -> return all items
      if (this.selectedTags.length === 0 && this.search.length === 0) return filtered;

      // otherwise -> filter items by selected tags and search
      filtered = this.items
        .filter((item) => {
          const index = this.selectedTags
            .findIndex((selectedTag) => selectedTag.value === item.value);
          return !(index > -1);
        })
        .filter((item) => {
          let includes = false;

          ['displayName', ...this.additionalSearchableProperties].forEach((property) => {
            if (!includes && item[property]) {
              includes = item[property].toLowerCase()
                .includes(this.search.toLowerCase());
            }
          });

          return includes;
        });

      // if there are no items -> add 'mdt-multiselect-no-items' item and return it
      if (!filtered.length) {
        return [{ displayName: this.msg.noObjectsAvailable, value: 'mdt-multiselect-no-items' }];
      }

      return filtered;
    },
    searchboxPlaceholder() {
      const placeholder = this.searchPlaceholder || `${this.$options.filters.translate('general_search')}...`;
      return this.selectedTags.length ? '' : placeholder;
    },
    limitReached() {
      return this.selected.length === this.selectedLimit;
    },
  },
  watch: {
    selected(selected) {
      this.selectedTags = selected || [];
    },
    errors(value) {
      this.serverErrors = value;
    },
    isDropdownOpen(value) {
      if (value) {
        this.clearHoveredItems();
        this.$nextTick(() => {
          this.focusInput();
          this.scrollTheView();
        });
      }
    },
  },
  validations() {
    return {
      selectedTags: {
        isTagSelected: () => (this.required ? this.selectedTags.length > 0 : true),
      },
    };
  },
  methods: {
    setTouched() {
      this.$v.selectedTags.$touch();
    },
    isValid() {
      this.setTouched();
      return !this.$v.selectedTags.$error && !this.serverErrors.length;
    },
    selectTag(tag) {
      this.setTouched();
      this.selectedTags.push(tag);
      this.search = '';
      this.$emit('updateSelected', this.selectedTags);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');

      this.closeDropdown();

      // Reset server errors
      this.serverErrors = [];
    },
    removeTag(tag) {
      const index = this.selectedTags
        .findIndex((selectedTag) => selectedTag.value === tag.value);

      if (index > -1) {
        this.setTouched();
        this.selectedTags.splice(index, 1);
        this.$emit('updateSelected', this.selectedTags);

        // emit mdtDataChanged event so changes could be detected
        this.$emit('mdtDataChanged');
      }
    },
    focusInput() {
      const inputEl = this.$refs.input;
      if (inputEl) {
        if (this.isDropdownOpen) inputEl.focus();
        else inputEl.blur();
      }
    },
    clearSearch() {
      if (!this.search) this.closeDropdown();
      else {
        this.search = '';
        this.focusInput();
      }
      this.clearHoveredItems();
    },
    clearHoveredItems() {
      const scroll = this.$refs.scroll?.$el;
      if (scroll) {
        const hoveredItems = scroll.querySelectorAll('li.hovered');
        hoveredItems.forEach((item) => {
          item.classList.remove('hovered');
        });
      }
    },
    scrollTheView() {
      const scroll = this.$refs.scroll?.$el;
      if (scroll) {
        // scroll the view if neccessary to show whole component
        scroll.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    },
    goToSelectedItem(item) {
      const scroll = this.$refs.scroll?.$el;
      if (scroll) {
        const selectedItem = item || scroll.querySelector('li.selected');
        if (selectedItem) {
          scroll.firstChild.scroll({ top: selectedItem.offsetTop, behavior: 'smooth' });
        }
      }
    },
    goToPreviousItem() {
      const scroll = this.$refs.scroll?.$el;
      if (scroll) {
        const items = scroll.querySelectorAll('li');
        const selectedItem = scroll.querySelector('li.selected');
        const hoveredItem = scroll.querySelector('li.hovered');
        let previousItem;
        if (!selectedItem && !hoveredItem) {
          [previousItem] = items;
        } else if (items.length === 1) {
          [previousItem] = items;
        } else {
          previousItem = hoveredItem
            ? hoveredItem.previousElementSibling
            : selectedItem.previousElementSibling;
        }
        if (!previousItem) return;
        items.forEach((item) => {
          item.classList.remove('hovered');
        });

        // if previous item is 'mdt-multiselect-no-items' -> don't select it
        if (previousItem?.dataset?.value === 'mdt-multiselect-no-items') return;

        previousItem.classList.add('hovered');
        this.goToSelectedItem(previousItem);
      }
    },
    goToNextItem() {
      const scroll = this.$refs.scroll?.$el;
      if (scroll) {
        const items = scroll.querySelectorAll('li');
        const selectedItem = scroll.querySelector('li.selected');
        const hoveredItem = scroll.querySelector('li.hovered');
        let nextItem;
        if (!selectedItem && !hoveredItem) {
          [nextItem] = items;
        } else if (items.length === 1) {
          [nextItem] = items;
        } else {
          nextItem = hoveredItem
            ? hoveredItem.nextElementSibling
            : selectedItem.nextElementSibling;
        }
        if (!nextItem) return;
        items.forEach((item) => {
          item.classList.remove('hovered');
        });

        // if nextItem item is 'mdt-multiselect-no-items' -> don't select it
        if (nextItem?.dataset?.value === 'mdt-multiselect-no-items') return;

        nextItem.classList.add('hovered');
        this.goToSelectedItem(nextItem);
      }
    },
    selectItem() {
      const scroll = this.$refs.scroll?.$el;
      if (scroll) {
        const hoveredItem = scroll.querySelector('li.hovered');
        if (!hoveredItem) return;
        const option = this.filteredItems
          .find((obj) => obj.value.toString() === hoveredItem.dataset.value);
        if (option) this.selectTag(option);
      }
    },
    wrapperClick() {
      if (!this.limitReached) {
        this.onToggleDropdown();
      }
    },
    onToggleDropdown() {
      if (this.disabled || this.readonly) return;
      this.toggleDropdown();
      this.focusInput();
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-multiselect {
  text-align: left;

  &.has-error {
    color: $color-danger;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgba($color-danger, 0.6);
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: rgba($color-danger, 0.6);
    }
    :-moz-placeholder { /* Firefox 18- */
      color: rgba($color-danger, 0.6);
    }

    .multiselect-wrapper {
      background-color: rgba($color-danger, 0.05);
      border: 2px solid $color-danger !important;
    }
  }

  &.disabled {
    cursor: default;
    opacity: 0.3;

    * {
      pointer-events: none;
    }

    .multiselect-wrapper {
      border-color: $color-text-secondary;
    }
  }

  &.readonly {
    cursor: not-allowed;

    .multiselect-label,
    .input-errors {
      cursor: text;
    }

    .multiselect-wrapper,
    .multiselect-wrapper * {
      pointer-events: none;
    }
  }

  // select with height 32px
  &.select-size-32 {
    .multiselect-wrapper {
      min-height: 32px;
      line-height: 30px;

      &.focused {
        .icon-dropdown {
          top: 6px;
        }
      }

      .icon-dropdown {
        top: 8px;
        font-size: 14px;
      }

      .search-clear {
        font-size: 14px;
      }
    }

    .search {
      & > input {
        font-size: 14px;
      }
    }

    .multiselect-dropdown {
      .dropdown-item {
        font-size: 14px;
      }
    }
  }
}

.multiselect-label {
  margin-bottom: 8px;
  color: $color-text-secondary;
  font-size: 14px;
  line-height: 14px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $color-back-basic;
  font-size: 16px;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $color-back-basic;
  font-size: 16px;
}
:-moz-placeholder { /* Firefox 18- */
  color: $color-back-basic;
  font-size: 16px;
}

.multiselect-wrapper {
  position: relative;
  width: 100%;
  min-height: 40px;
  padding: 0 34px 0 10px;
  line-height: 38px;
  background-color: $color-back-primary;
  border: 1px solid $border-color;
  border-radius: 4px;
  cursor: text;

  &:hover {
    border-color: $color-text-secondary;
  }

  &.focused {
    padding: 0 35px 0 9px;
    border: 2px solid $color-theme;
    outline: 0;

    .multiselect-selection {
      .selected-tag {
        margin: 5px 5px 5px 0;
      }
    }

    .icon-dropdown {
      top: 10px;
      right: 11px;
    }
  }

  &.has-error {
    background-color: #fff0ee;
    border: 2px solid $color-danger;
  }

  .icon-dropdown {
    position: absolute;
    top: 12px;
    right: 12px;
    color: $color-text-secondary;
    font-size: 16px;
  }

  .search-clear {
    margin-left: 5px;
    padding: 0;
    color: $color-text-secondary;
    font-size: 16px;
    cursor: pointer;
  }
}

.mdt-multiselect-selection {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.multiselect-selection {
  display: flex;
  flex: 1 1;
  flex-basis: unset;
  flex-wrap: wrap;

  .selected-tag {
    display: block;
    min-height: 24px;
    line-height: 24px;
    padding: 0 8px;
    margin: 5px 5px 5px 0;
    background-color: $color-back-basic;
    color: $color-text-secondary;
    border-radius: 4px;
    white-space: break-spaces;
    word-break: break-all;
    cursor: default;

    .tag-icon-close {
      margin-left: 4px;
      padding: 0;
      color: $color-text-secondary;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        color: $color-text-primary;
      }
    }
  }
}

.search {
  display: flex;
  flex: 1;

  & > input {
    background: transparent;
    border: none;
    outline: 0;
    box-shadow: none;
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: $color-text-primary;
  }
}

.multiselect-dropdown {
  position: absolute;
  top: calc(100% + 6px);
  left: -1px;
  width: 100%;
  min-width: 200px;
  background-color: $color-back-primary;
  box-shadow: 0px 2px 20px 0px #0000002a;
  border-radius: 4px;
  overflow: hidden;
  z-index: 101;

  &.right-aligned {
    left: auto;
    right: -1px;
  }

  &.top {
    top: auto;
    bottom: calc(100% + 5px);
  }

  .dropdown-item {
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    color: $color-text-secondary;
    font-size: 16px;
    cursor: pointer;

    &.mdt-multiselect-no-items {
      user-select: none;
      cursor: default;

      &:hover,
      &.hovered {
        background-color: transparent;
      }
    }

    &:hover:not(.readonly),
    &.hovered:not(.readonly) {
      color: $color-text-primary;
      background-color: $color-back-light;
    }

    &.readonly {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .multiselect-dropdown-items-wrapper {
    max-height: 200px;
  }
}

.input-errors {
  padding-top: 4px;
  font-size: 12px;
  font-weight: $font-weight-normal;
  white-space: pre-line;
}
</style>
