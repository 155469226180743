import store from '@/store';

export default function currency(value, showCurrency) {
  if (!value && typeof value !== 'number') return value;
  const [number, decimal] = value.toString().split('.');
  const thousandsRegex = /\B(?=(\d{3})+(?!\d))/g;
  const { currency: defaultCurrency, currencyOrder: defaultCurrencyOrder } = store.state
    .bootstrap.settings;
  let formattedNumber;
  switch (defaultCurrency) {
    case 'CHF': {
      const formatedNumber = number.replace(thousandsRegex, '\'');
      formattedNumber = decimal ? `${formatedNumber}.${decimal}` : `${formatedNumber}`;
      break;
    }
    default: {
      const formatedNumber = number.replace(thousandsRegex, '.');
      formattedNumber = decimal ? `${formatedNumber},${decimal}` : `${formatedNumber}`;
      break;
    }
  }
  if (showCurrency) {
    if (defaultCurrencyOrder === 'left') return `${defaultCurrency} ${formattedNumber}`;
    return `${formattedNumber} ${defaultCurrency}`;
  }
  return formattedNumber;
}
