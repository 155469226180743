<template>
  <div class="mdt-wizard-content">
    <template v-for="(element, index) in wizardActivePageContent">
      <div
        v-if="Array.isArray(element)"
        :key="index"
        class="multi-row">
        <template v-for="(item) in element">
          <transition
            :key="`wizard-${item.name}`"
            :name="transitionName"
            mode="out-in">
            <MdtWizardContentElement
              :key="`wizard-${item.name}`"
              :ref="item.name"
              :element="item"
              :transition-name="transitionName"
              class="column"
              v-on="$listeners" />
          </transition>
        </template>
      </div>
      <transition
        v-else
        :key="`wizard-${element.name}`"
        :name="transitionName"
        mode="out-in">
        <MdtWizardContentElement
          :key="`wizard-${element.name}`"
          :ref="element.name"
          :element="element"
          :transition-name="transitionName"
          class="row"
          v-on="$listeners" />
      </transition>
    </template>
  </div>
</template>

<script>
import MdtWizardContentElement from '@/components/shared/wizard/MdtWizardContentElement.vue';

export default {
  name: 'MdtWizardContent',
  components: {
    MdtWizardContentElement,
  },
  props: {
    wizardActivePageContent: {
      type: Array,
      required: true,
    },
    transitionName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-wizard-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.row,
.multi-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 8px;
}

.column {
  padding: 8px 10px 8px 0;

  &:last-child {
    padding-right: 0;
  }
}

.slide-down-enter-active,
.slide-down-leave-active,
.slide-up-enter-active,
.slide-up-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}
.slide-down-enter,
.slide-up-leave-active {
  opacity: 0;
  transform: translate(0, -1em);
}
.slide-down-leave-active,
.slide-up-enter {
  opacity: 0;
  transform: translate(0, 1em);
}
</style>
