import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
export default {
  getUploadImages(state, path) {
    return api.get(path).then((response) => response.data);
  },
  postUploadImages(state, settings) {
    return api.post(settings.path, settings.data).then((response) => response.data);
  },
  resetAdvertisementData({ commit }) {
    commit('resetAdvertisementData');
  },
  getAdvertisementData({ commit }, params) {
    return api.post('/api/v2/marketing/objects', params).then((response) => {
      commit('setAdvertisementData', response.data || {});
    });
  },
  getAdvertisementUpdateData({ commit }, params) {
    return api.get(`/api/v2/marketing/objects/${params.id}/edit`).then((response) => {
      commit('setAdvertisementData', response.data || {});
    });
  },
  advertise(state, publishData) {
    return api.post('/api/v1/marketing/ad/publish', publishData, { timeout: 0 }).then((response) => response.data);
  },
  adSave(state, publishData) {
    return api.post('/api/v1/marketing/ad/save', publishData).then((response) => response.data);
  },
  deactivateEveryObjectAdvertisement(state, settings) {
    return api.post('/api/v1/marketing/objects/deactivate', settings.params);
  },
  refreshEveryObjectAdvertisement(state, settings) {
    return api.post('/api/v1/marketing/objects/refresh', settings.params);
  },
  b2bResetProjectAdvertisementData({ commit }) {
    commit('b2bResetProjectAdvertisementData');
  },
  b2bGetProjectAdvertisementData({ commit }, targets) {
    return api.get('/api/v1/b2b/objects/create-advertisement', { params: { targets } }).then((response) => {
      commit('b2bSetProjectAdvertisementData', response.data || {});
    });
  },
  b2bProjectAdSave({ commit }, publishData) {
    return api.post('/api/v1/b2b/objects/create-advertisement', publishData).then((response) => {
      commit('b2bSetProjectAdvertisementDataAfterSave', response.data);
      return response.data;
    });
  },
  b2bProjectAdvertise(state, publishData) {
    return api.post('/api/v1/b2b/objects/publish', publishData, { timeout: 0 }).then((response) => response.data);
  },
  b2bDeactivateEveryProjectAdvertisement(state, settings) {
    return api.post('/api/v1/marketing/objects/deactivate', settings.params);
  },
};
