<template>
  <div
    class="mdt-phone-number-input"
    :class="[{
      'has-error': inputHasError,
      disabled,
      readonly,
    }, `input-${size}`]">
    <div
      v-if="label"
      class="input-label">
      <div>
        <span>{{ label }}</span>
        <span
          v-if="!required && !hideOptional"
          class="input-label-optional">
          ({{ 'admin_marketing_optional' | translate }})
        </span>
        <i
          v-if="required"
          class="fa-asterisk field-required" />
      </div>
      <i
        v-if="tooltip"
        v-tooltip="tooltip"
        class="far fa-info-circle info-icon" />
    </div>
    <div
      class="input-wrapper">
      <input
        ref="input"
        v-model="inputValue"
        v-overflow-tooltip
        v-tooltip="{
          content: inputValue,
          trigger: 'manual',
        }"
        title=""
        :required="required"
        :disabled="disabled"
        :readonly="readonly"
        class="text-cut"
        @input="onInput"
        @countrychange="onInput"
        @keyup.enter="$emit('keyupEnter')">
    </div>
    <div
      v-if="clientErrors.length || serverErrors.length"
      class="input-errors">
      <span class="client-errors">
        {{ clientErrors.join('\n') }}
        {{ clientErrors.length && serverErrors.length ? '\n' : '' }}
      </span>
      <span class="server-errors">
        {{ serverErrors.join('\n') }}
      </span>
    </div>
  </div>
</template>

<script>
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils';

export default {
  name: 'MdtPhoneNumberInput',
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'size-40',
      validator: (value) => {
        const match = ['size-40', 'size-32'];
        return match.includes(value);
      },
    },
    errors: {
      type: Array,
      default: () => [],
    },
    onlyCountries: {
      type: Array,
      default: () => [],
    },
    tooltip: {
      type: String,
      default: '',
    },
    allowedPhoneType: {
      type: Number,
      default: null,
    },
    hideOptional: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
      isValidNumber: true,
      inputTouched: false,
      serverErrors: this.errors,
    };
  },
  computed: {
    requiredErrorActive() {
      return this.required && !this.inputValue && this.inputTouched;
    },
    inputHasError() {
      return this.requiredErrorActive || !this.isValidNumber || !!this.serverErrors.length;
    },
    clientErrors() {
      const errors = [];
      const { translate } = this.$options.filters;

      if (this.requiredErrorActive) {
        errors.push(translate('general_field_is_required'));
      }

      if (!this.isValidNumber && !this.allowedPhoneType) {
        errors.push(translate('admin_enter_valid_field')
          .replace('[d]', this.label || translate('general_field')));
      }

      if (this.value
        && this.allowedPhoneType !== null
        && this.iti?.getNumberType() !== this.allowedPhoneType) {
        errors.push(translate('general_valid_mobile_phone_number_msg'));
      }

      return errors;
    },
  },
  watch: {
    value(value) {
      this.iti.setNumber(value);
      this.inputValue = this.stripDialCode(value);
      this.validateNumber();
    },
    errors(value) {
      this.serverErrors = value;
    },
  },
  mounted() {
    this.iti = intlTelInput(this.$refs.input, {
      preferredCountries: ['ch'],
      onlyCountries: this.onlyCountries,
      separateDialCode: true,
    });
    this.inputValue = this.stripDialCode(this.value);
    this.validateNumber();
  },
  methods: {
    onInput() {
      const fullNumber = this.iti.getNumber();

      this.isValid();
      this.$emit('input', fullNumber);
      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');

      // Reset server errors
      this.serverErrors = [];
    },
    isValid() {
      this.inputTouched = true;
      this.validateNumber();

      return !this.inputHasError;
    },
    validateNumber() {
      if (this.value) {
        if (this.allowedPhoneType !== null) {
          const isValidPhoneType = this.iti.getNumberType() === this.allowedPhoneType;
          this.isValidNumber = this.iti.isValidNumber() && isValidPhoneType;
        } else {
          this.isValidNumber = this.iti.isValidNumber();
        }
      } else {
        this.isValidNumber = true;
      }
    },
    stripDialCode(value) {
      // Strip dial code from number (ex. +38763999999 -> 63999999)
      const countryDialCode = `+${this.iti.getSelectedCountryData().dialCode}`;
      const [, numberWithoutDialCode] = value.split(countryDialCode);

      return numberWithoutDialCode || value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../node_modules/intl-tel-input/build/css/intlTelInput.min.css';

.mdt-phone-number-input {
  text-align: left;

  &.has-error {
    color: $color-danger;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgba($color-danger, 0.6);
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: rgba($color-danger, 0.6);
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: rgba($color-danger, 0.6);
    }
    :-moz-placeholder { /* Firefox 18- */
      color: rgba($color-danger, 0.6);
    }

    input {
      margin-left: -1px;
      background-color: rgba($color-danger, 0.05);
      border: 2px solid $color-danger;
      line-height: 36px;

      &:hover,
      &:focus {
        border-color: $color-danger !important;
        outline: 0;
      }
    }

    // error input with height 32px
    &.input-size-32 {
      .input-wrapper {
        input {
          line-height: 28px;

          &:focus {
            line-height: 28px;
          }
        }
      }
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;

    input {
      border-color: $color-text-secondary;
    }
  }

  &.readonly {
    ::v-deep .iti__flag-container {
      cursor: not-allowed;
    }
  }

  // input with height 32px
  &.input-size-32 {
    .input-wrapper {
      input {
        font-size: 14px;
        line-height: 30px;

        &:focus {
          line-height: 28px;
          border: 2px solid $color-theme;
          outline: 0;
        }
      }
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-size: 14px;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      font-size: 14px;
    }
    :-ms-input-placeholder { /* IE 10+ */
      font-size: 14px;
    }
    :-moz-placeholder { /* Firefox 18- */
      font-size: 14px;
    }
  }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $color-back-basic;
  font-size: 16px;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $color-back-basic;
  font-size: 16px;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $color-back-basic;
  font-size: 16px;
}
:-moz-placeholder { /* Firefox 18- */
  color: $color-back-basic;
  font-size: 16px;
}

.input-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  color: $color-text-secondary;
  font-size: 14px;
  line-height: 14px;

  .field-required {
    color: $color-danger;
    margin-left: 4px;
  }

  .info-icon {
    margin-left: 8px;
    color: $color-text-secondary;

    &:hover {
      color: $color-text-primary;
    }
  }
}

.input-wrapper {
  position: relative;

  input {
    display: block;
    width: 100%;
    line-height: 38px;
    background-color: $color-back-primary;
    color: $color-text-primary;
    border: 1px solid $border-color;
    border-radius: 4px;
    font-size: 16px;
    outline: 0;

    &:hover {
      border-color: $color-text-secondary;
    }

    &:focus:not[readonly] {
      margin-left: -1px !important;
      line-height: 36px;
      border: 2px solid $color-theme;
    }

    &[readonly] {
      border-color: $border-color;
      cursor: not-allowed !important;
    }
  }

  ::v-deep .iti {
    width: 100%;
    color: $color-text-primary;

    .iti__flag-container {
      padding: 2px;

      .iti__selected-flag {
        background-color: $color-back-light;
      }
    }

    .iti__country-list {
      max-height: 264px; // See 8 countries in list

      .iti__dial-code {
        color: $color-text-secondary;
      }

      .iti__highlight {
        background-color: $color-back-light;
      }
    }
  }
}

.input-errors {
  padding-top: 4px;
  font-size: 12px;
  font-weight: $font-weight-normal;
  white-space: pre-line;
}
</style>
