export default {
  selected: (state) => state.selected,
  selectWidgetActiveIndex: (state) => {
    const { selected } = state;
    const index = state.selectWidgetItems
      .findIndex((item) => item._id === selected.id);
    return index === -1 ? 0 : index;
  },
  displayedItems: (state, getters) => {
    const itemsLimit = 5;
    const itemsLen = state.selectWidgetItems.length;
    if (itemsLen <= itemsLimit) return state.selectWidgetItems;

    let items = [];
    const activeIndex = getters.selectWidgetActiveIndex;

    if ([0, 1].includes(activeIndex)) {
      items = state.selectWidgetItems.slice(0, itemsLimit);
    } else if ([itemsLen - 2, itemsLen - 1].includes(activeIndex)) {
      items = state.selectWidgetItems.slice(itemsLen - itemsLimit, itemsLen);
    } else {
      items = state.selectWidgetItems.slice(activeIndex - 2, activeIndex + 3);
    }

    return items;
  },
  detailViewLoader: (state) => state.detailView.loading,
};
