<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <component
    :is="element.componentName"
    v-if="element.type === 'component'"
    :ref="element.name"
    v-model="element.vModel"
    v-bind="element.props"
    :style="[element.options && element.options]"
    v-on="element.vOn"
    @mdtDataChanged="$emit('mdtDataChanged')">
    <component
      :is="slot.is"
      v-for="(slot, i) in element.$slots"
      :key="i"
      :slot="slot.name"
      v-bind="slot.props"
      :style="[slot.options && slot.options]">
      {{ slot.content }}
    </component>
  </component>
  <!-- eslint-enable vue/no-mutating-props -->
</template>

<script>
import MdtWizardComponents from '@/components/shared/wizard/components';

export default {
  name: 'MdtWizardContentElement',
  components: {
    ...MdtWizardComponents,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-down-enter-active,
.slide-down-leave-active,
.slide-up-enter-active,
.slide-up-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}
.slide-down-enter,
.slide-up-leave-active {
  opacity: 0;
  transform: translate(0, -2em);
}
.slide-down-leave-active,
.slide-up-enter {
  opacity: 0;
  transform: translate(0, 2em);
}
</style>
