/* eslint-disable no-underscore-dangle */
function mouseover(event) {
  const element = event.target;
  if (element.offsetWidth < element.scrollWidth) {
    if (element._tooltip) {
      element._tooltip.show();
    } else {
      // eslint-disable-next-line no-lonely-if
      if (event.target.hasChildNodes()) element.removeAttribute('title');
      else element.setAttribute('title', event.target.textContent);
    }
  } else {
    if (element._tooltip) element._tooltip.hide();
    if (element.hasAttribute('title')) element.removeAttribute('title');
  }
}

function mouseout(event) {
  const element = event.target;
  if (element._tooltip) element._tooltip.hide();
  if (element.hasAttribute('title')) element.removeAttribute('title');
}

export default {
  bind(el) {
    el.addEventListener('mouseover', mouseover);
    el.addEventListener('mouseout', mouseout);
  },
  unbind(el) {
    el.removeEventListener('mouseover', mouseover);
    el.removeEventListener('mouseout', mouseout);
  },
};
