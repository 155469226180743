<template>
  <div
    class="b2b-view"
    :class="viewBackground ? 'white' : 'light-gray'">
    <MdtNotify />
    <MdtLightbox />
    <UnprotectedViewHeader />
    <div class="b2b-view-content">
      <RouterView class="b2b-view-router" />
    </div>
    <UnprotectedViewFooter />
  </div>
</template>

<script>
import UnprotectedViewHeader from '@/components/unprotected-view/UnprotectedViewHeader.vue';
import UnprotectedViewFooter from '@/components/unprotected-view/UnprotectedViewFooter.vue';
import MdtLightbox from '@/components/shared/MdtLightbox.vue';

export default {
  name: 'B2BView',
  components: {
    UnprotectedViewHeader,
    UnprotectedViewFooter,
    MdtLightbox,
  },
  computed: {
    viewBackground() {
      const { name } = this.$route;
      return name === 'B2BProjectMemorandum' || name === 'B2BProjectMemorandumPitch';
    },
  },
};
</script>

<style lang="scss" scoped>
.b2b-view {
  padding: 0 $unprotected-view-padding;

  @media only screen and (max-width: 576px) {
    padding: 0 $unprotected-view-padding-small;
  }

  .b2b-view-content {
    width: 100%;
    max-width: $unprotected-view-max-width;
    min-height: calc(100vh - #{$login-header-height} - #{$login-footer-height});
    margin: 0 auto;
    color: $color-text-primary;

    .b2b-view-router {
      width: 100%;
      min-height: calc(100vh - #{$login-header-height} - #{$login-footer-height});
      padding: $unprotected-view-padding 0;

      @media only screen and (max-width: 576px) {
        padding: $unprotected-view-padding-small 0;
      }
    }
  }
}

.white {
  background-color: $memorandum-background-color;
}

.light-gray {
  background-color: $color-back-light;
}
</style>
