// Simplified version of https://github.com/euvl/vue-notification
import { helpers } from '@/utility';
import MdtNotify from '@/components/shared/MdtNotify.vue';

const Notify = {
  install(Vue) {
    if (this.installed) return;

    this.installed = true;

    Vue.component('MdtNotify', MdtNotify);

    const eventsNotify = new Vue({ name: 'VueNotification' });

    const notify = (params, type) => {
      if (!params) return;
      if (typeof params === 'string') {
        params = {
          title: '',
          text: params,
        };
      }

      params.type = params.type || type;
      params.duration = helpers.valueOrUndefined(params.duration);
      eventsNotify.$emit('add', params);
    };

    // Add notify shortands like "this.$notify.danger(params)"
    const types = ['info', 'success', 'warning', 'danger'];
    types.forEach((type) => {
      notify[type] = (params) => {
        notify(params, type);
      };
    });

    notify.close = (id) => {
      eventsNotify.$emit('close', id);
    };

    notify.eventsNotify = eventsNotify;

    Vue.prototype.$notify = notify;
    Vue.notify = notify;
  },
};

export default Notify;
