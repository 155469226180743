<template>
  <div
    ref="ckeditorParent"
    class="ck-wrapper"
    :class=" { 'has-error': $v.inputValue.$error, readonly }">
    <div
      v-if="label"
      class="ckeditor-label">
      <div>
        <span>{{ label }}</span>
        <span v-if="!required"> ({{ 'admin_marketing_optional' | translate }})</span>
      </div>
    </div>
    <ckeditor
      ref="ckeditor"
      :value="inputValue"
      :editor="editor"
      :config="editorConfig"
      :required="required"
      @input="onInput"
      @ready="onReady" />
    <div
      v-if="clientErrors.length"
      class="input-errors">
      <span class="client-errors">
        {{ clientErrors.join('\n') }}
      </span>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@emonitorag/ckeditor5-build';
import { helpers } from '@/utility';

export default {
  name: 'MdtCkeditor',
  components: {
    ckeditor: CKEditor.component,
  },
  mixins: [validationMixin],
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    toolbar: {
      type: Array,
      default: () => [
        'bold',
        'italic',
        'fontcolor',
        'numberedList',
        'bulletedList',
        'link',
        'htmlEmbed',
        // 'uploadImage',
        '|',
        'undo',
        'redo',
      ],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '',
    },
  },
  data() {
    const editorConfig = {
      placeholder: this.placeholder,
      toolbar: this.toolbar,
    };

    if (this.toolbar.includes('uploadImage')) {
      const { baseBackendUrl } = this.$store.getters;
      const userToken = this.$store.state.auth.user.token;
      // Example, name.melon.rent -> name, localhost:8000 -> rent
      const tenantName = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : 'rent';

      editorConfig.simpleUpload = {
        uploadUrl: `${baseBackendUrl}/api/v1/ckeditor-image-upload`,
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Tenant-Name': tenantName,
        },
      };
    }

    return {
      inputValue: this.value,
      editor: ClassicEditor,
      editorConfig,
      firstEmit: true,
    };
  },
  computed: {
    clientErrors() {
      const errors = [];
      if (this.$v.inputValue.$dirty) {
        if (!this.$v.inputValue.required) {
          errors.push(this.$options.filters.translate('general_field_is_required'));
        }
      }
      return errors;
    },
  },
  validations() {
    return {
      inputValue: {
        required: this.required ? required : false,
      },
    };
  },
  watch: {
    value(value) {
      this.inputValue = value;
    },
    height() {
      // set ckeditor height if editor instance and height prop available
      if (!!this.editorInstance && helpers.isNotUndefined(this.height)) this.setHeight(this.height);
    },
    readonly() {
      this.setReadOnly();
    },
  },
  methods: {
    setTouched() {
      this.$v.inputValue.$touch();
    },
    isValid() {
      this.setTouched();
      const isValid = !this.$v.inputValue.$error;
      return isValid;
    },
    onInput(value) {
      // Fixes issue when ckeditor calls onInput() on init if it's empty
      if (this.firstEmit && !this.inputValue && !value) {
        this.firstEmit = false;
      } else {
        this.$emit('mdtDataChanged');
        this.firstEmit = false;
      }

      this.inputValue = value;
      this.setTouched();
      this.$emit('input', value);
    },
    getInstance() {
      return this.$refs.ckeditor.$_instance;
    },
    onReady() {
      this.editorInstance = this.getInstance();
      if (this.editorInstance) {
        this.setReadOnly();
        this.bindEvents();
      }
      this.$emit('ready');
    },
    setReadOnly() {
      if (this.readonly) {
        this.editorInstance.enableReadOnlyMode('lockId');
      } else {
        this.editorInstance.disableReadOnlyMode('lockId');
      }
    },
    setHeight(height) {
      const ckeditorRef = this.$refs.ckeditorParent;
      if (ckeditorRef) {
        const ckEditorEditableInline = ckeditorRef.querySelector('.ck-wrapper .ck-editor__main');
        if (ckEditorEditableInline) ckEditorEditableInline.setAttribute('style', `height: ${height}`);
      }
    },
    bindEvents() {
      // set ckeditor height from prop if available
      if (helpers.isNotUndefined(this.height)) this.setHeight(this.height);
    },
  },
};
</script>

<style lang="scss">
:root {
  --ck-border-radius: 4px !important; // border radius for ckeditor wrapper
  --ck-z-modal: 1003 !important;
  --ck-color-engine-placeholder-text: #{$color-back-basic} !important;
  --ck-color-base-border: #{$border-color} !important;
  --ck-color-toolbar-background: $color-back-primary !important;
}
</style>

<style lang="scss" scoped>
.ckeditor-label {
  margin-bottom: 8px;
  color: $color-text-secondary;
  font-size: 14px;
  line-height: 14px;
}

.ck-wrapper {
  line-height: 1.5;

  &.readonly {
    ::v-deep {
      .ck.ck-toolbar {
        border-color: rgba($border-color, 0.3) !important;
        pointer-events: none;
      }

      .ck.ck-editor__editable_inline {
        border-color: rgba($border-color, 0.3) !important;
      }

      .ck-editor__editable:not(.ck-focused):hover {
        border-color: rgba($border-color, 0.3) !important;
      }
    }
  }

  ::v-deep {
    .ck.ck-content {
      & > p,
      & > ul,
      & > ol,
      & > blockquote,
      & > pre {
        margin-bottom: var(--ck-spacing-large);
      }
    }

    .ck-editor__editable_inline {
      height: 100%;
      min-height: 122px;
      max-height: 500px;
    }

    .ck-editor__editable:not(.ck-focused):hover {
      border-color: $color-text-secondary !important;
    }

    .ck-content.ck-focused {
      border: 1px solid $border-color !important;
      box-shadow: 0 0 4px $color-theme inset !important;
    }

    // Adjust toolbars first button tooltip
    .ck.ck-toolbar .ck-button:first-child .ck-tooltip__text {
      left: -15%;

      &::after {
        left: 15%;
      }
    }

    ul, ol {
      padding-inline-start: 40px;

      li {
        list-style-position: inside;
      }
    }

    ul li {
      list-style-type: disc;
    }

    figure {
      margin: 0 0 var(--ck-spacing-large) 0;
    }
  }

  &.has-error {
    color: $color-danger;

    ::v-deep {
      .ck-editor__editable:not(.ck-focused):hover {
        border-color: $color-danger !important;
      }

      .ck-content {
        background-color: rgba($color-danger, 0.05) !important;
        border: 2px solid $color-danger !important;
        box-shadow: 0 0 2px rgba($color-danger, 0.4) !important;
      }
    }
  }

  .input-errors {
    padding-top: 4px;
    font-size: 12px;
    font-weight: $font-weight-normal;
    white-space: pre-line;
  }
}
</style>
