import Vue from 'vue';
import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
export default {
  getAllData({ dispatch, getters }, data) {
    const params = data || { id: getters.waitlistId };
    // Remove stashed data
    dispatch('deleteWaitlistData');
    return dispatch('getWaitlist', params).then((waitlist) => waitlist);
  },
  getWaitlist({ commit }, params) {
    // set detail view loader to true
    commit('setDetailViewLoader', true);
    return api.get(`/api/v1/waitlist/applications/${params.id}`).then((response) => {
      commit('setWaitlist', response.data);
      return response.data;
    }).finally(() => {
      // set detail view loader to false
      commit('setDetailViewLoader', false);
    });
  },
  getWaitlistWithoutLoader({ commit }, params) {
    return api.get(`/api/v1/waitlist/applications/${params.id}`).then((response) => {
      commit('setWaitlist', response.data);
      return response.data;
    });
  },
  deleteWaitlistData({ commit }) {
    commit('deleteWaitlistData');
  },
  setFavorite({ dispatch, commit }, options) {
    return dispatch('table/setFavorite', options, { root: true }).then(() => {
      commit('setFavorite', options);
    });
  },
  reorderOptions({ getters }, params) {
    const { waitlistSettingsKey } = getters;
    const options = {
      value: params,
    };
    return api.post(`/api/v1/settings/${waitlistSettingsKey}/list`, options);
  },
  getCriteriaMail(state, params) {
    return api.get(`/api/v1/waitlist/applications/${params.applicationId}/send-criteria-form`)
      .then((response) => response.data);
  },
  postCriteriaMail(state, settings) {
    return api.post(`/api/v1/waitlist/applications/${settings.applicationId}/send-criteria-form`, settings.params)
      .then((response) => response.data);
  },
  getCriteriaUnprotected(state, params) {
    return api.get(`/api/v1/waitlist/applications/criteria-form/${params.uuid}/${params.token}/`)
      .then((response) => response.data);
  },
  getCriteriaPreviewUnprotected(state, params) {
    return api.get(`/api/v1/waitlist/applications/criteria-preview/${params.uuid}/${params.token}`)
      .then((response) => response.data);
  },
  saveCriteriaUnprotected(state, settings) {
    return api.post(`/api/v1/waitlist/applications/criteria-form/${settings.uuid}/${settings.token}/`, settings.params)
      .then((response) => response.data);
  },
  getDeclineRequest(state, params) {
    return api.get(`/api/v1/waitlist/applications/${params.waitlistId}/set-to-inactive`)
      .then((response) => response.data);
  },
  postDeclineRequest(state, settings) {
    return api.post(`/api/v1/waitlist/applications/${settings.waitlistId}/set-to-inactive`, settings.params)
      .then((response) => response.data);
  },
  getDeclineRequestMail(state, params) {
    return api.get(`/api/v1/waitlist/applications/${params.waitlistId}/send-email-set-to-inactive`)
      .then((response) => response.data);
  },
  postDeclineRequestMail(state, settings) {
    return api.post(`/api/v1/waitlist/applications/${settings.waitlistId}/send-email-set-to-inactive`, settings.params)
      .then((response) => response.data);
  },
  getDeclineRequestBulk() {
    return api.get('/api/v1/waitlist/applications/bulk-set-to-inactive')
      .then((response) => response.data);
  },
  postDeclineRequestBulk(state, settings) {
    return api.post('/api/v1/waitlist/applications/bulk-set-to-inactive', settings.params)
      .then((response) => response.data);
  },
  getDeclineRequestMailBulk(state, settings) {
    return api.get('/api/v1/waitlist/applications/bulk-send-email-set-to-inactive', { params: settings.params })
      .then((response) => response.data);
  },
  postDeclineRequestMailBulk(state, settings) {
    return api.post('/api/v1/waitlist/applications/bulk-send-email-set-to-inactive', settings.params)
      .then((response) => response.data);
  },
  postAcceptRequestDirect(state, params) {
    return api.post(`/api/v1/waitlist/applications/${params.waitlistId}/set-to-accepted`)
      .then((response) => response.data);
  },
  getAcceptRequestMail(state, params) {
    return api.get(`/api/v1/waitlist/applications/${params.waitlistId}/send-email-set-to-accepted`)
      .then((response) => response.data);
  },
  postAcceptRequestMail(state, settings) {
    return api.post(`/api/v1/waitlist/applications/${settings.waitlistId}/send-email-set-to-accepted`, settings.params)
      .then((response) => response.data);
  },
  searchAddresses(state, request) {
    const settings = {
      params: request.params,
      cancelToken: Vue.axiosRequest.createCancelToken('waitlistSearchAddresses'),
    };
    return api.get('/api/v1/waitlist/applications/address', settings)
      .then((response) => response.data);
  },
  getProposalMail(state, params) {
    return api.get(`/api/v1/waitlist/applications/${params.applicationId}/send-apartment-proposal/${params.objectId}`)
      .then((response) => response.data);
  },
  postProposalMail(state, settings) {
    return api.post(`/api/v1/waitlist/applications/${settings.applicationId}/send-apartment-proposal/${settings.objectId}`, settings.params)
      .then((response) => response.data);
  },
  postProposalNotInterestedInit(state, params) {
    return api.get(`/api/v1/waitlist/applications/${params.applicationId}/set-apartment-proposal-no-interest-init/${params.objectId}`)
      .then((response) => response.data);
  },
  postProposalNotInterested(state, settings) {
    return api.post(`/api/v1/waitlist/applications/${settings.applicationId}/set-apartment-proposal-no-interest/${settings.objectId}`, settings.params)
      .then((response) => response.data);
  },
  getProposalUnprotected(state, params) {
    return api.get(`/api/v1/waitlist/applications/proposal-view/${params.uuid}/${params.token}/`)
      .then((response) => response.data);
  },
  postProposalAnswerUnprotected(state, settings) {
    return api.post(`/api/v1/waitlist/applications/proposal-view/${settings.uuid}/${settings.token}/`, settings.params)
      .then((response) => response.data);
  },
  getUpdateCriteria(state, params) {
    return api.get(`/api/v1/waitlist/applications/${params.applicationId}/update-criteria`)
      .then((response) => response.data);
  },
  postUpdateCriteria(state, settings) {
    return api.post(`/api/v1/waitlist/applications/${settings.applicationId}/update-criteria`, settings.params)
      .then((response) => response.data);
  },
  getUpdateCriteriaEditMail(state, params) {
    return api.get(`/api/v1/waitlist/applications/${params.waitlistId}/send-reminder-email-update-criteria`)
      .then((response) => response.data);
  },
  postUpdateCriteriaEditMail(state, settings) {
    return api.post(`/api/v1/waitlist/applications/${settings.waitlistId}/send-reminder-email-update-criteria`, settings.params)
      .then((response) => response.data);
  },
  sendToWaitlist(state, params) {
    return api.post(`/api/v1/waitlist/applications/${params.applicationId}/send-application-to-waitlist`)
      .then((response) => response.data);
  },
  getSendToWaitlistMail(state, params) {
    return api.get(`/api/v1/waitlist/applications/${params.applicationId}/send-email-application-to-waitlist`)
      .then((response) => response.data);
  },
  postSendToWaitlistMail(state, settings) {
    return api.post(`/api/v1/waitlist/applications/${settings.applicationId}/send-email-application-to-waitlist`, settings.params)
      .then((response) => response.data);
  },
  getDeactivateUnprotected(state, settings) {
    return api.post(`/api/v1/waitlist/applications/public-decline/${settings.uuid}/${settings.token}/`, settings.params)
      .then((response) => response.data);
  },
  postBulkActivate(state, params) {
    return api.post('/api/v1/waitlist/applications/bulk-set-to-active', params)
      .then((response) => response.data);
  },
  searchHouseholdPersons(state, request) {
    const settings = {
      params: request.params,
      cancelToken: Vue.axiosRequest.createCancelToken('waitlistSearchHouseholdPersons'),
    };
    return api.get(`/api/v1/waitlist/applications/${request.tenantId}/get-possible-tenants-list`, settings)
      .then((response) => (!request.householdPersons.length ? response.data : response.data
        .filter((item) => !request.householdPersons
          .find((hp) => item.id === hp.id))));
  },

  getRelocationRequest(state, params) {
    return api.get(`/api/v1/waitlist/applications/${params.tenantId}/new-relocation-request`)
      .then((response) => response.data);
  },
  postRelocationRequest(state, settings) {
    return api.post(`/api/v1/waitlist/applications/${settings.tenantId}/new-relocation-request`, settings.params)
      .then((response) => response.data);
  },
  getRelocationRequestMail(state, params) {
    return api.get(`/api/v1/waitlist/applications/${params.applicationId}/send-email-new-relocation-request`)
      .then((response) => response.data);
  },
  postRelocationRequestMail(state, settings) {
    return api.post(`/api/v1/waitlist/applications/${settings.applicationId}/send-email-new-relocation-request`, settings.params)
      .then((response) => response.data);
  },
};
