<template>
  <div
    v-click-outside="onClickOutside"
    class="mdt-color-picker">
    <div
      v-if="label"
      class="color-picker-label">
      <div>
        <span>{{ label }}</span>
        <span v-if="!required"> ({{ 'admin_marketing_optional' | translate }})</span>
      </div>
      <i
        v-if="tooltip"
        v-tooltip="tooltip"
        class="far fa-info-circle info-icon" />
    </div>
    <div class="color-picker-wrapper">
      <MdtInput
        ref="colorPickerInput"
        :value="inputValue"
        :required="required"
        disabled
        type="text"
        class="color-picker-input"
        @input="onInput" />
      <div
        v-if="!required"
        class="color-picker-clear-icon-wrapper">
        <i
          class="fas fa-eraser color-picker-clear-icon"
          @click="onInput('')" />
      </div>
      <div
        class="color-picker-sample"
        :class="{ cleared: !inputValue }"
        :style="{ backgroundColor: inputValue }"
        @click="isPickerOpen = !isPickerOpen">
        <div
          v-if="!inputValue"
          class="no-color-line" />
      </div>
      <component
        :is="`${type}-picker`"
        v-if="isPickerOpen"
        :value="inputValue"
        @input="onInput($event.hex)" />
    </div>
  </div>
</template>

<script>
import { helpers } from '@/utility';

export default {
  name: 'MdtColorPicker',
  components: {
    MaterialPicker: () => import(/* webpackChunkName: "material-color-picker" */ 'vue-color/src/components/Material.vue'),
    CompactPicker: () => import(/* webpackChunkName: "compact-color-picker" */ 'vue-color/src/components/Compact.vue'),
    SwatchesPicker: () => import(/* webpackChunkName: "swatches-color-picker" */ 'vue-color/src/components/Swatches.vue'),
    SliderPicker: () => import(/* webpackChunkName: "slider-color-picker" */ 'vue-color/src/components/Slider.vue'),
    SketchPicker: () => import(/* webpackChunkName: "sketch-color-picker" */ 'vue-color/src/components/Sketch.vue'),
    ChromePicker: () => import(/* webpackChunkName: "chrome-color-picker" */ 'vue-color/src/components/Chrome.vue'),
    PhotoshopPicker: () => import(/* webpackChunkName: "photoshop-color-picker" */ 'vue-color/src/components/Photoshop.vue'),
    TwitterPicker: () => import(/* webpackChunkName: "twitter-color-picker" */ 'vue-color/src/components/Twitter.vue'),
    GrayscalePicker: () => import(/* webpackChunkName: "grayscale-color-picker" */ 'vue-color/src/components/Grayscale.vue'),
  },
  props: {
    value: {
      required: true,
      validator: (value) => {
        const isString = typeof value === 'string';
        const isNull = value === null;
        return isString || isNull;
      },
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'chrome',
      validator: (value) => {
        const match = ['material', 'compact', 'swatches', 'slider', 'sketch', 'chrome', 'photoshop', 'twitter', 'grayscale'];
        return match.includes(value);
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputValue: helpers.isNotUndefined(this.value) ? this.value : '',
      isPickerOpen: false,
    };
  },
  watch: {
    value(value) {
      this.inputValue = helpers.isNotUndefined(value) ? value : '';
    },
  },
  methods: {
    isValid() {
      return this.$refs.colorPickerInput.isValid();
    },
    onInput(value) {
      this.inputValue = value;
      this.isValid();
      this.$emit('input', this.inputValue);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');
    },
    onClickOutside() {
      this.isPickerOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-color-picker {
  &.has-error {
    color: #fe705c;

    input {
      border-color: #fe705c;
      box-shadow: 0 0 2px #fe705c66;
    }
  }
}

.color-picker-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 14px;

  .info-icon {
    margin-right: 10px;
    color: $color-text-secondary;

    &:hover {
      color: $color-text-primary;
    }
  }
}

.color-picker-wrapper {
  position: relative;
  display: flex;
  flex-wrap: nowrap;

  .color-picker-input {
    flex: 1;

    ::v-deep input {
      padding-left: 44px;
      text-transform: lowercase;
    }
  }

  .color-picker-clear-icon-wrapper {
    position: relative;
    width: 32px;
    height: 32px;
    top: 4px;
    right: 0;
    margin-left: 12px;
    background-color: $color-back-light;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }

    .color-picker-clear-icon {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }

  .color-picker-sample {
    position: absolute;
    width: 32px;
    height: 24px;
    top: 8px;
    left: 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &.cleared {
      border: 1px solid $border-color;
      background: $color-back-primary;
    }

    .no-color-line {
      position: relative;
      top: 50%;
      left: -3px;
      width: calc(100% + 6px);
      height: 1px;
      background-color: $border-color;
      transform: rotate(-36deg);
    }
  }

  .vc-material,
  .vc-chrome,
  .vc-compact,
  .vc-swatches,
  .vc-slider,
  .vc-sketch,
  .vc-photoshop,
  .vc-twitter,
  .vc-grayscale {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
  }

  .vc-material {
    height: auto;
  }

  // Show only hex value on chrome modal
  ::v-deep {
    .vc-chrome-sliders {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .vc-chrome-hue-wrap {
        margin-bottom: 0;
      }

      .vc-chrome-alpha-wrap {
        display: none;
      }
    }

    .vc-chrome-toggle-btn,
    .vc-input__label {
      display: none;
    }
  }
}
</style>
