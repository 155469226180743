export default {
  setWaitlist(state, waitlist) {
    state.waitlist = waitlist;
  },
  deleteWaitlistData(state) {
    state.waitlist = {};
    state.detailView.loading = true;
  },
  setDetailViewLoader(state, loading) {
    state.detailView.loading = loading;
  },
  setFavorite(state, data) {
    state.waitlist.evaluation_favorite = data.checked;
  },
};
