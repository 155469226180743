<template>
  <div class="navigation-top">
    <div class="navigation-top-left">
      <NavigationTopProject />
    </div>
    <div class="navigation-top-right">
      <Headway
        v-if="isHeadwayVisible"
        v-tooltip="{
          content: msg.headwayTooltip,
          classes: 'tooltip-top-4',
        }"
        class="headway-app navigation-option" />
      <NavigationTopCommunication
        v-if="isPermitted('shared_communication')"
        v-tooltip="{
          content: msg.communication,
          classes: 'tooltip-top-4',
        }"
        class="navigation-option" />
      <MdtDropdownIcon
        v-if="isUserAdmin"
        icon="fas fa-cog"
        :items="settingsItems"
        :right-aligned="true"
        :single-click-mode="true"
        class="navigation-option"
        @projectSettings="openProjectSettings" />
      <NavigationTopUser
        v-tooltip="{
          content: msg.profile,
          classes: 'tooltip-top-4',
        }"
        class="navigation-option" />
    </div>
  </div>
</template>

<script>
import NavigationTopProject from './NavigationTopProject.vue';
import NavigationTopCommunication from './NavigationTopCommunication.vue';
import NavigationTopUser from './NavigationTopUser.vue';
import Headway from './Headway.vue';

export default {
  name: 'NavigationTop',
  components: {
    NavigationTopProject,
    NavigationTopCommunication,
    NavigationTopUser,
    Headway,
  },
  data() {
    return {
      settingsItems: [
        {
          name: this.$options.filters.translate('admin_project_settings'),
          action: 'projectSettings',
        },
      ],
      msg: {
        communication: this.$options.filters.translate('admin_communication'),
        profile: this.$options.filters.translate('general_profile_settings'),
        headwayTooltip: this.$options.filters.translate('general_changelog_notification_msg'),
      },
    };
  },
  computed: {
    isUserAdmin() {
      const userRole = this.$store.getters['auth/userRole'];
      return userRole === 'superadmin' || userRole === 'admin';
    },
    isProjectTypeRent() {
      return this.$store.getters.isProjectType('reletting');
    },
    isProjectTypeMarket() {
      return this.$store.getters.isProjectType('first-time-letting');
    },
    isHeadwayVisible() {
      return this.isProjectTypeMarket || this.isProjectTypeRent;
    },
  },
  methods: {
    openProjectSettings() {
      if (!this.$route.path.includes('project-settings')) {
        this.$router.push({ name: 'ProjectSettings' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  background-color: $color-back-primary;
  box-shadow: 0px 2px 20px 0px #0000002a;
}

.navigation-top-right {
  display: flex;
  flex-direction: row;

  .navigation-option {
    position: relative;

    &.active {
      ::v-deep {
        .icon-wrapper > i {
          color: $color-text-primary;
          cursor: default;
        }
      }

      &:not(.mdt-dropdown-icon):before {
        content: '';
        position: absolute;
        width: 32px;
        height: 32px;
        top: 16px;
        left: 9px;
        background-color: $color-back-light;
        border-radius: 50%;
      }
    }
  }

  ::v-deep {
    .icon-wrapper,
    .icon-options-wrapper {
      width: 32px;
      height: 20px;
      margin: 22px 0 0 0;
      font-size: 20px;

      &:hover {
        i {
          color: $color-text-primary;
        }
      }

      i {
        color: $color-text-secondary;
        z-index: 1;
      }
    }

    .icon-options-wrapper {
      height: 32px;
      margin: 16px 9px 0 9px;
    }
  }
}
</style>
