<template>
  <div
    class="mdt-input-tags"
    :class="[
      { 'has-error': $v.tags.$dirty && $v.tags.$error, disabled, readonly },
      `input-tags-${size}`, `theme-${theme}`]">
    <div
      v-if="label"
      class="input-tags-label">
      {{ label }}
      <span v-if="!required">({{ 'admin_marketing_optional' | translate }})</span>
    </div>
    <VueTagsInput
      v-model="inputValue"
      :tags="tags"
      :placeholder="placeholder"
      :validation="validation"
      :disabled="disabled"
      @tags-changed="onInput" />
    <div
      v-if="clientErrors.length"
      class="input-errors">
      <span class="client-errors">
        {{ clientErrors.join('\n') }}
      </span>
    </div>
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import { validationMixin } from 'vuelidate';

export default {
  name: 'MdtInputTags',
  components: {
    VueTagsInput,
  },
  mixins: [validationMixin],
  props: {
    value: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
      validator: (value) => {
        const match = ['', 'email'];
        return match.includes(value);
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'size-40',
      validator: (value) => {
        const match = ['size-40', 'size-32'];
        return match.includes(value);
      },
    },
    theme: {
      type: String,
      default: 'default',
      validator: (value) => {
        const match = ['default', 'simple'];
        return match.includes(value);
      },
    },
  },
  data() {
    // eslint-disable-next-line no-control-regex, prefer-regex-literals
    const emailPattern = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
    return {
      inputValue: '',
      tags: [],
      validation: [...this.type === 'email' ? [{
        classes: 'tag',
        rule: emailPattern,
        disableAdd: true,
      }] : []],
      emailPattern,
      msg: {
        notValidEmailFormat: this.$options.filters.translate('admin_items_not_in_valid_email_format_error_msg'),
      },
    };
  },
  computed: {
    clientErrors() {
      const errors = [];
      if (this.$v.tags.$dirty) {
        if (!this.$v.tags.required) {
          errors.push(this.$options.filters.translate('general_field_is_required'));
        }
        if (!this.$v.tags.email) {
          errors.push(this.msg.notValidEmailFormat);
        }
      }
      return errors;
    },
  },
  validations() {
    const isValidMails = (tags) => {
      let isValid = true;
      tags.forEach((tag) => {
        if (!this.emailPattern.test(tag?.text)) isValid = false;
      });
      return isValid;
    };

    return {
      tags: {
        required: () => (this.required ? this.tags.length > 0 : true),
        email: this.type === 'email' ? isValidMails : true,
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.tags = value.map((tag) => ({
          text: tag,
        }));
      },
    },
  },
  methods: {
    setTouched() {
      this.$v.tags.$touch();
    },
    isValid() {
      this.setTouched();
      return !this.$v.tags.$error;
    },
    onInput(tags) {
      this.setTouched();
      this.$emit('input', tags.map((tag) => tag.text));

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-input-tags {
  &.has-error {
    color: $color-danger;

    ::-webkit-input-placeholder, /* Chrome/Opera/Safari */
    ::-moz-placeholder { /* Firefox 19+ */
      color: rgba($color-danger, 0.6);
    }

    .vue-tags-input ::v-deep {
      .ti-input {
        padding: 4px;
        background-color: rgba($color-danger, 0.05);
        border: 2px solid $color-danger !important;
      }
    }

    // error input with height 32px
    &.input-tags-size-32 {
      .vue-tags-input ::v-deep {
        .ti-input {
          padding: 0 !important;
        }
      }
    }
  }

  &.disabled,
  &.readonly {
    .vue-tags-input ::v-deep {
      .ti-actions,
      .ti-new-tag-input-wrapper {
        display: none;
      }
    }
  }

  &.disabled {
    cursor: default;

    * {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  &.readonly {
    cursor: default;

    .input-tags-label,
    .input-errors {
      cursor: text;
    }

    .mdt-wizard-tags * {
      pointer-events: none;
    }
  }

  &.theme-simple {
    .vue-tags-input ::v-deep {
      .ti-input {
        padding: 0 !important;
        background-color: transparent !important;
        border: none !important;
      }
    }
  }

  // input with height 32px
  &.input-tags-size-32 {
    .vue-tags-input {
      &.ti-focus ::v-deep {
        .ti-input {
          padding: 0 !important;
        }
      }

      ::v-deep {
        .ti-input {
          padding: 1px !important;
        }
      }
    }

    ::-webkit-input-placeholder, /* Chrome/Opera/Safari */
    ::-moz-placeholder { /* Firefox 19+ */
      font-size: 14px;
    }
  }

  ::-webkit-input-placeholder, /* Chrome/Opera/Safari */
  ::-moz-placeholder { /* Firefox 19+ */
    color: $color-back-basic;
    font-size: 16px;
  }

  .input-tags-label {
    margin-bottom: 8px;
    color: $color-text-secondary;
    font-size: 14px;
    line-height: 14px;
  }

  .vue-tags-input {
    max-width: none;

    &.ti-focus ::v-deep {
      .ti-input {
        padding: 4px;
        border: 2px solid $color-theme;
      }
    }

    &.ti-focus:hover ::v-deep {
      .ti-input {
        border-color: $color-theme;
      }
    }

    &.ti-disabled {
      ::-webkit-input-placeholder, /* Chrome/Opera/Safari */
      ::-moz-placeholder { /* Firefox 19+ */
        color: $color-text-secondary;
      }

      ::v-deep {
        .ti-input {
          background-color: rgba($color-back-hover, 0.6);

          &:hover {
            border-color: $color-text-secondary;
          }
        }
      }
    }

    ::v-deep {
      .ti-input {
        padding: 5px;
        border-radius: 4px;

        &:hover {
          border-color: $color-text-secondary;
        }
      }

      .ti-tag {
        padding: 0 8px;
        background-color: $color-back-light;
        color: $color-text-secondary;
        border-radius: 4px;
        font-size: 14px;

        .ti-content {
          height: 24px;
        }

        &.ti-invalid {
          background-color: rgba($color-danger, 0.2);
          color: $color-danger;
        }

        &.ti-invalid.ti-deletion-mark {
          background-color: rgba($color-danger, 0.4);
          color: $color-danger;
        }

        &.ti-valid.ti-deletion-mark {
          background-color: $color-back-hover;
        }
      }

      .ti-icon-close {
        font-family: 'Font Awesome 6 Pro' !important;
        font-weight: $font-weight-bold;
        margin-left: 8px;

        &:hover {
          opacity: 0.75;
        }
      }

      .ti-icon-close:before {
        content: '\f057';
      }

      .ti-new-tag-input-wrapper {
        input {
          background-color: transparent;
        }
      }
    }
  }
}

.input-errors {
  padding-top: 4px;
  font-size: 12px;
  font-weight: $font-weight-normal;
  white-space: pre-line;
}
</style>
