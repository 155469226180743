<template>
  <div
    class="mdt-wizard-simple-embedded"
    :style="[{ width, maxWidth }]">
    <div class="wizard-header flex-center-v flex-noshrink">
      <div>
        <div
          v-if="wizardTotalPages > 1"
          class="btn btn-basic btn-size-32 theme no-hover wizard-step">
          {{ 'general_step' | translate }} {{ wizardActivePage }}/{{ wizardTotalPages }}
        </div>
        <span
          v-if="wizardTitle"
          class="header-title">
          {{ wizardTitle }}
        </span>
      </div>
    </div>
    <div class="wizard-body">
      <slot name="content" />
    </div>
    <div class="wizard-footer flex-center-v flex-noshrink">
      <div
        v-if="wizardActivePage !== 1"
        class="btn btn-basic button-back"
        @click.stop="goBackward">
        {{ 'admin_marketing_previous' | translate }}
      </div>
      <div
        v-if="wizardActivePage !== wizardTotalPages"
        v-tooltip="forwardTooltip"
        class="btn btn-basic button-forward"
        :class="{ 'btn-disabled basic': loading || forwardDisabled }"
        @click.stop="!loading && !forwardDisabled ? goForward() : null">
        {{ 'admin_marketing_next' | translate }}
      </div>
      <div
        v-else-if="isButtonVisible.complete"
        v-tooltip="forwardTooltip"
        class="btn btn-primary button-complete"
        :class="{ 'btn-disabled basic btn-bordered': loading || forwardDisabled }"
        @click.stop="!loading && !forwardDisabled ? complete() : null">
        {{ msg.complete }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MdtWizardSimpleEmbedded',
  props: {
    wizardTotalPages: {
      type: Number,
      required: true,
    },
    wizardActivePage: {
      type: Number,
      required: true,
    },
    wizardTitle: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Object,
      default: () => ({}),
    },
    forwardDisabled: {
      type: Boolean,
      default: false,
    },
    forwardTooltip: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      msg: {
        complete: this.buttons?.complete?.text || this.$options.filters.translate('general_save'),
      },
    };
  },
  computed: {
    isButtonVisible() {
      return {
        complete: [undefined, true].includes(this.buttons?.complete?.visible),
      };
    },
  },
  methods: {
    isActiveContentPageValid() {
      return Object.values(this.$parent.$refs)
        .filter((r) => r)
        .reduce((previous, current) => (
          Array.isArray(current) ? [...previous, ...current] : [...previous, current]
        ), [])
        .map((r) => !r.isValid || (r.isValid && r.isValid()))
        .every((r) => r);
    },
    goBackward() {
      this.$nextTick(() => {
        this.$emit('goBackward', this.wizardActivePage);
      });
    },
    goForward() {
      this.$nextTick(() => {
        if (this.isActiveContentPageValid()) {
          this.$emit('goForward', this.wizardActivePage);
        }
      });
    },
    complete() {
      this.$nextTick(() => {
        if (this.isActiveContentPageValid()) {
          this.$emit('complete');
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-wizard-simple-embedded {
  .wizard-header {
    position: relative;
    display: flex;

    .wizard-step {
      margin-bottom: 30px;
    }

    .header-title {
      display: block;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: $font-weight-bold;
    }
  }

  .wizard-footer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: $modal-simple-embedded-footer-height;

    .btn {
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }

    ::v-deep .btn {
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>
