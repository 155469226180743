import { ls } from '@/utility';

export default {
  mainMenuLS: () => ls.mainMenu.get(),
  mainMenuCollapsedHovered: (state) => state.main.collapsedHovered,
  mainMenuCollapsed: (state) => state.main.collapsed,
  prevRoutes: (state) => state.prevRoutes,

  // main navigation
  mainNavigation: (state) => {
    const { items } = state.main;
    return items.sort((a, b) => a.position - b.position);
  },

  // project settings menu
  projectSettingsNavigation: (state) => {
    const { items } = state.projectSettings;
    return items.sort((a, b) => a.position - b.position);
  },
};
