<template>
  <div class="navigation-left-wrapper">
    <NavigationLeftResizeButton
      v-if="mode !== 'settings'"
      :collapsed="menuCollapsed"
      :hovered="collapsedHovered" />
    <div
      class="navigation-left"
      :class="[{
        collapsed: menuCollapsed,
        transitioning: mainNavigationTransitioning,
      }, `mode-${mode}`]">
      <NavigationLeftLogo
        v-if="!menuCollapsed || collapsedHovered"
        :logo-name="logoName"
        class="logo" />
      <div
        v-if="menuTitle"
        class="menu-title">
        {{ menuTitle }}
      </div>
      <transition
        name="main-navigation"
        @enter="mainNavigationTransitioning = true"
        @enterCancelled="mainNavigationTransitioning = false"
        @afterLeave="mainNavigationTransitioning = false"
        @leaveCancelled="mainNavigationTransitioning = false">
        <NavigationLeftMenuCollapsed
          v-if="menuCollapsed"
          :items="items"
          :hovered="collapsedHovered"
          class="menu" />
        <NavigationLeftMenu
          v-else
          :items="items"
          class="menu" />
      </transition>
    </div>
  </div>
</template>

<script>
import NavigationLeftResizeButton from './NavigationLeftResizeButton.vue';
import NavigationLeftLogo from './NavigationLeftLogo.vue';
import NavigationLeftMenu from './NavigationLeftMenu.vue';
import NavigationLeftMenuCollapsed from './NavigationLeftMenuCollapsed.vue';

export default {
  name: 'NavigationLeft',
  components: {
    NavigationLeftResizeButton,
    NavigationLeftLogo,
    NavigationLeftMenu,
    NavigationLeftMenuCollapsed,
  },
  props: {
    menuCollapsed: {
      type: Boolean,
      required: true,
    },
    collapsedHovered: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      default: 'default',
      validator: (value) => {
        const match = ['default', 'settings'];
        return match.includes(value);
      },
    },
  },
  data() {
    return {
      mainNavigationTransitioning: false,
    };
  },
  computed: {
    menuTitle() {
      switch (this.mode) {
        case 'settings':
          return this.$options.filters.translate('admin_project_settings');
        default:
          return null;
      }
    },
    logoName() {
      switch (this.mode) {
        case 'settings':
          return 'simple';
        default:
          return 'negative';
      }
    },
    items() {
      switch (this.mode) {
        case 'settings':
          return this.$store.getters['menu/projectSettingsNavigation'];
        default:
          return this.$store.getters['menu/mainNavigation'];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-left-wrapper {
  height: 100%;
  overflow: hidden;
  z-index: -2;
}

.navigation-left {
  position: relative;
  padding: 20px;
  height: 100%;
  color: $color-white;
  background-image: url('#{$path-images}/left-menu-gradient.svg');
  background-color: $menu-background-color;
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  background-position: bottom left;

  &.mode-settings {
    background-color: $color-back-light;
    background-image: none;

    ::v-deep {
      .menu-title,
      .menu-item,
      .menu-item-parent,
      .menu-item-child,
      .privacy-policy {
        color: $color-text-secondary !important;
      }

      .menu-item-parent.router-link-active,
      .menu-item-parent:hover,
      .menu-item-child.router-link-active,
      .menu-item-child:hover {
        color: $color-text-primary !important;
        background-color: rgba($color-text-secondary-rgb, 0.1);
      }

      .menu-item-parent.router-link-active .menu-item-label {
        color: $color-text-primary;
      }
    }
  }

  &.collapsed,
  &.transitioning {
    padding: 0;
  }
}

.logo {
  position: absolute;
  top: 0;
  left: 40px;
  height: $menu-top-height;
  margin-top: 20px;
  overflow: hidden;
}

.menu-title {
  position: absolute;
  top: 75px;
  left: 40px;
  color: rgba($color-white, 0.6);
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  user-select: none;
}

.menu {
  position: relative;
  height: 100%;
  overflow: auto;
  padding-bottom: calc(#{$menu-top-height} + #{$menu-footer-height});
}
</style>
