<template>
  <div
    class="mdt-file-download"
    @click="openFile">
    <div class="file-icon">
      <i
        class="fas"
        :class="helpers.fileIconByType(path.substr(path.lastIndexOf('/') + 1))" />
    </div>
    <div class="section-right">
      <div
        v-overflow-tooltip
        v-tooltip="{
          content: name,
          trigger: 'manual',
        }"
        class="file-name text-cut">
        {{ name }}
      </div>
      <i
        class="fas fa-download icon-download"
        @click.stop="downloadFile" />
    </div>
  </div>
</template>

<script>
import { helpers, pdf } from '@/utility';

export default {
  name: 'MdtFileDownload',
  props: {
    name: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      helpers,
      loading: false,
    };
  },
  methods: {
    openFile() {
      window.open(this.path, '_blank');
    },
    downloadFile() {
      if (this.loading) return;

      this.loading = true;
      pdf.download(this.path)
        .catch(this.showResponseError)
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-file-download {
  display: flex;
  align-items: center;
  height: 52px;
  width: 100%;
  background-color: $color-back-light;
  cursor: pointer;

  .file-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 52px;
    height: 100%;
    background-color: rgba($color-text-primary-rgb, 0.04);
    font-size: 24px;
  }

  .section-right {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: calc(100% - 52px);
    padding: 0 20px 0 9px;

    .file-name {
      max-width: calc(100% - 36px);
      font-size: 14px;
      font-weight: bold;
    }

    .icon-download {
      font-size: 18px;
      color: $color-text-secondary;

      &:hover {
        color: $color-text-primary;
      }
    }
  }
}
</style>
