<template>
  <div
    v-click-outside="closeDropdown"
    class="navigation-top-project flex-center-v"
    :class="{ active: otherProjects.length && isDropdownOpen }">
    <div
      class="project-wrapper flex-center-v no-userselect"
      :class="{ pointer: otherProjects.length }"
      @click="toggleDropdown">
      <div class="project-name">
        {{ activeProject.name }}
      </div>
      <i
        v-if="otherProjects.length"
        class="fas fa-angle-down icon-down"
        :class="{ 'fa-angle-up': isDropdownOpen }" />
    </div>
    <div
      v-if="otherProjects.length && isDropdownOpen"
      class="project-dropdown">
      <div class="project-dropdown-items">
        <div
          v-for="project in otherProjects"
          :key="project.name"
          class="project-dropdown-item pointer"
          @click="goToProject(project)">
          {{ project.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixinToggleDropdown } from '@/mixins';

export default {
  name: 'NavigationTopProject',
  mixins: [mixinToggleDropdown],
  computed: {
    activeProject() {
      return this.$store.state.menu.top.projects.find((project) => project.active) || {};
    },
    otherProjects() {
      return this.$store.state.menu.top.projects.filter((project) => !project.active);
    },
  },
  methods: {
    goToProject(project) {
      if (!project.url) return;
      window.location.assign(project.url);
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-top-project {
  position: relative;
  height: $menu-top-height;

  &.active .project-wrapper {
    color: $color-text-important;
  }
}

.project-name {
  font-size: 20px;
  font-weight: $font-weight-bold;
}

.icon-down {
  padding-left: 10px;
  font-weight: $font-weight-normal;
}

.project-dropdown {
  position: absolute;
  min-width: 235px;
  top: 90%;
  left: 0;
  background-color: $color-back-primary;
  box-shadow: 0px 2px 20px 0px #0000002a;
  font-size: 0.875rem;
  border-radius: 4px;
  z-index: 1;

  .project-dropdown-items {
    max-height: calc(100vh - 100px);
    overflow: auto;

    .project-dropdown-item {
      padding: 0 20px;
      line-height: 40px;
      color: $color-text-secondary;

      &:hover {
        background-color: $color-back-light;
      }
    }
  }
}
</style>
