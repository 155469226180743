<template>
  <div>
    <MdtNotify />
    <stzh-toastbar />
    <div class="application-form">
      <div class="header-helper" :style="headerHelperStyles" />
      <UnprotectedViewHeader />
      <RouterView />
      <UnprotectedViewFooter
        :left="formsSettings.product_form_left_footer"
        :right="formsSettings.product_form_right_footer"
        :datenschutz="formsSettings.product_form_data_protection" />
    </div>
  </div>
</template>

<script>
import UnprotectedViewHeader from '@/components/unprotected-view/UnprotectedViewHeader.vue';
import UnprotectedViewFooter from '@/components/unprotected-view/UnprotectedViewFooter.vue';

export default {
  name: 'ApplicationForm',
  components: {
    UnprotectedViewHeader,
    UnprotectedViewFooter,
  },
  computed: {
    formsSettings() {
      return this.$store.getters['applicationForm/getFormsSettings'];
    },
    headerHelperStyles() {
      let height;
      switch (this.$route.name) {
        case 'CreateAdult':
          height = '125px';
          break;
        case 'LandingPage':
          height = '120px';
          break;
        default:
          height = '225px';
          break;
      }
      return { height };
    },
  },
};
</script>

<style lang="scss" scoped>
.application-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: $stzh-back-light;

  ::v-deep :is(h1, h2, h3, h4, h5, h6) {
    font-family: $stzh-font-heavy;
  }
  ::v-deep :is(
    .info-text,
    .field-label,
    .has-error,
    .raw-html-embed p,
    .raw-html-embed div,
    .unprotected-view-footer) {
    font-family: $stzh-font-regular;
  }

  .header-helper {
    position: fixed;
    width: 100%;
    height: 225px;
    background: $stzh-back-light;
    z-index: 10;
  }

  ::v-deep .unprotected-view-header {
    width: $application-form-max-width;
    position: fixed;
    z-index: 601; // STZH Design System is 600
    background: $stzh-back-light;
  }
  ::v-deep .footer-info-left {
    p:first-child {
      font-size: 14px !important;
    }
  }
}
</style>
