<template>
  <div
    v-if="mainFiltersVisible || showMainSearch || additionalFiltersVisible"
    class="table-filters">
    <div
      v-if="mainFiltersVisible || showMainSearch"
      class="main-table-filters"
      :class="{ 'position-right': !mainFiltersVisible }">
      <MdtFilters
        v-if="mainFiltersVisible"
        :filters="filters"
        :position="position"
        class="filter"
        @applyFilters="applyFilters" />
      <MdtSearch
        v-if="showMainSearch"
        v-model="searchVal"
        :placeholder="search.placeholder"
        size="size-32"
        class="filter filter-search"
        @search="onSearch" />
    </div>
    <div
      v-if="additionalFiltersVisible"
      class="mdt-table-additional-filters-wrapper">
      <MdtTableAdditionalFilters
        :filters="additionalFilters"
        :table-id="tableId"
        @applyAdditionalFilters="applyAdditionalFilters" />
      <MdtSearch
        v-if="showAdditionalSearch"
        v-model="searchVal"
        :placeholder="search.placeholder"
        size="size-32"
        class="filter filter-search"
        @search="onSearch" />
    </div>
  </div>
</template>

<script>
import MdtTableAdditionalFilters from './MdtTableAdditionalFilters.vue';

export default {
  name: 'MdtTableFilters',
  components: {
    MdtTableAdditionalFilters,
  },
  props: {
    search: {
      type: Object,
      default: () => ({}),
    },
    filters: {
      type: Array,
      default: () => [],
    },
    additionalFilters: {
      type: Object,
      default: () => ({}),
    },
    position: { // MdtFilters dropdown position
      type: String,
      default: 'bottom',
      validator: (value) => ['bottom', 'top'].includes(value),
    },
    tableId: {
      type: String,
      default: '',
    },
  },
  computed: {
    searchVal: {
      get() {
        return this.tableId
          ? (this.$store.getters['table/additionalTableSearchVal'](this.tableId) || '')
          : (this.$store.state.table.searchVal || '');
      },
      set(searchVal) {
        if (this.tableId) {
          this.$store.state.table.additionalTables[this.tableId].searchVal = searchVal;
        } else this.$store.state.table.searchVal = searchVal;
      },
    },
    mainFiltersVisible() {
      return !!(this.filters?.length);
    },
    additionalFiltersVisible() {
      return !!(this.additionalFilters?.choices?.length);
    },
    searchVisible() {
      return !!(this.search?.enabled);
    },
    showMainSearch() {
      return this.searchVisible && (this.mainFiltersVisible || !this.additionalFiltersVisible);
    },
    showAdditionalSearch() {
      return this.searchVisible && !this.mainFiltersVisible;
    },
  },
  methods: {
    onSearch() {
      this.$emit('search', this.searchVal);
    },
    applyFilters(filters) {
      this.$emit('applyFilters', filters);
    },
    applyAdditionalFilters(filters) {
      this.$emit('applyAdditionalFilters', filters);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-filters {
  display: flex;
  flex-direction: column;
}

.main-table-filters {
  display: flex;
  justify-content: space-between;

  &.position-right {
    justify-content: flex-end;
  }
}

.mdt-table-additional-filters-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 10px;

  &.position-right {
    justify-content: flex-end;
  }
}

.filter {
  margin-right: 5px;
}

.filter-search {
  width: 257px;
  margin-bottom: 10px;
}
</style>
