<template>
  <div class="not-ready-view">
    <div class="not-ready-view-content flex-center">
      <ArchivedProject
        v-if="Object.keys(tenantStatus).length"
        :tenant-status="tenantStatus" />
      <div
        v-else
        class="page-wrapper">
        <div class="page-content">
          <div class="page-title">
            {{ statusCode }}
          </div>
          <div class="page-description">
            {{ 'admin_500_page_message_1' | translate }}
          </div>
          <div class="page-info">
            {{ 'admin_500_page_message_2' | translate }}
          </div>
          <div
            v-if="statusCode === 500"
            class="page-contact-redirect">
            <a
              class="redirect-home"
              href="/">Home</a>
            <a
              class="contact-support"
              href="mailto:support@emonitor.ch">Contact Support</a>
          </div>
        </div>
        <img
          src="@/assets/images/500.svg"
          class="page-image">
      </div>
    </div>
    <UnprotectedViewFooter :powered-by="true" />
  </div>
</template>

<script>
import UnprotectedViewFooter from '@/components/unprotected-view/UnprotectedViewFooter.vue';
import ArchivedProject from '@/components/unprotected-view/ArchivedProject.vue';

export default {
  name: 'NotReadyView',
  components: {
    UnprotectedViewFooter,
    ArchivedProject,
  },
  props: {
    statusCode: {
      type: Number,
      required: true,
    },
    tenantStatus: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.not-ready-view {
  padding: 0 40px;
  background-color: $color-back-light;
}

.not-ready-view-content {
  width: 100%;
  max-width: 1150px;
  min-height: calc(100vh - #{$login-footer-height});
  margin: 0 auto;

  // page-wrapper is for views with content (left) + image (right)
  .page-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .page-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 35%;
      margin-right: 23px;

      .page-title {
        color: $color-back-basic;
        font-size: 128px;
        font-weight: $font-weight-bold;
        line-height: 128px;
      }

      .page-description {
        padding-top: 16px;
        color: $color-text-secondary;
        font-size: 32px;
        font-weight: $font-weight-bold;
      }

      .page-info {
        padding-top: 16px;
        color: $color-text-secondary;
        font-size: 16px;
      }

      .page-contact-redirect {
        margin-top: 32px;
        font-weight: 700;
      }

        .contact-support {
          background-color: $color-back-basic;
          color: $color-text-primary;
          margin-left: 16px;
          padding: 10px 16px;
          border-radius: 4px;
        }

        .redirect-home {
          background-color: $color-theme;
          color: $color-white;
          padding: 10px 16px;
          border-radius: 4px;
        }

      .page-button-left {
        margin: 32px 10px 0 0;
      }

      .page-button-right {
        margin: 32px 0 0 10px;
      }
    }

    .page-image {
      width: 65%;
      margin-left: 23px;
    }

    @media only screen and (max-width: 768px) {
      display: block;
      text-align: center;

      .page-content {
        width: 100%;
        max-width: 500px;
        align-items: center;
        margin: 0 auto;
        padding-bottom: 60px;
      }

      .page-image {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
      }
    }

    @media only screen and (max-width: 360px) {
      .page-content {
        .page-title {
          font-size: 96px;
        }

        .page-description {
          font-size: 24px;
        }

        .page-info {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
