<template>
  <div class="mdt-tags-list">
    <vue-scroll :ops="vueScrollOptions">
      <ul
        class="tags-list"
        :style="{ maxHeight }">
        <li
          v-for="(tag) in tags"
          :key="tag.value"
          v-tooltip="tag.readonly !== undefined ? tag.readonly.unavailable_tooltip : ''"
          class="tag">
          {{ tag.displayName }}
          <i
            v-if="tag.readonly === undefined || tag.readonly.value === false"
            class="fas fa-times-circle icon-close"
            @click.stop="removeTag(tag)" />
        </li>
      </ul>
    </vue-scroll>
  </div>
</template>

<script>
export default {
  name: 'MdtTagsList',
  props: {
    value: {
      type: Array,
      required: true,
    },
    maxHeight: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tags: this.value,
      vueScrollOptions: {
        scrollPanel: {
          scrollingX: false,
        },
        bar: {
          keepShow: true,
        },
      },
    };
  },
  watch: {
    value(tags) {
      this.tags = tags;
    },
  },
  methods: {
    removeTag(item) {
      this.$emit('remove', item);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-tags-list {
  padding-bottom: 8px;
  border: 1px solid $border-color;

  .tags-list {
    display: flex;
    flex: 1 1;
    flex-basis: unset;
    flex-wrap: wrap;
    padding: 0 0 0 8px;

    .tag {
      display: inline-block;
      height: 24px;
      line-height: 24px;
      padding: 0 8px;
      margin: 8px 8px 0 0;
      color: $color-text-secondary;
      background-color: rgba($color-back-hover, 0.5);
      border-radius: 4px;
      font-size: 14px;
      white-space: break-spaces;
      word-break: break-all;
      cursor: default;

      .icon-close {
        width: 24px;
        height: 24px;
        line-height: 24px;
        padding: 0;
        color: $color-text-secondary;

        &:hover {
          background-color: transparent;
          opacity: 0.75;
        }
      }
    }
  }
}
</style>
