export default {
  project: {},
  detailView: {
    loading: true,
  },
  createProjectWizard: {
    wizardData: {
      details: {},
      address: {},
      keyData: {},
      contactDetails: {},
      criteriaForm: {},
      uploads: {
        images: [],
        tempImages: [],
        files: [],
        tempFiles: [],
      },
    },
    rawData: {},
  },
};
