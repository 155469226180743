<template>
  <div
    v-click-outside="closeDropdown"
    class="mdt-button-select">
    <div class="flex-center-v">
      <div
        ref="selectionButton"
        v-tooltip="(disabled && tooltip) || (selectedItem.disabled && selectedItem.tooltip)"
        class="btn selection-button"
        :class="[
          btnClass, `btn-${size}`,
          { 'btn-disabled': disabled || selectedItem.disabled, 'single-item': items.length === 1 },
        ]"
        @click="selectItem(selectedItem)">
        {{ selectedItem.displayName }}
      </div>
      <div
        v-if="items.length > 1"
        class="btn icon-select-wrapper"
        :class="[
          btnClass, `btn-${size}`,
          { 'btn-disabled': disabled },
        ]"
        @click="toggleDropdownElement">
        <i
          class="fas"
          :class="isDropdownOpen ? 'fa-angle-up' : 'fa-angle-down'" />
      </div>
    </div>
    <ul
      v-if="isDropdownOpen"
      ref="dropdown"
      class="dropdown"
      :class="[position, { relative }]">
      <li
        v-for="(item, i) in filteredItems"
        :key="i"
        v-tooltip="item.disabled && item.tooltip"
        class="dropdown-item flex-center-v"
        :class="[{ disabled: item.disabled }, size]"
        @click="selectItem(item)">
        {{ item.displayName }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mixinToggleDropdown } from '@/mixins';

export default {
  name: 'MdtButtonSelect',
  mixins: [mixinToggleDropdown],
  props: {
    items: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    btnClass: {
      type: String,
      default: 'btn-primary',
    },
    tooltip: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'bottom',
      validator: (value) => ['bottom', 'top'].includes(value),
    },
    relative: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'size-40',
      validator: (value) => {
        const match = ['size-40', 'size-32'];
        return match.includes(value);
      },
    },
  },
  data() {
    return {
      selectedItem: {},
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => item.displayName !== this.selectedItem.displayName);
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(items) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedItem = items[0];
      },
    },
  },
  methods: {
    selectItem(item) {
      if (this.disabled || item.disabled) return;
      this.closeDropdown();
      this.$nextTick(() => {
        this.$emit('buttonSelect', item);
      });
    },
    toggleDropdownElement() {
      if (this.disabled) return;
      this.toggleDropdown();

      // Manually position dropdown because of fixed position (needed because overflow)
      this.$nextTick(() => {
        if (!this.isDropdownOpen) return;

        const buttonEl = this.$refs.selectionButton.getBoundingClientRect();
        const dropdownEl = this.$refs.dropdown;
        if (dropdownEl) {
          dropdownEl.style.minWidth = `${Math.round(buttonEl.width)}px`;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-button-select {
  position: relative;
  display: inline-flex;
  flex-direction: column;

  .selection-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &.single-item {
      border-radius: 4px;
    }

    &:not(.single-item) {
      border-right: 1px solid rgba($color-text-primary-rgb, 0.2);
    }
  }

  .icon-select-wrapper {
    margin-left: 0px !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

// This dropdown has bug when shown and user scrolls,
// Change to position absolute but have to solve other issues
.dropdown {
  position: absolute;
  width: 100%;
  left: 0;
  top: calc(100% + 4px);
  background-color: $color-white;
  box-shadow: 0px 2px 20px 0px #0000002a;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: $font-weight-normal;
  z-index: 1;

  &.top {
    top: auto;
    bottom: calc(100% + 4px);
  }

  &.relative {
    position: relative;
    top: auto;
    margin-top: 4px;

    &.top {
      margin-bottom: 4px;
    }
  }

  .dropdown-item {
    height: 40px;
    padding: 0 20px;
    cursor: pointer;

    &.size-32 {
      height: 32px;
      padding: 0 14px;
    }

    &:hover { color: $color-theme; }
    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}
</style>
