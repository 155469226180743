import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
const url = '/api/v1/reporting/r-reports';

export default {
  getReports() {
    return api.get(url).then((response) => response.data);
  },
  createReport(state, {
    title,
    selectedBuildings,
    inactives,
    favorites,
    comment,
  }) {
    const buildings = selectedBuildings.map((building) => building.value);
    const data = {
      title,
      buildings,
      'no-inactives': !inactives,
      favorits: favorites,
      comment,
      action: 'create_report',
    };

    return api.post(url, data, { timeout: 0 }).then((response) => response.data);
  },
};
