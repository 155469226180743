<template>
  <span
    class="mdt-pdf-preview"
    :class="`theme-${theme}`">
    <slot v-if="slotOnly" />
    <i
      v-else-if="theme === 'default'"
      v-tooltip="fileName"
      class="fas clickable icon-pdf default-icon-pdf"
      :class="helpers.fileIconByType(fileName)"
      @click="openPdfPreview">
      <slot class="preview-label" />
    </i>
    <div
      v-else-if="theme === 'highlighted'"
      class="highlight-wrapper"
      @click.stop="openPdfPreview">
      <i
        :title="fileName"
        class="fas highlight-icon-pdf"
        :class="helpers.fileIconByType(fileName)" />
      <slot />
    </div>
    <MdtModal
      v-if="isModalOpen"
      :title="title"
      :subtitle="fileName"
      :type="'custom'"
      :max-height="'93vh'"
      @close="closeModal"
      @cancel="closeModal">
      <template slot="body-header">
        <div
          v-if="pdfLink"
          class="preview-wrapper flex-center">
          <object
            v-if="fileExtension === 'pdf'"
            id="pdf-preview"
            :data="pdfLink"
            :name="fileName" />
          <img
            v-else
            id="img-preview"
            :src="pdfLink"
            :alt="fileName">
        </div>
        <MdtLoader v-else />
      </template>
      <div
        v-if="!disableRemoval"
        slot="button"
        class="btn btn-danger button-delete"
        @click="isModalConfirmationOpen = true">
        {{ 'general_delete' | translate }}
      </div>
      <MdtButtonSelect
        slot="button"
        :items="selectionItems"
        :btn-class="'btn-bordered'"
        :position="'top'"
        class="button-select"
        @buttonSelect="onButtonSelect" />
    </MdtModal>
    <MdtModalConfirmation
      v-if="isModalConfirmationOpen"
      :title="'modal_confirmation_document_delete_title' | translate"
      :info-msg="$options.filters.translate(deleteConfirmationTranslation)
        .replace('{document_name}', fileName)"
      type="danger"
      @close="isModalConfirmationOpen = false"
      @confirm="deleteFile" />
  </span>
</template>

<script>
import Vue from 'vue';
import { pdf, helpers } from '@/utility';

export default {
  name: 'MdtPdfPreview',
  props: {
    src: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default() {
        return this.$options.filters.translate('general_document_preview');
      },
    },
    selectionItems: {
      type: Array,
      default() {
        return [{
          displayName: this.$options.filters.translate('general_download'),
          action: 'pdfDownload',
        }];
      },
    },
    disableRemoval: {
      type: Boolean,
      default: false,
    },
    slotOnly: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
      validator: (value) => {
        const match = ['default', 'highlighted'];
        return match.includes(value);
      },
    },
    deleteConfirmationTranslation: {
      type: String,
      default: 'modal_confirmation_document_delete_info',
    },
  },
  data() {
    const { baseBackendUrl } = this.$store.getters;

    return {
      helpers,
      isModalOpen: false,
      isModalConfirmationOpen: false,
      pdfLink: '',
      baseBackendUrl,
    };
  },
  computed: {
    fileName() {
      return this.src.substr(this.src.lastIndexOf('/') + 1);
    },
    fileExtension() {
      const { src } = this;
      return src.substr(src.lastIndexOf('.') + 1).toLowerCase();
    },
    fileMimeType() {
      return this.fileExtension === 'svg' ? 'image/svg+xml' : 'application/pdf';
    },
  },
  watch: {
    src(value) {
      if (this.isModalOpen) {
        value = value.replace(this.baseBackendUrl, '').replace(Vue.prototype.$config.baseApiUrl, '');
        pdf.content(value).then((response) => {
          const blob = new Blob([response.data], { type: this.fileMimeType });
          this.pdfLink = window.URL.createObjectURL(blob);
        }).catch(() => {
          this.closeModal();
        });
      }
    },
  },
  methods: {
    openPdfPreview() {
      // Prevent propagation
      if (this.isModalOpen) return;

      const src = this.src.replace(this.baseBackendUrl, '').replace(Vue.prototype.$config.baseApiUrl, '');
      pdf.content(src).then((response) => {
        const blob = new Blob([response.data], { type: this.fileMimeType });
        this.pdfLink = window.URL.createObjectURL(blob);
        this.isModalOpen = true;
        this.$emit('previewReady');
      }).catch(() => {
        this.closeModal();
      });
    },
    onButtonSelect(button) {
      if (!button.action) return;

      if (button.action === 'pdfDownload') {
        pdf.download(this.src);
        return;
      }

      // Emit button action
      this.$emit(button.action);
    },
    deleteFile() {
      this.$emit('deleteFile');
      this.pdfLink = '';
      this.closeModal();
      this.isModalConfirmationOpen = false;
    },
    closeModal() {
      this.isModalOpen = false;
      this.$emit('modalClosed');
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-pdf-preview {
  &.theme-highlighted {
    width: calc(100% - 48px);

    .preview-label {
      display: inline-block;
      max-width: calc(100% - 52px);
      height: 52px;
      line-height: 52px;
    }
  }
}

.mdt-modal:not(.mdt-modal-confirmation) {
  ::v-deep .modal-body {
    .body-content {
      width: 75vw;
    }
  }
}

.preview-label {
  padding-left: 9px;
  word-break: break-all;
}

.highlight-wrapper {
  display: inline-flex;
  width: 100%;
  padding-right: 16px;
  color: $color-text-primary;
  font-size: 14px;
  font-weight: $font-weight-bold;
  border-radius: 4px;
  overflow: hidden;

  .highlight-icon-pdf {
    width: 52px;
    height: 52px;
    line-height: 52px;
    background-color: rgba($color-text-primary-rgb, 0.04);
    font-size: 24px;
    text-align: center;
    cursor: pointer;
  }

  .preview-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
}

.preview-wrapper {
  height: 100%;
}

#pdf-preview {
  width: 100%;
  height: calc(93vh - #{$modal-header-height} - #{$modal-footer-height});
}

#img-preview {
  max-width: 100%;
  max-height: calc(90vh - #{$modal-header-height} - #{$modal-footer-height});
}

.button-select {
  margin-left: 17px;
}
</style>
