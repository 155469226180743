export default {
  favicon: '',
  bootstrap: {},
  publicPage: {},
  lightbox: {
    visible: false,
    images: [],
    index: 0,
  },
  language: 'de',
  documentScroll: {
    top: 0,
    left: 0,
    counter: 0,
  },
  siteTitle: '',
  mdtAppResize: 0,
  countryCodeList: [],
};
