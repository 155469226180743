import Vue from 'vue';
import apiServiceAsync from '@/api/apiServiceAsync';
import { url, helpers } from '@/utility';

const api = await apiServiceAsync();
export default {
  getSelectWidgetItems({ commit, getters, rootGetters }) {
    const tableStatus = rootGetters['table/activeTableStatusFilter'];
    const pagination = rootGetters['table/pagination'];
    const basicFilters = rootGetters['table/basicFilters'];

    // Get table status from query parameters and from selected application as fallback
    if (!tableStatus.key) {
      const queryVal = url.getParameterByName('facets-progress_state');
      if (queryVal) {
        tableStatus.key = 'facets-progress_state';
        tableStatus.choice.value = queryVal;
      } else {
        const progressState = getters?.selected?.progress_state;
        if (progressState) {
          tableStatus.key = 'facets-progress_state';
          tableStatus.choice.value = progressState;
        }
      }
    }

    const options = {
      params: {
        minimalData: true,
        [tableStatus.key]: tableStatus.choice.value,
        page: pagination?.currentPage,
        rowsPerPage: pagination?.rowsPerPage,
        ...basicFilters,
      },
    };

    // handle default or sale route regarding project type
    const { projectType } = rootGetters;
    if (projectType === 'sale') {
      return api.get('/api/v1/sales/applications/', options).then((response) => {
        commit('setItems', response.data);
      });
    }
    return api.get('/api/v1/applications/', options).then((response) => {
      commit('setItems', response.data);
    });
  },
  getAllData({ getters, dispatch, rootGetters }, params) {
    params = params || { id: getters.selected.id };
    // Remove stashed data
    dispatch('deleteApplicationsData');

    // Get new data
    return dispatch('getSelected', params).then((application) => {
      // get project type
      const { projectType } = rootGetters;

      // get evaluation data if project type different then 'sale'
      if (projectType !== 'sale') {
        dispatch('getEvaluation', params);
      }

      // Check if priority data is needed
      const priorityStates = ['active.new', 'active.in_progress', 'active.evaluated', 'inactive', 'with_offer.declined'];
      if (priorityStates.includes(application.progress_state)) {
        dispatch('getPriorityObjects', params);
      }

      // Check if offer data is needed
      if (application.progress_state === 'with_offer') {
        dispatch('getOffer', params);
      }

      // Check if assign data is needed
      if (application.progress_state === 'assigned') {
        dispatch('getAssignment', params);
      }

      // Check if buying confirmation data is needed
      const buyingConfirmationStates = ['sale.new', 'sale.client.signed', 'sale.fee.transferred', 'sale.reservation.completed'];
      if (buyingConfirmationStates.includes(application.progress_state)) {
        dispatch('getBuyingConfirmation', params);
      }

      return application;
    });
  },
  getSelected({ commit }, { id, loader = true }) {
    // set detail view loader by default to true
    commit('setDetailViewLoader', loader);
    return api.get(`/api/v1/applications/${id}`).then((response) => {
      commit('setSelected', response.data);
      return response.data;
    }).finally(() => {
      // set detail view loader to false
      commit('setDetailViewLoader', false);
    });
  },
  getSelectedWithoutLoader({ commit }, params) {
    return api.get(`/api/v1/applications/${params.id}`).then((response) => {
      commit('setSelected', response.data);
      return response.data;
    });
  },
  getEvaluation(store, params) {
    const applicationId = params?.id || store.getters.selected.id;
    return api.get(`/api/v1/applications/${applicationId}/evaluation/init`).then((response) => {
      store.commit('setEvaluation', response.data);
      if (store.rootState.sidepanel.key === 'ApplicationEvaluation') {
        store.dispatch('sidepanel/updateDataOnly', response.data, { root: true });
      }
      return response.data;
    });
  },
  getPriorityObjects({ commit }, params) {
    api.get(`/api/v1/applications/${params.id}/priority-apartments`)
      .then((response) => {
        commit('setPriorityObjects', response.data);
      });
  },
  getPriorityObjectsEditData(state, params) {
    return api.get(`/api/v1/objects/edit-prios/${params.id}`);
  },
  savePriorityObjects(state, params) {
    return api.post(`/api/v1/objects/edit-prios/${params.id}`, { prios: params.prios })
      .then((response) => response.data);
  },
  getAlternativeObjects({ getters, rootGetters, commit }, params) {
    const applicationId = getters.selected.id;
    const searchVal = rootGetters['table/searchVal'];
    const basicFilters = rootGetters['table/basicFilters'];
    params.search = helpers.valueOrUndefined(searchVal);
    Object.keys(basicFilters).forEach((key) => {
      params[key] = basicFilters[key];
    });
    return api.get(`/api/v1/applications/${applicationId}/alternative-apartments`, { params })
      .then((response) => {
        commit('setAlternativeObjects', response.data);
      });
  },
  getOffer({ commit }, params) {
    api.get(`/api/v1/applications/${params.id}/offer`).then((response) => {
      commit('setOffer', response.data);
    });
  },
  saveOfferComment({ getters }, comment) {
    const applicationId = getters.selected.id;
    api.post(`/api/v1/applications/${applicationId}/offer`, { comment });
  },
  getAssignment({ commit }, params) {
    api.get(`/api/v1/applications/${params.id}/assignment`).then((response) => {
      commit('setAssign', response.data);
    });
  },
  getAssignmentV2({ commit }, params) {
    api.get(`/api/v1/tenants/${params.id}/contract-info`).then((response) => {
      commit('setAssign', response.data);
    });
  },
  saveAssignmentComment({ getters }, comment) {
    const applicationId = getters.selected.id;
    api.post(`/api/v1/applications/${applicationId}/assignment`, { comment });
  },
  deleteSelected({ commit, getters }) {
    const applicationId = getters.selected.id;
    return api.delete(`/api/v1/applications/${applicationId}`).then((response) => {
      if (response.status === 204) {
        commit('deleteApplicationsData');
        commit('deleteSelected', applicationId);
      }
      return response.data;
    });
  },
  deleteApplicationsData({ commit }) {
    commit('deleteApplicationsData');
  },
  resetApplicationStatus(state, params) {
    return api.post(`/api/v1/applications/${params.id}/change-status`)
      .then((response) => response.data);
  },
  setFavorite({ commit }, data) {
    const options = {
      overall_star: data.checked,
    };
    return api.put(`/api/v1/applications/${data.id}/evaluation`, options).then(() => {
      commit('setFavorite', data);
    });
  },
  reorderOptions(state, params) {
    const options = {
      value: params,
    };
    return api.post('/api/v1/settings/applicationDetailViewProps/list', options);
  },
  updateEvaluationCard({ getters, commit }, params) {
    const applicationId = getters.selected.id;
    return api.put(`/api/v1/applications/${applicationId}/evaluation`, params)
      .then((response) => {
        commit('updateApplicationProps', response.data);
        return response.data;
      });
  },
  createEvaluationComment({ getters, commit }, params) {
    const applicationId = getters.selected.id;
    return api.post(`/api/v1/applications/${applicationId}/evaluation/comments`, params)
      .then((response) => {
        commit('updateApplicationProps', response.data.data);
        commit('updateComments', response.data.data);
        return response.data;
      });
  },
  updateEvaluationComment({ getters }, params) {
    const applicationId = getters.selected.id;
    const path = `/api/v1/applications/${applicationId}/evaluation/comments/${params.commentId}`;
    return api.put(path, params.data);
  },
  deleteEvaluationComment({ getters, commit }, params) {
    const applicationId = getters.selected.id;
    const path = `/api/v1/applications/${applicationId}/evaluation/comments/${params.commentId}`;
    return api.delete(path).then(() => {
      commit('deleteComment', params.commentId);
    });
  },
  checkAdultValidity({ commit }, adultId) {
    return api.get(`/api/v1/validity/${adultId}`).then((response) => {
      const adult = {
        id: adultId,
        data: response.data,
      };
      commit('updateAdultValidity', adult);
      return response.data;
    });
  },
  getApartmentAssignmentOffer(state, params) {
    return api.get(`/api/v1/objects/assignment/${params.apartmentId}/application/${params.applicationId}/make-offer-init`)
      .then((response) => response.data);
  },
  getApartmentOfferEmailModal({ getters }, settings) {
    const applicationId = getters.selected.id;
    const path = `/api/v1/communication/send-offer/${applicationId}/${settings.apartmentId}`;
    return api.get(path, settings.params).then((response) => response.data);
  },
  postApartmentOfferEmailModal({ getters }, settings) {
    const applicationId = getters.selected.id;
    return api.post(`/api/v1/communication/send-offer/${applicationId}/${settings.apartmentId}`, settings.params)
      .then((response) => response.data);
  },
  getApartmentDirectAssignment({ getters }, settings) {
    const applicationId = getters.selected.id;
    const { apartmentId } = settings;
    const path = `/api/v1/objects/assignment/${apartmentId}/application/${applicationId}/direct-assignment-init`;
    return api.get(path).then((response) => response.data);
  },
  getApartmentDirectAssignmentMail({ getters }, settings) {
    const applicationId = getters.selected.id;
    const { apartmentId } = settings;
    return api.get(`/api/v1/communication/send-assignment/${applicationId}/${apartmentId}`)
      .then((response) => response.data);
  },
  postApartmentDirectAssignmentMail({ getters }, settings) {
    const applicationId = getters.selected.id;
    const { apartmentId } = settings;
    const path = `/api/v1/communication/send-assignment/${applicationId}/${apartmentId}`;
    return api.post(path, settings.params).then((response) => response.data);
  },
  postApartmentDirectAssignment({ getters }, settings) {
    const applicationId = getters.selected.id;
    const { apartmentId } = settings;
    const path = `/api/v1/communication/send-direct-assignment/${applicationId}/${apartmentId}`;
    return api.post(path, settings.params).then((response) => response.data);
  },
  getSingleReferenceMail(state, settings) {
    return api.get(`/api/v1/communication/${settings.type}/${settings.adultId}/reference-mail`, {
      params: settings.options,
    })
      .then((response) => response.data);
  },
  postSingleReferenceMail(state, settings) {
    return api.post(`/api/v1/communication/${settings.type}/${settings.adultId}/reference-mail`, settings.params)
      .then((response) => response.data);
  },
  getManualReferenceMail(state, settings) {
    return api.get(`/api/v1/adults/${settings.adultId}/evaluation/${settings.type}`)
      .then((response) => response.data);
  },
  saveManualReferenceMail(state, settings) {
    return api.put(`/api/v1/adults/${settings.adultId}/evaluation`, settings.params)
      .then((response) => response.data);
  },
  getApartmentDenial({ getters }, settings) {
    const applicationId = getters.selected.id;
    const { apartmentId } = settings;
    const path = `/api/v1/communication/send-denial/${applicationId}/${apartmentId}`;
    return api.get(path).then((response) => response.data);
  },
  sendApartmentDenialMails({ getters }, settings) {
    const applicationId = getters.selected.id;
    const { apartmentId } = settings;
    const path = `/api/v1/communication/send-denial/${applicationId}/${apartmentId}`;
    return api.post(path, settings.params).then((response) => response.data);
  },
  postAssignmentCancel(state, assignmentId) {
    return api.post(`/api/v1/objects/assignment/${assignmentId}/cancel`)
      .then((response) => response.data);
  },
  getAssignmentStatus(state, assignmentId) {
    return api.get(`/api/v1/objects/assignment/${assignmentId}/contract-status-change`)
      .then((response) => response.data);
  },
  postAssignmentStatus({ rootGetters }, settings) {
    if (rootGetters.availableFeatures.digitalcontract) {
      return api.post(
        `/api/v1/tenants/${settings.applicationId}/change-contract-state`,
        {
          contractState: settings.contractState,
        },
        {
          params: {
            assignment_id: settings.digitalContractAssignmentId,
            contract_id: settings.contractId,
          },
        },
      ).then((response) => response.data);
    }

    return api.post(
      `/api/v1/objects/assignment/${settings.assignmentId}/contract-status-change`,
      {
        contractState: settings.contractState,
        comment: settings.comment,
      },
    ).then((response) => response.data);
  },
  getEdit(state, params) {
    const applicationId = params.id;
    return api.get(`/api/v1/applications/${applicationId}/edit`)
      .then((response) => response.data);
  },
  postEdit(state, settings) {
    const { id, applicationId } = settings.params;
    const route = {
      ApplicationEdit: `/api/v1/applications/${id}/edit`,
      ApplicationAdult: `/api/v1/adults/${id}/edit`,
      ApplicationChild: `/api/v1/children/${id}/edit`,
    }[settings.key];

    return api.post(route, settings.data, { params: { applicationId } })
      .then((response) => response.data);
  },
  getAdult(state, params) {
    const { id, applicationId } = params;
    return api.get(`/api/v1/adults/${id}/edit`, { params: { applicationId } })
      .then((response) => response.data);
  },
  getChild(state, params) {
    const { id, applicationId } = params;
    return api.get(`/api/v1/children/${id}/edit`, { params: { applicationId } })
      .then((response) => response.data);
  },
  deletePerson(state, params) {
    const { key, id } = params;
    return api.delete(`/api/v1/${key}/${id}/edit`)
      .then((response) => response.data);
  },
  getOfferDetail({ getters }, params) {
    const applicationId = params?.id || getters.selected.id;
    return api.get(`/api/v1/applications/${applicationId}/offer-detail`)
      .then((response) => response.data);
  },
  getOfferDetailDeclined(state, params) {
    const { applicationId, apartmentId } = params;
    return api.get(`/api/v1/applications/${applicationId}/offer-detail-declined/${apartmentId}`)
      .then((response) => response.data);
  },
  saveOfferDetail(state, settings) {
    const applicationId = settings.id;
    return api.post(`/api/v1/applications/${applicationId}/offer-detail`, settings.params)
      .then((response) => response.data);
  },
  deleteDocument({ getters }, params) {
    const applicationId = getters.selected.id;
    return api.post(`/api/v1/applications/${applicationId}/delete-document`, params)
      .then((response) => response.data);
  },
  deleteCardDocument({ commit }, params) {
    commit('deleteCardDocument', params);
  },
  getAdultMail(state, adultId) {
    return api.get(`/api/v1/communication/${adultId}/send-mail`)
      .then((response) => response.data);
  },
  postAdultMail(state, settings) {
    return api.post(`/api/v1/communication/${settings.adultId}/send-mail`, settings.params)
      .then((response) => response.data);
  },
  getDocumentsRequest({ getters }, params) {
    const applicationId = getters.selected.id;
    return api.get(`/api/v1/communication/documents-request/${applicationId}`, { params })
      .then((response) => response.data);
  },
  postDocumentsRequest({ getters }, settings) {
    const applicationId = getters.selected.id;
    return api.post(`/api/v1/communication/documents-request/${applicationId}`, settings.params)
      .then((response) => response.data);
  },
  getUploadDocumentsSetup(state, path) {
    return api.get(path).then((response) => response.data);
  },
  getEditUploadDocumentsSetup(state, settings) {
    return api.get(settings.path, { params: settings.params })
      .then((response) => response.data);
  },
  postUploadDocumentsSetup(state, settings) {
    return api.post(settings.path, settings.data).then((response) => response.data);
  },
  downloadDossier({ getters }) {
    const applicationId = getters.selected.id;
    return api.post(
      '/api/v1/generate-serve-dossier-files',
      { targets: [applicationId] },
      {
        responseType: 'arraybuffer',
        timeout: 0,
        cancelToken: Vue.axiosRequest.createCancelToken('mdtDownload'),
      },
    );
  },
  downloadMultipleDossiers({ dispatch }, applicationIds) {
    dispatch('table/setLoading', true, { root: true });
    return api.post(
      '/api/v1/generate-serve-dossier-files',
      { targets: applicationIds },
      {
        responseType: 'arraybuffer',
        timeout: 0,
        cancelToken: Vue.axiosRequest.createCancelToken('mdtDownload'),
      },
    ).finally(() => { dispatch('table/setLoading', false, { root: true }); });
  },
  updateImportant(state, params) {
    return api.post(
      '/api/v1/applications/update-related-data',
      {
        data: {
          is_marked_important: params.isMarkedImportant,
        },
        action: params.action,
        application_ids: params.applicationId,
      },
    );
  },
  getApartmentOfferReminder({ getters }, settings) {
    const applicationId = getters.selected.id;
    const { apartmentId } = settings;
    const path = `/api/v1/communication/send-offer-reminder/${applicationId}/${apartmentId}`;
    return api.get(path).then((response) => response.data);
  },
  sendApartmentOfferReminderMails({ getters }, settings) {
    const applicationId = getters.selected.id;
    const { apartmentId } = settings;
    const path = `/api/v1/communication/send-offer-reminder/${applicationId}/${apartmentId}`;
    return api.post(path, settings.params).then((response) => response.data);
  },
  getManualReferenceMailUnprotected(state, settings) {
    return api.get(`/api/v1/adults/${settings.id}/evaluation/${settings.type}/reference`)
      .then((response) => response.data);
  },
  saveManualReferenceMailUnprotected(state, settings) {
    return api.post(`/api/v1/adults/${settings.id}/evaluation/${settings.type}/reference`, settings.params)
      .then((response) => response.data);
  },
  getAssignmentContractUpdate(state, assignmentId) {
    return api.get(`/api/v1/objects/assignment/${assignmentId}/update`)
      .then((response) => response.data);
  },
  getAssignmentContractUpdateV2(state, assignmentId) {
    return api.get(`/api/v1/objects/assignment/${assignmentId}/update-contract-check`)
      .then((response) => response.data);
  },
  postAssignmentContractUpdate(state, settings) {
    return api.post(`/api/v1/objects/assignment/${settings.assignmentId}/update`, settings.params)
      .then((response) => response.data);
  },
  postAssignmentContractUpdateV2(state, settings) {
    return api.post(`/api/v1/objects/assignment/${settings.assignmentId}/update-contract-check`, settings.params)
      .then((response) => response.data);
  },
  getAssignmentRelease(state, assignmentId) {
    return api.get(`/api/v1/objects/assignment/${assignmentId}/release`)
      .then((response) => response.data);
  },
  postAssignmentRelease(state, settings) {
    return api.post(`/api/v1/objects/assignment/${settings.assignmentId}/release`, settings.params)
      .then((response) => response.data);
  },
  getApplicationDenial(state, settings) {
    const path = '/api/v1/communication/send-application-denial';
    return api.get(path, settings).then((response) => response.data);
  },
  sendApplicationDenialMails(state, settings) {
    const path = '/api/v1/communication/send-application-denial';
    return api.post(path, settings.params, { timeout: 0 }).then((response) => response.data);
  },
  getOfferUnprotected(state, settings) {
    return api.get(`/api/v1/applications/${settings.id}/offer-info`)
      .then((response) => response.data);
  },
  saveOfferlUnprotected(state, settings) {
    return api.post(`/api/v1/applications/${settings.id}/offer-confirm`, settings.params)
      .then((response) => response.data);
  },
  getAssignmentObjectContractStatus({ rootGetters }, settings) {
    const { params } = settings;
    if (rootGetters.availableFeatures.digitalcontract) {
      return api.get(`/api/v1/tenants/${settings.applicationId}/change-contract-state`, { params })
        .then((response) => response.data);
    }

    return api.get(`/api/v1/objects/assignment/${settings.objectId}/application/${settings.applicationId}/contract-status-change`)
      .then((response) => response.data);
  },
  postAssignmentObjectContractStatus(state, settings) {
    return api.post(`/api/v1/objects/assignment/${settings.objectId}/application/${settings.applicationId}/contract-status-change`, settings.params)
      .then((response) => response.data);
  },
  getApplicationDenialInit(state, assignmentId) {
    const path = `/api/v1/applications/${assignmentId}/denial-init`;
    return api.get(path).then((response) => response.data);
  },
  postServeDocumentWithUpdate({ getters }, settings) {
    const applicationId = settings.applicationId || getters.selected.id;
    const path = `/api/v1/applications/${applicationId}/serve-document-with-update`;
    return api.post(path, settings.params).then((response) => response.data);
  },
  createReservation(state, settings) {
    return api.post('/api/v1/objects/create-reservation', settings.params)
      .then((response) => response.data);
  },
  removeReservation(state, settings) {
    return api.post('/api/v1/objects/remove-reservation', settings.params)
      .then((response) => response.data);
  },
  resolveComment({ getters }, params) {
    const applicationId = getters.selected.id;
    const path = `/api/v1/applications/${applicationId}/evaluation/comments-resolve/${params.commentId}`;
    return api.put(path, params.data);
  },
  getBookkeepingConfirmationUnprotected(state, settings) {
    return api.get(`/api/v1/sales/applications/accounting-confirmation-form/${settings.uuid}/${settings.token}`)
      .then((response) => response.data);
  },
  postBookkeepingConfirmationUnprotected(state, settings) {
    return api.post(`/api/v1/sales/applications/accounting-confirmation-form/${settings.uuid}/${settings.token}`, settings.params)
      .then((response) => response.data);
  },
  deactivateBuyingProcess(state, settings) {
    return api.post(settings.path, settings.params)
      .then((response) => response.data);
  },
  getBuyingConfirmation({ commit }, params) {
    api.get(`/api/v1/sales/applications/${params.id}/confirmation-info`).then((response) => {
      commit('setBuyingConfirmation', response.data);
    });
  },
  getBuyingConfirmationContractUpdate(state, { applicationId, apartmentId }) {
    return api.get(`/api/v1/sales/applications/${applicationId}/confirmation-update`, { params: { apartmentId } })
      .then((response) => response.data);
  },
  postBuyingConfirmationContractUpdate(state, settings) {
    return api.post(`/api/v1/sales/applications/${settings.applicationId}/confirmation-update`, settings.params, { timeout: 0 })
      .then((response) => response.data);
  },
  getBuyingConfirmationCreateContractManualModal(state, params) {
    return api.get(`/api/v1/sales/applications/${params.applicationId}/send-interested-contract-manually`).then((response) => response.data);
  },
  postBuyingConfirmationCreateContractManualModal(state, settings) {
    return api.post(`/api/v1/sales/applications/${settings.applicationId}/send-interested-contract-manually`, settings.params, { timeout: 0 })
      .then((response) => response.data);
  },
  sendBuyingConfirmationPaymentInfo(state, params) {
    return api.post(`/api/v1/sales/applications/${params.applicationId}/send-payment-info`, {}, { timeout: 0 })
      .then((response) => response.data);
  },
  getBuyingConfirmationAccountingReminderModal(state, settings) {
    return api.get(`/api/v1/sales/applications/${settings.applicationId}/send-accounting-reminder`, settings.params)
      .then((response) => response.data);
  },
  postBuyingConfirmationAccountingReminderModal(state, settings) {
    return api.post(`/api/v1/sales/applications/${settings.applicationId}/send-accounting-reminder`, settings.params, { timeout: 0 })
      .then((response) => response.data);
  },
  getTotalPoints(state, params) {
    return api.get(`/api/v1/applications/${params.id}/points`).then((response) => response.data);
  },
  getDeleteInfo(state, { objectIds }) {
    return api.get('/api/v1/objects/get-delete-info', {
      params: {
        object_ids: objectIds,
      },
    }).then((response) => response.data);
  },
  postAllocationProposal(state, { id, bulkAction }) {
    return api.post(`/api/v1/objects/${id}/related-applications`, { bulkAction })
      .then((response) => response.data);
  },
};
