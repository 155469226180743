var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navigation-top"},[_c('div',{staticClass:"navigation-top-left"},[_c('NavigationTopProject')],1),_c('div',{staticClass:"navigation-top-right"},[(_vm.isHeadwayVisible)?_c('Headway',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.msg.headwayTooltip,
        classes: 'tooltip-top-4',
      }),expression:"{\n        content: msg.headwayTooltip,\n        classes: 'tooltip-top-4',\n      }"}],staticClass:"headway-app navigation-option"}):_vm._e(),(_vm.isPermitted('shared_communication'))?_c('NavigationTopCommunication',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.msg.communication,
        classes: 'tooltip-top-4',
      }),expression:"{\n        content: msg.communication,\n        classes: 'tooltip-top-4',\n      }"}],staticClass:"navigation-option"}):_vm._e(),(_vm.isUserAdmin)?_c('MdtDropdownIcon',{staticClass:"navigation-option",attrs:{"icon":"fas fa-cog","items":_vm.settingsItems,"right-aligned":true,"single-click-mode":true},on:{"projectSettings":_vm.openProjectSettings}}):_vm._e(),_c('NavigationTopUser',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.msg.profile,
        classes: 'tooltip-top-4',
      }),expression:"{\n        content: msg.profile,\n        classes: 'tooltip-top-4',\n      }"}],staticClass:"navigation-option"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }