export default {
  setApartment(state, apartment) {
    state.apartment = apartment;
  },
  setWithOffer(state, withOffer) {
    state.withOffer = withOffer;
  },
  setAssigned(state, assigned) {
    state.assigned = assigned;
  },
  setRented(state, rented) {
    state.rented = rented;
  },
  setReserved(state, reserved) {
    state.reserved = reserved;
  },
  setSold(state, sold) {
    state.sold = sold;
  },
  deleteApartmentData(state) {
    state.apartment = {};
    state.withOffer = {};
    state.assigned = {};
    state.rented = {};
    state.reserved = {};
    state.sold = {};
    state.detailView.loading = true;
  },
  setDetailViewLoader(state, loading) {
    state.detailView.loading = loading;
  },
  setCreateApartmentWizardData(state, wizardData) {
    state.createWizard.rawData.subsidized = wizardData.subsidized;
    state.createWizard.rawData.propertyTypes = wizardData.objectClasses;
    state.createWizard.rawData.areaItems = wizardData.areaItems;
    state.createWizard.wizardData.details.area.push({ ...wizardData.areaItems[0], value: null });
    state.createWizard.rawData.characteristics = wizardData.characteristics;
    state.createWizard.rawData.addresses = wizardData.address;
    state.createWizard.rawData.categories = wizardData.uploadFileCategories;
    state.createWizard.rawData.householdTypes = wizardData.householdTypes;
    state.createWizard.wizardData.rental.adultAge = wizardData.adultAge;
    state.createWizard.rawData.owners = wizardData.proprietors;
    state.createWizard.rawData.applications = wizardData.applicationForms;
    state.createWizard.rawData.inspectForm = wizardData.useInspectForm;
  },
  setCreateParkingWizardData(state, wizardData) {
    state.createWizard.rawData.subsidized = wizardData.subsidized;
    state.createWizard.rawData.propertyTypes = wizardData.objectClasses;
    state.createWizard.rawData.areaItems = wizardData.areaItems;
    state.createWizard.rawData.characteristics = wizardData.characteristics;
    state.createWizard.rawData.addresses = wizardData.address;
    state.createWizard.rawData.categories = wizardData.uploadFileCategories;
    state.createWizard.rawData.householdTypes = wizardData.householdTypes;
    state.createWizard.wizardData.rental.adultAge = wizardData.adultAge;
    state.createWizard.rawData.owners = wizardData.proprietors;
    state.createWizard.rawData.applications = wizardData.applicationForms;
    state.createWizard.rawData.inspectForm = wizardData.useInspectForm;
  },
  setCreateOtherWizardData(state, wizardData) {
    state.createWizard.rawData.subsidized = wizardData.subsidized;
    state.createWizard.rawData.propertyTypes = wizardData.objectClasses;
    state.createWizard.rawData.areaItems = wizardData.areaItems;
    state.createWizard.wizardData.details.area.push({ ...wizardData.areaItems[0], value: null });
    state.createWizard.rawData.characteristics = wizardData.characteristics;
    state.createWizard.rawData.addresses = wizardData.address;
    state.createWizard.rawData.categories = wizardData.uploadFileCategories;
    state.createWizard.rawData.householdTypes = wizardData.householdTypes;
    state.createWizard.wizardData.rental.adultAge = wizardData.adultAge;
    state.createWizard.rawData.owners = wizardData.proprietors;
    state.createWizard.rawData.applications = wizardData.applicationForms;
    state.createWizard.rawData.inspectForm = wizardData.useInspectForm;
  },
  setCreatePlotWizardData(state, wizardData) {
    state.createWizard.rawData.subsidized = wizardData.subsidized;
    state.createWizard.rawData.propertyTypes = wizardData.objectClasses;
    state.createWizard.rawData.areaItems = wizardData.areaItems;
    state.createWizard.wizardData.details.area.push({ ...wizardData.areaItems[0], value: null });
    state.createWizard.rawData.characteristics = wizardData.characteristics;
    state.createWizard.rawData.addresses = wizardData.address;
    state.createWizard.rawData.categories = wizardData.uploadFileCategories;
    state.createWizard.rawData.householdTypes = wizardData.householdTypes;
    state.createWizard.wizardData.rental.adultAge = wizardData.adultAge;
    state.createWizard.rawData.owners = wizardData.proprietors;
    state.createWizard.rawData.applications = wizardData.applicationForms;
    state.createWizard.rawData.inspectForm = wizardData.useInspectForm;
  },
  setCreateCraftWizardData(state, wizardData) {
    state.createWizard.rawData.subsidized = wizardData.subsidized;
    state.createWizard.rawData.propertyTypes = wizardData.objectClasses;
    state.createWizard.rawData.areaItems = wizardData.areaItems;
    state.createWizard.wizardData.details.area.push({ ...wizardData.areaItems[0], value: null });
    state.createWizard.rawData.characteristics = wizardData.characteristics;
    state.createWizard.rawData.addresses = wizardData.address;
    state.createWizard.rawData.categories = wizardData.uploadFileCategories;
    state.createWizard.rawData.householdTypes = wizardData.householdTypes;
    state.createWizard.wizardData.rental.adultAge = wizardData.adultAge;
    state.createWizard.rawData.owners = wizardData.proprietors;
    state.createWizard.rawData.applications = wizardData.applicationForms;
    state.createWizard.rawData.inspectForm = wizardData.useInspectForm;
  },
  clearCreateWizardCachedValues(state) {
    state.createWizard = {
      wizardData: {
        details: {
          title: '',
          propertyType: '',
          refNumber: null,
          rooms: null,
          floor: null,
          floorNum: null,
          area: [],
          characteristics: [],
        },
        prices: {
          rentalPrice: null,
          nettoPrice: null,
          extraPrice: null,
          deposit: null,
          rentalPricePerSquareMeter: null,
          nettoPricePerSquareMeter: null,
          capitalShare: null,
          electricityCost: null,
          sellingPrice: null,
        },
        address: {
          address: '',
        },
        upload: {
          images: [],
          tempImages: [],
          files: [],
          tempFiles: [],
        },
        rental: {
          minimumOccupancy: 0,
          maximumOccupancy: 0,
          minimumOccupancyAdults: 0,
          maximumOccupancyAdults: 0,
          minimumOccupancyChildren: 0,
          maximumOccupancyChildren: 0,
          householdType: [],
          adultAge: {},
          capital: {
            from: null,
            to: null,
          },
          income: {
            from: null,
            to: null,
          },
          catsAllowed: true,
          dogsAllowed: true,
        },
        additionalInformations: {
          owner: '',
          application: '',
          visitation: '',
          targetGroup: '',
          inspectForm: null,
          subsidized: null,
        },
      },
      rawData: {},
    };
  },
  saveCreateWizardPageData(state, params) {
    if (params.push) {
      state.createWizard.wizardData[params.page][params.key].push(params.value);
    } else if (params.splice) {
      state.createWizard.wizardData[params.page][params.key].splice(params.value, 1);
    } else {
      state.createWizard.wizardData[params.page][params.key] = params.value;
    }
  },
  setParentRouteName(state, route) {
    state.parentRouteName = route;
  },
};
