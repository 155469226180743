<template>
  <div
    class="mdt-wizard-modal mdt-modal-component flex-center"
    @wheel.stop
    @touchmove.stop
    @scroll.stop>
    <div
      class="mdt-wizard-modal-wrapper"
      :style="{ width: width }">
      <div
        class="mdt-wizard-modal-menu"
        :style="{ height: height }">
        <slot name="menu" />
      </div>
      <div class="mdt-wizard-modal-content">
        <div class="modal-header flex-center-v flex-noshrink">
          <span class="header-title">
            {{ title }}
          </span>
          <i
            class="fas fa-times icon-close"
            @click.stop="onClose" />
        </div>
        <div
          class="modal-body"
          :style="style">
          <vue-scroll
            :ops="vueScrollOptions"
            class="body-content-vuescroll">
            <div class="body-content-wrapper">
              <slot />
            </div>
          </vue-scroll>
        </div>
        <div class="modal-footer flex-center-v flex-noshrink">
          <slot name="button" />
        </div>
      </div>
    </div>
    <MdtModalConfirmation
      v-if="modalKey === 'close-confirmation'"
      :title="'admin_discard_changes' | translate"
      :btn-text="'admin_close_and_discard' | translate"
      type="discard-changes"
      class="discard-changes"
      @close="modalKey = ''"
      @confirm="$emit('close')" />
  </div>
</template>

<script>
import variables from '@/scss/abstract/_variablesExport.module.scss';
import { helpers } from '@/utility';

export default {
  name: 'MdtWizardModal',
  props: {
    title: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    minHeight: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: String,
      default: '90vh',
    },
    isDataChanged: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      helpers,
      modalHeaderHeight: variables.modalHeaderHeight,
      modalFooterHeight: variables.modalFooterHeight,
      modalKey: '',
      vueScrollOptions: {
        scrollPanel: {
          scrollingX: false,
        },
      },
    };
  },
  computed: {
    style() {
      const diffHeight = `${this.modalHeaderHeight} - ${this.modalFooterHeight}`;
      return {
        ...this.height && { height: `calc(${this.height} - ${diffHeight})` },
        ...this.minHeight && { minHeight: `calc(${this.minHeight} - ${diffHeight})` },
        maxHeight: `calc(${this.maxHeight} - ${diffHeight})`,
      };
    },
  },
  mounted() {
    // handle scrollbars on modal open
    this.helpers.handleScrollbarsOnModalOpen();
  },
  destroyed() {
    // handle scrollbars on modal close
    this.helpers.handleScrollbarsOnModalClose();
  },
  methods: {
    onClose() {
      if (this.isDataChanged === true) this.modalKey = 'close-confirmation';
      else this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-wizard-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba($color-text-primary-rgb, 0.7);
  z-index: 999;
}

.mdt-wizard-modal-wrapper {
  display: flex;
  max-height: 90vh;
  background-color: $color-back-primary;
  box-shadow: 0 3px 30px #00000040;
  border-radius: 8px;
  z-index: 998;
  overflow: hidden;

  .mdt-wizard-modal-menu {
    background: $color-back-light;
    z-index: 1;
  }

  .modal-header {
    position: relative;
    display: flex;
    height: $modal-header-height;
    padding: 0 83px 0 60px;
    border-radius: 4px 4px 0 0;

    .header-title {
      padding-right: 53px;
      font-size: 24px;
      font-weight: $font-weight-bold;
    }

    .icon-close {
      position: absolute;
      top: 30px;
      right: 30px;
      color: $color-text-secondary;
      font-size: 16px;
    }
  }

  .mdt-wizard-modal-content {
    width: 100%;
  }

  .modal-body {
    padding: 20px 30px 0 60px;
    height: calc(100% - #{$modal-header-height} - #{$modal-footer-height});

    .body-content-wrapper {
      // modal-body content bottom padding = 0 which keeps vuescroll to be shown all until footer
      // we add padding to content wrapper so the content will have space between footer
      padding-bottom: 30px;
    }
  }

  .modal-footer {
    position: relative;
    justify-content: flex-end;
    height: $modal-footer-height;
    padding: 0 30px 0 60px;
    box-shadow: 0px 0px 20px 0px #0000001A;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    .btn {
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }

    ::v-deep .btn {
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>
