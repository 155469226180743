<template>
  <div
    v-click-outside="closeDropdown"
    class="navigation-top-user"
    :class="{ active: isDropdownOpen }">
    <div
      class="icon-wrapper flex-center pointer no-userselect"
      @click="toggleDropdown">
      <i class="fas fa-user-circle" />
    </div>
    <div
      v-if="isDropdownOpen"
      class="user-dropdown">
      <div class="user-data flex-center-v">
        <i class="fas fa-user-circle circle-icon" />
        <div class="user-labels">
          <div class="user-name">
            {{ user.name }} {{ user.lastName }}
          </div>
          <div class="user-mail">
            {{ user.email }}
          </div>
        </div>
      </div>
      <div class="action-buttons">
        <div
          class="btn btn-light settings-button"
          @click="goToProfileSettings">
          <i class="fas fa-pen icon-space" />
          {{ 'general_account' | translate }}
        </div>
        <div
          class="btn btn-light logout-button"
          @click="logout">
          <i class="fas fa-sign-out-alt icon-space" />
          <span>{{ 'general_logout' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixinToggleDropdown } from '@/mixins';

export default {
  name: 'NavigationTopUser',
  mixins: [mixinToggleDropdown],
  computed: {
    user() {
      return this.$store.state.auth.user || {};
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push({ name: 'Login' });
      });
    },
    goToProfileSettings() {
      if (!this.$route.path.includes('profile-settings')) {
        this.$router.push({ name: 'ProfileSettings' });
        this.closeDropdown();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-top-user {
  position: relative;
}

.user-dropdown {
  position: absolute;
  width: 360px;
  min-width: 360px;
  max-width: 360px;
  top: calc(100% + 8px);
  right: -8px;
  padding: 24px;
  background-color: $color-back-primary;
  box-shadow: 0px 2px 20px 0px #0000002a;
  font-size: 0.875rem;
  border-radius: 4px;
}

.user-data {
  width: 100%;
  padding-bottom: 16px;

  .circle-icon {
    font-size: 48px;
    margin-right: 16px;
  }

  .user-labels {
    display: flex;
    flex-direction: column;
    margin-top: -2px;

    .user-name {
      margin-bottom: 2px;
      color: $color-text-primary;
      font-size: 20px;
      font-weight: $font-weight-bold;
    }

    .user-mail {
      word-break: break-all;
      color: $color-text-secondary;
      font-size: 16px;
    }
  }
}

.action-buttons {
  .btn {
    width: 100%;
    margin-bottom: 8px;
    font-weight: $font-weight-normal;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: $color-text-primary;

      i {
        color: $color-text-primary;
      }
    }

    .icon-space {
      margin-right: 12px;
    }
  }
}
</style>
