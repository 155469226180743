import Vue from 'vue';
import apiServiceAsync from '@/api/apiServiceAsync';
import { helpers } from '@/utility';

const api = await apiServiceAsync();
const prepareRequestOptions = (store) => {
  const {
    searchVal,
    basicFilters,
    sortKey,
  } = store.getters;

  return {
    params: {
      search: helpers.valueOrUndefined(searchVal),
      ...basicFilters,
      sort: helpers.valueOrUndefined(sortKey),
    },
  };
};
export default {
  getData(store, applicationId) {
    store.state.loading = true;
    const options = prepareRequestOptions(store);
    return api.get(`/api/v1/communication/inbox/${store.state.mailbox}`, options).then((response) => {
      store.commit('setData', response.data);

      // Select first item
      if (response.data.items.length) {
        if (applicationId) {
          const selected = response.data.items
            .find((item) => item.application_id === applicationId);
          if (selected) store.dispatch('selectItem', selected);
        } else {
          store.dispatch('selectItem', response.data.items[0]);
        }
      } else {
        store.dispatch('selectItem', {});
      }
    }).finally(() => {
      store.state.loading = false;
    });
  },
  getDataListOnly(store) {
    const options = prepareRequestOptions(store);
    return api.get(`/api/v1/communication/inbox/${store.state.mailbox}`, options).then((response) => {
      store.commit('setDataListOnly', response.data);

      // Select first item
      if (response.data.items.length) {
        store.dispatch('selectItem', response.data.items[0]);
      } else {
        store.dispatch('selectItem', {});
      }
    }).finally(() => {
      store.state.loading = false;
    });
  },
  loadMoreItems({ getters, commit, state }) {
    const {
      searchVal,
      basicFilters,
      listPagination,
    } = getters;

    const options = {
      params: {
        search: helpers.valueOrUndefined(searchVal),
        ...basicFilters,
        rowsPerPage: listPagination?.rowsPerPage,
        page: ++listPagination.currentPage,
      },
    };

    return api.get(`/api/v1/communication/inbox/${state.mailbox}`, options).then((response) => {
      commit('setDataListMoreItems', response.data);
    });
  },
  loadMoreMessages({ getters, commit }) {
    const {
      inboxPagination,
      selectedItem,
    } = getters;

    const options = {
      params: {
        rowsPerPage: inboxPagination.rowsPerPage,
        page: ++inboxPagination.currentPage,
      },
    };
    return api.get(`/api/v1/communication/${selectedItem.application_id}/inbox-feed`, options)
      .then((response) => {
        commit('setInboxMoreMessages', response.data);
      });
  },
  readSelectedItem({ commit, getters }) {
    const appId = getters.selectedItem.application_id;
    return api.post(`/api/v1/communication/${appId}/mail-is-read`).then((response) => {
      commit('readSelectedItem');
      return response.data;
    });
  },
  selectItem({ commit, dispatch }, item) {
    commit('selectItem', item);
    // If archive category is empty then don't trigger this action because app id does not exist
    if (item.application_id) dispatch('getInboxMessages', item.application_id);
  },
  getInboxMessages({ commit }, appId) {
    commit('unsetInbox');
    return api.get(`/api/v1/communication/${appId}/inbox-feed`).then((response) => {
      commit('setInbox', response.data);
      return response.data;
    }).catch(() => { commit('setSendingMail', false); });
  },
  search(store, searchVal) {
    store.state.loading = true;
    store.commit('updateSearchVal', searchVal);
    store.dispatch('getDataListOnly');
  },
  setBasicFilters(store, filters) {
    store.state.loading = true;
    store.commit('setBasicFilters', filters);
    store.dispatch('getDataListOnly');
  },
  clearAllFilters(store) {
    store.state.loading = true;
    store.commit('updateSearchVal', '');
    store.commit('setBasicFilters', {});
    store.dispatch('getDataListOnly');
  },
  sendMail({ commit, dispatch }, settings) {
    commit('setSendingMail', true);
    const appId = settings.applicationId;
    return api.post(`/api/v1/communication/${appId}/send-direct-mail`, settings.params)
      .then((response) => {
        // Refresh inbox
        dispatch('getInboxMessages', appId);
        return response.data;
      })
      .finally(() => {
        commit('setSendingMail', false);
      });
  },
  getMailTemplates(state, settings) {
    const appId = settings.applicationId;
    return api.get(`/api/v1/communication/${appId}/send-direct-mail`)
      .then((response) => response.data);
  },
  getDocumentsCategorization(state, settings) {
    const appId = settings.applicationId;
    const attachId = settings.attachmentId;
    return api.get(`/api/v1/communication/${appId}/${attachId}/categorize-documents`)
      .then((response) => response.data);
  },
  postDocumentsCategorization(state, settings) {
    const appId = settings.applicationId;
    const attachId = settings.attachmentId;
    return api.post(`/api/v1/communication/${appId}/${attachId}/categorize-documents`, settings.data)
      .then((response) => response.data);
  },
  deleteData({ commit }) {
    commit('deleteData');
  },
  updateMessage(store, params) {
    return api.patch(`/api/v1/communication/${params.mailId}/update`, params.data)
      .then((response) => store.commit('updateMessage', response.data));
  },
  getUnreadMessages({ rootState }) {
    return api.get('/api/v1/communication/unread-count-messages').then((response) => {
      const { unread_messages: unreadMessages } = response.data;
      if (unreadMessages !== undefined) rootState.bootstrap.unread_messages = unreadMessages;
    });
  },
  getMessengerSidepanel({ rootState }, params) {
    params.title = rootState.bootstrap.messages.admin_messenger || 'admin_messenger';
    return params;
  },
  updateUnreadMessagesCounter({ rootState }, payload) {
    return api.post('/api/v1/get-unread-messages', payload, {
      timeout: 0,
      cancelToken: Vue.axiosRequest.createCancelToken('getUnreadMessages'),
    })
      .then((response) => {
        const { unread_messages: unreadMessages } = response.data;
        if (unreadMessages !== undefined) rootState.bootstrap.unread_messages = unreadMessages;
        delete response.data.unread_messages;
        return response.data;
      })
      .catch((error) => ({ error, payload }));
  },
  getManualOfferDenial(state, settings) {
    const path = `/api/v1/communication/manual-offer-denial/${settings.applicationId}/${settings.apartmentId}`;
    return api.get(path).then((response) => response.data);
  },
  postManualOfferDenial(state, settings) {
    const path = `/api/v1/communication/manual-offer-denial/${settings.applicationId}/${settings.apartmentId}`;
    return api.post(path).then((response) => response.data);
  },
  deleteMessage(state, messageId) {
    return api.delete(`/api/v1/communication/${messageId}/delete`);
  },
  setMailbox({ commit }, mailbox) {
    commit('setMailbox', mailbox);
  },
  setMailState({ getters, rootState }, options) {
    const appId = getters.selectedItem.application_id;
    return api.post(`/api/v1/communication/${appId}/inbox-feed`, options)
      .then((response) => {
        const { unread_messages: unreadMessages } = response.data;
        if (unreadMessages !== undefined) rootState.bootstrap.unread_messages = unreadMessages;
        return response.data;
      });
  },
  sortListData({ commit, dispatch }, sortKey) {
    commit('updateSortKey', sortKey);
    dispatch('getDataListOnly');
  },
  setFavorite({ commit }, data) {
    const options = {
      overall_star: data.checked,
    };
    return api.put(`/api/v1/applications/${data.id}/evaluation`, options).then(() => {
      commit('setFavorite', data);
    });
  },
  setPreventScrollTo({ commit }, value) {
    commit('setPreventScrollTo', value);
  },
  sendUpsellingNotification(state, params) {
    return api.post('/api/v1/communication/send-upselling-notification', params).then((response) => response.data);
  },
};
