export default class User {
  constructor(options) {
    this.update(options);
  }

  update(options) {
    this.id = options.id;
    this.name = options.first_name;
    this.lastName = options.last_name;
    this.email = options.email;
    this.token = options.token;
    this.role = options.role;
    this.permissions = options.permissions;
    this.features = options.features;
    this.username = options.username;
    this.userType = options.userType;
  }
}
