import Vue from 'vue';

export default {
  setProject(state, project) {
    state.project = project;
  },
  deleteProjectsData(state) {
    state.project = {};
    state.detailView.loading = true;
  },
  setDetailViewLoader(state, loading) {
    state.detailView.loading = loading;
  },
  setCreateProjectWizardData(state, wizardData) {
    state.createProjectWizard.rawData
      .buildingZonesHarmonized = wizardData.building_zones_harmonized;
    state.createProjectWizard.rawData.propertyUses = wizardData.property_uses;
    state.createProjectWizard.rawData.landRegistryEntries = wizardData.land_registry_entries;
    state.createProjectWizard.rawData.projectEntries = wizardData.project_entries;
    state.createProjectWizard.rawData
      .publicTransportQualityClasses = wizardData.public_transport_quality_classes;
    state.createProjectWizard.rawData.densities = wizardData.densities;
    state.createProjectWizard.rawData.areas = wizardData.areas;
    state.createProjectWizard.rawData.volumen = wizardData.volumen;
    state.createProjectWizard.rawData.parkingSpaces = wizardData.parking_spaces;
    state.createProjectWizard.rawData.ownershipForms = wizardData.ownership_forms;
    state.createProjectWizard.rawData.criteriaForm = wizardData.investment_criteria;
    state.createProjectWizard.rawData.owners = [{
      displayName: Vue.options.filters.translate('general_new_owner'),
      value: 'new',
    }, ...wizardData.proprietor];
    state.createProjectWizard.rawData.contactPeople = [{
      displayName: Vue.options.filters.translate('general_new_contact_person'),
      value: 'new',
      given_name: '',
      family_name: '',
      phone: '',
      email: '',
    }, ...wizardData.contact_person];
    state.createProjectWizard.rawData.categories = wizardData.upload_file_categories;
    state.createProjectWizard.rawData.typeOfAdvertisement = wizardData.type_of_advertisement;
  },
  clearCreateProjectWizardCachedValues(state) {
    state.createProjectWizard = {
      wizardData: {
        details: {
          title: null,
          refNumber: null,
          parcelNumber: null,
          landArea: null,
          buildingZonesHarmonized: [],
          buildingZoneActual: null,
          apartments: null,
          propertyUses: [],
          landRegistryEntry: null,
          projectEntries: [],
          publicTransportQualityClass: null,
          buyingPriceRough: null,
          investitionTotalRough: null,
          insuranceValue: null,
          rentalIncomeInventory: null,
          rentalIncomeProject: null,
          densities: [],
          areas: [],
          volumen: [],
          parkingSpaces: [],
          typeOfAdvertisement: null,
        },
        address: {
          address: null,
          houseNumber: null,
          additionalAddress: null,
          postalCode: null,
          city: null,
          constructionYear: null,
        },
        keyData: {
          signingNDATo: '',
          indicationNBO: '',
          accessDataRoomBO: '',
          indicationBO: '',
          signing: '',
          closing: '',
        },
        contactDetails: {
          ownershipForm: null,
          ownerSelect: null,
          owner: null,
          contactPersonSelect: null,
          contactPersonFirstName: null,
          contactPersonLastName: null,
          contactPersonEmail: null,
          contactPersonPhone: null,
        },
        uploads: {
          images: [],
          tempImages: [],
          files: [],
          tempFiles: [],
        },
        criteriaForm: {},
      },
      rawData: {},
    };
  },
  saveCreateProjectWizardPageData(state, params) {
    state.createProjectWizard.wizardData[params.page][params.key] = params.value;
  },
};
