export default {
  methods: {
    showResponseError(err) {
      if (err?.response?.data?.notify) this.$store.dispatch('notify', err.response.data.notify);
      else if (err?.stack) this.$notify.danger(err.stack.split('\n', 1).join('').slice(5));
    },
    showStzhResponseError(err) {
      if (!err.notify) return;
      const { notify } = err;
      const stzhToastbar = document.querySelector('stzh-toastbar');

      notify.forEach((n) => {
        stzhToastbar.toast(n.description, { type: 'error', duration: 3 });
      });
    },
  },
};
