import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
export default {
  getEdit(state, settings) {
    return api.get(`/api/v1/buildings/${settings.id}/edit`).then((response) => response.data);
  },
  postEdit(state, settings) {
    return api.post(`/api/v1/buildings/${settings.params.id}/edit`, settings.data).then((response) => response.data);
  },
  getCreate() {
    return api.get('/api/v1/buildings/create').then((response) => response.data);
  },
  create(state, settings) {
    return api.post('/api/v1/buildings/create', settings).then((response) => response.data);
  },
  deactivate(state, targets) {
    return api.post('/api/v1/buildings/', { bulkAction: 'deactivate', targets }).then((response) => response.data);
  },
  activate(state, targets) {
    return api.post('/api/v1/buildings/', { bulkAction: 'activate', targets }).then((response) => response.data);
  },
  deactivateAdvertisements(state, targets) {
    return api.post('api/v1/marketing/objects/deactivate', { targets }).then((response) => response.data);
  },
};
