<template>
  <div
    class="mdt-tags"
    :class=" { 'has-error': hasError }">
    <div
      v-if="title"
      class="title">
      {{ title }}
      <span v-if="!required">({{ 'admin_marketing_optional' | translate }})</span>
    </div>
    <div
      v-if="description"
      class="description">
      {{ description }}
    </div>
    <vue-scroll v-if="items.length">
      <div class="tags">
        <div
          v-for="(category, index) in items"
          :key="index"
          class="tag-item">
          <span
            v-if="category.title"
            :key="`category-${index}`"
            :class="category.items ? 'category' : 'tag'">
            {{ category.title }}
          </span>
          <div
            v-if="category.items && category.items.length"
            :key="`tags-wrapper-${index}`"
            class="tags-wrapper">
            <div
              v-for="(tag, key) in category.items"
              :key="`tag-${index}-${key}`"
              class="tag"
              :class="{ selected: isSelected(tag) }"
              @click="onTagClick(tag)">
              {{ tag.displayName }}
            </div>
          </div>
        </div>
      </div>
    </vue-scroll>
    <div
      v-if="hasError"
      class="validation-message">
      {{ 'general_field_is_required' | translate }}
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';

export default {
  name: 'MdtTags',
  mixins: [validationMixin],
  props: {
    value: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  validations() {
    return {
      selectedTags: {
        required: this.required ? required : false,
        minLength: this.required ? minLength(1) : minLength(0),
      },
    };
  },
  computed: {
    hasError() {
      return this.$v.selectedTags.$error;
    },
    selectedTags() {
      return this.value;
    },
  },
  methods: {
    setTouched() {
      this.$v.selectedTags.$touch();
    },
    isValid() {
      this.setTouched();
      return !this.$v.selectedTags.$invalid;
    },
    isSelected(tag) {
      let isSelected = false;
      this.selectedTags.forEach((item) => {
        if (item.key === tag.key) isSelected = true;
      });
      return isSelected;
    },
    onTagClick(tag) {
      const { selectedTags } = this;
      const index = this.selectedTags.findIndex((item) => item.key === tag.key);
      if (index > -1) selectedTags.splice(index, 1);
      else selectedTags.push(tag);

      this.setTouched();
      this.$emit('select', selectedTags);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-tags {
  font-size: 0.875rem;

  &.has-error {
    .title,
    .description {
      color: $color-danger;
    }
  }

  .title {
    padding: 20px 0 40px 0;
    color: $color-text-primary;
    font-size: 1.125rem;
  }

  .description {
    width: 100%;
    padding-bottom: 40px;
    color: $color-text-secondary;
  }

  .category {
    width: 100%;
    padding-bottom: 10px;
    font-size: 1rem;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .tags .tag-item {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding-top: 40px;

     &:first-child {
      padding-top: 0;
    }
  }

  .tags-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
  }

  .tag {
    padding: 0 20px;
    margin: 0 5px 5px 0;
    color: $color-text-primary;
    background-color: $color-back-hover;
    font-size: 1rem;
    line-height: 32px;
    border-radius: 20px;
    cursor: pointer;
  }

  .tag.selected {
    color: $color-white;
    background-color: $color-theme;
  }

  .validation-message {
    padding: 5px 0;
    color: $color-danger;
    font-size: 0.75rem;
  }
}
</style>
