<template>
  <div class="table-td-button-select">
    <MdtButtonSelect
      :items="proposalItems.length ? proposalItems : items"
      :disabled="disabled"
      :tooltip="tooltips[tableRow._progress_state]"
      :relative="true"
      :size="'size-32'"
      @buttonSelect="openModal" />
  </div>
</template>

<script>
export default {
  name: 'TableTdButtonSelect',
  props: {
    items: {
      type: Array,
      required: true,
    },
    tableRow: {
      type: Object,
      required: true,
    },
    tooltips: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      apartmentIdActual: this.tableRow._id,
      applicationIdActual: this.$route.name === 'ApartmentDetails' ? this.tableRow._id : null,
      proposalItems: [],
      msg: {
        notAvailable: this.$options.filters.translate('admin_object_already_suggested'),
      },
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    disabled() {
      // if proposal state is passed -> return false (it will be handled as item disabled + tooltip)
      // eslint-disable-next-line no-underscore-dangle
      if (this.tableRow._proposal_progress_state) return false;

      let disabled = true;

      if (this.routeName === 'ApplicationDetails') {
        // eslint-disable-next-line no-underscore-dangle
        disabled = !['available', 'inactive'].includes(this.tableRow._progress_state);
      } else if (this.routeName === 'ApartmentDetails') {
        // eslint-disable-next-line no-underscore-dangle
        disabled = !['active.new', 'active.in_progress', 'with_offer.declined', 'active.evaluated'].includes(this.tableRow._progress_state);
      }

      return disabled;
    },
  },
  watch: {
    tableRow(tableRow) {
      if (this.routeName === 'ApplicationDetails') {
        this.apartmentIdActual = tableRow._id;
      }
    },
    items: {
      immediate: true,
      handler() {
        // update proposal items disabled and tooltips
        // eslint-disable-next-line no-underscore-dangle
        if (this.tableRow._proposal_progress_state) this.updateProposalItems();
      },
    },
  },
  mounted() {
    // Update apartmentId from url if user is on ApartmentDetails
    if (this.routeName === 'ApartmentDetails') {
      this.apartmentIdActual = +(this.$route.params.id);
    }
  },
  methods: {
    updateProposalItems() {
      // eslint-disable-next-line no-underscore-dangle
      const disabled = (this.routeName === 'WaitlistDetails' && this.tableRow._progress_state !== 'available')
        // eslint-disable-next-line no-underscore-dangle
        || (this.routeName === 'ApartmentDetails' && this.tableRow._progress_state !== 'waitlist.accepted');

      this.proposalItems = JSON.parse(JSON.stringify(this.items));
      this.proposalItems.forEach((item) => {
        if (item.view.name !== 'sendNoInterest') item.tooltip = this.msg.notAvailable;
        switch (item.view.name) {
          case 'sendPropose':
            // eslint-disable-next-line no-underscore-dangle
            item.disabled = disabled || this.tableRow._proposal_progress_state === 'propose';
            break;
          case 'sendNoInterest': {
            const enabledNotInterestedButtons = ['propose', 'open'];
            item.disabled = disabled
              // eslint-disable-next-line no-underscore-dangle
              || !enabledNotInterestedButtons.includes(this.tableRow._proposal_progress_state);
            break;
          }
          default: break;
        }
      });
    },
    openModal(selectedItem) {
      if (this.routeName === 'ApartmentDetails') {
        // Update selected.id because of actions use getters to get application id
        this.$store.state.applications.selected.id = this.tableRow._id;
      }

      const modalKey = {
        sendApartmentOffer: 'offer-modal',
        sendAlternativeApartmentOffer: 'offer-modal',
        directAssignment: 'direct-assignment-modal',
        sendApartmentDecline: 'denial-modal',
        sendPropose: 'proposal-mail-modal',
        sendNoInterest: 'proposal-not-interested-modal',
      }[selectedItem.view.name];

      // set object and application id(s) properly for proposal modal(s)
      switch (modalKey) {
        case 'proposal-mail-modal':
        case 'proposal-not-interested-modal': {
          this.apartmentIdActual = this.routeName === 'WaitlistDetails' ? this.tableRow._id : +(this.$route.params.id);
          this.applicationIdActual = this.routeName === 'WaitlistDetails' ? +(this.$route.params.id) : this.tableRow._id;
          break;
        }
        default: break;
      }

      this.$emit('tableTdButtonSelect', {
        modalKey,
        apartmentId: this.apartmentIdActual,
        applicationId: this.applicationIdActual,
        isAlternativeApartment: selectedItem.view.name === 'sendAlternativeApartmentOffer',
        tableRow: this.tableRow,
      });
    },
  },
};
</script>
