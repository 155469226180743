export default {
  setInvestor(state, investor) {
    state.investor = investor;
  },
  deleteInvestorsData(state) {
    state.investor = {};
    state.detailView.loading = true;
  },
  setDetailViewLoader(state, loading) {
    state.detailView.loading = loading;
  },
};
