<template>
  <div
    v-tooltip="$options.filters.translate('general_attachment')"
    class="mdt-attachment-upload icon-hover flex-center">
    <i class="fa fa-paperclip icon-paperclip" />
    <input
      ref="inputFile"
      type="file"
      :multiple="multiple"
      class="input-file"
      @change="filesChange($event.target.files)">
  </div>
</template>

<script>
import { file } from '@/utility';

export default {
  name: 'MdtAttachmentUpload',
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    filesChange(fileList) {
      if (!fileList.length) return;

      // Construct form data
      const formData = new FormData();
      Array
        .from(Array(fileList.length).keys())
        .forEach((i) => {
          const fileItem = fileList[i];
          if (fileItem.name.length <= 200) {
            formData.append('file', fileItem, fileItem.name);
          } else {
            this.$notify({
              type: 'danger',
              text: this.$options.filters.translate('admin_file_name_should_not_exceed_200_chars'),
            });
          }
        });

      if (formData.get('file')) {
        file.upload('/api/v1/communication/upload-attachments', formData).then((data) => {
          if (data.status === 'success') {
            if (data.uploaded.length) {
              this.$emit('filesUploaded', data.uploaded);
            }

            if (data.errors.length) {
              data.errors.forEach((error) => {
                this.$notify({
                  type: 'danger',
                  title: error.file_names.join(', '),
                  text: error.message || error.detail,
                });
              });
            }
          }
        }).catch((err) => {
          this.$store.dispatch('notify', err?.response?.data?.notify);
          const { data } = err.response;
          if (data?.errors.length) {
            data.errors.forEach((error) => {
              this.$notify({
                type: 'danger',
                title: error.file_names.join(', '),
                text: error.message || error.detail,
              });
            });
          }
        });
      }

      // clear input file value to allow same file upload twice
      this.$refs.inputFile.value = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-attachment-upload {
  position: relative;

  .icon-paperclip {
    font-size: 18px;
    transform: rotate(-45deg);
  }

  .input-file {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;

    &::-webkit-file-upload-button {
      cursor: pointer;
    }
  }
}
</style>
