import { PublicClientApplication } from '@azure/msal-browser';

const scopes = {
  scopes: ['user.read'],
  prompt: 'select_account',
};

async function ssoInstance(cred) {
  const credentials = {
    auth: {
      clientId: cred.azureSSOApplicationId,
      authority: `https://login.microsoftonline.com/${cred.azureSSODirectoryId}`,
      redirectUri: window.location.origin,
    },
  };
  const instance = new PublicClientApplication(credentials);

  await instance.initialize();
  return instance;
}

async function loginPopup(cred) {
  const instance = await ssoInstance(cred);
  const login = await instance.loginPopup(scopes);
  return login;
}

export default {
  loginPopup,
};
