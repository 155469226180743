<template>
  <vue-scroll
    :ops="vueScrollOptions"
    class="mdt-status-buttons-vuescroll">
    <div
      class="mdt-status-buttons text-center"
      :class="{ wide: wide }">
      <div
        v-for="(item, i) in items"
        :key="i"
        class="status-button-item flex-center pointer"
        :class="{
          active: selectedValue === item.key,
          'with-count': withCount,
          disabled: item.disabled,
        }"
        @click="onClick(item)">
        <span
          v-if="item.count !== undefined"
          class="count">
          {{ item.count }}
        </span>
        <i
          v-if="item.icon"
          class="status-icon"
          :class="item.icon" />
        <span class="text">
          {{ item.displayName }}
        </span>
        <span
          v-if="item.inboxCount > 0"
          class="btn btn-size-24 unread-message">
          {{ item.inboxCount }} {{ 'general_new' | translate }}
        </span>
      </div>
    </div>
  </vue-scroll>
</template>

<script>
export default {
  name: 'MdtStatusButtons',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    textUpper: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedValue: this.value,
      vueScrollOptions: {
        scrollPanel: {
          scrollingY: false,
        },
        rail: {
          gutterOfSide: '12px',
        },
        bar: {
          keepShow: true,
        },
      },
    };
  },
  computed: {
    withCount() {
      return this.items.filter((item) => item.count !== undefined).length;
    },
  },
  watch: {
    value(value) {
      this.selectedValue = value;
    },
  },
  methods: {
    onClick(item) {
      if (this.selectedValue === item.key) return;
      this.$emit('input', item.key);
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-status-buttons-vuescroll {
  height: auto !important;

  .mdt-status-buttons {
    margin-bottom: 24px;
  }
}

.mdt-status-buttons {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $border-color;

  &.wide .status-button-item {
    flex: 1;
  }

  .status-button-item {
    margin: 0 10px -1px 0;
    padding: 0 25px 12px 25px;
    white-space: nowrap;

    &.with-count {
      padding-bottom: 9px;
    }

    &:last-child {
      margin-right: 0;
    }

    .text,
    .status-icon {
      color: $color-text-secondary;
    }

    .status-icon {
      margin-right: 8px;
    }

    &.active {
      padding-bottom: 10px;
      border-bottom: 2px solid $color-theme;
      cursor: default;

      .count {
        color: $color-white;
        background-color: $color-theme;
      }

      .text,
      .status-icon {
        color: $color-text-primary;
      }
    }

    &.active.with-count {
      padding-bottom: 7px;
      border-bottom: 2px solid $color-theme;
      cursor: default;
    }

    &:hover:not(.active) {
      .text,
      .status-icon {
        color: $color-text-primary;
      }
    }

    .unread-message {
      margin-left: 8px;
      color: $color-success;
      background-color: rgba($color-success, 0.2);
    }

    .count {
      min-width: 24px;
      height: 24px;
      line-height: 24px;
      margin-right: 8px;
      padding: 0 8px;
      color: $color-text-secondary;
      background-color: $color-back-basic;
      border-radius: 4px;
      text-align: center;
    }

    &.disabled {
      opacity: 0.3;
      user-select: none;
      pointer-events: none;
    }
  }
}
</style>
