import Route from '../../model/navigation-left/route';

export default {
  initialize(state, bootstrap) {
    // initialize main menu items
    const mainItems = bootstrap.menus.mainMenu
      && bootstrap.menus.mainMenu.length
      ? bootstrap.menus.mainMenu.map((nav) => new Route(nav)) : [];
    state.main.items = mainItems;

    // initialize project settings menu items
    const projectSettingsItems = bootstrap.menus.projectSettings
      && bootstrap.menus.projectSettings.length > 0
      ? bootstrap.menus.projectSettings.map((nav) => new Route(nav)) : [];
    state.projectSettings.items = projectSettingsItems;
    // initialize top menu projects
    state.top.projects = bootstrap.projects;
  },
  updatePrevRoutes(state, routeFrom) {
    state.prevRoutes.unshift(routeFrom);
    state.prevRoutes.length = 3;
  },
  setMainMenuCollapsedHovered(state, collapsedHovered) {
    state.main.collapsedHovered = collapsedHovered;
  },
  setMainMenuCollapsed(state, collapsed) {
    state.main.collapsed = collapsed;
  },
};
