<template>
  <div
    class="mdt-toggle-switch"
    :class="{ 'has-error': $v.checked.$dirty && serverErrors.length }">
    <label
      class="flex-center-v"
      :class="[{
        'switched-positions': !switchedPositions,
      }, `theme-${theme}`, `toggle-${size}`]">
      <span
        v-if="!switchedPositions"
        class="slot slot-before">
        <slot />
      </span>
      <input
        type="checkbox"
        class="switch"
        :checked="checked"
        :disabled="disabled"
        @change="onChange">
      <span
        v-tooltip="toggleTooltip"
        class="slider round" />
      <span
        v-if="switchedPositions"
        class="slot slot-after">
        <slot />
      </span>
    </label>
    <div
      v-if="$v.checked.$dirty && serverErrors.length"
      class="toggle-errors">
      <span class="server-errors">
        {{ serverErrors.join('\n') }}
      </span>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';

export default {
  name: 'MdtToggle',
  mixins: [validationMixin],
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    switchedPositions: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'basic',
      validator: (value) => {
        const match = ['basic', 'highlight'];
        return match.includes(value);
      },
    },
    size: {
      type: String,
      default: 'size-24',
      validator: (value) => {
        const match = ['size-24', 'size-20', 'size-18'];
        return match.includes(value);
      },
    },
    tooltip: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      serverErrors: this.errors,
    };
  },
  computed: {
    toggleTooltip() {
      if (!this.tooltip || !Object.keys(this.tooltip).length) return null;
      return this.checked ? this.tooltip.deactivate : this.tooltip.activate;
    },
  },
  watch: {
    errors(value) {
      this.serverErrors = value;
    },
  },
  validations() {
    return {
      checked: {},
    };
  },
  methods: {
    setTouched() {
      this.$v.checked.$touch();
    },
    isValid() {
      this.setTouched();
      return !this.serverErrors.length;
    },
    onChange(e) {
      this.setTouched();
      const { checked } = e.target;
      this.$emit('change', checked);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');

      // Reset server errors
      this.serverErrors = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-toggle-switch {
  &.has-error {
    label {
      &.theme-highlight {
        padding: 12px 14px;
        background-color: rgba($color-danger, 0.05);
        border: 2px solid $color-danger;
      }

      &:not(.theme-highlight) {
        color: $color-danger;
      }
    }
  }

  label {
    &.theme-highlight {
      width: 100%;
      padding: 14px 16px;
      background-color: $color-back-light;
      border-radius: 8px;

      .slider {
        background-color: $color-text-secondary;
      }

      .slot {
        width: 100%;
      }
    }

    // toggle with height 20px
    &.toggle-size-20 {
      .slider {
        width: 32px;
        min-width: 32px;
        height: 20px;
      }

      .slider:before {
        width: 18px;
        height: 18px;
      }

      input:checked + .slider:before {
        left: -1px;
      }
    }

    // toggle with height 18px
    &.toggle-size-18 {
      .slider {
        width: 29px;
        min-width: 29px;
        height: 18px;
      }

      .slider:before {
        width: 16px;
        height: 16px;
      }

      input:checked + .slider:before {
        left: -1px;
      }

      &.theme-highlight {
        padding: 7px 8px;

        .slot {
          line-height: 16px;
        }
      }
    }

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      width: 40px;
      min-width: 40px;
      height: 24px;
      position: relative;
      cursor: pointer;
      background-color: #e6e6e6;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      width: 22px;
      height: 22px;
      left: 1px;
      bottom: 1px;
      background-color: $color-white;
      box-shadow: 0px 2px 20px 0px #0000002a;
      transition: .4s;
    }

    input:checked + .slider {
      background-color: $color-theme;
    }

    input:disabled + .slider {
      opacity: 0.5;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px $color-theme;
    }

    input:checked + .slider:before {
      left: 0;
      transform: translateX(77%);
    }

    .slider.round {
      border-radius: 20px;
      margin-right: 8px;
    }

    &.switched-positions {
      .slider.round {
        margin-left: 8px;
        margin-right: auto;
      }
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
}

.toggle-errors {
  padding-top: 4px;
  color: $color-danger;
  font-size: 12px;
  font-weight: $font-weight-normal;
}
</style>
