<template>
  <div
    class="mdt-input-password-with-confirm"
    :class="[direction]">
    <MdtInputPassword
      ref="password"
      v-model="password"
      use-default-validation
      :config="{
        label: type === 'edit'
          ? $options.filters.translate('general_new_password')
          : $options.filters.translate('general_password'),
      }"
      @mdtDataChanged="$emit('mdtDataChanged')"
      @keyupEnter="$emit('keyupEnter', $event)" />
    <MdtInputPassword
      ref="confirmPassword"
      :value="value"
      use-default-validation
      :config="{
        label: $options.filters.translate('general_confirm_password'),
        errors: confirmPasswordErrors,
      }"
      @input="$emit('input', $event)"
      @mdtDataChanged="$emit('mdtDataChanged')"
      @keyupEnter="$emit('keyupEnter', $event)" />
  </div>
</template>

<script>
export default {
  name: 'MdtInputPasswordWithConfirm',
  props: {
    value: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'edit',
      validator: (value) => ['edit', 'create'].includes(value),
    },
    direction: {
      type: String,
      default: 'column',
      validator: (value) => ['column', 'row'].includes(value),
    },
  },
  data() {
    return {
      password: this.value,
    };
  },
  computed: {
    confirmPasswordErrors() {
      if (this.password !== this.value && this.value.length) {
        return [this.$options.filters.translate('admin_confirm_password_error_msg')];
      }

      return [];
    },
  },
  methods: {
    isValid() {
      return Object.values(this.$refs).map((r) => r.isValid()).every((r) => r);
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-input-password-with-confirm {
  &.column {
    .mdt-input-password:first-child {
      margin-bottom: 24px;
    }
  }

  &.row {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .mdt-input-password {
      width: 50%;

      &:first-child {
        padding-right: 10px;
      }

      &:last-child {
        padding-left: 10px;
      }
    }
  }
}
</style>
