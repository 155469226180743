import Vue from 'vue';
import translate from './translate';
import formatDate from './formatDate';
import currency from './currency';
import capitalize from './capitalize';

Vue.filter('translate', translate);
Vue.filter('formatDate', formatDate);
Vue.filter('currency', currency);
Vue.filter('capitalize', capitalize);

export default {};
