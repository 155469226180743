<template>
  <div
    v-click-outside="closeDropdown"
    class="mdt-sort-by"
    :class="{ active: isDropdownOpen }">
    <span
      v-if="label"
      class="label">
      {{ label }}:
    </span>
    <span class="icon-options">
      <span
        class="pointer display-and-icon"
        @click="toggleDropdown">
        <span class="font-bold"> {{ displayName }}</span>
        <i :class="icon" />
      </span>
      <ul
        v-if="isDropdownOpen"
        class="dropdown-items"
        :class="{ 'right-aligned': rightAligned }">
        <li
          v-for="(item, i) in items"
          :key="i"
          :class="{ active: value === item.value }"
          class="dropdown-item flex-center-v"
          @click="$emit('select', item.value); closeDropdown()">
          {{ item.displayName }}
        </li>
      </ul>
    </span>
  </div>
</template>

<script>
import { mixinToggleDropdown } from '@/mixins';

export default {
  name: 'MdtDropdown',
  mixins: [mixinToggleDropdown],
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'fas fa-angle-down',
    },
    items: {
      type: Array,
      default: () => [],
    },
    rightAligned: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    displayName() {
      const selected = this.items.find((item) => item.value === this.value);
      return selected ? selected.displayName : '-';
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-sort-by {
  position: relative;

  &.active .icon-options {
    background-color: $color-white;
  }
}

.label {
  margin-right: 4px;
}

.icon-options {
  position: relative;

  i {
    margin: 0 4px;
    border-radius: 4px;
    cursor: pointer;
  }
}

.dropdown-items {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  margin-top: 5px;
  background-color: $color-white;
  box-shadow: 0px 2px 20px 0px #0000002a;
  font-size: 0.875rem;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;

  &.right-aligned {
    left: auto;
    right: 0;
  }

  li {
    padding: 0 10px;
    height: 32px;
    cursor: pointer;

    &.active {
      background-color: $color-back-basic;
    }

    &:hover:not(.active) {
      background-color: $color-back-light;
    }

    & > i {
      padding-right: 10px;
    }
  }
}
</style>
