<template>
  <div
    v-if="items && items.length"
    v-click-outside="closeDropdown"
    class="mdt-dropdown-icon no-userselect"
    :class="{ active: (!singleClickMode || !singleItemOnly) && isDropdownOpen, disabled }">
    <MdtButton
      v-if="showAsButton"
      v-tooltip="wrapperTooltip"
      :icon="icon"
      :theme="theme"
      :class="{ disabled: singleClickMode && singleItemOnly && singleItemOnly.disabled }"
      @click.native="onClick" />
    <div
      v-else
      v-tooltip="wrapperTooltip"
      class="icon-options-wrapper flex-center"
      :class="{ disabled: singleClickMode && singleItemOnly && singleItemOnly.disabled }"
      @click="onClick">
      <i
        class="icon-options"
        :class="icon" />
    </div>
    <ul
      v-if="(!singleClickMode || !singleItemOnly) && isDropdownOpen"
      ref="scroll"
      class="dropdown-items"
      :class="{ 'right-aligned': rightAligned }">
      <li
        v-for="(item, i) in items"
        :key="i"
        v-tooltip="item.tooltip"
        class="dropdown-item flex-center-v justify-between"
        :class="{
          disabled: item.disabled,
          pointer: item.action,
        }"
        @click="onItemClick(item)">
        <i
          v-if="item.icon"
          class="icon"
          :class="item.icon" />
        <span>
          {{ item.name }}
        </span>
        <template v-if="item.slot">
          <i class="fas fa-angle-right slot-icon" />
          <div
            class="dropdown-item-slot"
            :style="{ top: dropdownItemSlotTopPosition }">
            <slot :name="item.slot" />
          </div>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import { mixinToggleDropdown } from '@/mixins';
import { NESTED_DROPDOWN_ITEM_HEIGHT } from '@/utility/constants';

export default {
  name: 'MdtDropdownIcon',
  mixins: [mixinToggleDropdown],
  props: {
    items: {
      type: Array,
      required: true,
    },
    nestedItems: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      default: 'fas fa-ellipsis-v',
    },
    rightAligned: {
      type: Boolean,
      default: false,
    },
    showAsButton: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
      validator: (value) => {
        const match = ['default', 'light'];
        return match.includes(value);
      },
    },
    singleClickMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
    tooltipPosition: {
      type: String,
      default: 'auto',
      validator: (value) => {
        const match = [
          'auto',
          'auto-start',
          'auto-end',
          'top',
          'top-start',
          'top-end',
          'right',
          'right-start',
          'right-end',
          'bottom',
          'bottom-start',
          'bottom-end',
          'left',
          'left-start',
          'left-end',
        ];
        return match.includes(value);
      },
    },
  },
  data() {
    return {
      dropdownItemSlotTopPosition: '-4px',
      mousePosY: 0,
    };
  },
  computed: {
    singleItemOnly() {
      return this.items.length === 1
        ? {
          action: this.items[0].action,
          tooltip: this.items[0].tooltip || this.items[0].name || this.tooltip,
          disabled: this.items[0].disabled,
          item: this.items[0],
        } : null;
    },
    wrapperTooltip() {
      return {
        content: this.singleClickMode && this.singleItemOnly
          ? this.singleItemOnly.tooltip
          : this.tooltip,
        placement: this.tooltipPosition,
      };
    },
  },
  watch: {
    isDropdownOpen(value) {
      if (value) {
        this.$nextTick(() => {
          this.scrollTheView();
        });
      }
    },
  },
  mounted() {
    this.handleDropdownVerticalPosition();
  },
  methods: {
    handleDropdownVerticalPosition() {
      // calculate difference between bottom of viewport and mouse position
      // use this to specify position dropdown above or below icon
      document.addEventListener('mouseup', (event) => {
        if (!this.isDropdownOpen) {
          this.mousePosY = event.clientY;
          // diff between bottom of viewport and mouse position
          const diff = window.innerHeight - this.mousePosY;
          // height for two items in main dropdown
          const firstTwoItemsHeight = NESTED_DROPDOWN_ITEM_HEIGHT * 2;
          // height of nested dropdown
          const nestedDropdownHeight = this.nestedItems.length * NESTED_DROPDOWN_ITEM_HEIGHT;
          // calculate approximately top position of nested dropdown
          const nestedDropdownTopPosition = nestedDropdownHeight - firstTwoItemsHeight;
          // specify position of nested dropdown ( top or bottom )
          this.dropdownItemSlotTopPosition = diff > nestedDropdownTopPosition
            ? `-${NESTED_DROPDOWN_ITEM_HEIGHT}px` // if there is enough space below icon
            : `-${nestedDropdownTopPosition}px`; // if there is not enough space below icon
        }
      });
    },
    onClick() {
      if (this.disabled) return;
      if (this.singleClickMode && this.singleItemOnly && !this.singleItemOnly.disabled) {
        this.$emit(this.singleItemOnly.action, this.singleItemOnly.item.key);
      } else this.toggleDropdown();
    },
    onItemClick(item) {
      if (item.disabled || !item.action) return;
      this.$emit(item.action, item);
      this.closeDropdown();
    },
    scrollTheView() {
      const { scroll } = this.$refs;
      if (scroll) {
        // scroll the view if neccessary to show whole component
        scroll.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-dropdown-icon {
  position: relative;

  &.active {
    .icon-options-wrapper {
      background-color: rgba($color-text-primary-rgb, 0.05);
      cursor: default;

      .icon-options {
        color: $color-text-primary;
      }
    }
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;

    ::v-deep .icon-options-wrapper {
      cursor: not-allowed;
    }
  }
}

.mdt-button {
  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;

    ::v-deep .pointer {
      cursor: not-allowed;
    }
  }
}

.icon-options-wrapper {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover {
    .icon-options {
      color: $color-text-primary;
    }
  }

  .icon-options {
    color: $color-text-secondary;
    font-size: 20px;
    border-radius: 4px;
  }
}

.dropdown-items {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  min-width: 200px;
  background-color: $color-back-primary;
  box-shadow: 0px 2px 20px 0px #0000002a;
  font-size: 0.875rem;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 13;

  &.right-aligned {
    left: auto;
    right: 0;
  }

  .dropdown-item {
    position: relative;
    padding: 0 10px;
    height: 40px;
    color: $color-text-secondary;

    .dropdown-item-slot {
      display: none;
      position: absolute;
      top: -4px;
      left: calc(100% - 4px);
      min-width: 200px;
      background-color: $color-back-primary;
      box-shadow: 0px 2px 20px 0px #0000002a;
      font-size: 0.875rem;
      border-radius: 4px;
      white-space: nowrap;
    }

    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    &:hover:not(.disabled) {
      color: $color-text-primary;
      background-color: $color-back-light;

      .dropdown-item-slot {
        display: block;
      }
    }

    .icon {
      padding-right: 10px;
    }

    .slot-icon {
      padding-left: 20px;
    }
  }
}
</style>
