export default {
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      this.$emit('dropdownToggled', this.isDropdownOpen);
    },
    closeDropdown() {
      this.isDropdownOpen = false;
      this.$emit('dropdownToggled', this.isDropdownOpen);
    },
    openDropdown() {
      this.isDropdownOpen = true;
      this.$emit('dropdownToggled', this.isDropdownOpen);
    },
  },
};
