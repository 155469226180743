<template>
  <div class="table-pagination flex-center-v no-userselect">
    <div class="entries-total-amount">
      {{ entriesTotalAmount }}
    </div>
    <div class="flex-center">
      <div
        ref="pagSize"
        v-click-outside="closePageSizeOptions"
        class="pagination-size flex-center"
        @click="toggleDropdown">
        <span class="page-size">{{ size }} / page</span>
        <i class="fa fa-caret-down" />
        <transition name="zoom-in-bottom">
          <div
            v-show="showPageSizeOptions"
            class="size-options">
            <div
              v-for="(item, i) in pageSizes"
              :key="i"
              class="size-item flex-center"
              @click.stop="onSelect(i);">
              {{ item }} / page
            </div>
          </div>
        </transition>
      </div>
      <div class="pagination-page flex-center-v">
        <span
          class="page-item page-back flex-center"
          :class="{ 'is-disabled': isBackDisabled }"
          @click="onBack">
          <i class="fa fa-angle-left" />
        </span>
        <span
          v-for="(page, i) in pages"
          :key="i"
          class="page-item flex-center"
          :class="{
            'is-activated': page.activated && !page.disabled,
            'is-disabled': page.disabled,
            dots: page.value === '...',
          }"
          @click="onPage(page)">
          {{ page.value }}
        </span>
        <span
          class="page-item page-next flex-center"
          :class="{ 'is-disabled': isNextDisabled }"
          @click="onNext">
          <i class="fa fa-angle-right" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
/* Simplified version of https://github.com/TheoXiong/vue-table-dynamic/blob/master/src/components/VuePagination.vue */
export default {
  name: 'TablePagination',
  props: {
    total: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    pageSizes: {
      type: Array,
      default: () => [5, 10, 20, 50].reverse(),
    },
    currentPageActual: {
      type: Number,
      default: 1,
    },
    table: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showPageSizeOptions: false,
      pages: [],
      size: 10,
      currentPage: 1,
      pageCount: 1,
    };
  },
  computed: {
    isBackDisabled() {
      return this.currentPage === 1;
    },
    isNextDisabled() {
      return this.currentPage === this.pageCount;
    },
    skipNotifyPageChange() {
      return this.table?.savedTableData?.tableRoute?.name === this.$route.name;
    },
    entriesTotalAmount() {
      const start = (this.currentPageActual - 1) * this.pageSize + 1;
      const end = Math.min(start + this.pageSize - 1, this.total);
      const translatedTotalAmount = this.$options.filters.translate('general_total_amount_of_entries').replace('[a]', start).replace('[b]', end).replace('[c]', this.total);
      return translatedTotalAmount;
    },
  },
  watch: {
    total: {
      immediate: true,
      handler(val) {
        if (val > 0 && this.size > 0) {
          this.$nextTick(() => {
            this.initPages(val, this.currentPageActual);
          });
        }
      },
    },
    pageSize: {
      immediate: true,
      handler(val) {
        if (val > 0 && this.size !== val) {
          this.size = val;
          this.$nextTick(() => {
            this.initPages(this.total, this.currentPageActual);
          });
        }
      },
    },
    pageSizes: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.length && this.size !== this.pageSize) {
          [this.size] = val;
        }
        this.closePageSizeOptions();
      },
    },
    currentPageActual: {
      immediate: true,
      handler(val) {
        if (val !== this.currentPage) {
          this.initPages(this.total, val);
        }
      },
    },
  },
  methods: {
    initPages(total, page) {
      if (total === 0) return;
      this.pageCount = Math.ceil(total / this.size);
      this.toPage(page || 1, 'init');
    },
    onPage(page) {
      if (page.disabled || page.activated) return;
      this.toPage(page.number);
    },
    onBack() {
      if (this.isBackDisabled) return;
      this.toPage(this.currentPage - 1);
    },
    onNext() {
      if (this.isNextDisabled) return;
      this.toPage(this.currentPage + 1);
    },
    toPage(targetPage, init) {
      if (targetPage < 1 || targetPage > this.pageCount) return;

      const pageCountMax = 9;
      if (this.pageCount <= pageCountMax) {
        this.pages.splice(0, this.pages.length);
        for (let i = 1; i < (this.pageCount + 1); i++) {
          this.pages.push({
            number: i,
            value: String(i),
            disabled: false,
            activated: targetPage === i,
          });
        }

        this.currentPage = targetPage;
        if (!this.skipNotifyPageChange) this.notifyPageChange(this.currentPage, init);
        return;
      }

      const pages = [];
      const tresholdLeft = Math.floor(pageCountMax / 2);
      const tresholdRight = this.pageCount - tresholdLeft + 1;

      if (targetPage <= tresholdLeft) {
        for (let i = 1; i < (pageCountMax + 1); i++) {
          pages.push({
            number: i,
            value: String(i),
            activated: targetPage === i,
            disabled: false,
          });
        }

        pages[pages.length - 2] = {
          number: this.pageCount - 1,
          value: '...',
          activated: false,
          disabled: true,
        };
        pages[pages.length - 1].value = String(this.pageCount);
        pages[pages.length - 1].number = this.pageCount;
      } else if (targetPage >= tresholdRight) {
        for (let i = (this.pageCount - pageCountMax + 1); i < (this.pageCount + 1); i++) {
          pages.push({
            number: i,
            value: String(i),
            activated: targetPage === i,
            disabled: false,
          });
        }

        pages[0].value = '1';
        pages[0].number = 1;
        pages[1] = {
          number: 2,
          value: '...',
          activated: false,
          disabled: true,
        };
      } else {
        for (let i = 0; i < pageCountMax; i++) {
          const number = targetPage - Math.floor(pageCountMax / 2) + i;
          pages.push({
            number,
            value: String(number),
            activated: targetPage === number,
            disabled: false,
          });
        }

        pages[0] = {
          number: 1,
          value: '1',
          activated: false,
          disabled: false,
        };
        pages[1] = {
          number: 2,
          value: '...',
          activated: false,
          disabled: true,
        };
        pages[pages.length - 2] = {
          number: this.pageCount - 1,
          value: '...',
          disabled: true,
          activated: false,
        };
        pages[pages.length - 1] = {
          number: this.pageCount,
          value: String(this.pageCount),
          disabled: false,
          activated: false,
        };
      }

      this.currentPage = targetPage;
      this.pages.splice(0, this.pages.length);
      this.pages.push(...pages);
      if (!this.skipNotifyPageChange) this.notifyPageChange(this.currentPage, init);
    },
    notifyPageChange(currentPage, init) {
      this.$emit('currentPage', currentPage, init);
    },
    onSelect(index) {
      if (this.size !== this.pageSizes[index]) {
        this.size = this.pageSizes[index];
        this.$emit('size', this.size);
        this.initPages(this.total);
      }

      this.closePageSizeOptions();
    },
    closePageSizeOptions() {
      this.showPageSizeOptions = false;
    },
    toggleDropdown() {
      this.showPageSizeOptions = !this.showPageSizeOptions;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-pagination {
  font-size: 14px;
  justify-content: space-between;
}

.entries-total-amount{
  color: $color-text-secondary;
}

.pagination-size {
  position: relative;
  height: 32px;
  min-width: 80px;
  padding: 0 12px;
  margin-right: 12px;
  color: $color-text-secondary;
  border: 1px solid $border-color;
  border-radius: 4px;
  cursor: pointer;
  @include button-hover($border-color, 0.2);

  .page-size {
    margin-right: 15px;
  }

  .size-options {
    position: absolute;
    bottom: calc(100% + 5px);
    left: -1px;
    right: -1px;
    background-color: $color-back-primary;
    border: 1px solid #e5eef5;
    border-radius: 4px;
    box-shadow: 0px 2px 20px 0px #0000002a;
    overflow: hidden;
    z-index: 3;

    .size-item {
      height: 32px;
    }

    .size-item:hover {
      background-color: $color-back-light;
    }
  }
}

.page-item {
  min-width: 20px;
  height: 20px;
  margin: 0 4px;
  padding: 0 4px;
  line-height: 20px;
  color: $color-text-secondary;
  background-color: $color-back-light;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: $color-back-hover;
  }

  &.is-activated {
    color: $color-white;
    background-color: $color-theme;
    cursor: default;
  }

  &.is-disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &.page-back,
  &.page-next {
    background: none;
    border: none;
  }

  &.page-back {
    margin-right: 6px;
  }

  &.page-next {
    margin-left: 6px;
  }

  &.dots {
    background-color: transparent;
    cursor: default;
    opacity: 1;
  }
}
</style>
