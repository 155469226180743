<template>
  <div
    v-if="viewReady === null"
    class="view-not-ready flex-center">
    <MdtLoader />
  </div>
  <div
    v-else-if="viewReady"
    :class="`app melon-${projectTheme}-theme`">
    <RouterView />
  </div>
  <div
    v-else
    class="view-not-ready flex-center">
    <NotReadyView
      :status-code="initErrorStatusCode"
      :tenant-status="tenantStatus" />
  </div>
</template>

<script>
import cssVars from 'css-vars-ponyfill';
import variables from '@/scss/abstract/_variablesExport.module.scss';
import { helpers, color } from '@/utility';
import NotReadyView from '@/layouts/NotReadyView.vue';

export default {
  name: 'App',
  components: {
    NotReadyView,
  },
  data() {
    return {
      viewReady: null,
      initRequestSent: false,
      initErrorStatusCode: null,
      tenantStatus: {},
    };
  },
  computed: {
    projectTheme() {
      return this.$store.getters.projectTheme;
    },
    siteTitle() {
      return this.$store.getters.siteTitle;
    },
  },
  watch: {
    $route(to, from) {
      // delete table data on route change
      if (to.name !== from.name) {
        this.$store.dispatch('table/deleteTableData');
      }
      // get current route when view is not ready yet
      // and get proper init app data for unprotected and default views
      if (!this.initRequestSent && from.name === null) {
        this.initRequestSent = true;
        this.getInitAppData(to);
      }
    },
  },
  beforeCreate() {
    this.$store.dispatch('loadLSConfigurations');
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleScroll(event) {
      const scroll = {
        top: event.target?.scrollingElement?.scrollTop || 0,
        left: event.target?.scrollingElement?.scrollLeft || 0,
      };
      this.$store.state.documentScroll = scroll;
    },
    handleResize() {
      this.$store.dispatch('updateMdtAppResize');
    },
    getInitAppData(routeTo) {
      this.$store.dispatch('getInitAppData', routeTo).then(() => {
        this.setTheme();
        this.viewReady = true;
      }).catch((e) => {
        this.viewReady = false;
        this.initErrorStatusCode = e.response.status;
        this.tenantStatus = e.response.data;
      });
    },
    setTheme() {
      const { publicPage } = this.$store.state;
      let cssVariables;

      if (this.$route.meta.b2b && publicPage.public_theme_color) {
        cssVariables = {
          '--color-theme': publicPage.public_theme_color,
          '--color-theme-rgb': color.hexToRgb(publicPage.public_theme_color),
          '--color-text-primary': publicPage.public_font_color,
          '--color-text-primary-rgb': color.hexToRgb(publicPage.public_font_color),
          '--color-text-secondary': publicPage.public_second_font_color,
          '--color-text-secondary-rgb': color.hexToRgb(publicPage.public_second_font_color),
        };
        helpers.setFontPublic(publicPage.public_font, this.$store.getters.baseBackendUrl);

        const style = document.createElement('style');
        style.setAttribute('type', 'text/css');
        style.innerHTML = publicPage.public_custom_css;
        document.head.appendChild(style);
      } else {
        const projectThemeCapitalize = this.$options.filters.capitalize(this.projectTheme);
        cssVariables = {
          '--color-theme': variables[`colorTheme${projectThemeCapitalize}`],
          '--color-theme-rgb': variables[`colorTheme${projectThemeCapitalize}Rgb`],
        };
      }

      cssVars({ variables: cssVariables });

      // Update document title
      document.title = this.siteTitle;
    },
  },
};
</script>

<style lang="scss">
@import './scss/main.scss';

.view-not-ready {
  height: 100%;
  min-height: 100vh;
  background-color: $color-back-light;
}

#app,
.template-default {
  color: $color-text-primary;
  background-color: $color-back-primary;
}
</style>
