<template>
  <div
    v-click-outside="closeDropdown"
    class="mdt-datepicker"
    :class="{ opened: isDropdownOpen }">
    <div
      class="datepicker-input"
      :class="{ disabled: disabled }">
      <MdtInput
        ref="inputEl"
        v-model="inputValue"
        :label="label"
        :placeholder="format"
        :required="required"
        :readonly="readonly"
        :disabled="disabled"
        :type="'date'"
        :size="size"
        :hide-optional="hideOptional"
        :errors="errors"
        :tooltip="tooltip"
        @input="setInput"
        @clickInput="onToggleDropdown">
        <i
          slot="icon"
          class="fas fa-calendar-day icon no-pointerevents"
          :class="{ selected: selected.day }" />
      </MdtInput>
    </div>
    <div
      v-if="isDropdownOpen"
      ref="scroll"
      class="datepicker-wrapper"
      :class="[
        position, {
          relative,
          'right-aligned': rightAligned,
          'has-selection': helpers.isNotUndefined(selected.day),
        }]">
      <div class="datepicker-header flex-center-v">
        <div class="datepicker-current-date">
          {{ months[current.month] }} {{ current.year }}
        </div>
        <div class="months-buttons">
          <div
            class="btn btn-basic btn-size-32 icon"
            @click="setMonth(current.month - 1)">
            <i class="fas fa-angle-left" />
          </div>
          <div
            class="btn btn-basic btn-size-32 icon"
            @click="setMonth(current.month + 1)">
            <i class="fas fa-angle-right" />
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th
              v-for="(weekday, i) in weekdays"
              :key="i">
              {{ weekday }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(days, index) in calendar"
            :key="index">
            <td
              v-for="(day, d) in days"
              :key="d"
              :class="{
                current: day.current,
                past: day.past,
                disabled: !day.day || !allowPastDates && day.past,
                selected: day.selected,
              }"
              @click.stop="setDay(day)">
              {{ day.day }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/* Much more simplified version than: https://github.com/ridewn/vue-date-picker/blob/master/src/DatePicker.vue */
import { mixinToggleDropdown } from '@/mixins';
import { helpers, array } from '@/utility';

export default {
  name: 'MdtDatepicker',
  mixins: [mixinToggleDropdown],
  props: {
    value: {
      type: [String, Date],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: 'DD.MM.YYYY',
    },
    position: {
      type: String,
      default: 'bottom',
      validator: (value) => ['bottom', 'top'].includes(value),
    },
    rightAligned: {
      type: Boolean,
      default: false,
    },
    allowPastDates: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    relative: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'size-40',
      validator: (value) => {
        const match = ['size-40', 'size-32'];
        return match.includes(value);
      },
    },
    hideOptional: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  data() {
    const today = moment();
    const weekdays = [...moment().locale(this.$store.state.language).localeData().weekdaysMin()];
    // Move Sunday to last place
    weekdays.push(weekdays.shift());

    return {
      helpers,
      months: moment().locale(this.$store.state.language).localeData().months(),
      weekdays,
      current: {
        year: today.year(),
        month: today.month(),
        day: today.date(),
      },
      selected: {
        year: '',
        month: '',
        day: '',
        dayOfWeek: '',
      },
      inputValue: '',
      serverErrors: this.errors || [],
    };
  },
  computed: {
    calendar() {
      const currentMonthDate = moment(new Date(this.current.year, this.current.month, 1));
      const days = [];
      const startOfMonthDay = currentMonthDate.day() === 0 ? 7 : currentMonthDate.day();
      for (let i = 0; i < startOfMonthDay - 1; i++) {
        days.push('');
      }

      const daysInMonth = currentMonthDate.daysInMonth();
      for (let i = 1; i < daysInMonth + 1; i++) {
        days.push(i);
      }

      const dayObjects = days.map((day) => {
        const date = day ? new Date(this.current.year, this.current.month, day) : null;
        const current = moment().isSame(date, 'day');
        const past = moment().isAfter(date, 'day');
        const selected = (this.selected.day === day)
          && (this.current.month === this.selected.month)
          && (this.current.year === this.selected.year);

        return {
          day,
          current,
          past,
          selected,
        };
      });

      return array.chunk(dayObjects, 7);
    },
  },
  watch: {
    isDropdownOpen(value) {
      if (value) {
        this.$nextTick(() => {
          this.scrollTheView();
        });
      }
    },
    value(value) {
      if (!value) this.inputValue = '';
    },
    errors(value) {
      this.serverErrors = value;
    },
  },
  mounted() {
    if (!this.value) return;

    // Format date to moment input type
    let date = this.value;
    if (typeof this.value === 'string') {
      const splitKey = date.includes('.') ? '.' : '/';
      date = date.split(splitKey).reverse().join('-');
    }

    date = moment(date);
    if (!date.isValid()) return;

    this.current.year = date.year();
    this.current.month = date.month();
    this.current.day = date.date();
    this.setDay({ day: this.current.day }, 'init');
  },
  methods: {
    isValid() {
      if (this.required) return this.$refs.inputEl.isValid() && this.isDateValid(this.inputValue);
      return this.$refs.inputEl.isValid();
    },
    isDateValid(date) {
      date = moment(date, 'DD.MM.YYYY', true);
      return date.isValid();
    },
    setInput(value) {
      let date = value;

      // if input date is not null and date is not valid -> handle it properly and return
      if (this.helpers.isNotUndefined(date) && !this.isDateValid(date)) {
        this.onInvalidInput(date);
        return;
      }

      // if date is undefined -> handle dates properly
      if (this.helpers.isUndefined(date)) {
        const today = moment();
        this.current = {
          year: today.year(),
          month: today.month(),
          day: today.date(),
        };
        this.inputValue = '';
        this.setDay({ day: null }, false, true);
      } else { // if date is not undefined -> handle dates properly
        // Format date to moment input type
        date = moment(date, 'DD.MM.YYYY', true);

        this.current.year = date.year();
        this.current.month = date.month();
        this.current.day = date.date();
        this.setDay({ day: this.current.day });
      }
    },
    onInvalidInput(date) {
      this.selected.day = null;
      this.inputValue = date;
      this.$emit('onSelectedInput', this.inputValue);
      this.$emit('mdtDataChanged');
    },
    setMonth(month) {
      if (month === 12) {
        this.current.year += 1;
        this.current.month = 0;
        return;
      }

      if (month === -1) {
        this.current.year -= 1;
        this.current.month = 11;
        return;
      }

      this.current.month = month;
    },
    setDay(day, init, isEmptyDate) {
      // Prevent setting past days if not allowed
      if (!this.allowPastDates && day.past) return;

      this.selected.year = this.current.year;
      this.selected.month = this.current.month;
      this.selected.day = day.day;
      this.selected.dayOfWeek = new Date(
        this.selected.year,
        this.selected.month,
        day.day,
      ).getDay();
      this.$emit('onSelected', this.selected);

      let date = '';

      // when is not empty date called from setInput -> format date and inputValue properly
      if (!isEmptyDate) {
        date = new Date(this.selected.year, this.selected.month, this.selected.day);
        this.inputValue = moment(date).format(this.format);
      } else { // on empty date clear inputValue
        this.inputValue = '';
      }

      this.$emit('input', date);
      this.$emit('onSelectedInput', this.inputValue);

      if (!init) {
        // emit mdtDataChanged event so changes could be detected
        this.$emit('mdtDataChanged');
      }

      this.closeDropdown();
    },
    scrollTheView() {
      const { scroll } = this.$refs;
      if (scroll) {
        // scroll the view if neccessary to show whole component
        scroll.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    },
    onToggleDropdown() {
      if (this.disabled || this.readonly) return;
      this.toggleDropdown();
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-datepicker {
  position: relative;
  font-size: 14px;

  ::v-deep .input-wrapper {
    width: 100%;
    height: 100%;
    min-width: 145px;
    background-color: $color-white;

    input {
      cursor: pointer;
    }

    .icon.selected {
      color: $color-text-secondary;
    }
  }

  .datepicker-input.disabled {
    ::v-deep .input-wrapper {
      input {
        cursor: default;
      }
    }
  }
}

.datepicker-wrapper {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 314px;
  min-width: 314px;
  max-width: 314px;
  padding: 30px;
  box-shadow: 0px 2px 20px 0px #0000002a;
  border-radius: 4px;
  background-color: $color-back-primary;
  z-index: 101;

  &.right-aligned {
    left: auto;
    right: 0;
  }

  &.top {
    top: auto;
    bottom: calc(100% + 1px);
  }

  &.relative {
    position: relative;
    top: auto;
    margin-top: 4px;

    &.top {
      margin-bottom: 4px;
    }
  }

  &.has-selection {
    & > table {
      tbody td {
        &.current:not(.selected) {
          color: $color-theme !important;
          background-color: rgba($color-theme-rgb, 0.1) !important;
        }
      }
    }
  }

  &:not(.has-selection) {
    & > table {
      tbody td {
        &.current {
          color: $color-theme !important;
          background-color: rgba($color-theme-rgb, 0.1) !important;
          border: 1px solid $color-theme;
        }
      }
    }
  }

  .datepicker-header {
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-bottom: 12px;

    .datepicker-current-date {
      color: $color-text-primary;
      font-size: 18px;
      font-weight: $font-weight-bold;
    }

    .months-buttons {
      .icon {
        color: $color-text-secondary;

        &:first-child {
          margin-right: 8px;
        }
      }
    }
  }

  & > table {
    width: 100%;
    padding: 0;
    border-spacing: 0px;
    text-align: center;
    text-transform: uppercase;

    tr {
      white-space: initial;
    }

    th, td {
      width: 36px;
      height: 36px;
      font-size: 14px;
      font-weight: $font-weight-normal;
    }

    thead th {
      color: $color-text-secondary;
    }

    tbody td {
      color: $color-text-primary;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: rgba($color-text-secondary-rgb, 0.1);
      }

      &.current {
        color: $color-theme;
        background-color: rgba($color-theme-rgb, 0.1);
        font-weight: $font-weight-bold;
      }

      &.past {
        color: rgba($color-text-secondary-rgb, 0.5);
      }

      &.disabled {
        cursor: default;
        pointer-events: none;
      }

      &.selected {
        color: $color-white;
        background-color: $color-theme;
      }
    }
  }
}
</style>
