export default {
  prevRoutes: [{}, {}, {}], // Keep last 3 states of previous routes
  main: {
    items: [],
    collapsed: false,
    collapsedHovered: false,
  },
  top: {
    projects: [],
  },
  projectSettings: {
    items: [],
  },
};
