<template>
  <div class="mdt-file-upload">
    <div
      v-if="!isUploading"
      class="dropbox pointer flex-center">
      <i class="fas fa-plus-circle icon-plus" />
      <input
        type="file"
        :accept="fileExtensions.join(', ')"
        :multiple="multiple"
        :disabled="disabled"
        class="input-file"
        @change="filesChange($event.target.files)">
      <div class="options-info">
        <div class="options-info-label">
          {{ 'general_allowed_file_types' | translate }}:
          {{ fileExtensions.join(', ') }}
        </div>
        <div class="options-info-label">
          {{ 'general_maximum_file_size' | translate }}:
          {{ fileSizeLimit }}MB
        </div>
      </div>
    </div>
    <div
      v-else
      class="progress flex-center">
      <div
        class="progress-indicator"
        :style="{ width: `${progress}%` }" />
      <i class="fas fa-file-pdf icon-pdf" />
      <div class="progress-value flex-center">
        {{ progress }}%
      </div>
    </div>
  </div>
</template>

<script>
import { file } from '@/utility';

export default {
  name: 'MdtFileUpload',
  props: {
    uploadUrl: {
      type: String,
      required: true,
    },
    fieldName: {
      type: String,
      default: 'file',
    },
    extensions: {
      type: Array,
      default: () => [],
    },
    sizeLimit: {
      type: Number,
      default: 0,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isUploading: false,
      progress: 0,
    };
  },
  computed: {
    fileExtensions() {
      return this.extensions.length
        ? this.extensions : this.$store.state.bootstrap.settings.allowedFileTypes;
    },
    fileSizeLimit() {
      return this.sizeLimit
        ? this.sizeLimit : this.$store.state.bootstrap.settings.maxFileSize;
    },
  },
  methods: {
    filesChange(fileList) {
      if (!fileList.length) return;

      // Construct form data
      const formData = new FormData();
      Array
        .from(Array(fileList.length).keys())
        .forEach((i) => {
          const fileItem = fileList[i];
          formData.append(this.fieldName, fileItem, fileItem.name);
        });
      this.save(formData);
    },
    save(formData) {
      this.isUploading = true;
      this.progress = 0;

      file.upload(
        this.uploadUrl,
        formData,
        (evt) => { this.progress = Math.round((100 * evt.loaded) / evt.total); },
      ).then((data) => {
        if (data.status === 'success') {
          if (data.uploaded.length) {
            this.$emit('uploaded', data.uploaded);
          }

          if (data.errors.length) {
            data.errors.forEach((error) => {
              this.$notify({
                type: 'danger',
                title: error.file_names.join(', '),
                text: error.message || error.detail,
              });
            });
          }
        }
      }).catch((err) => {
        this.$store.dispatch('notify', err?.response?.data?.notify);
        const { data } = err.response;
        if (data && data?.errors?.length) {
          data.errors.forEach((error) => {
            this.$notify({
              type: 'danger',
              title: error.file_names.join(', '),
              text: error.message || error.detail,
            });
          });
        } else {
          this.$notify({
            type: 'danger',
            text: this.$options.filters.translate('general_upload_error'),
          });
        }
      }).finally(() => {
        this.progress = 0;
        this.isUploading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-file-upload {
  position: relative;
  width: 100%;
  min-height: 310px;
  background-color: $color-back-card;
  border: 1px dashed $color-text-secondary;
  border-radius: 8px;
  text-align: center;
  overflow: hidden;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $color-back-hover;

    .dropbox {
      .icon-plus {
        color: #bdcbd1;
      }
    }
  }
}

.dropbox {
  position: relative;
  min-height: inherit;
  width: inherit;

  .icon-plus {
    color: $color-back-basic;
    font-size: 39px;
    transition: color 0.3s ease;
  }

  .input-file {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .options-info {
    position: absolute;
    width: 100%;
    bottom: 24px;
    line-height: 18px;
    color: $color-text-secondary;
    font-size: 0.875rem;
    pointer-events: none;
  }
}

.progress {
  position: absolute;
  width: 100%;
  min-height: 100%;

  .progress-indicator {
    position: absolute;
    left: 0;
    height: 100%;
    width: auto;
    background-color: rgba($color-theme-rgb, 0.7);
    border-radius: 4px;
  }

  .icon-pdf {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5rem;
    color: rgba($color-text-secondary-rgb, 0.5);
  }

  .progress-value {
    width: 50px;
    height: 50px;
    background-color: rgba($color-back-primary-rgb, 0.8);
    z-index: 1;
  }
}
</style>
