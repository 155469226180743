<template>
  <div class="navigation-left-menu">
    <vue-scroll :ops="vueScrollOptions">
      <div
        v-for="item in items"
        :key="item.key"
        class="menu-item">
        <div v-if="item.behaviour">
          <button
            type="button"
            :disabled="item.behaviour.ui_action.includes('inactive')"
            class="menu-item-parent menu-item-button unavailable-feature-open-button pointer"
            :class="{ 'menu-item-button-disabled': item.behaviour.ui_action.includes('inactive') }"
            @click="() => handleUnavailableFeature(item)">
            <i
              v-if="item.icon"
              class="menu-item-icon"
              :class="item.icon" />
            {{ item.title }}
          </button>
          <MdtFeatureUnavailable
            :modal-key="modalKey"
            :item-key="item.key"
            :title="unavailableFeature.title"
            :description="unavailableFeature.description"
            :image="require(`@/assets/images/digital-signature.png`)"
            :hide-footer-border="true"
            :btn-disabled="btnDisabled"
            @close="closeUnavailableFeatureModal"
            @cancel="closeUnavailableFeatureModal"
            @click="() => sendNotification(item, 'send')" />
        </div>
        <div
          v-else
          class="flex-center-v">
          <router-link
            :to="{ name: item.urlName }"
            class="menu-item-parent pointer">
            <i
              v-if="item.icon"
              class="menu-item-icon"
              :class="item.icon" />
            <span class="menu-item-label">
              {{ item.title }}
            </span>
            <div
              v-if="displayBetaTag(item)"
              class="menu-item-tag">
              BETA
            </div>
          </router-link>
          <i
            v-if="item.children && item.children.length"
            class="fas fa-angle-down expand-collapse-icon no-pointerevents"
            :class="{ 'fa-angle-up': showChildRoutes(item.urlName) }" />
        </div>
        <transition name="open-close">
          <div
            v-if="item.children && item.children.length && showChildRoutes(item.urlName)"
            class="menu-item-children">
            <router-link
              v-for="child in item.children"
              :key="child.key"
              :to="{ name: child.urlName }"
              class="menu-item-child pointer">
              {{ child.title }}
            </router-link>
          </div>
        </transition>
      </div>
    </vue-scroll>
    <div
      ref="privacyPolicy"
      class="privacy-policy flex-center pointer"
      @click="isModalOpen = true">
      {{ 'admin_privacy_policy' | translate }}
    </div>
    <MdtModalPrivacyPolicy
      v-if="isModalOpen"
      @close="isModalOpen = false" />
  </div>
</template>

<script>
import MdtModalPrivacyPolicy from '@/components/shared/modals/MdtModalPrivacyPolicy.vue';
import MdtFeatureUnavailable from '../shared/modals/MdtFeatureUnavailable.vue';

export default {
  name: 'NavigationLeftMenu',
  components: {
    MdtModalPrivacyPolicy,
    MdtFeatureUnavailable,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      vueScrollOptions: {
        scrollPanel: {
          scrollingX: false,
        },
        rail: {
          opacity: 0,
        },
        bar: {
          opacity: 0.5,
        },
      },
      isModalOpen: false,
      modalKey: '',
      unavailableFeature: {
        title: '',
        description: '',
        buttonLabel: '',
      },
      btnDisabled: false,
      betaTagsWhiteList: ['waitlist', 'analytics'],
    };
  },
  computed: {
    showChildRoutes() {
      // Show child routes if .menu-item-parent, one of it's children or detail view is active
      return (urlName) => this.$route.matched.some((match) => match.name === urlName)
        || this.$route.meta.parentName === urlName;
    },
    displayBetaTag() {
      return (menuPoint) => this.betaTagsWhiteList.includes(menuPoint.key);
    },
  },
  methods: {
    handleUnavailableFeature(item) {
      if (item.key === 'tenant') {
        this.$router.push({ name: item.urlName });
      }
      this.$nextTick(() => {
        this.handleTranslations(item);
        this.modalKey = item.key;
        this.sendNotification(item, 'open');
      });
    },
    handleTranslations(item) {
      switch (item.key) {
        case 'home':
        case 'interestedPeople':
        case 'viewingAppointments':
        case 'applications':
        case 'waitlist':
        case 'objects':
        case 'projectSettings':
        case 'userManagement':
        case 'communicationSettings':
        case 'websiteSettings':
        case 'softwareInterfaces':
          // Break these cases apart if necessary in future
          break;
        case 'digitalSignatureSettings':
        case 'tenant':
          this.unavailableFeature = {
            title: this.$options.filters.translate('admin_digital_signature'),
            description: this.$options.filters.translate('admin_digital_contract_unavailable'),
            buttonLabel: this.$options.filters.translate('general_contact_me'),
          };
          break;
        default:
          this.unavailableFeature = {
            title: item.title,
            description: '',
            buttonLabel: this.$options.filters.translate('general_not_found'),
          };
      }
    },
    sendNotification(item, act) {
      if (!item.behaviour) return;
      if (!item.behaviour.notification_actions) return;
      /* eslint-disable */
      const { notification_actions } = item.behaviour;
      const notification_action = notification_actions.find((action) => action.includes(act));
      /* eslint-enable */
      this.btnDisabled = true;
      // eslint-disable-next-line camelcase
      const params = { notification_action };
      // send notification depend on [act] param
      this.$store.dispatch('communication/sendUpsellingNotification', params)
        .then((data) => {
          if (act === 'open') return;
          this.$store.dispatch('notify', data.notify);
        })
        .catch(this.showResponseError).finally(() => {
          this.btnDisabled = false;
          // do not close modal window until POST with payload
          // {notification_action: notification send_upselling_digital_contract_notification}
          if (act === 'send') {
            this.$store.dispatch('getBootstrap');
            this.modalKey = '';
          }
        });
    },
    closeUnavailableFeatureModal() {
      this.$store.dispatch('getBootstrap');
      this.modalKey = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-left-menu {
  position: relative;
  top: $menu-top-height + 20px;
}

.menu-item {
  position: relative;
  color: rgba($color-white, 0.6);

  &:first-child {
    .menu-item-parent {
      margin-top: 0;
    }
  }

  .menu-item-icon {
    width: 20px;
    margin-right: 20px;
    text-align: center;
  }

  .menu-item-label {
    display: inline-block;
  }

  .expand-collapse-icon {
    position: absolute;
    right: 20px;
    margin-top: 8px;
    color: rgba($color-white, 0.6);
  }

  .menu-item-tag {
    position: absolute;
    top: 15px;
    right: 20px;
    height: 20px;
    line-height: 20px;
    padding: 0 6px;
    border-radius: 12px;
    background-color: rgba(#fff, 0.2);
    color: #fff;
    font-size: 12px;
    font-weight: $font-weight-bold;
    pointer-events: none;
  }

  .menu-item-button {
    font-size: $font-size-base;
    font-family: $font-family;
    text-align:left;
    background: transparent;
  }

  .menu-item-button-disabled {
    opacity: 0.3 !important;
    cursor: default;
  }
}

.menu-item-parent {
  display: block;
  width: 100%;
  margin-top: 8px;
  padding-left: 20px;
  color: rgba($color-white, 0.6);
  line-height: 48px;
  border-radius: 4px;
  white-space: nowrap;
  position: relative;

  &:hover {
    color: $color-white;
    background-color: rgba($color-back-primary-rgb, 0.04);
  }

  &.router-link-active {
    color: $color-theme;
    background-color: rgba($color-back-primary-rgb, 0.08);

    .menu-item-icon {
      color: $color-theme;
    }

    .menu-item-label {
      color: $color-theme;
    }

    & + .expand-collapse-icon {
      color: $color-theme;
    }
  }
}

.menu-item-child {
  display: block;
  margin-top: 8px;
  padding-left: 60px;
  color: rgba($color-white, 0.6);
  line-height: 40px;
  border-radius: 4px;

  &:hover {
    color: $color-white;
    background-color: rgba($color-back-primary-rgb, 0.04);
  }

  &.router-link-active {
    color: $color-theme;
    background-color: rgba($color-back-primary-rgb, 0.08);
  }
}

.privacy-policy {
  position: fixed;
  height: 40px;
  left: 40px;
  bottom: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;

  &:hover {
    opacity: 0.75;
  }
}
</style>
