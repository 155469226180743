<template>
  <div
    class="mdt-edit-button flex-center-h"
    :class="{ active: editable }">
    <div
      v-if="editable && save"
      class="btn btn-primary btn-size-32 save-button"
      @click="$emit('save')">
      {{ 'general_save' | translate }}
    </div>
    <div
      class="flex-center pointer no-userselect edit-button"
      :class="`theme-${theme}`"
      @click="$emit('edit')">
      <i :class="editable ? cancelIcon : editIcon" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MdtEditButton',
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    save: {
      type: Boolean,
      default: false,
    },
    editIcon: {
      type: String,
      default: 'fas fa-pen',
    },
    cancelIcon: {
      type: String,
      default: 'fas fa-times',
    },
    theme: {
      type: String,
      default: 'light',
      validator: (value) => {
        const match = ['default', 'light'];
        return match.includes(value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-edit-button {
  &.active .edit-button {
    color: $color-white;
  }

  .save-button {
    margin-right: 5px;
  }

  .edit-button {
    width: 32px;
    height: 32px;
    border-radius: 50%;

    &.theme-default {
      background-color: $color-theme;
      color: $color-white;
      @include button-hover($color-theme-rgb, 0.75);
    }

    &.theme-light {
      background-color: rgba($color-text-secondary-rgb, 0.1);
      color: $color-text-secondary;
      @include button-hover($color-text-secondary, 0.2);

      &:hover {
        color: $color-text-primary;
      }
    }

    i {
      font-size: 14px;
    }
  }
}
</style>
