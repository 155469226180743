<template>
  <div class="table-main-title font-bold">
    {{ title }}
  </div>
</template>

<script>

export default {
  name: 'TableMainTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.table-main-title {
  display: flex;
  flex-direction: flex-start;
  padding-bottom: 30px;
  font-size: 24px;
}
</style>
