import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
const url = '/api/v1/users';

export default {
  getSettings() {
    return api.get(`${url}/user-profile-settings`).then((response) => response.data);
  },
  saveSettings(context, data) {
    return api.post(`${url}/user-profile-settings`, data).then((response) => response.data);
  },
  changePassword(context, data) {
    return api.post(`${url}/reset-password`, data).then((response) => response.data);
  },
  saveNotificationsSettings(state, params) {
    return api.post(`${url}/notification-settings`, params).then((response) => response.data);
  },
  getUserSystemNotifications() {
    return api.get(`${url}/system-notification-preference`).then((response) => response.data);
  },
  postUserSystemNotifications(state, params) {
    return api.post(`${url}/system-notification-preference`, params).then((response) => response.data);
  },
};
