<template>
  <div
    class="mdt-wizard-simple-modal mdt-modal-component flex-center"
    @wheel.stop
    @touchmove.stop
    @scroll.stop>
    <div
      class="mdt-wizard-simple-modal-wrapper"
      :style="[{ width }]">
      <div class="mdt-wizard-simple-modal-content">
        <div
          ref="modalHeader"
          class="modal-header flex-center-v flex-noshrink">
          <div>
            <div class="btn btn-basic btn-size-32 theme no-hover wizard-step">
              {{ 'general_step' | translate }} {{ wizardActivePage }}/{{ wizardTotalPages }}
            </div>
            <span
              v-if="wizardTitle"
              class="header-title">
              {{ wizardTitle }}
            </span>
          </div>
          <i
            class="fas fa-times icon-close"
            @click.stop="onClose" />
        </div>
        <div
          class="modal-body">
          <vue-scroll :ops="vueScrollOptions">
            <div
              class="body-content"
              :style="bodyStyle">
              <div class="body-content-wrapper">
                <slot name="content" />
              </div>
            </div>
          </vue-scroll>
        </div>
        <div class="modal-footer flex-center-v flex-noshrink">
          <div
            class="btn btn-light"
            @click.stop="onClose">
            {{ 'general_cancel' | translate }}
          </div>
          <div
            v-if="wizardActivePage !== 1"
            class="btn btn-basic button-back"
            @click.stop="goBackward">
            {{ 'admin_marketing_previous' | translate }}
          </div>
          <div
            v-if="wizardActivePage !== wizardTotalPages"
            v-tooltip="forwardTooltip"
            class="btn btn-basic button-forward"
            :class="{ 'btn-disabled basic': loading || forwardDisabled }"
            @click.stop="!loading && !forwardDisabled ? goForward() : null">
            {{ 'admin_marketing_next' | translate }}
          </div>
          <slot name="button" />
          <div
            v-if="wizardActivePage === wizardTotalPages"
            v-tooltip="forwardTooltip"
            class="btn btn-primary button-complete"
            :class="{ 'btn-disabled basic btn-bordered': loading || forwardDisabled }"
            @click.stop="!loading && !forwardDisabled ? complete() : null">
            {{ msg.complete }}
          </div>
        </div>
      </div>
    </div>
    <MdtModalConfirmation
      v-if="modalKey === 'close-confirmation'"
      :title="'admin_discard_changes' | translate"
      :btn-text="'admin_close_and_discard' | translate"
      type="discard-changes"
      class="discard-changes"
      @close="modalKey = ''"
      @confirm="$emit('close')" />
  </div>
</template>

<script>
import variables from '@/scss/abstract/_variablesExport.module.scss';
import { helpers } from '@/utility';

export default {
  name: 'MdtWizardSimple',
  props: {
    wizardTotalPages: {
      type: Number,
      required: true,
    },
    wizardActivePage: {
      type: Number,
      required: true,
    },
    wizardTitle: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Object,
      default: () => ({}),
    },
    forwardDisabled: {
      type: Boolean,
      default: false,
    },
    forwardTooltip: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: '',
    },
    minHeight: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: String,
      default: '90vh',
    },
    isDataChanged: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      modalKey: '',
      modalHeaderHeight: variables.modalSimpleHeaderHeight,
      vueScrollOptions: { scrollPanel: { scrollingX: false } },
      msg: {
        complete: this.buttons?.complete?.text || this.$options.filters.translate('general_save'),
      },
    };
  },
  computed: {
    bodyStyle() {
      const diffHeight = `${this.modalHeaderHeight} - ${variables.modalSimpleFooterHeight}`;
      return {
        height: this.height
          ? `calc(${this.height} - ${diffHeight})` : null,
        minHeight: this.minHeight
          ? `calc(${this.minHeight} - ${diffHeight})` : null,
        maxHeight: `calc(${this.maxHeight} - ${diffHeight})`,
      };
    },
  },
  mounted() {
    // handle scrollbars on modal open
    helpers.handleScrollbarsOnModalOpen();
    // set modal header height
    this.setModalHeaderHeight();
  },
  destroyed() {
    // handle scrollbars on modal close
    helpers.handleScrollbarsOnModalClose();
  },
  methods: {
    setModalHeaderHeight() {
      const { modalHeader } = this.$refs;
      this.modalHeaderHeight = `${modalHeader.offsetHeight}px`;
    },
    isActiveContentPageValid() {
      let scrollToErrorRef;
      const isContentPageValid = Object.values(this.$parent.$refs)
        .filter((r) => r)
        .reduce((previous, current) => (
          Array.isArray(current) ? [...previous, ...current] : [...previous, current]
        ), [])
        .map((r) => {
          if (r.isValid && !r.isValid() && !scrollToErrorRef) {
            scrollToErrorRef = r;
          }
          return !r.isValid || (r.isValid && r.isValid());
        })
        .every((r) => r);

      if (scrollToErrorRef) {
        scrollToErrorRef.$el?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      }
      return isContentPageValid;
    },
    goBackward() {
      this.$nextTick(() => {
        this.$emit('goBackward', this.wizardActivePage);
      });
    },
    goForward() {
      this.$nextTick(() => {
        if (this.isActiveContentPageValid()) {
          this.$emit('goForward', this.wizardActivePage);
        }
      });
    },
    complete() {
      this.$nextTick(() => {
        if (this.isActiveContentPageValid()) {
          this.$emit('complete');
        }
      });
    },
    onClose() {
      if (this.isDataChanged === true) this.modalKey = 'close-confirmation';
      else this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-wizard-simple-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba($color-text-primary-rgb, 0.7);
  z-index: 1002;
}

.mdt-wizard-simple-modal-wrapper {
  max-width: 99vw;
  background-color: $color-back-primary;
  box-shadow: 0 3px 30px #00000040;
  border-radius: 8px;

  .modal-header {
    position: relative;
    display: flex;
    height: $modal-simple-header-height;
    padding: 0 30px;
    border-radius: 4px 4px 0 0;

    .wizard-step {
      margin-bottom: 6px;
    }

    .header-title {
      display: block;
      padding-right: 53px;
      font-size: 24px;
      font-weight: $font-weight-bold;
    }

    .icon-close {
      position: absolute;
      top: 21px;
      right: 21px;
      color: $color-text-secondary;
      font-size: 16px;
    }
  }

  .mdt-wizard-simple-modal-content {
    max-height: 90vh;
  }

  .modal-body {
    padding: 0 30px;

    .body-content-wrapper {
      // modal-body content bottom padding = 0 which keeps vuescroll to be shown all until footer
      // we add padding to content wrapper so the content will have space between footer
      padding-bottom: 30px;
    }
  }

  ::v-deep .modal-footer {
    position: relative;
    justify-content: flex-end;
    height: $modal-simple-footer-height;
    padding: 0 30px;
    box-shadow: 0px 0px 20px 0px #0000001A;
    border-radius: 8px;

    .btn {
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }

    ::v-deep .btn {
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>
