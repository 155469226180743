<template>
  <div
    class="mdt-number-incrementer"
    :class="{ 'has-error': $v.value.$error || serverErrors.length, disabled, readonly }">
    <div
      v-if="label"
      class="label">
      {{ label }}
      <i
        v-if="required"
        class="fa-asterisk field-required" />
      <i
        v-if="tooltip"
        v-tooltip="tooltip"
        class="far fa-info-circle info-icon" />
    </div>
    <div class="wrapper flex-center-v">
      <div
        id="decrement"
        class="icon flex-center pointer no-userselect"
        :class="{ disabled: decrementDisabled }"
        @click="decrement">
        <i class="fas fa-minus" />
      </div>
      <span class="number">
        {{ helpers.isNotUndefined(value) ? value : '-' }}
      </span>
      <div
        id="increment"
        class="icon flex-center pointer no-userselect"
        :class="{ disabled: incrementDisabled }"
        @click="increment">
        <i class="fas fa-plus" />
      </div>
      <i
        v-if="showClearButton"
        class="fas fa-eraser clear-button"
        @click="clearValue" />
    </div>
    <div
      v-if="clientErrors.length || serverErrors.length"
      class="input-errors">
      <span class="client-errors">
        {{ clientErrors.join('\n') }}
        {{ clientErrors.length && serverErrors.length ? '\n' : '' }}
      </span>
      <span class="server-errors">
        {{ serverErrors.join('\n') }}
      </span>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, integer } from 'vuelidate/lib/validators';
import { helpers } from '@/utility';

export default {
  name: 'MdtNumberIncrementer',
  mixins: [validationMixin],
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: Number,
      required: false,
    },
    label: {
      type: String,
      default: '',
    },
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: Number.MIN_SAFE_INTEGER,
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disableClear: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      helpers,
      serverErrors: this.errors || [],
    };
  },
  computed: {
    clientErrors() {
      const errors = [];
      const isDirty = this.$v.value.$dirty;
      if (!this.$v.value.required && isDirty) {
        errors.push(this.$options.filters.translate('general_field_is_required'));
      }
      return errors;
    },
    decrementDisabled() {
      return this.disabled || (
        this.value !== undefined && this.value !== null && (this.value - this.step) < this.min);
    },
    showClearButton() {
      return !this.required && !this.disableClear && !this.disabled;
    },
    incrementDisabled() {
      return this.disabled || (
        this.value !== undefined && this.value !== null && (this.value + this.step) > this.max);
    },
  },
  watch: {
    value() {
      this.isValid();
    },
    errors(value) {
      this.serverErrors = value;
    },
  },
  validations() {
    return {
      value: {
        required: this.required ? required : false,
        integer,
      },
    };
  },
  methods: {
    setTouched() {
      this.$v.value.$touch();
    },
    isValid() {
      this.setTouched();
      const isValid = !this.$v.value.$error && !this.serverErrors.length;
      return isValid;
    },
    decrement() {
      // Return if disabled or minimum reached if decrement
      if (this.decrementDisabled) return;

      let { value } = this;
      // If value is valid integer
      if (Number.isInteger(value)) {
        if ((value - this.step) < this.min) value = null;
        else value -= this.step;
      } else { // If value is not integer revert to minimum value or 0
        value = this.min !== Number.MIN_SAFE_INTEGER ? this.min : 0;
      }
      // If value changed -> emit new value and reset server errors
      if (value !== this.value) {
        this.$emit('input', value);

        // emit mdtDataChanged event so changes could be detected
        this.$emit('mdtDataChanged');

        this.serverErrors = [];
      }
    },
    increment() {
      // Return if disabled or maximum reached if increment
      if (this.incrementDisabled) return;

      let { value } = this;
      // If value is valid integer increment value
      if (Number.isInteger(value)) {
        if ((value + this.step) > this.max) value = null;
        else value += this.step;
      } else {
        // If value is not integer revert to minimum or 0
        value = this.min !== Number.MIN_SAFE_INTEGER ? this.min : 0;
      }
      // If value changed -> emit new value and reset server errors
      if (value !== this.value) {
        this.$emit('input', value);

        // emit mdtDataChanged event so changes could be detected
        this.$emit('mdtDataChanged');

        this.serverErrors = [];
      }
    },
    clearValue() {
      // emit null value and reset server errors
      this.$emit('input', null);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');

      this.serverErrors = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-number-incrementer {
  &.has-error {
    color: $color-danger;

    .icon i {
      color: $color-danger;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;

    .number {
      border-color: $color-text-secondary;
    }

    ::v-deep .icon {
      color: $color-text-secondary !important;
      opacity: 1 !important;
    }
  }

  &.readonly {
    cursor: not-allowed;

    .label,
    .input-errors {
      cursor: text;
    }

    .wrapper * {
      pointer-events: none;
    }
  }

  .label {
    margin-bottom: 12px;
    color: $color-text-secondary;
    font-size: 14px;
    line-height: 14px;

    .info-icon {
      margin-left: 8px;
      color: $color-text-secondary;

      &:hover {
        color: $color-text-primary;
      }
    }

    .field-required {
      color: $color-danger;
      margin-left: 2px;
    }
  }

  .wrapper {
    display: inline-flex;
  }

  .icon {
    width: 32px;
    height: 32px;
    line-height: 32px;
    background-color: $color-back-basic;
    border-radius: 4px;

    &:hover {
      opacity: 0.75;
    }

    &.disabled {
      color: rgba(72, 72, 72, 0.6);
      background-color: rgba(226, 226, 226, 0.5);
      opacity: 0.5;
      cursor: not-allowed;
    }

    i {
      color: $color-text-secondary;
      font-size: 0.75rem;
    }
  }

  .clear-button {
    margin-left: 8px;
    color: $color-text-secondary;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }

  .number {
    min-width: 44px;
    height: 32px;
    line-height: 32px;
    margin: 0 8px;
    padding: 0 12px;
    border: 1px solid $border-color;
    border-radius: 4px;
    text-align: center;
  }

  .input-errors {
    padding-top: 4px;
    font-size: 12px;
    font-weight: $font-weight-normal;
    white-space: pre-line;
  }

  .required-asterisk {
    color: $color-danger;
  }
}
</style>
