<template>
  <MdtModal
    :title="'general_fee' | translate"
    width="400px"
    :is-data-changed="isDataChanged"
    :button-disabled="loading"
    @close="close"
    @cancel="close"
    @save="createFee">
    <MdtLoader v-if="loading" />
    <div v-else>
      <MdtInput
        ref="percentage"
        v-model="percentage"
        type="number"
        :label="'general_billing_rate' | translate"
        :tooltip="'admin_billing_rate_tooltip' | translate"
        required
        @mdtDataChanged="isDataChanged = true">
        <i
          slot="icon"
          class="fas fa-percentage icon" />
      </MdtInput>
      <MdtDatepicker
        ref="date"
        :value="date"
        :label="'general_billing_date' | translate"
        required
        relative
        @onSelectedInput="date = $event"
        @mdtDataChanged="isDataChanged = true" />
    </div>
  </MdtModal>
</template>

<script>
export default {
  name: 'ModalBudgetCreateFee',
  props: {
    selectedRows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      percentage: '',
      date: '',
      isDataChanged: false,
      loading: false,
    };
  },
  methods: {
    createFee() {
      if (!this.isValid()) return;

      this.loading = true;
      this.$store.dispatch('budget/createFee', {
        targets: this.selectedRows,
        honorary_percent: this.percentage,
        accounting_date: this.$options.filters.formatDate(this.date, 'YYYY-MM-DD', 'DD.MM.YYYY'),
      }).then((data) => {
        this.$store.dispatch('notify', data.notify);
        this.$store.dispatch('table/getTableDataOnly');
        this.close();
      }).catch(this.showResponseError)
        .finally(() => {
          this.loading = false;
        });
    },
    isValid() {
      return Object.values(this.$refs).map((r) => r.isValid()).every((r) => r);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-input {
  margin-bottom: 20px;
}
</style>
