import { ls } from '@/utility';

export default {
  loading: false,
  sortKey: '',
  searchVal: '',
  stateFilters: {},
  filters: [], // basic table filters received from backend
  basicFilters: {}, // applied basic table filters
  tableAdditionalFilters: {}, // additional table filters received from backend
  additionalFilters: {}, // applied additional table filters
  tableData: {
    header: [],
    body: [],
  },
  pagination: ls.pagination.get(),
  bulkOptions: [],
  bulkExtraOptions: [],
  settings: {},
  search: {},
  createNew: {},
  title: {},
  excelDownload: {},
  randomPeopleViewing: {},
  allocationRequest: {},
  randomApplicantsViewing: {},
  legend: [],
  // additional tables
  additionalTables: {},
  tableTemplate: {
    apiPoint: '',
    loading: false,
    sortKey: '',
    searchVal: '',
    stateFilters: {},
    filters: [],
    basicFilters: {},
    tableAdditionalFilters: {},
    additionalFilters: {},
    tableData: {
      header: [],
      body: [],
    },
    pagination: ls.pagination.get(),
    bulkOptions: [],
    bulkExtraOptions: [],
    settings: {},
    search: {},
    createNew: {},
    title: {},
    excelDownload: {},
    randomPeopleViewing: {},
    allocationRequest: {},
    randomApplicantsViewing: {},
    legend: [],
  },
  // saved table data on main route/page before going to detail view
  savedTableData: {
    tableRoute: null,
    sortKey: '',
    searchVal: '',
    pagination: {},
    stateFilters: {},
    filters: [],
    basicFilters: {},
    tableAdditionalFilters: {},
    additionalFilters: {},
  },
  currentAdditionalTableRequestData: {},
};
