<template>
  <div class="mdt-loader" />
</template>

<script>
export default {
  name: 'MdtLoader',
};
</script>

<style lang="scss" scoped>
.mdt-loader,
.mdt-loader:after {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.mdt-loader {
  margin: 20px auto;
  border-top: 6px solid rgba(#000, 0.1);
  border-right: 6px solid rgba(#000, 0.1);
  border-bottom: 6px solid rgba(#000, 0.1);
  border-left: 6px solid $color-theme;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;

  &.center-it {
    top: calc(50% - 55px);
    left: calc(50% - 25px);
  }
}

@keyframes load8 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
