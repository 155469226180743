import MdtLoader from '@/components/shared/MdtLoader.vue';
import MdtMessage from '@/components/shared/MdtMessage.vue';
import MdtFileUpload from '@/components/shared/MdtFileUpload.vue';
import MdtTransition from '@/components/shared/MdtTransition.vue';
import MdtDropdownIcon from '@/components/shared/MdtDropdownIcon.vue';
import MdtToggle from '@/components/shared/MdtToggle.vue';
import MdtTags from '@/components/shared/MdtTags.vue';
import MdtSortBy from '@/components/shared/MdtSortBy.vue';
import MdtTagsList from '@/components/shared/MdtTagsList.vue';
import MdtGallery from '@/components/shared/MdtGallery.vue';
import MdtFileDownload from '@/components/shared/MdtFileDownload.vue';
import MdtCkeditor from '@/components/shared/MdtCkeditor.vue';
import TableMain from '@/components/table-main/TableMain.vue';
import MdtTextAccordion from '@/components/shared/MdtTextAccordion.vue';

export default {
  install(Vue) {
    Vue.component('MdtLoader', MdtLoader);
    Vue.component('MdtMessage', MdtMessage);
    Vue.component('MdtFileUpload', MdtFileUpload);
    Vue.component('MdtTransition', MdtTransition);
    Vue.component('MdtDropdownIcon', MdtDropdownIcon);
    Vue.component('MdtToggle', MdtToggle);
    Vue.component('MdtTags', MdtTags);
    Vue.component('MdtSortBy', MdtSortBy);
    Vue.component('MdtTagsList', MdtTagsList);
    Vue.component('MdtGallery', MdtGallery);
    Vue.component('MdtFileDownload', MdtFileDownload);
    Vue.component('MdtCkeditor', MdtCkeditor);
    Vue.component('TableMain', TableMain);
    Vue.component('MdtTextAccordion', MdtTextAccordion);
  },
};
