<template>
  <transition
    appear
    class="transition"
    name="expand"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
    @after-leave="afterLeave">
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'MdtTransition',
  methods: {
    enter(el) {
      // Calculate element width and height
      const { width } = getComputedStyle(el);
      el.style.width = width;
      el.style.position = 'absolute';
      el.style.visibility = 'hidden';
      el.style.height = 'auto';

      const { height } = getComputedStyle(el);
      el.style.width = '';
      el.style.position = '';
      el.style.visibility = '';
      el.style.height = 0;

      // Force repaint to make sure the animation is triggered correctly.
      getComputedStyle(el).height; // eslint-disable-line no-unused-expressions
      setTimeout(() => {
        el.style.height = height;
      });
    },
    afterEnter(el) {
      el.style.height = 'auto';
      this.$emit('expanded');
    },
    leave(el) {
      const { height } = getComputedStyle(el);
      el.style.height = height;

      // Force repaint to make sure the animation is triggered correctly.
      getComputedStyle(el).height; // eslint-disable-line no-unused-expressions
      setTimeout(() => {
        el.style.height = 0;
      });
    },
    afterLeave(el) {
      el.style.height = 'auto';
      this.$emit('collapsed');
    },
  },
};
</script>

<style lang="scss" scoped>
.expand-enter-active,
.expand-leave-active {
  transition: height 0.4s ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}
</style>
