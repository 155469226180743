<template>
  <div
    class="mdt-info-card"
    :class="[`theme-${theme}`, { selectable }]">
    <i
      v-if="showClose && selectable && !singleContact"
      class="fas fa-times icon-close"
      @click.stop="$emit('remove', cardData)" />
    <div class="header">
      <div class="title font-bold">
        {{ cardData.title }}
      </div>
      <div
        v-if="cardData.subtitle"
        class="subtitle">
        {{ cardData.subtitle }}
      </div>
    </div>
    <div
      v-if="cardData.contacts && cardData.contacts.length"
      class="info-items">
      <div
        v-for="(contact, key) in cardData.contacts"
        :key="contact.id"
        class="contact"
        :class="{ selected: adultId === contact.id }"
        @click="$emit('select', contact)"
        @mouseenter="contactHovered = key"
        @mouseleave="contactHovered = null">
        <div
          v-for="(item, i) in cardDataItems(contact)"
          :key="i"
          class="info-item flex-center-v">
          <i
            v-if="item.icon"
            class="icon"
            :class="item.icon" />
          <span class="value">
            {{ item.value }}
          </span>
        </div>
        <i
          v-show="showClose && !singleGroupedContact
            && contactHovered === key && cardData.contacts.length > 1"
          class="fas fa-times-circle contact-icon-close highlight circle pointer"
          @click.stop="$emit('removeContact', contact)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MdtInfoCard',
  props: {
    cardData: {
      type: Object,
      required: true,
    },
    adultId: {
      type: Number,
      default: 0,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    singleContact: {
      type: Boolean,
      default: false,
    },
    singleGroupedContact: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
      validator: (value) => {
        const match = ['default', 'highlight'];
        return match.includes(value);
      },
    },
  },
  data() {
    return {
      contactHovered: null,
    };
  },
  computed: {
    cardDataItems() {
      return (contact) => contact.items.filter((item) => item.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-info-card {
  position: relative;
  margin-bottom: 20px;
  padding: 24px;
  background-color: $color-back-light;
  border-radius: 4px;
  cursor: default;

  &:last-child {
    margin-bottom: 0;
  }

  &.selected {
    background-color: rgba($color-theme-rgb, 0.1);

    .contact {
      &:hover {
        background-color: rgba($color-theme-rgb, 0.05);
      }

      &.selected {
        background-color: rgba($color-theme-rgb, 0.1);
      }
    }
  }

  // add hover cursor pointer if selectable, otherwise not
  &.selectable {
    .contact {
      &:hover:not(.selected) {
        cursor: pointer;
      }
    }
  }

  &:not(.selectable) {
    background-color: $color-back-light;
    pointer-events: none;

    .contact {
      background-color: transparent !important;
    }
  }

  &.theme-highlight {
    background-color: rgba($color-text-secondary-rgb, 0.05);
  }

  .icon-close {
    position: absolute;
    top: 24px;
    right: 32px;
    padding: 0;
    color: $color-text-secondary;
    font-size: 16px;
  }

  .header {
    flex-wrap: wrap;
    padding-right: 48px;
    padding-bottom: 16px;
    font-size: 18px;
  }

  .title,
  .subtitle {
    color: $color-text-primary;
  }

  .title {
    font-size: 18px;
  }

  .subtitle {
    padding-top: 8px;
    color: $color-text-secondary;
    font-size: 14px;
  }

  .info-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contact {
    position: relative;
    padding: 12px 16px;
    border-radius: 4px;

    &:hover {
      background-color: rgba($color-text-secondary-rgb, 0.05);
    }

    &.selected {
      background-color: rgba($color-text-secondary-rgb, 0.1);
    }
  }

  .contact-icon-close {
    position: absolute;
    top: calc(50% - 10px);
    right: 16px;
    min-width: 20px;
    line-height: 20px;
    margin: 0;
    padding: 0;
    color: $color-text-secondary;
    font-size: 20px;
  }

  .info-item {
    display: flex;
    padding-bottom: 8px;
    color: $color-text-primary;

    &:last-child {
      padding-bottom: 0;
    }

    .icon {
      margin-right: 8px;
      color: $color-text-secondary;
    }

    .value {
      color: $color-text-secondary;
      font-size: 14px;
      line-height: 14px;

      &.name {
        color: $color-text-primary;
        font-size: 16px;
        line-height: 16px;
        font-weight: $font-weight-bold;
      }
    }
  }
}
</style>
