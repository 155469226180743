<template>
  <MdtWizardModal
    ref="mdtWizardModal"
    :title="wizardTitle"
    :width="width"
    :height="height"
    :min-height="minHeight"
    :max-height="maxHeight"
    :is-data-changed="isDataChanged"
    @close="$emit('close')">
    <MdtWizardMenu
      slot="menu"
      :wizard-data="wizardData"
      :wizard-active-page="wizardActivePage"
      :publishing="publishing"
      @goToPage="goToPage"
      @goForward="goForward" />
    <MdtWizardContent
      v-if="!publishing"
      ref="wizardContent"
      :wizard-active-page-content="activePageData.content"
      :transition-name="transitionName"
      v-on="$listeners"
      @mdtDataChanged="isDataChanged = true" />
    <MdtLoader
      v-else
      class="publishing-loader" />
    <div
      slot="button"
      class="btn btn-light wizard-cancel-btn"
      @click.stop="onCancel">
      {{ 'general_cancel' | translate }}
    </div>
    <div
      v-if="wizardActivePage !== 1"
      slot="button"
      class="btn btn-basic wizard-back-btn"
      :class="{ 'btn-disabled': publishing }"
      @click.stop="!publishing && goBackward()">
      {{ 'admin_marketing_previous' | translate }}
    </div>
    <div
      slot="button"
      class="btn btn-basic wizard-forward-btn"
      :class="{
        'btn-primary': wizardActivePage === wizardData.length,
        'btn-disabled': publishing || isDataChanged === false,
      } "
      @click.stop="(!publishing && isDataChanged == true) && goForward()">
      {{ wizardActivePage === wizardData.length ? msg.complete : msg.continue }}
    </div>
  </MdtWizardModal>
</template>

<script>
import MdtWizardModal from '@/components/shared/wizard/MdtWizardModal.vue';
import MdtWizardMenu from '@/components/shared/wizard/MdtWizardMenu.vue';
import MdtWizardContent from '@/components/shared/wizard/MdtWizardContent.vue';

export default {
  name: 'MdtWizard',
  components: {
    MdtWizardModal,
    MdtWizardMenu,
    MdtWizardContent,
  },
  props: {
    wizardTitle: {
      type: String,
      default: '',
    },
    wizardData: {
      type: Array,
      required: true,
    },
    publishing: {
      type: Boolean,
      required: true,
    },
    buttons: {
      type: Object,
      default: () => ({}),
    },
    forwardDisabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    minHeight: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: String,
      default: '90vh',
    },
  },
  data() {
    return {
      isDataChanged: false,
      wizardActivePage: 1,
      transitionName: 'slide-up',
      msg: {
        continue: this.$options.filters.translate('admin_marketing_next'),
        complete: (this.buttons && this.buttons.complete) || this.$options.filters.translate('admin_marketing_complete'),
      },
    };
  },
  computed: {
    activePageData() {
      return this.wizardData.length > 0 ? this.wizardData[this.wizardActivePage - 1] : {};
    },
  },
  methods: {
    isActiveContentPageValid() {
      let isContentPageValid = true;
      let scrollToErrorRef;
      const activePageDataContent = this.activePageData.content || [];
      activePageDataContent.forEach((element) => {
        if (element && Array.isArray(element)) {
          element.forEach((item) => {
            if (item.type === 'component') {
              const reference = this.$refs.wizardContent.$refs[item.name];
              const [refEl] = reference || [];
              const ref = refEl ? refEl.$refs[item.name] : {};
              if (!ref || !Object.keys(ref).length
                || (ref.isValid && !ref.isValid())) {
                if (!scrollToErrorRef) scrollToErrorRef = ref;
                isContentPageValid = false;
              }
            }
          });
        } else if (element && element.type === 'component') {
          const reference = this.$refs.wizardContent.$refs[element.name];
          const [refEl] = reference || [];
          const ref = refEl ? refEl.$refs[element.name] : {};
          if (!ref || !Object.keys(ref).length
            || (ref.isValid && !ref.isValid())) {
            if (!scrollToErrorRef) scrollToErrorRef = ref;
            isContentPageValid = false;
          }
        }
      });

      if (scrollToErrorRef) scrollToErrorRef.$el.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      else this.toTop();

      return isContentPageValid;
    },
    goBackward() {
      this.transitionName = 'slide-down';
      this.$nextTick(() => {
        if (this.wizardActivePage === 1) return;
        this.wizardActivePage--;
        this.$emit('goBackward', this.wizardActivePage);
      });
    },
    goForward() {
      this.transitionName = 'slide-up';
      this.$nextTick(() => {
        if (this.isActiveContentPageValid() && !this.forwardDisabled) {
          if (this.wizardActivePage === this.wizardData.length) {
            this.$emit('complete', this.getWizardCompleteData());
          } else {
            this.wizardActivePage++;
            this.$emit('goForward', this.wizardActivePage);
          }
        }
      });
    },
    toTop() {
      /*
        NOTE:
        if in 1 step we have scroll (a lot of content),
        and if we from step 1 switch to step 2 which also have scroll (a lot of content),
        then, we will see the step 2 content in position where we end step 1
      */
      const refEl = this.$refs.wizardContent;
      refEl.$el.scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
    goToPage(page) {
      if (this.wizardActivePage < page) this.transitionName = 'slide-up';
      else if (this.wizardActivePage > page) this.transitionName = 'slide-down';
      this.$nextTick(() => {
        this.wizardActivePage = page;
      });
    },
    getWizardCompleteData() {
      const wizardCompleteData = {};
      this.wizardData.forEach((page) => {
        const pageContent = page.content || [];
        pageContent.forEach((element) => {
          if (Array.isArray(element)) {
            element.forEach((item) => {
              if (item.type === 'component') {
                if (!wizardCompleteData[page.pageName]) wizardCompleteData[page.pageName] = {};
                wizardCompleteData[page.pageName][item.name] = item.cvModel;
              }
            });
          } else if (element.type === 'component') {
            if (!wizardCompleteData[page.pageName]) wizardCompleteData[page.pageName] = {};
            wizardCompleteData[page.pageName][element.name] = element.cvModel;
          }
        });
      });
      return wizardCompleteData;
    },
    onCancel() {
      const modalRef = this.$refs.mdtWizardModal;
      if (modalRef) modalRef.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.publishing-loader {
  position: absolute;
  top: 25%;
  left: 32%;
}
</style>
