<template>
  <div class="mdt-search">
    <MdtInput
      v-model="searchValue"
      :placeholder="placeholder"
      :size="size"
      :class="{ active: !isFocused && searchValue.length }"
      @blur="isFocused = false;"
      @focus="isFocused = true;"
      @keyupEnter="search">
      <i
        v-show="!searchValue.length"
        slot="icon"
        class="fas fa-search no-value" />
      <i
        v-show="searchValue.length"
        slot="icon"
        class="fas fa-times-circle has-value pointer"
        @click="resetValue" />
    </MdtInput>
  </div>
</template>

<script>
export default {
  name: 'MdtSearch',
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'size-40',
      validator: (value) => {
        const match = ['size-40', 'size-32'];
        return match.includes(value);
      },
    },
  },
  data() {
    return {
      isFocused: false,
      searchValue: this.value,
    };
  },
  watch: {
    value(value) {
      this.searchValue = value;
    },
  },
  methods: {
    search() {
      this.$emit('input', this.searchValue);
      this.$emit('search', this.searchValue);
    },
    resetValue() {
      this.searchValue = '';
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-search {
  min-width: 200px;
}
</style>
