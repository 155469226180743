<template>
  <div
    class="mdt-radio"
    @click="onChange">
    <input
      type="radio"
      :checked="checked">
    <label>
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: 'MdtRadio',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onChange() {
      this.$emit('change', !this.checked);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-radio {
  position: relative;
  display: inline-block;

  & > input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    cursor: pointer;

    &:checked ~ label:before {
      background-color: $color-theme;
      border-color: $color-theme;
    }

    &:checked ~ label:after {
      background-color: $color-back-primary;
    }
  }

  label {
    position: initial;
    display: inline-block;
    min-width: calc(1em + 2px);
    color: $color-text-secondary;
    font-size: 16px;
    text-indent: 1.5em;
  }

  label:before,
  label:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: calc(1em + 2px);
    height: calc(1em + 2px);
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 50%;
    z-index: 0;
  }

  label:before {
    border-color: $color-text-secondary;
  }

  label:after {
    transform: scale(0.6);
  }
}
</style>
