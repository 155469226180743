import { ls } from '@/utility';

export default {
  open({ getters, dispatch }, sidepanel) {
    // Toggle sidepanel if same sidepanel is opened
    if (getters.isSame(sidepanel)) {
      dispatch('close');
      return;
    }

    // Close sidepanel before opening new one
    dispatch('close');

    // Collapse main navigation on opening sidepanel if viewport width <= 1440px
    const vWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    if (vWidth <= 1440) {
      dispatch('menu/setMainMenuCollapsed', true, { root: true });
      ls.mainMenu.set({ collapsed: true });
    }

    if (!sidepanel.data) dispatch('getData', sidepanel);
  },
  close({ commit }) {
    commit('close');
  },
  updateData({ commit }, data) {
    commit('updateData', data);
  },
  updateDataOnly({ getters, commit }, data) {
    if (getters.sidepanel.key) {
      commit('updateData', { data });
    }
  },
  async getData({ dispatch, rootGetters }, sidepanel) {
    const { projectType } = rootGetters;
    const action = {
      ApplicationEvaluation: 'applications/getEvaluation',
      ApplicationEdit: 'applications/getEdit',
      ApplicationAdult: 'applications/getAdult',
      ApplicationChild: 'applications/getChild',
      ApplicationOffer: 'applications/getOfferDetail',
      ApplicationOfferDeclined: 'applications/getOfferDetailDeclined',
      ApartmentEdit: projectType === 'saleb2b' ? 'apartments/getEditV2' : 'apartments/getEdit',
      ApartmentOffer: 'apartments/getOfferDetail',
      Messenger: 'communication/getMessengerSidepanel',
      BudgetCostPlanEdit: 'budget/getCostPlanEdit',
      BudgetCostCategoryEdit: 'budget/getCostCategoryEdit',
      ApplicationTotalPoints: 'applications/getTotalPoints',
      BuildingEdit: 'buildings/getEdit',
      InvestorEdit: 'investors/getEdit',
      InterestedPersonB2BEdit: 'interestedPeople/getEditB2B',
      InvestorOffer: 'investors/getOfferSidepanel',
    }[sidepanel.key];
    if (!action) return;

    const data = await dispatch(action, sidepanel.params, { root: true });
    dispatch('updateData', {
      key: sidepanel.key,
      title: data.title,
      params: sidepanel.params,
      data,
      tableRowId: sidepanel.tableRowId,
    });
  },
  refreshData({ getters, dispatch }) {
    if (!getters.sidepanel.key) return;
    dispatch('getData', getters.sidepanel);
  },
  reopen({ getters, dispatch }) {
    const sidepanel = {
      key: getters.sidepanel.key,
      params: getters.sidepanel.params,
    };

    // Dispatch close because open will close sidepanel if params are same
    dispatch('close');
    dispatch('open', sidepanel);
  },
};
