<template>
  <ul
    v-if="files.length"
    class="mdt-file-list">
    <li
      v-for="(fileName, i) in fileNames"
      :key="fileName"
      class="file-item">
      <MdtPdfPreview
        :src="files[i]"
        :theme="'highlighted'"
        @deleteFile="deleteFile(i)">
        <div
          v-overflow-tooltip
          v-tooltip="{
            content: fileName,
            trigger: 'manual',
          }"
          class="preview-label">
          {{ fileName }}
        </div>
      </MdtPdfPreview>
      <i
        class="fas fa-trash icon-hover icon-delete"
        @click="deleteFile(i)" />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'MdtFileList',
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
  computed: {
    fileNames() {
      return this.files.map((fileRoute) => fileRoute.substr(fileRoute.lastIndexOf('/') + 1));
    },
  },
  methods: {
    deleteFile(index) {
      this.$emit('deleteFile', index);
    },
  },
};
</script>

<style lang="scss" scoped>
  .file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: $color-back-light;
    border-radius: 4px;
    overflow: hidden;
  }

  .file-name {
    word-break: break-all;
  }

  .icon-delete {
    margin-right: 9px;
  }
</style>
