import Vue from 'vue';
import multiselectSearchHelper from './multiselectSearchHelper';

const personType = (type) => {
  switch (type) {
    case 'adult': return Vue.filter('translate')('general_adult');
    case 'child': return Vue.filter('translate')('general_child');
    default: return '';
  }
};

const householdPersonsFormatter = (item, boldFormattedItem) => {
  let fullName = '';
  if (boldFormattedItem) fullName = boldFormattedItem;
  else fullName = item.full_name ? `${item.full_name}` : '';
  const apartmentTitle = item.apartment_title ? ` | ${item.apartment_title}` : '';
  return `${fullName}${apartmentTitle}`;
};

const householdPersonsSearchResultMapper = (pattern, searchData, updateItems) => {
  let resultItems = [];
  searchData.forEach((item) => {
    resultItems.push(multiselectSearchHelper.boldMatchedPattern(pattern, item.full_name, item));
  });
  resultItems = resultItems.map((item) => {
    const obj = {};
    obj.displayName = householdPersonsFormatter(item.item, item.displayName);
    obj.value = householdPersonsFormatter(item.item);
    obj.data = {
      ...item.item,
      resultType: personType(item.item.person_type),
    };
    return obj;
  });
  updateItems(resultItems);
};

const householdPersonsSelectedItemsMapper = (item) => {
  const formattedItem = householdPersonsFormatter(item.data);
  return {
    displayName: formattedItem,
    value: formattedItem,
    data: item.data,
  };
};

export default {
  householdPersonsSearchResultMapper,
  householdPersonsSelectedItemsMapper,
};
