<template>
  <div
    class="mdt-section-toggle clickable"
    :class="{ active: value }">
    {{ title }}
    <i
      class="fas"
      :class="value ? 'fa-angle-up' : 'fa-angle-down'" />
  </div>
</template>

<script>
export default {
  name: 'MdtSectionToggle',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-section-toggle {
  font-size: 16px;

  i {
    margin-left: 7px;
  }
}
</style>
