import axios from 'axios';

const axiosRequest = {
  install(Vue) {
    if (this.installed) return;
    this.installed = true;

    const { CancelToken } = axios;
    // Object of `CancelToken.source` objects
    const sources = {};

    // Returns new token
    const createCancelToken = (name, allowDoubleRequest) => {
      const source = name || new Date().getTime();

      if (sources[source] && !allowDoubleRequest) {
        // Cancels previous request with same name
        sources[source].cancel();
      }

      sources[source] = CancelToken.source();

      return sources[source].token;
    };

    // Cancel specific request
    const cancel = (name) => {
      const source = sources[name];

      if (source) {
        source.cancel();
        delete sources[name];
      }
    };

    // Cancel all registered requests
    const cancelAll = () => {
      Object.keys(sources).forEach((key) => {
        sources[key].cancel();
        delete sources[key];
      });
    };

    // isCancel checks if response is axios cancel
    const isCancel = (err) => axios.isCancel(err);

    Vue.axiosRequest = {
      createCancelToken,
      cancel,
      cancelAll,
      isCancel,
    };

    Vue.prototype.$axiosRequest = {
      createCancelToken,
      cancel,
      cancelAll,
      isCancel,
    };
  },
};

export default axiosRequest;
