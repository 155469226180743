<template>
  <div
    class="mdt-mail-preview-editor"
    :class="{ editable: isEditable }">
    <div class="editor-header flex-center-v">
      <div class="flex-center-v">
        <MdtButton
          v-if="showBackToList"
          :icon="'fas fa-arrow-left'"
          :theme="'light'"
          class="back-button"
          @click.native="showDiscardConfirm
            ? modalKey = 'discard-confirmation' : $emit('goBack')" />
        <span class="title">
          {{ mailTemplate.new ? msg.composeNewMail : mailTemplate.subject }}
        </span>
      </div>
      <MdtEditButton
        :editable="isEditable"
        @edit="toggleEditable" />
    </div>
    <vue-scroll style="height: auto;">
      <div
        v-if="loading"
        class="mail-preview-loader flex-center"
        :style="{ height: preloaderHeight() }">
        <MdtLoader />
      </div>
      <div
        v-else
        class="editor-body"
        @wheel.stop
        @touchmove.stop
        @scroll.stop>
        <div class="editor-body-subject">
          <MdtInput
            v-if="!isEditable"
            :key="'subject-readonly'"
            ref="mailSubject"
            :value="mailPreview.subject"
            :placeholder="'general_subject' | translate"
            :required="true"
            :readonly="true" />
          <MdtInput
            v-else
            :key="'subject-editable'"
            ref="mailSubject"
            v-model="mailTemplateEditor.subject"
            :placeholder="'general_subject' | translate"
            :required="true" />
        </div>
        <div class="editor-body-content">
          <MdtCkeditor
            v-if="!isEditable"
            :key="'ckeditor-readonly'"
            ref="mailBody"
            :value="mailPreviewBodyFormatted"
            :height="ckeditorHeight()"
            :readonly="true" />
          <MdtCkeditor
            v-else
            :key="'ckeditor-editable'"
            ref="mailBody"
            v-model="mailTemplateEditor.body"
            :required="true"
            :height="ckeditorHeight()" />
        </div>
      </div>
    </vue-scroll>
    <div
      ref="mailPreviewFooter"
      class="mail-preview-footer"
      :class="{ 'flex-end': !allowAttachmentUpload }">
      <div
        v-if="allowAttachmentUpload"
        class="attachment-wrapper">
        <div>
          <MdtAttachmentUpload @filesUploaded="$emit('fileUpload', $event); refreshComponent();" />
        </div>
        <MdtFileList
          :files="uploadedFiles"
          @deleteFile="$emit('fileDelete', $event); refreshComponent();" />
      </div>
      <span
        v-if="isEditable && !loading"
        class="btn btn-primary btn-size-32 save-btn"
        @click="saveMailTemplate">
        {{ 'general_save' | translate }}
      </span>
    </div>
    <MdtModalConfirmation
      v-if="modalKey === 'discard-confirmation'"
      :title="'admin_discard_changes' | translate"
      :btn-text="'admin_close_and_discard' | translate"
      type="discard-changes"
      @close="modalKey = ''"
      @confirm="$emit('goBack')" />
  </div>
</template>

<script>
import MdtAttachmentUpload from '@/components/shared/MdtAttachmentUpload.vue';
import MdtFileList from '@/components/shared/MdtFileList.vue';

export default {
  name: 'MdtMailPreviewEditor',
  components: {
    MdtAttachmentUpload,
    MdtFileList,
  },
  props: {
    mailPreview: {
      type: Object,
      required: true,
    },
    mailTemplate: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    allowAttachmentUpload: {
      type: Boolean,
      default: false,
    },
    uploadedFiles: {
      type: Array,
      required: true,
    },
    showBackToList: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isEditable: false,
      mailTemplateEditor: {
        subject: '',
        body: '',
      },
      msg: {
        composeNewMail: this.$options.filters.translate('admin_compose_new_mail'),
        subject: this.$options.filters.translate('general_subject'),
      },
      modalKey: '',
    };
  },
  computed: {
    mailPreviewBodyFormatted() {
      return this.formatMailBody(this.mailPreview.body);
    },
    mailTemplateBodyFormatted() {
      return this.formatMailBody(this.mailTemplate.body);
    },
    templatesRequired() {
      return !this.mailPreview.subject || !this.mailPreviewBodyFormatted;
    },
    showDiscardConfirm() {
      if (!this.isEditable) return false;
      if (this.mailTemplateEditor.subject !== this.mailTemplate.subject) return true;
      if (this.mailTemplateEditor.body !== this.mailTemplateBodyFormatted) return true;
      return false;
    },
  },
  mounted() {
    this.isEditable = !!this.mailTemplate.new;
  },
  methods: {
    isValid() {
      return !this.templatesRequired;
    },
    toggleEditable() {
      this.isEditable = !this.isEditable;
      if (this.isEditable) {
        this.mailTemplateEditor.subject = this.mailTemplate.subject;
        this.mailTemplateEditor.body = this.mailTemplateBodyFormatted;
      }
      this.$emit('updateEditMode', this.isEditable);
      // update ckeditor height
      this.updateCkeditorHeight();
    },
    isMailTemplateValid() {
      let isValid = true;
      const { mailSubject: mailSubjectRef, mailBody: mailBodyRef } = this.$refs;
      if (mailSubjectRef && !mailSubjectRef.isValid()) isValid = false;
      if (mailBodyRef && !mailBodyRef.isValid()) isValid = false;
      return isValid;
    },
    saveMailTemplate() {
      if (!this.isMailTemplateValid()) return;
      const mailTemplate = {
        subject: this.mailTemplateEditor.subject,
        body: this.mailTemplateEditor.body,
        id: this.mailTemplate.id,
      };
      this.$emit('saveMailTemplate', mailTemplate);
      this.isEditable = false;
    },
    formatMailBody(body) {
      return body.replace(
        /(\r\n\r\n)|\n\n|(<br \/>\n){2}|(<br \/>\r\n){2}|<p><\/p>/g,
        '<p>&nbsp;</p>',
      ).replace(
        /<br \/>\n|<br \/>\r\n/g,
        '<br>',
      ).replace(
        /<span class="mail-signature">\t/g,
        '<p>&nbsp;</p><span class="mail-signature">',
      );
    },
    refreshComponent() {
      // refresh view so that ckeditor height could be recalculated correctly
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    mailPreviewFooterHeight() {
      return this.$refs.mailPreviewFooter ? this.$refs.mailPreviewFooter.offsetHeight : 56;
    },
    ckeditorHeight() {
      const { mailBody: mailBodyRef } = this.$refs;
      const isMailBodyValid = !mailBodyRef || (mailBodyRef && mailBodyRef.isValid());
      const height = isMailBodyValid ? 342 - this.mailPreviewFooterHeight()
        : 342 - 22 - this.mailPreviewFooterHeight();
      if (height <= 200) return '200px';
      return `${height}px`;
    },
    preloaderHeight() {
      const height = 700 - 90 - 90 - 64 - this.mailPreviewFooterHeight();
      if (height <= 200) return '200px';
      return `${height}px`;
    },
    updateCkeditorHeight() {
      setTimeout(() => {
        const { mailBody: mailBodyRef } = this.$refs;
        if (mailBodyRef) mailBodyRef.setHeight(this.ckeditorHeight());
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-mail-preview-editor {
  position: relative;
  display: flex;
  flex-direction: column;

  &:not(.editable) {
    ::v-deep {
      input {
        border-color: rgba($border-color, 0.3);
      }
    }
  }
}

.editor-header {
  justify-content: space-between;
  flex-shrink: 0;

  .back-button {
    padding-right: 16px;
  }

  .title {
    color: $color-text-primary;
    font-size: 16px;
    font-weight: $font-weight-bold;
  }
}

.editor-body {
  padding: 20px 0 1px 0;
  font-size: 1rem;
  white-space: pre-wrap;

  .editor-body-subject {
    font-weight: $font-weight-bold;

    ::v-deep input {
      font-size: 1rem;
    }
  }

  .editor-body-content {
    padding-top: 15px;
    line-height: 1.5;
  }
}

.mail-preview-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;

  &.flex-end {
    justify-content: flex-end;
  }

  .save-btn {
    position: absolute;
    right: 0;
  }

  .attachment-wrapper {
    display: flex;
    flex-direction: column;

    .mdt-attachment-upload {
      margin-right: 10px;
    }

    .mdt-file-list {
      display: flex;
      flex-wrap: wrap;
      width: 600px;
      padding-top: 10px;

      ::v-deep .file-item {
        width: calc(50% - 5px);
        margin: 10px 0 0 10px;

        &:nth-child(2n+1) {
          margin-left: 0;
        }

        &:nth-child(1),
        &:nth-child(2) {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
