<template>
  <div class="mdt-gallery">
    <ModalObjectsImageUpload
      v-if="type === 'object' && editMode"
      :object-id="objectId"
      @close="editMode = false"
      @refreshImagesData="$emit('refreshImagesData', $event)" />
    <CreateApartmentWizardImageUpload
      v-else-if="type === 'temp' && editMode"
      :images="images"
      :temp-images="tempImages"
      @close="editMode = false"
      @imagesData="$emit('imagesData', $event)"
      @tempImagesData="$emit('tempImagesData', $event)" />
    <div
      v-if="label || (editable && images.length > 0)"
      class="gallery-header">
      <div
        v-if="label"
        class="gallery-title">
        <span>{{ label }}</span>
        <span
          v-if="!required"
          class="gallery-title-optional">
          ({{ 'admin_marketing_optional' | translate }})
        </span>
      </div>
      <MdtEditButton
        v-if="editable && images.length > 0"
        :editable="editMode"
        @edit="editMode = !editMode" />
    </div>
    <div
      class="gallery-wrapper"
      :class="{ verticaly: size === 3 }">
      <div
        class="gallery-column gallery-image main"
        :class="{ pointer: images[0] }"
        :style="[images[0] && { backgroundImage: `url('${getImageUrl(images[0])}')` }]"
        @click="previewLightboxImage(0)" />
      <div class="gallery-column thumbs">
        <div
          v-for="(index) in (size - 1)"
          :key="index"
          class="gallery-image"
          :class="{ pointer: images[index] }"
          :style="[
            images[index] && { backgroundImage: `url('${getImageUrl(images[index])}')` },
          ]"
          @click="previewLightboxImage(index)">
          <div
            v-if="images.length > size && index === (size - 1)"
            class="blury-wrapper">
            <div class="other-images-link">
              {{ showAllImagesMsg }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="editable && images.length === 0"
        v-tooltip="msg.uploadBtnTooltip"
        class="upload-button center-it flex-center pointer"
        @click="editMode = true">
        <i class="fas fa-plus" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import ModalObjectsImageUpload from '@/components/apartments/ModalObjectsImageUpload.vue';
import CreateApartmentWizardImageUpload from '@/components/apartments/create-wizard/CreateApartmentWizardImageUpload.vue';

export default {
  name: 'MdtGallery',
  components: {
    ModalObjectsImageUpload,
    CreateApartmentWizardImageUpload,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    objectId: {
      type: Number,
      default: null,
    },
    images: {
      type: Array,
      default: () => [],
    },
    tempImages: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: Number.MIN_SAFE_INTEGER,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'object',
      validator: (value) => {
        const match = ['object', 'temp'];
        return match.includes(value);
      },
    },
    size: {
      type: Number,
      default: 5,
      validator: (value) => [3, 5].includes(value),
    },
  },
  data() {
    const { baseBackendUrl } = this.$store.getters;

    return {
      baseBackendUrl,
      editMode: false,
      msg: {
        uploadBtnTooltip: this.$options.filters.translate('admin_marketing_upload_image'),
      },
    };
  },
  computed: {
    lightboxImages() {
      const images = this.type === 'object' ? this.images
        : Object.values(this.images.map((image) => image.imageUrl));
      return images.map((image) => this.addBaseUrl(image));
    },
    showAllImagesMsg() {
      return this.$options.filters.translate('admin_show_all_images_msg').replace('[d]', this.images?.length);
    },
  },
  methods: {
    addBaseUrl(image) {
      return image.includes(this.baseBackendUrl)
      || image.includes(Vue.prototype.$config.baseApiUrl)
      || image.startsWith('https://')
      || image.startsWith('http://')
      || image.startsWith('blob:')
        ? image
        : `${this.baseBackendUrl}${image}`;
    },
    getImageUrl(image) {
      const url = this.type === 'object' ? image : image.imageUrl;
      return this.addBaseUrl(url);
    },
    previewLightboxImage(index) {
      if (this.lightboxImages.length === 0 || !this.lightboxImages[index]) return;
      const lightboxSettings = {
        images: this.lightboxImages,
        index,
      };
      this.$store.dispatch('showLightbox', lightboxSettings);
    },
    isValid() {
      return this.min !== Number.MIN_SAFE_INTEGER ? this.images.length >= this.min : true;
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-gallery {
  width: 100%;
}

.gallery-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  padding-bottom: 20px;

  .gallery-title {
    color: $color-text-secondary;
    font-size: 14px;
    line-height: 14px;
  }
}

.gallery-wrapper {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  height: 280px;

  &.verticaly {
    .gallery-column {
      width: 65%;
    }

    .thumbs {
      flex-direction: column;
      flex-wrap: nowrap !important;
      width: 35%;
      height: calc(100% + 2px);

      .gallery-image {
        width: calc(100% - 2px) !important;
      }
    }

    .upload-button {
      left: calc(65% + 1px);
    }
  }

  .gallery-column {
    width: 50%;
    height: 100%;

    &.main {
      margin: 1px;
      background-color: $color-back-hover;
      background-size: cover;
      background-position: center;
    }

    &.thumbs {
      display: flex;
      flex-wrap: wrap;

      .gallery-image {
        background-color: $color-back-hover;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: calc(50% - 2px);
        height: calc(50% - 1px);
        margin: 1px;
      }
    }
  }

  .blury-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba($color-text-primary-rgb, 0.6);

    .other-images-link {
      color: $color-white;
      font-size: 12px;
      font-weight: $font-weight-bold;
      text-align: center;
    }
  }

  .upload-button {
    width: 50px;
    height: 50px;
    color: $color-white;
    background-color: $color-theme;
    border-radius: 50%;
    box-shadow: 0px 2px 20px 0px #0000002a;
    font-size: 1.375rem;
  }
}
</style>
