<template>
  <div
    class="navigation-left-menu-collapsed"
    @mouseenter="collapsedHovered = true"
    @mouseleave="onMouseLeave">
    <vue-scroll :ops="vueScrollOptions">
      <div
        v-for="item in items"
        :key="item.key"
        class="menu-item pointer">
        <div class="flex-center-v">
          <router-link
            :to="{ name: item.urlName }"
            class="menu-item-parent no-userselect">
            <i
              class="menu-item-icon no-userselect"
              :class="item.icon" />
            <span class="menu-item-label">
              {{ item.title }}
            </span>
          </router-link>
          <i
            v-if="collapsedHovered && showExpandCollapseIcon
              && item.children && item.children.length"
            class="fas fa-angle-down icon-hover expand-collapse-icon no-pointerevents"
            :class="{ 'fa-angle-up': showChildRoutes(item.urlName) }" />
        </div>
        <transition name="open-close">
          <div
            v-if="collapsedHovered && item.children && item.children.length
              && showChildRoutes(item.urlName)"
            class="menu-item-children">
            <router-link
              v-for="child in item.children"
              :key="child.key"
              :to="{ name: child.urlName }"
              class="menu-item-child pointer">
              {{ child.title }}
            </router-link>
          </div>
        </transition>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
export default {
  name: 'NavigationLeftMenuCollapsed',
  props: {
    items: {
      type: Array,
      required: true,
    },
    hovered: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      vueScrollOptions: {
        scrollPanel: {
          scrollingX: false,
        },
        rail: {
          opacity: 0,
        },
        bar: {
          opacity: 0.5,
        },
      },
      timer: null,
      showExpandCollapseIcon: false,
    };
  },
  computed: {
    collapsedHovered: {
      get() {
        return this.hovered;
      },
      set(value) {
        if (value) {
          clearTimeout(this.timer);
          this.showExpandCollapseIcon = false;
        }
        this.$store.dispatch('menu/setMainMenuCollapsedHovered', value);
      },
    },
    showChildRoutes() {
      // Show child routes if .menu-item-parent, one of it's children or detail view is active
      return (urlName) => this.$route.matched.some((match) => match.name === urlName)
        || this.$route.meta.parentName === urlName;
    },
  },
  watch: {
    collapsedHovered(collapsedHovered) {
      if (collapsedHovered) {
        this.timer = setTimeout(() => {
          this.showExpandCollapseIcon = true;
        }, 500);
      }
    },
  },
  methods: {
    onMouseLeave(event) {
      const { toElement } = event;
      if (toElement && !toElement.classList.contains('main-navigation-resize-button')) this.collapsedHovered = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-left-menu-collapsed {
  padding-top: $menu-top-height + 40px;
}

.menu-item {
  position: relative;
  color: $color-white;
  white-space: nowrap;

  &:first-child {
    .menu-item-parent {
      margin-top: 0;
    }
  }

  .menu-item-icon {
    width: 20px;
    text-align: center;
  }

  .menu-item-label {
    display: inline-block;
    padding-left: 20px;
  }

  .expand-collapse-icon {
    position: absolute;
    right: 20px;
    margin-top: 8px;
    color: rgba($color-white, 0.6);
  }
}

.menu-item-parent {
  display: block;
  width: 100%;
  margin-top: 8px;
  padding: 0 20px;
  color: rgba($color-white, 0.6);
  line-height: 48px;
  border-radius: 4px;
  white-space: nowrap;

  &:hover {
    color: $color-white;
    background-color: rgba($color-back-primary-rgb, 0.04);
  }

  &.router-link-active {
    color: $color-theme;
    background-color: rgba($color-back-primary-rgb, 0.08);

    .menu-item-icon {
      color: $color-theme;
    }

    .menu-item-label {
      color: $color-theme;
    }

    & + .expand-collapse-icon {
      color: $color-theme;
    }
  }
}

.menu-item-child {
  display: block;
  margin-top: 8px;
  padding-left: 60px;
  color: rgba($color-white, 0.6);
  line-height: 40px;
  border-radius: 4px;

  &:hover {
    color: $color-white;
    background-color: rgba($color-back-primary-rgb, 0.04);
  }

  &.router-link-active {
    color: $color-theme;
    background-color: rgba($color-back-primary-rgb, 0.08);
  }
}
</style>
