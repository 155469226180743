const freshdeskHelpWidget = {
  install(Vue) {
    if (this.installed) return;

    this.installed = true;

    const show = () => {
      window.FreshworksWidget('show');
    };

    const hide = () => {
      if (window.FreshworksWidget) {
        window.FreshworksWidget('hide');
      } else {
        clearTimeout(this.timeout);
      }
    };

    const init = () => {
      if (!window.FreshworksWidget) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          const fragment = document.createDocumentFragment();
          const script = document.createElement('script');
          script.innerHTML = 'window.fwSettings={ "widget_id": 44000001228 };!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()';
          fragment.appendChild(script);
          const script2 = document.createElement('script');
          script2.src = '//widget.freshworks.com/widgets/44000001228.js';
          script2.setAttribute('async', '');
          script2.setAttribute('defer', '');
          script2.onload = () => {
            let populateName = '';
            let populateEmail = '';
            const user = JSON.parse(localStorage.getItem('mdt-user'));
            if (user) {
              const { name, lastName, email } = user;
              populateName = `${name} ${lastName}`;
              populateEmail = email;
            }
            window.FreshworksWidget('prefill', 'ticketForm', { name: populateName, email: populateEmail });
          };
          fragment.appendChild(script2);
          document.head.appendChild(fragment);
        }, 2000);
      } else {
        show();
      }
    };

    const widget = {
      init,
      show,
      hide,
    };

    Vue.prototype.$freshdeskHelpWidget = widget;
    Vue.freshdeskHelpWidget = widget;
  },
};

export default freshdeskHelpWidget;
