<template>
  <div
    class="unprotected-view-header flex-center-v"
    :class="{ 'with-border': $route.name !== 'Login' }">
    <NavigationLeftLogo v-if="$route.name === 'Login' || $route.name === 'PasswordResetForm'" />
    <div
      v-else
      class="title-logo-container">
      <img
        class="logo pointer"
        :src="logo.image"
        @click="logo.url && openWebsite()">
      <span class="investment-title">{{ investmentTitle }}</span>
    </div>
  </div>
</template>

<script>
import NavigationLeftLogo from '@/components/navigation-left/NavigationLeftLogo.vue';

export default {
  name: 'UnprotectedViewHeader',
  components: {
    NavigationLeftLogo,
  },
  computed: {
    logo() {
      const { publicPage } = this.$store.state;
      const { baseBackendUrl } = this.$store.getters;

      if (!publicPage?.headerLogo?.image) return {};

      return {
        url: publicPage.headerLogo.url,
        image: publicPage.headerLogo.image.startsWith('http')
          ? publicPage.headerLogo.image
          : `${baseBackendUrl}${publicPage.headerLogo.image}`,
      };
    },
    investmentTitle() {
      const { name } = this.$route;
      const { translate } = this.$options.filters;
      if (name === 'B2BProjectMemorandum') return translate('general_investment_memorandum');
      if (name === 'B2BProjectTeaser' || name === 'B2BProjectMemorandumPitch') return translate('general_investment_teaser');
      return '';
    },
  },
  methods: {
    openWebsite() {
      window.open(this.logo.url.includes('//') ? this.logo.url : `//${this.logo.url}`, '_blank').focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.unprotected-view-header {
  width: 100%;
  max-width: $unprotected-view-max-width;
  height: $login-header-height;
  margin: 0 auto;

  &.with-border {
    border-bottom: 1px solid $border-color;
  }

  .title-logo-container {
    display: flex;
    align-items: baseline;
  }

  .logo {
    display: block;
    max-height: 60px;
    border-radius: 4px;
    margin-right: 20px;
  }

  .investment-title {
    font-size: 24px;
  }
}
</style>
