export default {
  settingsAreSet(state) {
    state.settingsSet = true;
  },
  setFilters(state, params) {
    state.filters = [...params];
  },
  setActiveFilters(state, params) {
    state.activeFilters = { ...params };
  },
  setSearch(state, params) {
    state.search = params;
  },
  setCurrentPage(state, params) {
    state.currentPage = params;
  },
};
