const updateAlternativeFilters = (state, filters) => {
  state.alternativeObjects.filters.forEach((filter) => {
    const match = filters.find((item) => item.key === filter.key);
    if (match) filter.choices = match.choices;
  });
};

export default {
  setItems(state, data) {
    state.selectWidgetItems = data.tableData.body;
  },
  setSelected(state, selected) {
    state.selected = selected;
  },
  setEvaluation(state, evaluation) {
    state.evaluation = evaluation;
  },
  setPriorityObjects(state, priorityObjects) {
    state.priorityObjects = priorityObjects;
  },
  setAlternativeObjects(state, alternativeObjects) {
    if (Object.keys(state.alternativeObjects).length) {
      const filters = JSON.parse(JSON.stringify(alternativeObjects.filters));
      updateAlternativeFilters(state, filters);
      const updatedFilters = JSON.parse(JSON.stringify(state.alternativeObjects.filters));
      state.alternativeObjects = alternativeObjects;
      state.alternativeObjects.filters = updatedFilters;
    } else state.alternativeObjects = alternativeObjects;
  },
  setOffer(state, offer) {
    offer.comment = offer.comment || '';
    state.offer = offer;
  },
  setAssign(state, assign) {
    state.assign = assign;
    state.assign.comment = assign.comment || '';
  },
  deleteSelected(state, id) {
    state.selected = {};
    for (let i = 0; i < state.selectWidgetItems.length; i++) {
      if (state.selectWidgetItems[i]._id === id) {
        state.selectWidgetItems.splice(i, 1);
        break;
      }
    }
  },
  deleteApplicationsData(state) {
    state.isEvaluated = false;
    state.selected = {};
    state.evaluation = {};
    state.priorityObjects = {};
    state.alternativeObjects = {};
    state.offer = {};
    state.assign = {};
    state.detailView.loading = true;
    // state.selectWidgetItems = [];
  },
  setDetailViewLoader(state, loading) {
    state.detailView.loading = loading;
  },
  setFavorite(state, data) {
    state.selected.evaluation_favorite = data.checked;
  },
  updateApplicationProps(state, { application }) {
    // Update only few props on application (application state and grade)
    Object.keys(application).forEach((key) => {
      state.selected[key] = application[key];
    });
  },
  updateComments(state, comment) {
    state.evaluation.comments.list.push(comment);
  },
  deleteComment(state, commentId) {
    const index = state.evaluation.comments.list
      .findIndex((comment) => comment.id === commentId);
    state.evaluation.comments.list.splice(index, 1);
  },
  updateAdultValidity(state, adult) {
    const listItem = state.evaluation.checkList.find((cl) => cl.category === 'validity');
    const foundAdult = listItem?.adults.find((a) => a.id === adult.id);
    if (foundAdult && adult.data.data) {
      foundAdult.decision = adult.data.data.decision;
      foundAdult.subdecisions = adult.data.data.subdecisions;
    }
  },
  deleteCardDocument(state, params) {
    const card = state.evaluation.checkList.find((cl) => cl.category === 'documents');
    const document = card.documents.find((doc) => doc.key === params.key);

    const { files } = document;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.id === params.id) {
        files.splice(i, 1);
        document.numDocs -= 1;
        break;
      }
    }
  },
  setBuyingConfirmation(state, buyingConfirmation) {
    state.buyingConfirmation = buyingConfirmation;
    state.buyingConfirmation.comment = buyingConfirmation.comment || '';
  },
};
