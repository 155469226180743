import { render, staticRenderFns } from "./SettingsView.vue?vue&type=template&id=5127d763&scoped=true"
import script from "./SettingsView.vue?vue&type=script&lang=js"
export * from "./SettingsView.vue?vue&type=script&lang=js"
import style0 from "./SettingsView.vue?vue&type=style&index=0&id=5127d763&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5127d763",
  null
  
)

export default component.exports