<template>
  <div class="mdt-text-accordion">
    <div
      class="header flex-center-v"
      :class="{ opened: showText }">
      <span class="title">
        {{ title }}
      </span>
      <i
        class="fas icon-hover icon-open"
        :class="[showText ? 'fa-angle-up' : 'fa-angle-down']"
        @click="showText = !showText" />
    </div>
    <div
      v-if="showText"
      class="text"
      v-html="text" />
  </div>
</template>

<script>
export default {
  name: 'MdtTextAccordion',
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showText: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.mdt-text-accordion {
  padding: 16px 20px;
  border-radius: 8px;
  background-color: $color-white;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.05);

  .header {
    position: relative;
    justify-content: space-between;

    &.opened .icon-open {
      color: $color-text-primary;
      background-color: $color-back-light;
    }

    .title {
      font-weight: $font-weight-bold;
    }
  }

  .text {
    @include ckeditor-preview;
    margin-top: 18px;
  }
}
</style>
