export default {
  reorderPositions(array, oldIndex, newIndex) {
    if (newIndex >= array.length) {
      let k = newIndex - array.length + 1;
      while (--k) {
        array.push(undefined);
      }
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    return array;
  },
  // chunk([1, 2, 3, 4, 5], 2) => returns [[1, 2], [3, 4], [5]]
  chunk(array, num) {
    const returnArray = [];
    let tempArray = [];

    array.forEach((item, i) => {
      tempArray.push(item);
      if (((i + 1) % num === 0) || i === array.length - 1) {
        returnArray.push([...tempArray]);
        tempArray = [];
      }
    });

    return returnArray;
  },
};
