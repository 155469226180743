export default {
  saveUserAfterRegister(state, user) {
    state.savedUserAfterRegister = user;
  },
  setDmsData(state, dmsData) {
    state.dmsData = dmsData;
  },
  emptyUserAfterDmsRegister(state) {
    state.savedUserAfterRegister = null;
  },
};
