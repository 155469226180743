import Vue from 'vue';
import Vuex, { createLogger } from 'vuex';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';

import apartments from './modules/apartments';
import applications from './modules/applications';
import auth from './modules/auth';
import communication from './modules/communication';
import dashboardWidgets from './modules/dashboard-widgets';
import marketing from './modules/marketing';
import menu from './modules/menu';
import sidepanel from './modules/sidepanel';
import table from './modules/table';
import tenants from './modules/tenants';
import reporting from './modules/reporting';
import interfaces from './modules/interface';
import budget from './modules/budget';
import profileSettings from './modules/profile-settings';
import interestedPeople from './modules/interested-people';
import projectSettings from './modules/project-settings';
import waitlist from './modules/waitlist';
import viewingAppointments from './modules/viewing-appointments';
import publicObjectList from './modules/public-object-list';
import b2b from './modules/b2b';
import investors from './modules/investors';
import projects from './modules/projects';
import buildings from './modules/buildings';
import analytics from './modules/analytics';
import dms from './modules/dms';
import applicationForm from './modules/application-form';

Vue.use(Vuex);

const logger = createLogger({
  // eslint-disable-next-line no-unused-vars
  filter(mutation, stateBefore, stateAfter) {
    return mutation.type;
  },
  // eslint-disable-next-line no-unused-vars
  actionFilter(action, actionState) {
    return action.type;
  },
  logMutations: false,
  logActions: false,
});

export default new Vuex.Store({
  plugins: [logger],
  modules: {
    apartments: {
      ...apartments,
      modules: {
        applicationsTable: table,
      },
    },
    applications,
    auth,
    communication,
    dashboardWidgets,
    marketing,
    menu,
    sidepanel,
    table,
    tenants,
    reporting,
    interface: interfaces,
    budget,
    profileSettings,
    interestedPeople,
    projectSettings,
    waitlist,
    viewingAppointments,
    publicObjectList,
    b2b,
    investors,
    projects,
    buildings,
    analytics,
    dms,
    applicationForm,
  },
  actions,
  getters,
  mutations,
  state,
});
