import Vue from 'vue';
import apiServiceAsync from '@/api/apiServiceAsync';
import apiPointMaps from '@/store/model/api-point-maps/apiPointMaps';

const api = await apiServiceAsync();
export default {
  getAllData({ getters, dispatch }, params) {
    params = params || { id: getters.apartmentId };
    // Remove stashed data
    dispatch('deleteApartmentData');

    return dispatch('getObject', params).then((apartment) => {
      if (apartment.progress_state === 'with_offer') {
        dispatch('getWithOffer', params);
      } else if (apartment.progress_state === 'assigned') {
        dispatch('getAssigned', params);
      } else if (apartment.progress_state === 'rented') {
        dispatch('getRented', params);
      } else if (apartment.progress_state === 'reserved') {
        dispatch('getReserved', params);
      } else if (apartment.progress_state === 'sold') {
        dispatch('getSold', params);
      }

      return apartment;
    });
  },
  getObject({ commit }, params) {
    // set detail view loader to true
    commit('setDetailViewLoader', true);
    return api.get(`/api/v1/objects/${params.id}`).then((response) => {
      commit('setApartment', response.data);
      return response.data;
    }).finally(() => {
      // set detail view loader to false
      commit('setDetailViewLoader', false);
    });
  },
  getWithOffer({ commit }, params) {
    api.get(`/api/v1/objects/${params.id}/with-offer`).then((response) => {
      commit('setWithOffer', response.data);
    });
  },
  getAssigned({ commit }, params) {
    api.get(`/api/v1/objects/${params.id}/assigned-rented`).then((response) => {
      commit('setAssigned', response.data);
    });
  },
  getRented({ commit }, params) {
    api.get(`/api/v1/objects/${params.id}/assigned-rented`).then((response) => {
      commit('setRented', response.data);
    });
  },
  getReserved({ commit }, params) {
    api.get(`/api/v1/sales/objects/${params.id}/reserved-payment-info`).then((response) => {
      commit('setReserved', response.data);
    });
  },
  getSold({ commit }, params) {
    api.get(`/api/v1/sales/objects/${params.id}/reserved-payment-info`).then((response) => {
      commit('setSold', response.data);
    });
  },
  terminateApartment(state, params) {
    return api.post(`/api/v1/objects/${params.id}/terminate`)
      .then((response) => response.data);
  },
  deleteApartmentData({ commit }) {
    commit('deleteApartmentData');
  },
  reorderOptions({ getters }, params) {
    const { apartmentSettingsKey } = getters;
    const options = {
      value: params,
    };
    return api.post(`/api/v1/settings/${apartmentSettingsKey}/list`, options);
  },
  getEdit(state, params) {
    const apartmentId = params.id;
    return api.get(`/api/v1/objects/${apartmentId}/edit`)
      .then((response) => response.data);
  },
  postEdit(state, settings) {
    const apartmentId = settings.params.id;
    return api.post(`/api/v1/objects/${apartmentId}/edit`, settings.data)
      .then((response) => response.data);
  },
  getEditV2(state, params) {
    const apartmentId = params.id;
    return api.get(`/api/v2/objects/${apartmentId}/edit`)
      .then((response) => response.data);
  },
  postEditV2(state, settings) {
    const apartmentId = settings.params.id;
    return api.post(`/api/v2/objects/${apartmentId}/edit`, settings.data)
      .then((response) => response.data);
  },
  getOfferDetail(state, params) {
    const apartmentId = params.id;
    return api.get(`/api/v1/objects/${apartmentId}/offer-detail`)
      .then((response) => response.data);
  },
  saveOfferDetail(state, settings) {
    const apartmentId = settings.id;
    return api.post(`/api/v1/objects/${apartmentId}/offer-detail`, settings.params)
      .then((response) => response.data);
  },
  getUploadDocumentsSetup(state, params) {
    return api.get(params.path, { params: params.payload })
      .then((response) => response.data);
  },
  postUploadDocumentsSetup(state, settings) {
    return api.post(settings.path, settings.data, { params: settings.params })
      .then((response) => response.data);
  },
  downloadFlyer({ getters }) {
    const { apartmentId } = getters;
    return api.post(
      '/api/v1/generate-serve-posting-files?type=flyer',
      { targets: [apartmentId] },
      {
        responseType: 'arraybuffer',
        timeout: 0,
        cancelToken: Vue.axiosRequest.createCancelToken('mdtDownload'),
      },
    );
  },
  downloadMultipleFlyers({ dispatch }, apartmentIds) {
    dispatch('table/setLoading', true, { root: true });
    return api.post(
      '/api/v1/generate-serve-posting-files?type=flyer',
      { targets: apartmentIds },
      {
        responseType: 'arraybuffer',
        timeout: 0,
        cancelToken: Vue.axiosRequest.createCancelToken('mdtDownload'),
      },
    ).finally(() => { dispatch('table/setLoading', false, { root: true }); });
  },
  downloadBusinessCard({ getters }) {
    const { apartmentId } = getters;
    return api.post(
      '/api/v1/generate-serve-posting-files?type=business-card',
      { targets: [apartmentId] },
      {
        responseType: 'arraybuffer',
        timeout: 0,
        cancelToken: Vue.axiosRequest.createCancelToken('mdtDownload'),
      },
    );
  },
  downloadMultipleBusinessCards({ dispatch }, apartmentIds) {
    dispatch('table/setLoading', true, { root: true });
    return api.post(
      '/api/v1/generate-serve-posting-files?type=business-card',
      { targets: apartmentIds },
      {
        responseType: 'arraybuffer',
        timeout: 0,
        cancelToken: Vue.axiosRequest.createCancelToken('mdtDownload'),
      },
    ).finally(() => { dispatch('table/setLoading', false, { root: true }); });
  },
  downloadCsvAbaImmo({ dispatch }, tenantIds) {
    dispatch('table/setLoading', true, { root: true });
    return api.get(
      '/api/v1/objects/assignment/export-csv-abaimmo',
      {
        params: {
          targets: tenantIds,
        },
      },
      {
        params: {
          targets: tenantIds,
        },
      },
      {
        responseType: 'arraybuffer',
        timeout: 0,
        cancelToken: Vue.axiosRequest.createCancelToken('mdtDownload'),
      },
    ).finally(() => { dispatch('table/setLoading', false, { root: true }); });
  },
  getCreateWizardData({ commit }, type) {
    return api.get(`/api/v1/objects/${type}/create`).then((response) => {
      switch (type) {
        case 'apartment':
        case 'house':
          commit('setCreateApartmentWizardData', response.data);
          break;
        case 'parkingspace':
          commit('setCreateParkingWizardData', response.data);
          break;
        case 'plot':
          commit('setCreatePlotWizardData', response.data);
          break;
        case 'other':
          commit('setCreateOtherWizardData', response.data);
          break;
        case 'craft':
          commit('setCreateCraftWizardData', response.data);
          break;
        default:
          break;
      }
    });
  },
  createWizardObject(state, params) {
    return api.post(`/api/v1/objects/${params.type}/create`, params.publishData).then((response) => response.data);
  },
  clearCreateWizardCachedValues({ commit }) {
    commit('clearCreateWizardCachedValues');
  },
  validateObjectProps(state, params) {
    return api.post('/api/v1/objects/apartments/validation', params);
  },
  // Added because of M20-966, plan is to move this action in the future
  downloadAdCosts(state, files) {
    const uploadedFiles = files.map((file) => ({
      url: file.rawUrl,
      plattform: file.selectedCategory,
      flattrate: file.flatrate,
      tax_included: file.taxIncluded,
      average: file.average,
    }));
    const data = { uploadedFiles };

    return api.post('/api/v1/reporting/adv-reports', data, { timeout: 0 });
  },
  setToRented(state, params) {
    return api.post('/api/v1/objects/set-to-rented', params)
      .then((response) => response.data);
  },
  saveCreateWizardPageData({ commit }, params) {
    commit('saveCreateWizardPageData', params);
  },
  deleteObject({ commit, getters }) {
    const { apartmentId } = getters;
    return api.delete(`/api/v1/objects/${apartmentId}`).then((response) => {
      commit('deleteApartmentData');
      return response.data;
    });
  },
  getApplicationTabsSetup(state, params) {
    return api.get(params.path).then((response) => response.data);
  },
  setToSold(state, params) {
    const path = `/api/v1/sales/objects/${apiPointMaps.mappedObjectPaths[Vue.router.currentRoute.name]}`;
    return api.post(path, params).then((response) => response.data);
  },
  getObjectPackages(state, params) {
    return api.get(`/api/v1/sales/objects/${params.objectId}/package-info`).then((response) => response.data);
  },
  postObjectPackages(state, settings) {
    return api.post(`/api/v1/sales/objects/${settings.objectId}/package-info`, settings.params).then((response) => response.data);
  },
  getBulkFileImport() {
    return api.get('api/v1/objects/import-bulk-files').then((response) => response.data);
  },
  postBulkFileImport(state, payload) {
    return api.post('api/v1/objects/import-bulk-files', payload).then((response) => response.data);
  },
  postObjectList(state, payload) {
    return api.post('api/v1/objects/bulk-import', payload, { timeout: 0 }).then((response) => response.data);
  },
  getObjectList(state, params) {
    return api.get('api/v1/objects/bulk-import', { params }).then((response) => response.data);
  },
  setParentRouteName({ commit }, parentRoute) {
    commit('setParentRouteName', parentRoute);
  },
};
