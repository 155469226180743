<template>
  <MdtModal
    v-if="itemKey === modalKey"
    title=""
    type="custom"
    width="550px"
    header-height="60px"
    footer-height="60px"
    :hide-footer-border="true"
    @close="$emit('close')"
    @cancel="$emit('cancel')">
    <div
      class="flex flex-column p-h-60">
      <div
        class="text-description text-center"
        v-html="description" />
    </div>
    <div
      class="text-center p-t-30">
      <button
        type="button"
        :disabled="btnDisabled"
        :class="{ 'btn-disabled': btnDisabled }"
        class="btn btn-primary contact-button"
        @click="$emit('click')">
        {{ 'general_contact_me' | translate }}
      </button>
    </div>
    <template #body-header>
      <div class="contract-image">
        <img :src="image">
      </div>
      <div class="text-center header-title">
        {{ title }}
      </div>
    </template>
  </MdtModal>
</template>

<script>
export default {
  name: 'MdtFeatureUnavailable',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    description: {
      type: String,
      default: '',
      required: true,
    },
    image: {
      type: String,
      default: '',
    },
    btnDisabled: {
      type: Boolean,
      default: false,
    },
    itemKey: {
      type: String,
      default: '',
      required: true,
    },
    modalKey: {
      type: String,
      default: '',
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.header-title {
  color: $color-text-primary;
  font-size: 24px;
  font-weight: $font-weight-bold;
  padding-top: 28px;
  padding-bottom: 20px;
}

.text-description {
  flex: 0.5;
  font-size: 16;
}

.contract-image {
  flex: 0.5;
  text-align: center;
  img {
    max-width: 250px;
  }
}

.p-t-30 {
  padding-top: 30px;
}

.p-h-60 {
  padding:0 60px;
}
</style>
