<template>
  <div
    v-tooltip.right="menuCollapsed ? msg.expand : msg.collapse"
    class="main-navigation-resize-button flex-center"
    @click="menuCollapsed = !menuCollapsed"
    @mouseleave="collapsedHovered ? collapsedHovered = false : null">
    <i
      v-if="menuCollapsed"
      class="fas fa-angle-right icon-right" />
    <i
      v-else
      class="fas fa-angle-left icon-left" />
  </div>
</template>

<script>
import { ls } from '@/utility';

export default {
  name: 'NavigationLeftResizeButton',
  props: {
    collapsed: {
      type: Boolean,
      required: true,
    },
    hovered: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      msg: {
        collapse: this.$options.filters.translate('general_collapse'),
        expand: this.$options.filters.translate('general_expand'),
      },
    };
  },
  computed: {
    menuCollapsed: {
      get() {
        return this.collapsed;
      },
      set(value) {
        this.$store.dispatch('menu/setMainMenuCollapsed', value);
        ls.mainMenu.set({ collapsed: value });
      },
    },
    collapsedHovered: {
      get() {
        return this.hovered;
      },
      set(value) {
        this.$store.dispatch('menu/setMainMenuCollapsedHovered', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.main-navigation-resize-button {
  position: absolute;
  top: calc(#{$menu-top-height} - 12px);
  right: -12px;
  width: 25px;
  height: 25px;
  background-color: $color-back-primary;
  color: $color-text-secondary;
  border-radius: 50%;
  box-shadow: 0 0 4px #00000077;
  cursor: pointer;
  z-index: 1;

  &:hover {
    background-color: $color-text-important;
    color: $color-white;
  }

  i {
    &.icon-left { padding-right: 2px; }
    &.icon-right { padding-left: 2px; }
  }
}
</style>
