<template>
  <div
    class="mdt-datepicker-range"
    :class="direction">
    <div class="from">
      <MdtDatepicker
        v-model="selectedRange[0]"
        :label="'general_by' | translate"
        :required="required"
        :hide-optional="hideOptional"
        :errors="useValidation ? fromDateValidationErrors() : []"
        @input="emitUpdate" />
    </div>
    <div class="to">
      <MdtDatepicker
        v-model="selectedRange[1]"
        :label="'general_till' | translate"
        :required="required"
        :right-aligned="direction === 'row'"
        :hide-optional="hideOptional"
        :errors="useValidation ? toDateValidationErrors() : []"
        @input="emitUpdate" />
    </div>
  </div>
</template>

<script>
import { helpers } from '@/utility';

export default {
  name: 'MdtDatepickerRange',
  props: {
    selected: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String,
      default: 'column',
      validator: (value) => ['column', 'row'].includes(value),
    },
    hideOptional: {
      type: Boolean,
      default: false,
    },
    useValidation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedRange: this.selected || [],
      msg: {
        startEndDateCannotBeTheSame: this.$options.filters.translate('admin_start_and_end_date_cannot_be_the_same'),
        startDateIsLaterThanEndDate: this.$options.filters.translate('admin_start_date_is_later_than_the_end_date'),
        endDateIsEarlierThanStartDate: this.$options.filters.translate('admin_end_date_is_earlier_than_start_date'),
      },
    };
  },
  watch: {
    selected: {
      immediate: true,
      deep: true,
      handler(selected) {
        this.selectedRange = selected || [];
      },
    },
  },
  methods: {
    isValid() {
      if (!this.useValidation) return true;
      return !this.fromDateValidationErrors().length && !this.toDateValidationErrors().length;
    },
    fromDateValidationErrors() {
      let fromDate = this.selectedRange[0];
      let toDate = this.selectedRange[1];
      if (helpers.isUndefined(fromDate) || helpers.isUndefined(toDate)) return [];
      fromDate = moment(fromDate, 'DD.MM.YYYY');
      toDate = moment(toDate, 'DD.MM.YYYY');
      const diffDays = fromDate.diff(toDate, 'days');
      if (diffDays === 0) return [this.msg.startEndDateCannotBeTheSame];
      if (diffDays > 0) return [this.msg.startDateIsLaterThanEndDate];
      return [];
    },
    toDateValidationErrors() {
      let fromDate = this.selectedRange[0];
      let toDate = this.selectedRange[1];
      if (helpers.isUndefined(fromDate) || helpers.isUndefined(toDate)) return [];
      fromDate = moment(fromDate, 'DD.MM.YYYY');
      toDate = moment(toDate, 'DD.MM.YYYY');
      const diffDays = toDate.diff(fromDate, 'days');
      if (diffDays === 0) return [this.msg.startEndDateCannotBeTheSame];
      if (diffDays < 0) return [this.msg.endDateIsEarlierThanStartDate];
      return [];
    },
    emitUpdate() {
      this.$emit('input', this.selectedRange);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-datepicker-range {
  cursor: default;

  .from {
    margin-bottom: 20px;
  }

  &.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .from,
    .to {
      flex: 1;
    }

    .from {
      margin: 0 5px 0 0;
    }

  }

  ::v-deep .input-label {
    text-transform: capitalize;
  }
}
</style>
