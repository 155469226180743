<template>
  <div class="mdt-range-slider">
    <div
      v-if="label"
      class="label">
      {{ label }}
    </div>
    <VueSlider
      ref="slider"
      v-model="value"
      :min="min"
      :max="max"
      :interval="step"
      :height="1"
      :dot-size="12"
      tooltip="none"
      :contained="true"
      :rail-style="railStyle"
      :process-style="processStyle"
      :dot-style="dotStyle"
      @change="onChange">
      <div class="tooltip-values">
        <span>{{ value[0] }}</span>
        <span>{{ value[1] }}</span>
      </div>
    </VueSlider>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import variables from '@/scss/abstract/_variablesExport.module.scss';

export default {
  name: 'MdtRangeSlider',
  components: {
    VueSlider,
  },
  model: {
    prop: 'selected',
  },
  props: {
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: [this.selected[0] || this.min, this.selected[1] || this.max],
      railStyle: {
        'background-color': '#DCDDDE',
      },
      processStyle: {
        position: 'relative',
        top: '-1px',
        height: '3px',
        'background-color': variables.colorTheme,
      },
      dotStyle: {
        'background-color': variables.colorTheme,
        'box-shadow': 'none',
        'z-index': 100000,
      },
    };
  },
  methods: {
    onChange(e) {
      this.$emit('input', e);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../node_modules/vue-slider-component/theme/default.css';

.mdt-range-slider {
  width: 100%;
  padding-bottom: 26px; // Add padding because slider tooltip adds padding

  .label {
    margin-bottom: 10px;
    color: $color-text-secondary;
    font-size: 14px;
    line-height: 14px;
  }

  ::v-deep {
    // Change tooltip placement from center to sides
    .vue-slider-rail {
      .tooltip-values {
        display: flex;
        justify-content: space-between;
        margin-top: 7px;
        color: $color-text-secondary;
      }
    }
  }
}
</style>
