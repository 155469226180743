import Vue from 'vue';
import button from './button';
import form from './form';
import global from './global';
import modal from './modal';
import notify from './notify';

Vue.use(button);
Vue.use(form);
Vue.use(global);
Vue.use(modal);
Vue.use(notify);

export default {};
