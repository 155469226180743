<template>
  <div
    class="mdt-textarea"
    :class="{ 'has-error': textareaHasError, disabled }">
    <div
      v-if="label"
      class="textarea-label">
      <div>
        {{ label }}
        <span
          v-if="!required && !hideOptional"
          class="textarea-label-optional">
          ({{ 'admin_marketing_optional' | translate }})
        </span>
        <i
          v-if="required"
          class="fa-asterisk field-required" />
      </div>
      <i
        v-if="tooltip"
        v-tooltip="tooltip"
        class="far fa-info-circle info-icon" />
    </div>
    <div class="textarea-wrapper">
      <div v-if="!preview">
        <textarea
          :value="value"
          :required="required"
          :placeholder="placeholder"
          :rows="rows"
          :disabled="disabled"
          :readonly="readonly"
          spellcheck="false"
          @input="onInput($event.target.value)" />
      </div>
      <span v-else>{{ value }}</span>
    </div>
    <div
      v-if="clientErrors.length || serverErrors.length"
      class="input-errors">
      <span class="client-errors">
        {{ clientErrors.join('\n') }}
        {{ clientErrors.length && serverErrors.length ? '\n' : '' }}
      </span>
      <span class="server-errors">
        {{ serverErrors.join('\n') }}
      </span>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'MdtTextarea',
  mixins: [validationMixin],
  props: {
    value: {
      required: true,
      validator: (value) => {
        const isString = typeof value === 'string';
        const isNull = value === null;
        return isString || isNull;
      },
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 3,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    hideOptional: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputValue: this.value,
      serverErrors: this.errors,
    };
  },
  computed: {
    clientErrors() {
      const errors = [];
      if (this.$v.inputValue.$dirty && !this.$v.inputValue.required) {
        errors.push(this.$options.filters.translate('general_field_is_required'));
      }
      return errors;
    },
    textareaHasError() {
      return this.$v.inputValue.$error || !!this.serverErrors.length;
    },
  },
  watch: {
    value(value) {
      this.inputValue = value;
    },
    errors(value) {
      this.serverErrors = value;
    },
  },
  validations() {
    return {
      inputValue: {
        required: this.required ? required : false,
      },
    };
  },
  methods: {
    reset() {
      this.onInput('');
      this.$v.$reset();
    },
    setTouched() {
      this.$v.inputValue.$touch();
    },
    isValid() {
      this.setTouched();
      return !this.textareaHasError;
    },
    onInput(value) {
      this.setTouched();
      this.inputValue = value;
      this.$emit('input', value);

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');

      // Reset server errors
      this.serverErrors = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-textarea {
  text-align: left;
  // show textarea's default browser's scrollbar fix for Microsoft Edge
  -ms-overflow-style: auto;

  &.has-error {
    color: $color-danger;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgba($color-danger, 0.6);
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: rgba($color-danger, 0.6);
    }
    :-moz-placeholder { /* Firefox 18- */
      color: rgba($color-danger, 0.6);
    }

    textarea {
      background-color: rgba($color-danger, 0.05);
      border: 2px solid $color-danger;

      &:hover,
      &:focus {
        border-color: $color-danger;
      }

      &:focus {
        margin-bottom: 0;
        padding: 10px;
      }
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;

    .textarea-wrapper {
      textarea {
        border-color: $color-text-secondary;
      }
    }
  }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $color-back-basic;
  font-size: 16px;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $color-back-basic;
  font-size: 16px;
}
:-moz-placeholder { /* Firefox 18- */
  color: $color-back-basic;
  font-size: 16px;
}

.textarea-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  color: $color-text-secondary;
  font-size: 14px;
  line-height: 14px;

  .field-required {
    color: $color-danger;
    margin-left: 4px;
  }
}

.textarea-wrapper {
  position: relative;

  textarea {
    display: block;
    padding: 10px;
    width: 100%;
    border: 1px solid $border-color;
    border-radius: 4px;
    background-color: $color-back-primary;
    color: $color-text-primary;
    font-size: 1rem;
    resize: none;
    line-height: 1.5;
    outline: 0;

    &:hover {
      border-color: $color-text-secondary;
    }

    &:focus:not[readonly] {
      margin-bottom: -1px;
      padding: 9px 10px 10px 9px;
      border: 2px solid $color-theme;
    }

    &[readonly] {
      border-color: $border-color;
      cursor: not-allowed !important;
    }
  }
}

.input-errors {
  padding-top: 4px;
  font-size: 12px;
  font-weight: $font-weight-normal;
  white-space: pre-line;
}
</style>
