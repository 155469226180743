<template>
  <div class="archived-project">
    <img
      src="@/assets/images/archived.svg"
      class="page-image">
    <div class="archived-page-text">
      <h2 class="archived-page-title">
        {{ tenantStatus.title }}
      </h2>
      <p v-html="infoMsg" />
      <p>{{ tenantStatus.msg2 }} support@emonitor.ch.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArchivedProject',
  props: {
    tenantStatus: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    tenantName() {
      return window.location.host.split('.')[0];
    },
    infoMsg() {
      const displayTenant = `<strong class="archived-tenant-name">${this.tenantName}</strong>`;
      return this.tenantStatus.msg1.replace('[d]', displayTenant);
    },
  },
};
</script>

<style lang="scss">
.archived-page-text {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color-text-secondary;

  .archived-page-title {
    margin-bottom: 16px;
  }

  .archived-tenant-name {
    text-transform: capitalize;
    color: $color-text-primary;
  }
}
</style>
