<template>
  <div class="mdt-lightbox">
    <vue-easy-lightbox
      move-disabled
      :visible="lightbox.visible"
      :imgs="lightbox.images"
      :index="lightbox.index"
      @hide="hideLightbox" />
  </div>
</template>

<script>
export default {
  name: 'MdtLightbox',
  computed: {
    lightbox() {
      return this.$store.state.lightbox;
    },
  },
  methods: {
    hideLightbox() {
      this.$store.dispatch('hideLightbox');
    },
  },
};
</script>
