<template>
  <div
    v-if="viewReady"
    class="template-default">
    <MdtNotify />
    <div class="main-wrapper settings-view">
      <div class="main-navigation-left">
        <NavigationLeft
          :menu-collapsed="false"
          :collapsed-hovered="false"
          mode="settings" />
      </div>
      <div class="main-center-container">
        <div class="main-navigation-top">
          <NavigationTop />
        </div>
        <div class="main-container">
          <RouterView class="main-content-router" />
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="view-not-ready flex-center">
    <MdtLoader />
  </div>
</template>

<script>
import NavigationLeft from '@/components/navigation-left/NavigationLeft.vue';
import NavigationTop from '@/components/navigation-top/NavigationTop.vue';
import { MELON_LANGUAGE_DE } from '@/utility/constants';

export default {
  name: 'SettingsView',
  components: {
    NavigationLeft,
    NavigationTop,
  },
  data() {
    return {
      viewReady: false,
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
  created() {
    if (!this.$store.state.bootstrap.bootstrapSet) {
      this.$store.dispatch('getBootstrap')
        .then(() => {
          this.viewReady = true;
          if (this.language === MELON_LANGUAGE_DE) {
            this.$freshdeskHelpWidget.init();
          }
        });
    } else {
      this.viewReady = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.view-not-ready {
  height: 100vh;
}

.main-container {
  background: $color-back-primary;

  .main-content-router {
    width: 100%;
    transition-duration: .4s;
    transition-property: width;
    transition-timing-function: ease-out;
  }
}

::v-deep {
  .navigation-top-left {
    visibility: hidden;
  }
}
</style>
