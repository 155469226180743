<template>
  <div class="mdt-notify">
    <transition-group name="slide-left">
      <div
        v-for="item in list"
        :key="item.id"
        class="notification"
        :class="item.type"
        @click="removeItem(item.id)"
        @mouseenter="pauseTimer(item)"
        @mouseleave="resumeTimer(item)">
        <div class="notification-icon flex-center-v">
          <i
            :class="{
              'fas fa-check-circle': item.type === 'success',
              'far fa-info-circle': item.type === 'info',
              'fas fa-exclamation-circle': ['danger', 'warning'].includes(item.type),
            }" />
        </div>
        <div class="notification-content">
          <div
            v-if="item.title"
            class="notification-title text-cut"
            v-html="item.title" />
          <div
            class="notification-body"
            @click="handleClick"
            v-html="item.text" />
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { helpers } from '@/utility';

export default {
  name: 'MdtNotify',
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.$notify.eventsNotify.$on('add', this.addItem);
    this.$notify.eventsNotify.$on('close', this.removeItem);
  },
  methods: {
    addItem(evt) {
      const {
        id,
        title,
        text,
        type,
        duration,
      } = evt;

      const item = {
        id: id || helpers.uniqueId('notify-'),
        title,
        text,
        type: type || 'info',
        timer: {
          id: null,
          duration: duration || 5000,
        },
        destroyed: false,
      };

      item.timer.id = setTimeout(() => {
        this.removeItem(item.id);
      }, item.timer.duration);
      this.list.push(item);
    },
    removeItem(id) {
      const index = this.list.findIndex((item) => item.id === id);
      const [item] = this.list.splice(index, 1);
      if (item) item.destroyed = true;
      clearTimeout(item?.timer?.id);
    },
    pauseTimer(item) {
      clearTimeout(item.timer.id);
    },
    resumeTimer(item) {
      if (item.destroyed) return;
      item.timer.id = setTimeout(() => {
        this.removeItem(item.id);
      }, item.timer.duration);
    },
    handleClick(event) {
      const element = event.srcElement;
      const { dataset } = element;
      if (dataset.routeName) {
        const params = {
          id: dataset.routeParamsId || undefined,
        };
        const route = {
          name: dataset.routeName,
          params,
        };
        this.goToRoute(route);
      }
    },
    goToRoute(route) {
      this.$router.push({ name: route.name, params: route.params });
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-notify {
  position: fixed;
  top: 100px;
  right: 40px;
  font-size: 0.875rem;
  z-index: 1100;

  .notification {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 260px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #000;
    border-radius: 8px;
    cursor: pointer;

    &.info {
      background-color: $color-info-light;
      border-color: $color-info;
      .notification-icon > i { color: $color-info; }
    }

    &.success {
      background-color: $color-success-light;
      border-color: $color-success;
      .notification-icon > i { color: $color-success; }
    }

    &.warning {
      background-color: $color-warning-light;
      border-color: $color-warning;
      .notification-icon > i { color: $color-warning; }
    }

    // Error msg
    &.danger {
      background-color: $color-danger-light;
      border-color: $color-danger;
      .notification-icon > i { color: $color-danger; }
    }

    &:hover {
      box-shadow: 0 0 2px $color-theme;
    }
  }

  .notification-icon {
    width: 30px;
    padding-right: 10px;
    font-size: 1.125rem;
    flex-shrink: 0;
  }

  .notification-content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);

    .notification-title {
      font-size: 1rem;
      font-weight: $font-weight-bold;
    }

    .notification-body {
      word-break: break-word;

      ::v-deep .url {
        background-color: transparent;
        border-color: transparent;
        color: #0052cc;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          color: #0065ff;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
