import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
export default {
  getHomeDashboard({ commit }, config) {
    return api.get('/api/v1/dashboard/get-dashboard-widgets', { timeout: 0 }).then((response) => {
      response.data.widgets.config = config;
      commit('setActiveWidgets', response.data.widgets);
      return response.data.detailsSidepanel;
    });
  },
  setActiveWidgetsStateReload({ commit }) {
    commit('setActiveWidgetsStateReload');
  },
  setActiveWidgetsStateDone({ commit }) {
    commit('setActiveWidgetsStateDone');
  },
  setActiveWidgetsStateError({ commit }) {
    commit('setActiveWidgetsStateError');
  },
  clearActiveWidgetState({ commit }) {
    commit('clearActiveWidgetState');
  },
  reorderWidgets(state, params) {
    return api.post(`/api/v1/settings/${params.key}/list`, params.data);
  },
};
