<template>
  <div class="mdt-wizard-menu">
    <div
      v-for="(page, index) in wizardData"
      :key="index"
      class="wizard-menu">
      <span
        class="menu-name"
        :class="{
          checked: index < wizardActivePage,
          pointer: index <= wizardActivePage && index + 1 !== wizardActivePage,
        }"
        @click="onMenuClick(index)">
        {{ page.menu.displayName }}
      </span>
      <div
        class="menu-icon-wrapper flex-center"
        :class="{
          checked: index < wizardActivePage,
          pointer: index <= wizardActivePage && index + 1 !== wizardActivePage,
        }"
        @click="onMenuClick(index)">
        <i
          v-if="publishing || wizardActivePage > index + 1"
          class="fas fa-check-circle menu-icon" />
        <p v-else-if="page.menu.image === undefined">
          {{ index + 1 }}
        </p>
        <img
          v-else-if="page.menu.image.type === 'img' && page.menu.image.value"
          :src="page.menu.image.value">
        <i
          v-else-if="page.menu.image.type === 'icon' && page.menu.image.value"
          class="fas"
          :class="[page.menu.image.value]" />
        <p
          v-else-if="page.menu.image.type === 'txt' && page.menu.image.value">
          {{ page.menu.image.value }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MdtWizardMenu',
  props: {
    wizardData: {
      type: Array,
      required: true,
    },
    wizardActivePage: {
      type: Number,
      required: true,
    },
    publishing: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onMenuClick(index) {
      if ((index + 1) === this.wizardActivePage || index > this.wizardActivePage) return;
      if (index === this.wizardActivePage) this.$emit('goForward');
      else this.$emit('goToPage', index + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-wizard-menu {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-width: 260px;
  height: 100%;
  padding: #{$modal-header-height + 10px} 0 #{$modal-footer-height + 40px} 77px;
}

.wizard-menu {
  height: 50px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 50px;
  padding-right: 40px;
  white-space: nowrap;
  cursor: default;

  &:last-child {
    margin-bottom: 0;
  }
}

.menu-name {
  font-size: 1rem;
  font-weight: $font-weight-bold;
  color: $color-text-secondary;

  &.checked {
    color: $color-theme;
  }

  &.pointer:hover {
    opacity: 0.75;
  }
}

.menu-icon-wrapper {
  position: absolute;
  right: -25px;
  width: 50px;
  height: 50px;
  background-color: $color-back-basic;
  color: $color-text-secondary;
  border-radius: 50%;
  font-size: 20px;
  font-weight: $font-weight-bold;

  &.checked {
    background-color: $color-theme;
    color: $color-white;
  }

  &.pointer:hover p {
    color: rgba($color-text-secondary-rgb, 0.75);
  }

  .menu-icon {
    font-size: 24px;
  }
}
</style>
