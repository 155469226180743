export default {
  setTenant(state, tenant) {
    state.tenant = tenant;
  },
  setContract(state, contract) {
    contract.comment = contract.comment || '';
    state.contract = contract;
  },
  setQuit(state, quit) {
    state.quit = quit;
  },
  deleteTenantData(state) {
    state.tenant = {};
    state.contract = {};
    state.quit = {};
    state.detailView.loading = true;
  },
  setDetailViewLoader(state, loading) {
    state.detailView.loading = loading;
  },
  setFavorite(state, favorite) {
    state.tenant.evaluation_favorite = favorite.checked;
  },
};
