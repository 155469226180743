import { ls } from '@/utility';

export default {
  setBootstrap(state, bootstrap) {
    state.bootstrap = bootstrap;
    // setBootstrap user from bootstrap call
    if (bootstrap.user) {
      state.bootstrap.bootstrapSet = true;
      // get current user from local storage
      const user = ls.user.get();
      // update user from boostrap user
      user.id = bootstrap.user.id;
      user.name = bootstrap.user.first_name;
      user.lastName = bootstrap.user.last_name;
      user.email = bootstrap.user.email;
      user.role = bootstrap.user.role;
      user.permissions = bootstrap.user.permissions;
      user.features = bootstrap.settings?.availableFeatures || {};
      user.username = bootstrap.user.username;
      // update user in state too
      state.auth.user = user;
      // set updated user to local storage
      ls.user.set(user);
    } else { // setBootstrap user from init call
      // get current user from local storage
      const user = ls.user.get();
      user.features = bootstrap.settings?.availableFeatures || {};
      // update user in state too
      state.auth.user = user;
      // set updated user to local storage
      ls.user.set(user);
    }
  },
  setSiteTitle(state, siteTitle) {
    state.siteTitle = siteTitle;
  },
  setPublicPageData(state, publicPageData) {
    state.favicon = publicPageData.favicon;
    state.publicPage = publicPageData;
  },
  showLightbox(state, settings) {
    state.lightbox.images = settings.images;
    state.lightbox.index = settings.index || 0;
    state.lightbox.visible = true;
  },
  hideLightbox(state) {
    state.lightbox.images = [];
    state.lightbox.index = 0;
    state.lightbox.visible = false;
  },
  resetBootstrap(state) {
    state.bootstrap = {};
  },
  setLanguage(state, language) {
    state.language = language || 'de';
    // Update LS language
    ls.language.set(state.language);
  },
  updateBootstrapMessages(state, messages) {
    messages = JSON.parse(JSON.stringify(messages));
    messages.platforms = undefined;
    state.bootstrap.messages = Object.assign(state.bootstrap.messages, messages);
  },
  updateDocumentScrollCounter(state) {
    state.documentScroll.counter++;
  },
  updateMdtAppResize(state) {
    state.mdtAppResize++;
  },
  setCountryCodeList(state, payload) {
    state.countryCodeList = payload;
  },
};
