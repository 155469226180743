import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
export default {
  getAllData({ dispatch, getters }, data) {
    const params = data || { id: getters.investorId };
    // Remove stashed data
    dispatch('deleteInvestorsData');
    return dispatch('getInvestor', params).then((investor) => investor);
  },
  getInvestor({ commit }, params) {
    commit('setDetailViewLoader', true);
    return api.get(`/api/v1/investors/${params.id}`).then((response) => {
      commit('setInvestor', response.data);
      return response.data;
    }).finally(() => {
      commit('setDetailViewLoader', false);
    });
  },
  getInvestorWithoutLoader({ commit }, params) {
    return api.get(`/api/v1/investors/${params.id}`).then((response) => {
      commit('setInvestor', response.data);
      return response.data;
    });
  },
  deleteInvestorsData({ commit }) {
    commit('deleteInvestorsData');
  },
  reorderOptions({ getters }, value) {
    const { investorSettingsKey } = getters;
    return api.post(`/api/v1/settings/${investorSettingsKey}/list`, { value });
  },
  getCreateInvestor() {
    return api.get('/api/v1/investors/create').then((response) => response.data);
  },
  createInvestor(state, params) {
    return api.post('/api/v1/investors/create', params).then((response) => response.data);
  },
  bulkDeactivate(state, targets) {
    return api.post('/api/v1/investors/', { targets, bulkAction: 'deactivate' }).then((response) => response.data);
  },
  bulkActivate(state, targets) {
    return api.post('/api/v1/investors/', { targets, bulkAction: 'activate' }).then((response) => response.data);
  },
  getEdit(state, { id }) {
    return api.get(`/api/v1/investors/${id}/edit`).then((response) => response.data);
  },
  postEdit(state, settings) {
    return api.post(`/api/v1/investors/${settings.params.id}/edit`, settings.data).then((response) => response.data);
  },
  getWaitlistRequest(state, investorId) {
    return api.get(`/api/v1/b2b/waitlist/${investorId}/project-request-form`).then((response) => response.data);
  },
  saveWaitlistRequest(state, { investorId, params }) {
    return api.post(`/api/v1/b2b/waitlist/${investorId}/project-request-form`, params).then((response) => response.data);
  },
  getSendToWaitlistMail(state, investorId) {
    return api.get(`api/v1/b2b/communication/project-request-form/${investorId}`).then((response) => response.data);
  },
  postSendToWaitlistMail(state, { investorId, params }) {
    return api.post(`api/v1/b2b/communication/project-request-form/${investorId}`, params).then((response) => response.data);
  },
  getWaitlistRequestPublic(state, { slug, token }) {
    return api.get(`api/v1/b2b/waitlist/action/${slug}/${token}/investor-waitlist-request-edit`).then((response) => response.data);
  },
  saveWaitlistRequestPublic(state, { slug, token, params }) {
    return api.post(`api/v1/b2b/waitlist/action/${slug}/${token}/investor-waitlist-request-edit`, params).then((response) => response.data);
  },
  getWaitlistRequestPublicPreview(state, { slug, token }) {
    return api.get(`api/v1/b2b/waitlist/action/${slug}/${token}/investor-waitlist-request-preview`).then((response) => response.data);
  },
  uploadOfferManually(state, { uploadOfferId, params }) {
    return api.post(`api/v1/investors/${uploadOfferId}/upload-investor-offer`, params).then((response) => response.data);
  },
  getProposalMailData(state, { investorId, projectId }) {
    return api.get(`/api/v1/investors/${investorId}/${projectId}/offer-project`).then((response) => response.data);
  },
  sendProposalMail(state, { investorId, projectId, params }) {
    return api.post(`/api/v1/investors/${investorId}/${projectId}/offer-project`, params).then((response) => response.data);
  },
  getAssignProjectData(state, { projectId, investorId }) {
    return api.get(`/api/v1/b2b/objects/assignment/${projectId}/${investorId}/project`).then((response) => response.data);
  },
  assignProject(state, { projectId, investorId }) {
    return api.post(`/api/v1/b2b/objects/assignment/${projectId}/${investorId}/project`).then((response) => response.data);
  },
  getOfferSidepanel(state, params) {
    return api.get(`/api/v1/investors/${params.id}/offer-sidepanel`).then((response) => response.data);
  },
  deleteOffer(state, { uploadOfferId, payload }) {
    return api.post(`api/v1/investors/${uploadOfferId}/upload-investor-offer`, payload).then((response) => response.data);
  },
  updateOfferEvaluation(state, { uploadOfferId, payload }) {
    return api.post(`/api/v1/investors/${uploadOfferId}/offer-sidepanel`, payload).then((response) => response.data);
  },
  getNboEvaluationAcceptMailData(state, applyId) {
    return api.get(`/api/v1/b2b/communication/nbo-accepted/${applyId}`).then((response) => response.data);
  },
  sendNboEvaluationAcceptMail(state, { applyId, payload }) {
    return api.post(`/api/v1/b2b/communication/nbo-accepted/${applyId}`, payload).then((response) => response.data);
  },
  getNboEvaluationRejectMailData(state, applyId) {
    return api.get(`/api/v1/b2b/communication/nbo-declined/${applyId}`).then((response) => response.data);
  },
  sendNboEvaluationRejectMail(state, { applyId, payload }) {
    return api.post(`/api/v1/b2b/communication/nbo-declined/${applyId}`, payload).then((response) => response.data);
  },
};
