<template>
  <div
    class="mdt-modal-processing mdt-modal-component flex-center"
    @wheel.stop
    @touchmove.stop
    @scroll.stop>
    <div class="mdt-modal-processing-wrapper">
      <div class="modal-processing-body">
        <vue-scroll>
          <div class="body-content">
            <slot />
          </div>
        </vue-scroll>
      </div>
      <div class="modal-processing-footer flex-center-h">
        <div
          v-if="type === 'downloading'"
          class="btn btn-danger stop-button"
          @click.stop="onDownloadStop">
          {{ 'general_stop' | translate }}
        </div>
        <slot name="button" />
      </div>
    </div>
  </div>
</template>

<script>
import { helpers } from '@/utility';

export default {
  name: 'MdtModalProcessing',
  props: {
    type: {
      type: String,
      default: 'downloading',
      validator(value) {
        return ['downloading', 'custom'].includes(value);
      },
    },
  },
  mounted() {
    // handle scrollbars on modal open
    helpers.handleScrollbarsOnModalOpen();
  },
  destroyed() {
    // handle scrollbars on modal close
    helpers.handleScrollbarsOnModalClose();
  },
  methods: {
    onDownloadStop() {
      this.$axiosRequest.cancel('mdtDownload');
      this.$emit('stopDownloading');
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-modal-processing {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  color: $color-text-primary;
  background-color: rgba($color-text-primary-rgb, 0.7);
  z-index: 1002;

  .mdt-modal-processing-wrapper {
    max-width: 420px;
    min-width: 220px;
    background-color: $color-back-primary;
    box-shadow: 0 3px 30px #00000040;
    border-radius: 8px;
  }

  .modal-processing-body {
    padding: 30px 30px 0 30px;
  }

  .modal-processing-footer {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: $modal-footer-height;
    margin-top: 1px;
    padding: 0 30px;
    background-color: $color-back-primary;
    border-radius: 8px;

    &.space-between {
      justify-content: space-between;

      ::v-deep .btn:first-child {
        margin-left: 0;
      }
    }

    .btn {
      width: 100%;
      justify-content: center;
    }
  }
}
</style>
